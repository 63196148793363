import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import {
  Column, DownloadButton, DownloadButtonProps, ExportApiReportFormatters,
} from '../../../ovComponents/3-pattern';
import { FETCH_TRANSFERS_QUERY } from './oneoffTransfersTable';
import { UserContext, usePermissions } from '../../../providers/userContextProvider';
import { FETCH_SCHEDULED_TRANSFERS_QUERY } from './scheduledTransfersTable';
import { useClientContext } from '../../../pages/client';
import { useHouseholdContext } from '../../../pages/household';
import { useCorporateContext } from '../../../pages/corporation';

const DownloadTransfers = ({ tab, filter, queryFilter }: { tab: 'oneoff' | 'scheduled'; filter: any; queryFilter: any }) => {
  const { t } = useTranslation(['components']);
  const { permissions } = usePermissions();
  const clientContext = useClientContext();
  const householdContext = useHouseholdContext();
  const corporateContext = useCorporateContext();
  const { activeOrganization } = useContext(UserContext);

  const isCanReadFirstOrLastNames = permissions.includes('read:client_low_risk_pii');

  const oneoffColumns: Column[] = [
    { gqlAlias: 'subAccount.account.id', header: t('activityTable.account') },
    ...((isCanReadFirstOrLastNames && [{ gqlAlias: 'subAccount.account.user.firstName', header: t('activityTable.userFirstName') }]) || []),
    ...((isCanReadFirstOrLastNames && [{ gqlAlias: 'subAccount.account.user.lastName', header: t('activityTable.userLastName') }]) || []),
    { gqlAlias: 'subAccount.account.type', header: t('activityTable.accountType') },
    { gqlAlias: 'type', header: t('activityTable.type') },
    { gqlAlias: 'source', header: t('activityTable.source') },
    { gqlAlias: 'amountCents', header: t('activityTable.amount'), formatter: ExportApiReportFormatters.DIVIDE_BY_100 },
    { gqlAlias: 'state', header: t('activityTable.state') },
    { gqlAlias: 'isRecurring', header: t('activityTable.autoTransfer') },
    { gqlAlias: 'createdAt', header: t('activityTable.createdAt') },
  ];

  const additionalOneoffColumns: Column[] = [
    { gqlAlias: 'instantInvest', header: t('activityTable.instantInvest') },
    { gqlAlias: 'initiatedByRepresentative', header: t('activityTable.initiatedByRepresentative') },
    { gqlAlias: 'bankAccount.id', header: t('activityTable.bankAccount') },
    { gqlAlias: 'subAccount.id', header: t('activityTable.subAccount') },
    { gqlAlias: 'failReason', header: t('activityTable.failReason') },
    { gqlAlias: 'cancelReason', header: t('activityTable.cancelReason') },
    { gqlAlias: 'cancelReconciledReason', header: t('activityTable.cancelReconciledReason') },
    { gqlAlias: 'subAccount.account.user.id', header: t('activityTable.user') },
    { gqlAlias: 'subAccount.account.organization.id', header: t('activityTable.organization') },
  ];

  const scheduledColumns = [
    { gqlAlias: 'subAccount.account.user.id', header: t('activityTable.user') },
    ...((isCanReadFirstOrLastNames && [{ gqlAlias: 'subAccount.account.user.firstName', header: t('activityTable.userFirstName') }]) || []),
    ...((isCanReadFirstOrLastNames && [{ gqlAlias: 'subAccount.account.user.lastName', header: t('activityTable.userLastName') }]) || []),
    { gqlAlias: 'subAccount.account.type', header: t('activityTable.account') },
    { gqlAlias: 'type', header: t('activityTable.type') },
    { gqlAlias: 'bankAccount.name', header: t('activityTable.bankAccount') },
    { gqlAlias: 'amountCents', header: t('activityTable.amount'), formatter: ExportApiReportFormatters.DIVIDE_BY_100 },
    { gqlAlias: 'frequency', header: t('activityTable.source') },
    { gqlAlias: 'state', header: t('activityTable.state') },
    { gqlAlias: 'scheduledDate', header: t('activityTable.scheduledDate') },
  ];

  const additionalScheduledColumns = [
    { gqlAlias: 'endDate', header: t('activityTable.endDate') },
    { gqlAlias: 'instantInvest', header: t('activityTable.instantInvest') },
    { gqlAlias: 'subAccount.id', header: t('activityTable.subAccount') },
    { gqlAlias: 'toSubAccount.id', header: t('activityTable.toSubAccount') },
    { gqlAlias: 'transferAll', header: t('activityTable.transferAll') },
    { gqlAlias: 'withdrawalReason', header: t('activityTable.withdrawalReason') },
    { gqlAlias: 'withdrawalReasonDescription', header: t('activityTable.withdrawalReasonDescription') },
  ];

  const organizationId = (filter?.clientGroupId || filter?.userIds)
    ? (corporateContext?.orgSettings?.id ?? householdContext?.orgSettings?.id)
    : (clientContext?.orgSettings?.id ?? activeOrganization.id);

  const config: Record<string, DownloadButtonProps> = {
    oneoff: {
      gql: FETCH_TRANSFERS_QUERY(permissions),
      fileName: `${t('transfersTable.tableHeader')}_${dayjs().format('YYYY-MM-DD')}`,
      filter: { ...filter, organizationId: organizationId ?? activeOrganization.id },
      additionalFilter: queryFilter,
      queryPath: 'fetchTransfers',
      datasetPath: 'transfers',
      columns: oneoffColumns,
      additionalColumns: additionalOneoffColumns,
      sortField: 'createdAt',
      sortDesc: false,
      progressTitle: t('transfers.exportProgressTitleOneOff'),
    },
    scheduled: {
      gql: FETCH_SCHEDULED_TRANSFERS_QUERY(permissions),
      fileName: `${t('transfersTable.scheduleTableHeader')}_${dayjs().format('YYYY-MM-DD')}`,
      filter: { ...filter, organizationId: organizationId ?? activeOrganization.id },
      additionalFilter: queryFilter,
      queryPath: 'fetchScheduledTransfers',
      datasetPath: 'scheduledTransfers',
      columns: scheduledColumns,
      additionalColumns: additionalScheduledColumns,
      sortField: '',
      sortDesc: false,
      progressTitle: t('transfers.exportProgressTitleScheduled'),
    },
  };

  return (
    <DownloadButton
      configurable
      gql={config[tab].gql}
      fileName={config[tab].fileName}
      filter={config[tab].filter}
      additionalFilter={config[tab].additionalFilter}
      queryPath={config[tab].queryPath}
      datasetPath={config[tab].datasetPath}
      columns={config[tab].columns}
      additionalColumns={config[tab].additionalColumns}
      sortField={config[tab].sortField}
      sortDesc={config[tab].sortDesc}
      progressTitle={config[tab].progressTitle}
    />
  );
};

export default DownloadTransfers;
