import { useContext, useEffect, useState } from 'react';
import {
  gql, useLazyQuery, useMutation, useQuery,
} from '@apollo/client';
import { AssignPortfolioToSubAccountVisual } from './assignPortfolioToSubAccount.visual';
import { WorkflowContext } from '../../workflowCompletion';
import { UserContext } from '../../../../../providers/userContextProvider';

const AVAILABLE_THEMES = `#graphql
  availableThemes {
    id
    key
    translatedName { en fr}
    translatedDescription { en fr}
  }
`;

const SUGGESTED_PRODUCT = `#graphql
  id
  translatedName { en fr}
  url
  children {
    id
    percentage
    financialProduct {
      id
      translatedName { en fr}
      primaryAssetClass {
        id
        translatedName { en fr}
        translatedDescription { en fr}
        key
      }
      secondaryAssetClass {
        id
        translatedName { en fr}
        translatedDescription { en fr}
        key
      }
    }
  }
`;

const UPDATE_SUB_ACCOUNT = gql`
  mutation updateSubAccount($input: UpdateSubAccountInput!) {
    updateSubAccount(input: $input) {
      subAccount {
        id
        suggestedFinancialProduct {
          ${SUGGESTED_PRODUCT}
        }
      }
    }
  }
`;

const UPDATE_GOAL = gql`
  mutation updateGoal($input: UpdateGoalInput!) {
    updateGoal(input: $input) {
      goal {
        id
        suitabilityScore
        suggestedFinancialProduct {
          ${SUGGESTED_PRODUCT}
        }
      }
    }
  }
`;

const FETCH_SUB_ACCOUNT = gql`
  query fetchSubAccount($subAccountId: ObjectID!) {
    fetchSubAccount(subAccountId: $subAccountId) {
      subAccount {
        id
        ${'' /* custodianSuitabilityScore */}
        ${AVAILABLE_THEMES}
        suggestedFinancialProduct {
          ${SUGGESTED_PRODUCT}
        }
      }
    }
  }
`;

const FETCH_GOAL = gql`
  query fetchGoal($goalId: ObjectID!) {
    fetchGoal(goalId: $goalId) {
      goal {
        id
        suitabilityScore
        ${AVAILABLE_THEMES}
        suggestedFinancialProduct {
          ${SUGGESTED_PRODUCT}
        }
      }
    }
  }
`;

const SUGGEST_PORTFOLIO = gql`
  query suggestPortfolio($suitabilityScore: Int!, $organizationId: ObjectID!, $themeId: ObjectID) {
    suggestFinancialProduct(input: {
      suitabilityScore: $suitabilityScore
      organizationId: $organizationId
      themeId: $themeId
    }) {
      financialProduct {
        ${SUGGESTED_PRODUCT}
      }
    }
  }
`;

const MOCK_CUSTODIAN_SUITABILITY_SCORE = 10;

export const AssignPortfolioToSubAccount = ({
  options, stepLoading, workflowCompletion, onNext,
}: { options: any, userId: string, onNext: () => void, stepLoading: boolean, workflowCompletion?: any, }) => {
  const { activeOrganization } = useContext(UserContext);
  const { workflowData } = useContext(WorkflowContext);
  const [objectData, setObjectData] = useState<any>({
    themeId: null,
    financialProductId: null,
    availableThemes: [],
    suggestFinancialProduct: null,
  });
  const [suggestedFinancialProduct, setSuggestedFinancialProduct] = useState<any>(null);

  const setPortfolioOnGoal = options.setPortfolioOnGoal && activeOrganization?.allowPortfolioPerGoal;
  const useExternalSuitabilityScore = options.useExternalSuitabilityScore && process.env.REACT_APP_ENV !== 'production';

  const { data } = useQuery(FETCH_SUB_ACCOUNT, {
    variables: { subAccountId: workflowData.currentSubAccountId },
    skip: setPortfolioOnGoal,
  });

  const { data: goalData } = useQuery(FETCH_GOAL, {
    variables: { goalId: workflowData.currentGoalId },
    skip: !setPortfolioOnGoal,
  });

  const [suggestPortfolio] = useLazyQuery(SUGGEST_PORTFOLIO);

  useEffect(() => {
    if (data) {
      setObjectData({
        ...objectData,
        availableThemes: data?.fetchSubAccount.subAccount.availableThemes,
        custodianSuitabilityScore: MOCK_CUSTODIAN_SUITABILITY_SCORE, // data?.fetchSubAccount?.subAccount?.custodianSuitabilityScore,
        suggestFinancialProduct: data?.fetchSubAccount.subAccount.suggestedFinancialProduct,
      });
    }
    // eslint-disable-next-line
  }, [data, setObjectData]);

  useEffect(() => {
    if (goalData) {
      setObjectData({
        ...objectData,
        suitabilityScore: goalData?.fetchGoal.goal.suitabilityScore,
        custodianSuitabilityScore: MOCK_CUSTODIAN_SUITABILITY_SCORE, // goalData?.fetchGoal.goal.custodianSuitabilityScore
        availableThemes: goalData?.fetchGoal.goal.availableThemes,
        suggestFinancialProduct: goalData?.fetchGoal.goal.suggestedFinancialProduct,
      });
    }
    // eslint-disable-next-line
  }, [goalData, setObjectData]);

  const [updateSubAccount, { loading }] = useMutation(UPDATE_SUB_ACCOUNT);
  const [updateGoal, { loading: goalLoading }] = useMutation(UPDATE_GOAL);

  useEffect(() => {
    if (objectData.themeId) {
      if (setPortfolioOnGoal || useExternalSuitabilityScore) {
        suggestPortfolio({
          variables: {
            suitabilityScore: useExternalSuitabilityScore ? objectData.custodianSuitabilityScore : objectData.suitabilityScore,
            organizationId: activeOrganization.id,
            themeId: objectData.themeId,
          },
          onCompleted: (d: any) => {
            setSuggestedFinancialProduct(d.suggestFinancialProduct.financialProduct);
          },
        });
      } else {
        if (workflowData.currentSubAccountId && workflowData.currentGoalId) {
          updateSubAccount({
            onCompleted: (d: any) => {
              setSuggestedFinancialProduct(d.updateSubAccount.subAccount.suggestedFinancialProduct);
            },
            variables: {
              input: {
                subAccountId: workflowData.currentSubAccountId,
                themeId: objectData.themeId || undefined,
              },
            },
          });
        }

        if (workflowData.currentGoalId && !workflowData.currentSubAccountId) {
          updateGoal({
            onCompleted: (d: any) => {
              setSuggestedFinancialProduct(d.updateGoal.goal.suggestedFinancialProduct);
            },
            variables: {
              input: {
                goalId: workflowData.currentGoalId,
                themeId: objectData.themeId || undefined,
              },
            },
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [objectData.themeId, updateSubAccount, suggestPortfolio, workflowData.currentSubAccountId]);

  const setPortfolio = async () => {
    if (setPortfolioOnGoal) {
      if (workflowData.currentGoalId) {
        await updateGoal({
          variables: {
            input: {
              goalId: workflowData.currentGoalId,
              financialProductId: suggestedFinancialProduct.id,
            },
          },
        });
      }
      if (workflowData.currentSubAccountId) {
        await updateSubAccount({
          variables: {
            input: {
              subAccountId: workflowData.currentSubAccountId,
              skipIPS: true,
            },
          },
        });
      }
    } else {
      await updateSubAccount({
        variables: {
          input: {
            subAccountId: workflowData.currentSubAccountId,
            financialProductId: suggestedFinancialProduct.id,
          },
        },
      });
    }
    onNext();
  };

  return (
    <AssignPortfolioToSubAccountVisual
      options={options}
      objectData={objectData}
      suggestedFinancialProduct={suggestedFinancialProduct}
      updateObject={setObjectData}
      continueFunc={setPortfolio}
      loading={loading || stepLoading || goalLoading}
      workflowCompletion={workflowCompletion}
    />
  );
};

export default AssignPortfolioToSubAccount;
