import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import { Box, Typography } from '../../../../../1-primative';
import { CompletionBox } from '../../../../../3-pattern/completionBox/completionBox';
import { useThemeTokens } from '../../../../../../providers/themeTokenProvider';
import { PaymentInstructionModal } from './paymentInstructionModal';
import { EmptyState } from '../../../../../2-component';

const formatCurrency = (value: number, currency = 'USD', locale = 'en-US') => new Intl.NumberFormat(locale, {
  style: 'currency',
  currency,
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}).format(value);

export const TRANSITION_SCHEDULED_INCOME_FUND_TRANSFER = gql`
  mutation transitionScheduledIncomeFundTransfer($input: TransitionScheduledIncomeFundTransferInput!) {
    transitionScheduledIncomeFundTransfer(input: $input) {
      scheduledIncomeFundTransfer { id }
    }
  }
`;

export const PaymentInstruction = ({
  account, refetch, updateMode = false, editable = true, userId, fundTitle,
}: {
  account: any, userId: string, refetch?: () => void, updateAccount?: any, updateMode?: boolean, editable?: boolean,
  fundTitle?: string,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [action, setAction] = useState<'create' | 'edit'>('create');
  const { t } = useTranslation(['affiliationTypes', 'client']);
  const { sys } = useThemeTokens();

  const hasScheduledIncomeFundTransfer = account.scheduledIncomeFundTransfer && account.scheduledIncomeFundTransfer.state !== 'ARCHIVED';
  const hasCustomAmount = !!account.scheduledIncomeFundTransfer?.annualAmountCents && account.scheduledIncomeFundTransfer?.annualAmountCents > 0;

  const [transitionScheduledIncomeFundTransfer] = useMutation(TRANSITION_SCHEDULED_INCOME_FUND_TRANSFER, {
    variables: {
      input: { scheduledIncomeFundTransferId: account.scheduledIncomeFundTransfer?.id, transition: 'archive' },
    },
    onCompleted: refetch,
  });

  if (!editable && !hasScheduledIncomeFundTransfer) {
    return (
      <Box mb={2}>
        <EmptyState>
          <Typography variant='bodyLarge'>{t('noLinkedAffiliate', { type: t('paymentInstruction:title') })}</Typography>
        </EmptyState>
      </Box>
    );
  }

  return (
    <>
      <CompletionBox
        variant={updateMode ? 'view' : 'edit'}
        state={hasScheduledIncomeFundTransfer ? 'complete' : 'todo'}
        edittable={editable}
        title={t('paymentInstruction:title')}
        onEdit={() => {
          setAction('edit');
          setOpen(true);
        }}
        onRemove={() => transitionScheduledIncomeFundTransfer()}
        onAdd={() => {
          setAction('create');
          setOpen(true);
        }}
      >
        {hasScheduledIncomeFundTransfer ? (
          <>
            <Typography variant='bodyMedium'>
              <span style={{ color: sys.color.onSurfaceVariant, marginRight: sys.spacing.sm }}>{t('paymentInstruction:totalAnnualWithdrawal')}</span>
              <b>{
                !hasCustomAmount ? t('paymentInstruction:modal.totalAnnualWithdrawalMinimumAmountOption') : t('paymentInstruction:modal.totalAnnualWithdrawalCustomAmountOption')
              }
              </b>
            </Typography>
            {hasCustomAmount && (
              <Typography variant='bodyMedium'>
                <span style={{ color: sys.color.onSurfaceVariant, marginRight: sys.spacing.sm }}>{t('paymentInstruction:amount')}</span>
                <b>{formatCurrency(account.scheduledIncomeFundTransfer.annualAmountCents / 100)}</b>
              </Typography>
            )}
            <Typography variant='bodyMedium'>
              <span style={{ color: sys.color.onSurfaceVariant, marginRight: sys.spacing.sm }}>{t('paymentInstruction:paymentFrequency')}</span>
              <b>{t(`paymentInstruction:modal.${account.scheduledIncomeFundTransfer.frequency}`)}</b>
            </Typography>
            <Typography variant='bodyMedium'>
              <span style={{ color: sys.color.onSurfaceVariant, marginRight: sys.spacing.sm }}>{t('paymentInstruction:startDate')}</span>
              <b>{account.scheduledIncomeFundTransfer.scheduledDate}</b>
            </Typography>
            <Typography variant='bodyMedium'>
              <span style={{ color: sys.color.onSurfaceVariant, marginRight: sys.spacing.sm }}>{t('paymentInstruction:depositTo')}</span>
              <b>{` ${account.scheduledIncomeFundTransfer.bankAccount.name} ${account.scheduledIncomeFundTransfer.bankAccount.bankAccountNumber}`}</b>
            </Typography>
          </>
        ) : (
          <Typography variant='bodyMedium'>{t('paymentInstruction:body', { fundPrefix: (fundTitle ?? account.type) })}</Typography>
        )}
      </CompletionBox>
      <PaymentInstructionModal
        data-testid="payment-instruction-modal"
        account={account}
        open={open}
        setOpen={setOpen}
        userId={userId}
        refetch={refetch}
        action={action}
        fundTitle={fundTitle ?? account.type}
      />
    </>
  );
};
