import { useState } from 'react';
import Filter from '@mui/icons-material/FilterList';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog, DialogContent, DialogFooter, DialogTitle, IconButton,
} from '../../2-component';
import { Box } from '../../1-primative';
import { useThemeTokens } from '../../../providers/themeTokenProvider';

export const FilterModal = ({
  children,
  filterExists,
  sx = {},
  title,
  onConfirm,
  onClose,
  disabled,
  testIds,
}: {
  children: any;
  filterExists?: boolean;
  sx?: any;
  title?: string;
  onConfirm?: () => void;
  onClose?: () => void;
  disabled?: boolean;
  testIds?: {
    dialogOpenButton?: string;
    dialogSubmitButton?: string;
  };
}) => {
  const { t } = useTranslation(['components', 'shared']);
  const [open, setOpen] = useState(false);
  const { sys } = useThemeTokens();

  const onCloseClick = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };
  const onConfirmClick = () => {
    setOpen(false);
    if (onConfirm) {
      onConfirm();
    }
  };

  return (
    <>
      <Box position='relative' sx={sx}>
        <IconButton onClick={() => setOpen(true)} label={t('filter')} data-testid={testIds?.dialogOpenButton}>
          <Filter />
        </IconButton>
        {filterExists && (
          <Box
            sx={{
              position: 'absolute',
              top: '8px',
              right: '8px',
              height: '8px',
              width: '8px',
              borderRadius: '50%',
              backgroundColor: sys.color.negative,
            }}
          />
        )}
      </Box>
      <Dialog open={open} onClose={onCloseClick} fullWidth maxWidth='xs'>
        <DialogTitle onClose={onCloseClick}>{title ?? t('filter')}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogFooter>
          <Box display='flex' justifyContent='flex-end'>
            <Button disabled={disabled} label={t('shared:done')} onClick={onConfirmClick} dataTestId={testIds?.dialogSubmitButton} />
          </Box>
        </DialogFooter>
      </Dialog>
    </>
  );
};
