import {
  ListItem, TextField,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateNewModal from '../../../components/modals/createNewModal';
import { NotificationChannel, TranslatedString } from '../../../interfaces';
import NotificationChannelsDefinitions from './notificationChannelsDefinitions';
import { defaultNotificationChannels } from './notificationGroups';

const CREATE_WORKFLOW = gql`
  mutation createNotificationGroup($input: CreateNotificationGroupInput!) {
    createNotificationGroup(input: $input) {
      notificationGroup {
        id
      }
    }
  }
`;

export interface NotificationGroupInput {
  name: TranslatedString,
  notificationChannels: NotificationChannel[],
  organizationId: string
}

const NewNotificationGroup = ({ afterCreate, defaultOrg }: { afterCreate: () => void, defaultOrg?: string }) => {
  const { t } = useTranslation(['orgSettings']);
  const [notificationGroup, setNotificationGroup] = useState<NotificationGroupInput>({
    name: { en: '' },
    organizationId: defaultOrg ?? '',
    notificationChannels: defaultNotificationChannels,
  });

  const [createNotificationGroup, { loading }] = useMutation(CREATE_WORKFLOW, {
    variables: {
      input: notificationGroup,
    },
  });

  const create = async (event: any) => {
    await createNotificationGroup();
    afterCreate();
  };
  const disabled = !notificationGroup.name || !notificationGroup.organizationId;
  return (
    <CreateNewModal loading={loading} disabled={disabled} title={t('notificationGroupModal.title')} onSubmit={create} sx={{ float: 'right', m: 1 }} maxWidth='xs'>
      <ListItem>
        <TextField
          fullWidth
          label={t('notificationGroupModal.name')}
          value={notificationGroup.name.en}
          onChange={(e) => setNotificationGroup({ ...notificationGroup, name: { en: e.target.value as string } })} />
      </ListItem>
      {notificationGroup.notificationChannels.map((notificationChannel, index) => (
        <ListItem key={notificationChannel.type} sx={{ mt: 1 }}>
          <NotificationChannelsDefinitions
            index={index}
            notificationChannel={notificationChannel}
            notificationObject={notificationGroup}
            setNotificationObject={setNotificationGroup}
          />
        </ListItem>
      ))}
    </CreateNewModal>
  );
};

export default NewNotificationGroup;
