import {
  Box, Card, CircularProgress, Tab, Tabs,
} from '@mui/material';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isNull } from 'lodash/fp';
import { ActionContext } from 'ovComponents';
import { AddClientToHouseholdWizard } from '../../components/wizards/addClientToHousehold/wizard';
import { generateClientNameString } from '../../util';
import { UserContext, usePermissions } from '../../providers/userContextProvider';
import { ClientSummary } from '../../components/layout/client/clientSummary';
import { usePageState } from '../../util/usePageState';
import { ClientOverview } from './components/clientOverview';
import Documents from './components/documents';
import Statements from './components/statements';
import { TransfersTable } from '../../components/tables/transfersTable/transfersTable';
import TradesTable from '../../components/tables/tradesTable/tradesTable';
import TransactionsTable from '../../components/tables/transactionsTable/transactionsTable';
import Notes from '../../components/notes';
import { NoteObjectTypes } from '../../interfaces/note';
import AuditLog from '../../components/tables/auditLog';
import { TabPanel } from '../../components/tabs/ovTabs';
import ClientDetails from './components/clientDetails';
import {
  Organization, TaxIdTypes, User,
} from '../../interfaces';
import { ovAnalyticsEvents } from '../../util/analytics/analytics';
import { ClientContext, FETCH_USER } from '.';
import { EditClientDetails } from './components/editClientDetails';
import { EditClientOrganization } from './components/editClientOrganization';
import { RelatedEntitiesTable } from './components/relatedEntitiesTable';
import { FETCH_USER_INCOMPLETE_DOCUMENTS, GET_ACTIVE_WORKFLOWS } from '../../ovComponents/5-page/viewPage/viewPage.queries';
import { AccessToEntity } from '../../ovComponents/4-module/widgets/accessToEntity/accessToEntity';
import { AnalyticsContext } from '../../providers/analyticsProvider';

const Client = () => {
  const { t } = useTranslation('client');
  const { permissions } = usePermissions();
  const { userId: paramsUserId } = useParams();
  const { activeEntity } = useContext(UserContext);
  const { sendAnalytic } = useContext(AnalyticsContext);
  const userId = paramsUserId ?? activeEntity?.id;
  const [editClientOpen, setEditClientOpen] = useState(false);
  const [editOrganizationOpen, setEditOrganizationOpen] = useState(false);
  const [tab, setTab] = usePageState('overview', 'tab');
  const [user, setUser] = useState<User>();
  const [selectedHighlight, setSelectedHighlight] = useState<string>('');
  const [addToHouseholdWizardOpen, setAddToHouseholdWizardOpen] = useState(false);

  const { data: workflowsData, refetch: workflowRefetch } = useQuery(GET_ACTIVE_WORKFLOWS, {
    variables: { userId },
    skip: !permissions.includes('read:workflow_completion'),
    fetchPolicy: 'no-cache',
  });

  const { data: incompleteData, refetch: incompleteRefetch } = useQuery(FETCH_USER_INCOMPLETE_DOCUMENTS, {
    variables: { userId },
    fetchPolicy: 'no-cache',
  });

  const { loading, refetch } = useQuery(FETCH_USER(permissions), {
    variables: {
      userId,
    },
    onCompleted(data) {
      setUser(taxIdSINBackwardsCompatibility(data?.fetchUser?.user));
    },
  });

  if (!userId) return <></>;

  if (loading || !user) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress sx={{ marginTop: 50 }} />
      </Box>
    );
  }

  const isIndividual = user.type === 'INDIVIDUAL';

  const handleAnalytics = (name: string): void => {
    switch (name) {
      case 'overview': {
        sendAnalytic(ovAnalyticsEvents.clientsProfileOverviewTabSelect);
        break;
      }
      case 'details': {
        sendAnalytic(ovAnalyticsEvents.clientsProfileDetailsTabSelect);
        break;
      }
      case 'documents': {
        sendAnalytic(ovAnalyticsEvents.clientsProfileDocumentsTabSelect);
        break;
      }
      case 'statements': {
        sendAnalytic(ovAnalyticsEvents.clientsProfileStatementsTabSelect);
        break;
      }
      case 'transfers': {
        sendAnalytic(ovAnalyticsEvents.clientsProfileTransfersTabSelect);
        break;
      }
      case 'trades': {
        sendAnalytic(ovAnalyticsEvents.clientsProfileTradesTabSelect);
        break;
      }
      case 'ledger': {
        sendAnalytic(ovAnalyticsEvents.clientsProfileLedgerTabSelect);
        break;
      }
      case 'notes': {
        sendAnalytic(ovAnalyticsEvents.clientsProfileNotesTabSelect);
        break;
      }
      case 'auditlog': {
        sendAnalytic(ovAnalyticsEvents.clientsProfileAuditLogTabSelect);
        break;
      }
      case 'access': {
        sendAnalytic(ovAnalyticsEvents.clientsAccessTabSelect);
        break;
      }
      default: {
        break;
      }
    }
  };

  const onHighlightClicked = (prop: string) => {
    if (prop !== 'organization') {
      setEditClientOpen(true);
      setSelectedHighlight(prop);
    } else {
      setEditOrganizationOpen(true);
    }
  };

  return (
    <ActionContext.Provider value={{
      refetch: () => {
        refetch();
        incompleteRefetch();
        workflowRefetch();
      },
      activeWorkflows: workflowsData?.fetchUser?.user?.activeWorkflowCompletions ?? [],
      unsignedDocuments: (!isNull(incompleteData?.fetchUser?.user?.completedAt) && (incompleteData?.fetchUser?.user?.allIncompleteFormAgreements?.length > 0)),
    }}>
      <ClientContext.Provider value={{
        id: userId,
        orgSettings: user.organization as Organization,
        totalMarketValueCents: user.statistics?.marketValueCents ?? 0,
      }}>
        <ClientSummary
          user={user}
          onHighlightClicked={onHighlightClicked}
          updateProfile={() => setEditClientOpen((prevState) => (!prevState))}
          updateOrganization={() => setEditOrganizationOpen(true)}
          addClientToHousehold={() => setAddToHouseholdWizardOpen(true)}
          refetch={refetch}
        />
        <Box mt={4} mb={2}>
          <Tabs
            value={tab}
            onChange={(event, newValue) => {
              handleAnalytics(newValue);
              setTab(newValue);
            }}
            variant="scrollable"
            scrollButtons="auto"
            sx={{ border: 0 }}
          >
            <Tab label={t('tabs.overview')} value="overview" />
            <Tab label={t('tabs.details')} value="details" />
            {!isIndividual
              && <Tab label={t('tabs.relationships')} value="relationships" />
            }
            {permissions.includes('read:files')
              && <Tab label={t('tabs.documents')} value="documents" />
            }
            {permissions.includes('read:account_statement_basic')
              && <Tab label={t('tabs.statements')} value="statements" />
            }
            {permissions.includes('read:transfer_basic')
              && <Tab label={t('tabs.transfers')} value="transfers" />
            }
            {permissions.includes('read:trade_basic')
              && <Tab label={t('tabs.trades')} value="trades" />
            }
            {permissions.includes('read:transaction')
              && <Tab label={t('tabs.ledger')} value="ledger" />
            }
            {permissions.includes('read:notes')
              && <Tab label={t('tabs.notes')} value="notes" />
            }
            {permissions.includes('read:audit_logs')
              && <Tab label={t('tabs.audit_log')} value="auditlog" />
            }
            {permissions.includes('read:organization_user_entities')
              && <Tab label={t('tabs.organization_user_entities')} value="access" />
            }
          </Tabs>
        </Box>
        <TabPanel value={tab} index="overview">
          <ClientOverview userId={userId} />
        </TabPanel>
        <TabPanel value={tab} index="details">
          <ClientDetails user={user} />
        </TabPanel>
        {!isIndividual && (
          <TabPanel value={tab} index="relationships">
            <RelatedEntitiesTable user={user} />
          </TabPanel>
        )}
        <TabPanel value={tab} index="documents">
          <Card>
            <Documents userId={userId} />
          </Card>
        </TabPanel>
        <TabPanel value={tab} index="statements">
          <Card>
            <Statements userIds={[userId]} />
          </Card>
        </TabPanel>
        <TabPanel value={tab} index="transfers">
          <Card>
            <TransfersTable
              filter={{ userId }}
              level='CLIENT'
            />
          </Card>
        </TabPanel>
        <TabPanel value={tab} index="trades">
          <Card>
            <TradesTable filter={{ userId }} />
          </Card>
        </TabPanel>
        <TabPanel value={tab} index="ledger">
          <Card>
            <TransactionsTable filter={{ userId }} />
          </Card>
        </TabPanel>
        <TabPanel value={tab} index="notes">
          <Card>
            <Notes objectId={userId} objectType={NoteObjectTypes.USER} />
          </Card>
        </TabPanel>
        <TabPanel value={tab} index="auditlog">
          <Card>
            <AuditLog objectId={userId} objectType="USER" />
          </Card>
        </TabPanel>
        <TabPanel value={tab} index="access">
          <Card>
            <AccessToEntity entityId={userId} options={{ showTitle: false, editAccessEnabled: true }} />
          </Card>
        </TabPanel>
        {
          editClientOpen && (
            <EditClientDetails
              userOriginal={user}
              selectedHighlight={selectedHighlight}
              onClose={() => {
                setSelectedHighlight('');
                setEditClientOpen(false);
              }}
            />
          )
        }
        {
          editOrganizationOpen && (
            <EditClientOrganization
              userId={user.id}
              userHouseholds={user.households}
              currentOrganization={user.organization}
              clientName={generateClientNameString(user)}
              onClose={() => {
                setEditOrganizationOpen(false);
              }}
            />
          )
        }
        {
          addToHouseholdWizardOpen && (
            <AddClientToHouseholdWizard
              userId={userId}
              userName={generateClientNameString(user)}
              handleClose={() => setAddToHouseholdWizardOpen(false)}
            />
          )
        }
      </ClientContext.Provider>
    </ActionContext.Provider>
  );
};

const taxIdSINBackwardsCompatibility = (user: User) => {
  if (!user.taxIdType && user.sinExists) {
    return {
      ...user,
      taxIdType: TaxIdTypes.SIN,
      taxIdExists: true,
    };
  }
  return user;
};

export default Client;
