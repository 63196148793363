import { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import { UserContext } from '../../../providers/userContextProvider';
import { Autocomplete } from '../../2-component';

export const FETCH_ROLES = gql`
  query fetchRoles($input: FetchRolesInput!) {
    fetchRoles(input: $input) {
      roles {
        id
        organization {
          id
        }
        translatedName {
          en
        }
        translatedDescription {
          en
        }
      }
    }
  }
`;

export const RoleSelect = ({
  selectedRoleIds, onChange, label, onBlur, error,
}: {
  selectedRoleIds: any[], onChange: (e: any) => void, label: string, onBlur?: () => void, error?: boolean,
}) => {
  const { activeOrganization } = useContext(UserContext);
  const roles = useQuery(FETCH_ROLES, {
    variables: {
      input: {
        filter: { organizationId: activeOrganization.id },
      },
    },
  });

  if (roles.loading) return <div></div>;

  const getRoleNames = (roleIds: any): string[] => roleIds.map((role: any) => {
    const fetchedRole = roles?.data?.fetchRoles?.roles?.find(
      (fetched: any) => fetched?.id === (role.id || role),
    );
    return { id: fetchedRole?.id, name: fetchedRole?.translatedName?.en };
  }).filter((obj: any) => Object.keys(obj).length !== 0);

  return (
    <Autocomplete
      label={label}
      options={roles?.data?.fetchRoles?.roles?.map((x: any) => ({
        key: x.id,
        label: x.translatedName.en,
      })) || []}
      value={getRoleNames(selectedRoleIds || [])?.map((x: any) => ({
        key: x.id,
        label: x.name,
      })) || []}
      isOptionEqualToValue={(option: any, value: any) => option.key === value.key}
      multiple
      fullWidth
      onChange={(e: React.SyntheticEvent, value: any) => onChange(value.map((x: any) => x.key))}
      onBlur={onBlur}
      error={error}
    />
  );
};
