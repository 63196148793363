import { useContext, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { StatusBar } from '../..';
import { Box, Grid, Typography } from '../../1-primative';
import { DowntimeSchedule, DowntimeSchedulePlatform, DowntimeScheduleState } from '../../../interfaces/downtimeSchedule';
import { UserContext } from '../../../providers/userContextProvider';

const FETCH_DOWNTIME_SCHEDULES = gql`
  query fetchDowntimeSchedules($input: FetchDowntimeSchedulesInput!) {
    fetchDowntimeSchedules(input: $input) {
      downtimeSchedules {
        id
        note
        startTime
        endTime
        excludeRoles
      }
    }
  }
`;

const UpcomingDowntimeSchedules = () => {
  const [upcomingDowntimeSchedules, setUpcomingDowntimeSchedules] = useState<DowntimeSchedule[]>([]);
  const { activeOrganization } = useContext(UserContext);

  const isUpcomingDowntimeSchedules = (downtimeSchedule: DowntimeSchedule) => {
    const startTime = dayjs(downtimeSchedule.startTime);
    const now = dayjs();
    const diff = startTime.diff(now, 'd');
    return diff <= 3 && diff > 0 && !!downtimeSchedule.note;
  };

  useQuery(FETCH_DOWNTIME_SCHEDULES, {
    variables: {
      input: {
        filter: {
          organization: activeOrganization.id,
          platforms: [DowntimeSchedulePlatform.WEB],
          state: DowntimeScheduleState.ACTIVE,
        },
      },
    },
    onCompleted(data) {
      if (data?.fetchDowntimeSchedules?.downtimeSchedules) {
        const downtimeSchedules = data.fetchDowntimeSchedules.downtimeSchedules.filter(isUpcomingDowntimeSchedules);

        setUpcomingDowntimeSchedules(downtimeSchedules);
      }
    },
  });

  if (upcomingDowntimeSchedules.length === 0) return <></>;

  return (
    <Grid container spacing={2} mb={1} mt={1}>
      {upcomingDowntimeSchedules.map((dsc) => (
        <Grid item xs={12} md={upcomingDowntimeSchedules.length === 1 ? 12 : 6} >
          <StatusBar>
            <Box display='flex' flexDirection='row' width='100%' justifyContent='space-between' alignItems='center'>
              <Box display='flex' alignItems='center' width={0} flex={1}>
                <Typography variant='bodyLarge' sx={{
                  textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', pr: 2, alignItems: 'center',
                }}>
                  <b>{dsc.note}</b>
                </Typography>
              </Box>
            </Box>
          </StatusBar>
        </Grid>
      ))}
    </Grid>
  );
};

export default UpcomingDowntimeSchedules;
