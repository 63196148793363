import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translateBackend } from 'assets/i18n/config';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import CustomFieldRenderer from 'ovComponents/3-pattern/customField/customFieldRenderer';
import { additionalInfo } from 'ovComponents/4-module/configurableOptionFields';
import InfoDialog from 'ovComponents/2-component/infoDialog/infoDialog';
import { kebabCase } from 'lodash';
import { invalidFields } from '../utils';
import {
  Typography, Form, Button, Box, Grid,
} from '../../../..';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';
import { User } from '../../../../../interfaces';
import {
  CharityRegistrationNumber, FieldComponent, InsiderSymbols, PoiUserField, RegulatedEntityDetails, ReportingIssuerDetails, SSSymbolsField,
} from './components/personsOfInterestFields';

export type UserWithCustomFields = Partial<User> & { customFields?: any[] };

export const PersonsOfInterestVisual = ({
  options,
  userData,
  loading,
  updateUser,
  continueFunc,
  activeCustomFields,
  workflowCompletion,
  grid = false,
  updateMode = false,
  ignoreCustomFieldTriggerRules,
}: {
  options: any;
  userData: UserWithCustomFields;
  loading: boolean;
  grid?: boolean;
  updateMode?: boolean;
  activeCustomFields?: string[];
  workflowCompletion?: any;
  updateUser: (user: UserWithCustomFields) => void;
  continueFunc: () => void;
  ignoreCustomFieldTriggerRules?: boolean;
}) => {
  const { sendAnalytic } = useContext(AnalyticsContext);
  const { t } = useTranslation('client');
  const [updated, setUpdated] = useState(false);
  const [focused, setFocused] = useState<string[]>([]);
  const [customOptionSelected, setCustomOptionSelected] = useState<any[]>([]);

  const update = (newValue: any) => {
    setUpdated(true);
    updateUser(newValue);
  };

  useEffect(() => {
    const customOptions: any[] = [];
    Object.keys(options).forEach((key: string) => {
      if (options[key]?.customField && options[key].enabled && activeCustomFields?.includes(key)) {
        customOptions.push(options[key]);
      }
    });
    setCustomOptionSelected(customOptions);
  }, [options, activeCustomFields]);

  const submit = () => {
    const fields = invalidFields(
      {
        ...options,
        ssSymbols: { ...options.ssSymbols, requiredIf: (data: any) => data?.isOwnerOfPublicCompany },
        insiderSymbols: { ...options.insiderSymbols, requiredIf: (data: any) => data?.memberOfIiroc },
        regulatorName: { ...options.regulatorName, requiredIf: (data: any) => data?.isEntityRegulated },
        firmNumber: { ...options.firmNumber, requiredIf: (data: any) => data?.isEntityRegulated },
        issuerName: { ...options.issuerName, requiredIf: (data: any) => data?.isReportingIssuer },
        issuerExchange: { ...options.issuerExchange, requiredIf: (data: any) => data?.isReportingIssuer },
        issuerTicker: { ...options.issuerTicker, requiredIf: (data: any) => data?.isReportingIssuer },
        isRegisteredWithCRA: { ...options.isRegisteredWithCRA, requiredIf: (data: any) => data?.isNonProfit },
        charityRegistrationNumber: { ...options.charityRegistrationNumber, requiredIf: (data: any) => data?.isRegisteredWithCRA },
        receivesDonationsFromPublic: { ...options.receivesDonationsFromPublic, requiredIf: (data: any) => data?.isNonProfit },
        otherPersonGuaranteeNames: { ...options.otherPersonGuaranteeNames, requiredIf: (data: any) => data?.hasGuarantor },
        tradingAuthorityName: { ...options.tradingAuthorityName, requiredIf: (data: any) => data?.tradingAuthorityOverAccountGranted },
        advisorInterestDetail: { ...options.advisorInterestDetail, requiredIf: (data: any) => data?.advisorInterest },
        investmentFirmTradeName: { ...options.investmentFirmTradeName, requiredIf: (data: any) => data?.investmentFirmTrade },
        thirdPartyName: { ...options.thirdPartyName, requiredIf: (data: any) => data?.isForThirdParty },
        pepReason: { ...options.pepReason, requiredIf: (data: any) => data?.politicallyExposedDomesticPerson || data?.politicallyExposedForeignPerson || data?.closeAssociateOfPEP },
      },
      userData,
    );
    setFocused(fields);

    if (fields.length === 0) {
      sendAnalytic(ovAnalyticsEvents.workflowsPersonOfInterestContinueButtonSelect, {
        workflowStepTitle: options?.title,
        workflowId: workflowCompletion?.workflow?.id,
        workflowName: workflowCompletion?.workflow?.name,
        activeWorkflowCompletionId: workflowCompletion?.id,
        objectId: workflowCompletion?.objectId,
        objectType: workflowCompletion?.objectType,
      });
      continueFunc();
    }
  };

  return (
    <Form testId={'poi-form'} onSubmit={submit}>
      {options?.title && (
        <Typography variant='displayLarge' sx={{ mt: 1 }}>
          {translateBackend(options?.title)}
          {additionalInfo(options?.title?.additionalInfo) && <InfoDialog information={additionalInfo(options?.title?.additionalInfo) ?? ''} />}
        </Typography>
      )}
      {options?.subtitle && (
        <Typography display='inline-flex' alignItems='end' variant='bodyLarge' component='div' sx={{ mb: 3, table: { width: '100%' } }}>
          <Box display='inline-block'>
            <ReactMarkdown linkTarget='_blank' remarkPlugins={[remarkGfm]}>
              {translateBackend(options?.subtitle)}
            </ReactMarkdown>
          </Box>
          {additionalInfo(options?.subtitle?.additionalInfo) && <InfoDialog information={additionalInfo(options?.subtitle?.additionalInfo) ?? ''} />}
        </Typography>
      )}

      <Grid container spacing={2}>
        <FieldComponent options={options} grid={grid} userData={userData} loading={loading} update={update} t={t} field='isEntityRegulated' nestedElements={['regulatorName', 'firmNumber']}>
          <RegulatedEntityDetails update={update} userData={userData} loading={loading} options={options} setFocused={setFocused} focused={focused} />
        </FieldComponent>

        <FieldComponent
          options={options}
          grid={grid}
          userData={userData}
          loading={loading}
          update={update}
          t={t}
          field='isReportingIssuer'
          nestedElements={['issuerName', 'issuerExchange', 'issuerTicker']}
        >
          <ReportingIssuerDetails update={update} userData={userData} loading={loading} options={options} setFocused={setFocused} focused={focused} />
        </FieldComponent>

        <FieldComponent
          options={options}
          grid={grid}
          userData={userData}
          loading={loading}
          update={update}
          t={t}
          field='isNonProfit'
          nestedElements={['charityRegistrationNumber', 'isRegisteredWithCRA', 'receivesDonationsFromPublic']}
        >
          {userData.isNonProfit && (
            <FieldComponent
              options={options}
              grid={grid}
              userData={userData}
              loading={loading}
              update={update}
              t={t}
              field='isRegisteredWithCRA'
              noGrid
              nestedElements={['charityRegistrationNumber']}
            />
          )}
          {userData.isNonProfit && <CharityRegistrationNumber update={update} userData={userData} loading={loading} options={options} setFocused={setFocused} focused={focused} />}
          {userData.isNonProfit && <FieldComponent options={options} grid={grid} userData={userData} loading={loading} update={update} t={t} field='receivesDonationsFromPublic' noGrid />}
        </FieldComponent>

        <FieldComponent options={options} grid={grid} userData={userData} loading={loading} update={update} t={t} field='isForThirdParty' nestedElements={['thirdPartyName']}>
          <PoiUserField
            update={update}
            userData={userData}
            loading={loading}
            options={options}
            setFocused={setFocused}
            focused={focused}
            conditionToRender={options?.thirdPartyName?.enabled && userData.isForThirdParty}
            field={'thirdPartyName'}
            testId={'third-party-name'}
            focusValue={'thirdPartyName'}
          />
        </FieldComponent>

        <FieldComponent options={options} grid={grid} userData={userData} loading={loading} update={update} t={t} field='isAFinancialEntity' />

        <FieldComponent options={options} grid={grid} userData={userData} loading={loading} update={update} t={t} field='isAffiliateOfFinancialEntity' />

        <FieldComponent options={options} grid={grid} userData={userData} loading={loading} update={update} t={t} field='instructionsFromFinancialEntity' />

        <FieldComponent options={options} grid={grid} userData={userData} loading={loading} update={update} t={t} field='isPublicBody' />

        <FieldComponent options={options} grid={grid} userData={userData} loading={loading} update={update} t={t} field='isLargeCorporation' />

        <FieldComponent options={options} grid={grid} userData={userData} loading={loading} update={update} t={t} field='powerOfAttorneyGranted' />

        <FieldComponent options={options} grid={grid} userData={userData} loading={loading} update={update} t={t} field='headOfInternationalOrganization' />

        <FieldComponent options={options} grid={grid} userData={userData} loading={loading} update={update} t={t} field='isOfficerOfPublicCompany' nestedElements={['ssSymbols']}>
          {options?.ssSymbols?.enabled && userData.isOfficerOfPublicCompany && (
            <SSSymbolsField update={update} userData={userData} loading={loading} options={options} setFocused={setFocused} focused={focused} />
          )}
        </FieldComponent>

        <FieldComponent
          options={options}
          grid={grid}
          userData={userData}
          loading={loading}
          update={update}
          t={t}
          field='isOwnerOfPublicCompany'
          nestedElements={['ssSymbols']}
          conditionToClear={!userData.isOfficerOfPublicCompany}
        >
          {options?.ssSymbols?.enabled && userData.isOwnerOfPublicCompany && !userData.isOfficerOfPublicCompany && (
            <SSSymbolsField update={update} userData={userData} loading={loading} options={options} setFocused={setFocused} focused={focused} />
          )}
        </FieldComponent>

        <FieldComponent options={options} grid={grid} userData={userData} loading={loading} update={update} t={t} field='isMemberOfIiroc' nestedElements={['insiderSymbols']}>
          <InsiderSymbols update={update} userData={userData} loading={loading} options={options} setFocused={setFocused} focused={focused} />
        </FieldComponent>

        <FieldComponent
          options={options}
          grid={grid}
          userData={userData}
          loading={loading}
          update={update}
          t={t}
          field='politicallyExposedForeignPerson'
          nestedElements={['pepReason']}
          conditionToClear={!userData.politicallyExposedDomesticPerson && !userData.closeAssociateOfPEP}
        >
          <PoiUserField
            update={update}
            userData={userData}
            loading={loading}
            options={options}
            setFocused={setFocused}
            focused={focused}
            conditionToRender={options?.pepReason?.enabled && userData.politicallyExposedForeignPerson}
            field={'pepReason'}
            testId={'pep-reason'}
            focusValue={'pepReason'}
          />
        </FieldComponent>

        <FieldComponent
          options={options}
          grid={grid}
          userData={userData}
          loading={loading}
          update={update}
          t={t}
          field='politicallyExposedDomesticPerson'
          nestedElements={['pepReason']}
          conditionToClear={!userData.politicallyExposedForeignPerson && !userData.closeAssociateOfPEP}
        >
          <PoiUserField
            update={update}
            userData={userData}
            loading={loading}
            options={options}
            setFocused={setFocused}
            focused={focused}
            conditionToRender={options?.pepReason?.enabled && userData.politicallyExposedDomesticPerson && !userData.politicallyExposedForeignPerson}
            field={'pepReason'}
            testId={'pep-reason'}
            focusValue={'pepReason'}
          />
        </FieldComponent>

        <FieldComponent
          options={options}
          grid={grid}
          userData={userData}
          loading={loading}
          update={update}
          t={t}
          field='closeAssociateOfPEP'
          nestedElements={['pepReason']}
          conditionToClear={!userData.politicallyExposedForeignPerson && !userData.politicallyExposedDomesticPerson}
        >
          <PoiUserField
            update={update}
            userData={userData}
            loading={loading}
            options={options}
            setFocused={setFocused}
            focused={focused}
            conditionToRender={options?.pepReason?.enabled && userData.closeAssociateOfPEP && !userData.politicallyExposedForeignPerson && !userData.politicallyExposedDomesticPerson}
            field={'pepReason'}
            testId={'pep-reason'}
            focusValue={'pepReason'}
          />
        </FieldComponent>

        <FieldComponent options={options} grid={grid} userData={userData} loading={loading} update={update} t={t} field='hasGuarantor' nestedElements={['otherPersonGuaranteeNames']}>
          <PoiUserField
            update={update}
            userData={userData}
            loading={loading}
            options={options}
            setFocused={setFocused}
            focused={focused}
            conditionToRender={options?.otherPersonGuaranteeNames?.enabled && userData.hasGuarantor}
            field={'otherPersonGuaranteeNames'}
            testId={'guarantee-name'}
            focusValue={'otherPersonGuaranteeNames'}
          />
        </FieldComponent>

        <FieldComponent options={options} grid={grid} userData={userData} loading={loading} update={update} t={t} field='tradingAuthorityOverAccountGranted' nestedElements={['tradingAuthorityName']}>
          <PoiUserField
            update={update}
            userData={userData}
            loading={loading}
            options={options}
            setFocused={setFocused}
            focused={focused}
            conditionToRender={options?.tradingAuthorityName?.enabled && userData.tradingAuthorityOverAccountGranted}
            field={'tradingAuthorityName'}
            testId={'trading-authority-name'}
            focusValue={'tradingAuthorityName'}
          />
        </FieldComponent>

        <FieldComponent options={options} grid={grid} userData={userData} loading={loading} update={update} t={t} field='discretionOverAccountGranted' />

        <FieldComponent options={options} grid={grid} userData={userData} loading={loading} update={update} t={t} field='advisorInterest' nestedElements={['advisorInterestDetail']}>
          <PoiUserField
            update={update}
            userData={userData}
            loading={loading}
            options={options}
            setFocused={setFocused}
            focused={focused}
            conditionToRender={options?.advisorInterestDetail?.enabled && userData.advisorInterest}
            field={'advisorInterestDetail'}
            testId={'advisor-interest-detail'}
            focusValue={'advisorInterestDetail'}
          />
        </FieldComponent>

        <FieldComponent options={options} grid={grid} userData={userData} loading={loading} update={update} t={t} field='investmentFirmTrade' nestedElements={['investmentFirmTradeName']}>
          <PoiUserField
            update={update}
            userData={userData}
            loading={loading}
            options={options}
            setFocused={setFocused}
            focused={focused}
            conditionToRender={options?.investmentFirmTradeName?.enabled && userData.investmentFirmTrade}
            field={'investmentFirmTradeName'}
            testId={'investment-firm-trade-name'}
            focusValue={'investmentFirmTradeName'}
          />
        </FieldComponent>

        <FieldComponent options={options} grid={grid} userData={userData} loading={loading} update={update} t={t} field='useBorrowedMoney' />

        <CustomFieldRenderer
          customOptions={customOptionSelected}
          update={update}
          customData={userData}
          focused={focused}
          setFocused={setFocused}
          loading={loading}
          ignoreCustomFieldTriggerRules={ignoreCustomFieldTriggerRules}
        />
      </Grid>

      <Box display='flex' justifyContent='end'>
        {(!updateMode || options.displayUpdateButton) && (
          <Button
            dataTestId={`persons-of-interest-${kebabCase(translateBackend(options.title))}-continue-button`}
            label={t(updateMode ? 'update' : 'continue')}
            disabled={loading || (!updated && updateMode)}
            sx={{ mt: 3, textAlign: 'center' }}
            type='submit'
          />
        )}
      </Box>
    </Form>
  );
};

export default PersonsOfInterestVisual;
