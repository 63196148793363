import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalToast } from '../../../../../../providers/globalToastProvider';
import { getSubAccountName, SubAccount, SubAccountStates } from '../../../../../../interfaces';
import { FETCH_GOAL } from '../editGoal';
import { ConfirmationModal } from '../../../../../3-pattern';
import { Typography } from '../../../../../1-primative';

const FETCH_SUBACCOUNTS_OF_GOAL = gql`
  query fetchSubAccounts($goalId: ObjectID!) {
    fetchSubAccounts(input: { filter: { goalId: $goalId }, pagination: { perPage: 1000 }}) {
      subAccounts {
        id
        state
        account {
          id
          type
          custodianAccountNumber
          householdClientGroup { id }
        }
        statistics { marketValueCents }
        financialProduct { translatedName { en } }
      }
    }
  }
`;

const DISSOCIATE_GOAL_FROM_HOUSEHOLD = gql`
  mutation dissociateGoalFromHousehold($goalId: ObjectID!) {
    updateGoal (
      input: {
        goalId: $goalId
        householdClientGroupId: null
      }
    ) {
      goal {
        user { id }
      }
    }
  }
`;

export const DissociateGoalFromHouseholdModal = ({
  handleClose, goalId, householdName,
}: { goalId: string, handleClose: () => void, householdName:string }) => {
  const { t } = useTranslation(['accountDetail']);
  const { showToast } = useGlobalToast();
  const navigate = useNavigate();
  const [householdSubAccounts, setHouseholdSubAccounts] = useState<SubAccount[]>([]);
  const { loading } = useQuery(FETCH_SUBACCOUNTS_OF_GOAL, {
    variables: { goalId },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setHouseholdSubAccounts(data.fetchSubAccounts.subAccounts.filter((subAccount: SubAccount) => (
        subAccount.state !== SubAccountStates.INACTIVE
      )));
    },
  });

  const dissociationCompleted = (data: { updateGoal: { goal: { user: { id: string } } } }): void => {
    handleClose();
    navigate(`/clients/${data.updateGoal.goal.user.id}/goal/${goalId}`);
    showToast({ message: t('goalsDetails:dissociateGoalDialog.success'), severity: 'success' });
  };

  const [dissociateAccountMutation] = useMutation(DISSOCIATE_GOAL_FROM_HOUSEHOLD, {
    variables: {
      goalId,
    },
    refetchQueries: [FETCH_GOAL],
    onCompleted: dissociationCompleted,
  });

  return (
    <ConfirmationModal
      title={t('goalsDetails:dissociateGoalDialog.title', { name: householdName })}
      open={true}
      maxWidth='sm'
      confirmButtonLabel={t('goalsDetails:dissociateGoalDialog.continueButton')}
      onConfirm={() => dissociateAccountMutation()}
      onCancel={handleClose}
      loading={loading}
    >
      <Typography>
        {loading && <Typography>{t('goalsDetails:dissociateGoalDialog.checkingSubaccounts')}...</Typography>}
        {householdSubAccounts.length > 0
        && <>
          {t('goalsDetails:dissociateGoalDialog.thereAreSubAccounts')}
          {householdSubAccounts.map((sa) => <Typography key={sa.id}>{getSubAccountName(sa)}</Typography>)}
        </>
        }
        {!loading && householdSubAccounts.length === 0 && <>
          {t('goalsDetails:dissociateGoalDialog.thereAreNoSubAccounts')}
        </>}
      </Typography>
    </ConfirmationModal>
  );
};
