import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogTitle } from '../../../2-component';
import { ConfirmationModal } from '../../../3-pattern/confirmationDialog/confirmationDialog';
import { Portfolio } from '../../../4-module/widgets/portfolio/portfolio';
import { PageObjectType } from '../..';
import { PortfolioReport } from '../../../../interfaces';

export const getObjectType = (typename: string): PageObjectType => (typename === PageObjectType.SUB_ACCOUNT.toString() ? PageObjectType.SUB_ACCOUNT : PageObjectType.GOAL);

export interface PortfolioAdjustmentActiveItem {
  id: string;
  type: PageObjectType;
  portfolioReportId?: string;
}

const PortfolioAdjustmentsModal = ({
  open,
  handleClose,
  item,
  onPortfolioRefresh,
}: {
  open: boolean;
  handleClose: () => void;
  item: PortfolioAdjustmentActiveItem;
  onPortfolioRefresh?: (portfolioReport: PortfolioReport) => void;
}) => {
  const { t } = useTranslation('components');
  const [exitConfirmationOpen, setExitConfirmationOpen] = useState<boolean>(false);
  const [isTradesUpdated, setIsTradesUpdated] = useState<boolean>(false);

  const close = () => {
    handleClose();
  };

  const onClose = () => {
    if (!isTradesUpdated) {
      close();
    } else {
      setExitConfirmationOpen(true);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth='xl' fullWidth>
        <DialogTitle onClose={onClose} testIds={{ dialogCloseButton: 'rebalance-report-table-portfolio-dialog-close-button' }}>
          {t('portfolio')}
        </DialogTitle>

        <DialogContent sx={{ '.MuiDialogContent-root&.MuiDialogContent-root': { p: '0px !important' } }}>
          <Portfolio id={item.id} type={item.type} portfolioReportId={item.portfolioReportId} setIsTradesUpdated={setIsTradesUpdated} onPortfolioRefresh={onPortfolioRefresh} hideTitle />
        </DialogContent>
      </Dialog>

      <ConfirmationModal
        open={exitConfirmationOpen}
        title={t('shared:wizardTitle', { wizardTitle: t('portfolio') })}
        bodyText={t('shared:wizardInterruptionMsg')}
        onConfirm={() => {
          setExitConfirmationOpen(false);
          close();
        }}
        onCancel={() => setExitConfirmationOpen(false)}
      />
    </>
  );
};

export default PortfolioAdjustmentsModal;
