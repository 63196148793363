import { ObjectType } from '../providers/statsHooks';

export const determinePageTypeFromRoute = (): ObjectType => {
  const segments = window.location.href.split('/');
  const householdsIndex = segments.lastIndexOf('households');
  const clientsIndex = segments.lastIndexOf('clients');

  if (householdsIndex === -1 && clientsIndex === -1) {
    return ObjectType.USER;
  }

  if (clientsIndex > householdsIndex) {
    return ObjectType.USER;
  }
  return ObjectType.CLIENT_GROUP;
};
