import { useTranslation } from 'react-i18next';
import LockIcon from '@mui/icons-material/Lock';
import { PortfolioSubAccount } from './interfaces';
import { TableHeadCell, Tooltip } from '../../../../2-component';
import { Box, Typography } from '../../../../1-primative';
import TaxRank from './taxRank';
import { TESTID_PORTFOLIO_WIDGET_HOLDINGS_TABLE_HEAD_CELL } from '../portfolio';

export const SubAccountTableHeadCell = ({ subAccount, index = 0 }: { subAccount: PortfolioSubAccount; index?: number }) => {
  const { t } = useTranslation(['components', 'accountTypes']);

  return (
    <TableHeadCell sx={{ whiteSpace: 'nowrap', width: `${Math.max(subAccount.name.length * 10, 150)}px`, minWidth: '65px' }} testId={TESTID_PORTFOLIO_WIDGET_HOLDINGS_TABLE_HEAD_CELL(index)}>
      <Box sx={{ width: `${Math.max(subAccount.name.length * 10, 150)}px`, minWith: '65px' }} display='flex' justifyContent='start' alignItems='center'>
        <TaxRank taxRank={subAccount.taxRank ?? 0} />

        <Typography sx={{ whiteSpace: 'nowrap' }}>{subAccount.name}</Typography>

        {!!subAccount.inactive && (
          <Tooltip title={t('portfolioTable.subAccountIsInactive')}>
            <LockIcon sx={{ verticalAlign: 'text-top', fontSize: '14px' }} />
          </Tooltip>
        )}
      </Box>
    </TableHeadCell>
  );
};
