import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';
import { isEmpty, omit } from 'lodash/fp';
import { Button, Form, TextField } from '../../2-component';
import { RoleProfileSelect } from '../../3-pattern/roleProfileSelect/roleProfileSelect';
import { NotificationGroupSelect } from '../../3-pattern/notificationGroupSelect/notificationGroupSelect';
import { Box } from '../../1-primative';
import { UserContext } from '../../../providers/userContextProvider';
import { defaultNotificationChannels, getNotificationChannels } from '../../../pages/orgSettings/components/notificationGroups';
import { NotificationChannel } from '../../../interfaces';
import NotificationChannelsDefinitions from '../../../pages/orgSettings/components/notificationChannelsDefinitions';

const FETCH_NOTIFICATION_DEFINITION = gql`
  query fetchNotificationDefinition($notificationDefinitionId: ObjectID!) {
    fetchNotificationDefinition(notificationDefinitionId: $notificationDefinitionId) {
      notificationDefinition {
        id
        name
        notificationGroup {
          id
          name { en fr }
        }
        titleTemplate {
          en fr
        }
        bodyTemplate {
          en fr
        }
        linkTemplate
        linkText {
          en fr
        }
        roleProfiles {
          id
        }
        triggerObjectType
        triggerEventType
        notificationChannels {
          type
          value
          setDefaultState
          allowEditByClient
        }
      }
    }
  }
`;

const CREATE_NOTIFICATION_DEFINITION = gql`
  mutation createNotificationDefinition($input: CreateNotificationDefinitionInput!) {
    createNotificationDefinition(input: $input) {
      notificationDefinition {
        id
      }
    }
  }
`;

const UPDATE_NOTIFICATION_DEFINITION = gql`
  mutation updateNotificationDefinition($input: UpdateNotificationDefinitionInput!) {
    updateNotificationDefinition(input: $input) {
      notificationDefinition {
        id
      }
    }
  }
`;

const DELETE_NOTIFICATION_DEFINITION = gql`
  mutation deleteNotificationDefinition($input: DeleteNotificationDefinitionInput!) {
    deleteNotificationDefinition(input: $input)
  }
`;

export const NotificationDefinitionForm = ({
  afterSubmit, action, triggerEvent = false, fetch = false, baseNotificationDefinition = {}, onRemove,
}: {
  afterSubmit: (id?: string) => void, action: 'create' | 'edit', triggerEvent?: boolean, fetch?: boolean, baseNotificationDefinition?: any, onRemove?: () => void,
}) => {
  const [notificationDefinition, setNotificationDefinition] = useState({
    ...baseNotificationDefinition,
    notificationChannels: baseNotificationDefinition?.notificationChannels ?? defaultNotificationChannels,
    roleProfileIds: baseNotificationDefinition.roleProfiles?.map((roleProfile: any) => roleProfile.id) || [],
  });
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation('orgSettings');

  useQuery(FETCH_NOTIFICATION_DEFINITION, {
    variables: {
      notificationDefinitionId: baseNotificationDefinition.id,
    },
    fetchPolicy: 'no-cache',
    skip: !fetch || !baseNotificationDefinition.id,
    onCompleted: (d: any) => {
      const fetchedNotificationDefinition = d?.fetchNotificationDefinition?.notificationDefinition;
      setNotificationDefinition({
        ...fetchedNotificationDefinition,
        notificationChannels: getNotificationChannels(fetchedNotificationDefinition.notificationChannels),
        roleProfileIds: fetchedNotificationDefinition.roleProfiles?.map((roleProfile: any) => roleProfile.id) || [],
      });
    },
  });

  const [createND] = useMutation(CREATE_NOTIFICATION_DEFINITION, {
    onCompleted: (d: any) => {
      afterSubmit(d.createNotificationDefinition.notificationDefinition.id);
    },
    variables: {
      input: {
        name: notificationDefinition.name,
        notificationGroupId: notificationDefinition.notificationGroup?.id,
        titleTemplate: omit(['__typename'], notificationDefinition.titleTemplate),
        bodyTemplate: omit(['__typename'], notificationDefinition.bodyTemplate),
        linkTemplate: notificationDefinition.linkTemplate,
        linkText: omit(['__typename'], notificationDefinition.linkText),
        roleProfileIds: notificationDefinition.roleProfileIds,
        triggerObjectType: 'User',
        triggerEventType: triggerEvent ? notificationDefinition.triggerEventType : undefined,
        organizationId: activeOrganization.id,
        notificationChannels: notificationDefinition.notificationChannels,
      },
    },
  });

  const [updateND] = useMutation(UPDATE_NOTIFICATION_DEFINITION, {
    onCompleted: (d: any) => {
      afterSubmit(d.updateNotificationDefinition.notificationDefinition.id);
    },
    variables: {
      input: {
        notificationDefinitionId: notificationDefinition.id,
        name: notificationDefinition.name,
        notificationGroupId: notificationDefinition.notificationGroup?.id,
        titleTemplate: omit(['__typename'], notificationDefinition.titleTemplate),
        bodyTemplate: omit(['__typename'], notificationDefinition.bodyTemplate),
        linkTemplate: notificationDefinition.linkTemplate,
        linkText: omit(['__typename'], notificationDefinition.linkText),
        roleProfileIds: notificationDefinition.roleProfileIds,
        triggerObjectType: 'User',
        triggerEventType: triggerEvent ? notificationDefinition.triggerEventType : undefined,
        notificationChannels: notificationDefinition.notificationChannels,
      },
    },
  });

  const [deleteND] = useMutation(DELETE_NOTIFICATION_DEFINITION, {
    onCompleted: () => {
      if (onRemove) onRemove();
    },
    variables: {
      input: {
        notificationDefinitionId: notificationDefinition.id,
      },
    },
  });

  const onSubmit = () => {
    if (action === 'create') {
      createND();
    } else {
      updateND();
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <TextField
        label={t('notificationDefinitionModal.name')}
        value={notificationDefinition.name}
        onChange={(e: any) => setNotificationDefinition({ ...notificationDefinition, name: e.target.value })}
        sx={{ mb: 2 }}
        fullWidth
      />
      <NotificationGroupSelect
        sx={{ mb: 2 }}
        label={t('notificationDefinitionModal.notificationGroup')}
        selectedNotificationGroup={notificationDefinition.notificationGroup}
        onNotificationGroupSelect={(value: any) => setNotificationDefinition({
          ...notificationDefinition,
          notificationGroup: value,
          ...(isEmpty(baseNotificationDefinition) ? { notificationChannels: getNotificationChannels(value.notificationChannels) } : {}),
        })}
      />
      <TextField
        label={t('notificationDefinitionModal.titleTemplate')}
        value={notificationDefinition.titleTemplate?.en}
        onChange={(e: any) => setNotificationDefinition({ ...notificationDefinition, titleTemplate: { en: e.target.value } })}
        sx={{ mb: 2 }}
        fullWidth
      />
      <TextField
        label={t('notificationDefinitionModal.bodyTemplate')}
        value={notificationDefinition.bodyTemplate?.en}
        onChange={(e: any) => setNotificationDefinition({ ...notificationDefinition, bodyTemplate: { en: e.target.value } })}
        sx={{ mb: 2 }}
        fullWidth
      />
      <TextField
        label={t('notificationDefinitionModal.linkTemplate')}
        value={notificationDefinition.linkTemplate}
        onChange={(e: any) => setNotificationDefinition({ ...notificationDefinition, linkTemplate: e.target.value })}
        sx={{ mb: 2 }}
        fullWidth
      />
      <TextField
        label={t('notificationDefinitionModal.linkText')}
        value={notificationDefinition.linkText?.en}
        onChange={(e: any) => setNotificationDefinition({ ...notificationDefinition, linkText: { en: e.target.value } })}
        sx={{ mb: 2 }}
        fullWidth
      />
      {triggerEvent && (
        <TextField
          label={t('notificationDefinitionModal.triggerEventType')}
          value={notificationDefinition.triggerEventType}
          onChange={(e: any) => setNotificationDefinition({ ...notificationDefinition, triggerEventType: e.target.value })}
          sx={{ mb: 2 }}
          fullWidth
        />
      )}
      <RoleProfileSelect
        label={t('notificationDefinitionModal.roleProfiles')}
        selectedRoleProfileIds={notificationDefinition.roleProfileIds || []}
        onChange={(value: any[]) => setNotificationDefinition({ ...notificationDefinition, roleProfileIds: value })}
      />
      {(notificationDefinition?.notificationChannels ?? defaultNotificationChannels).map((notificationChannel: NotificationChannel, index: number) => (
        <Box key={notificationChannel.type} sx={{ mt: 3 }}>
          <NotificationChannelsDefinitions
            index={index}
            notificationChannel={notificationChannel}
            notificationObject={notificationDefinition}
            setNotificationObject={setNotificationDefinition}
          />
        </Box>
      ))}
      <Box display='flex' justifyContent='flex-end' mt={2}>
        { action === 'edit' ? (
          <Button label={t('notificationDefinitionModal.remove')} onClick={deleteND} variant='tonal' color='destructive' sx={{ mr: 1 }} />
        ) : (<></>)}
        <Button type='submit' label={t(`notificationDefinitionModal.${action}`)} />
      </Box>
    </Form>
  );
};
