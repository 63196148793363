import { useContext, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { WorkflowContext } from '../../workflowCompletion';
import { CollectSignersVisual } from './collectSigners.visual';

const FETCH_USER_LATEST_INTEGRATION_FORM_AGREEMENT = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        firstName
        lastName
        primaryEmail
        latestFormAgreements {
          id
          type
          integrations {
            externalData
          }
        }
      }
    }
  }
`;

export const UPDATE_INTEGRATION_FORM_AGREEMENT = gql`
  mutation updateIntegrationFormAgreement($input: UpdateIntegrationFormAgreementInput!) {
    updateIntegrationFormAgreement(input: $input) {
      formAgreement {
        id
      }
    }
  }
`;

export const CollectSigners = ({
  options, userId, onNext, stepLoading,
}: { options: any; userId: any; onNext: () => void; stepLoading: boolean }) => {
  const { workflowData } = useContext(WorkflowContext);
  const [signingRoles, setSigningRoles] = useState<any>([]);
  const [integrationFormAgreement, setIntegrationFormAgreement] = useState<any>({});

  const { loading } = useQuery(FETCH_USER_LATEST_INTEGRATION_FORM_AGREEMENT, {
    variables: { userId: userId ?? workflowData.currentUserId },
    fetchPolicy: 'no-cache',
    onCompleted: async (data: any) => {
      const formAgreements = data?.fetchUser?.user?.latestFormAgreements?.filter((f: any) => f.type === 'INTEGRATION');
      if (formAgreements.length > 0) {
        setIntegrationFormAgreement(formAgreements[0]);

        const signerRoles = formAgreements[0]?.integrations[0]?.externalData?.signers?.filter((sr: any) => sr.roleType === 'SIGNER');

        const signerRolesWithAccountHolderInfo = signerRoles?.map((r: any) => {
          if (r.roleName === 'Account Holder 1') {
            return {
              ...r,
              firstName: data?.fetchUser?.user?.firstName,
              lastName: data?.fetchUser?.user?.lastName,
              email: data?.fetchUser?.user?.primaryEmail,
            };
          }
          return r;
        });
        setSigningRoles(signerRolesWithAccountHolderInfo);
      }
    },
  });

  return (
    <CollectSignersVisual
      options={options}
      integrationFormAgreementData={integrationFormAgreement}
      setIntegrationFormAgreementData={setIntegrationFormAgreement}
      signingRoles={signingRoles}
      loading={loading || stepLoading}
      onNext={onNext}
    />
  );
};
