import {
  ListItem, MenuItem, TextField,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { remove } from 'lodash/fp';
import TranslatableString from '../../../components/inputs/translatableString';
import FormModal from '../../../components/modals/formModal';
import OrganizationSelect from '../../../components/inputs/organizationSelect';
import RoleProfileSelect from '../../../components/inputs/roleProfileSelect';
import PermissionsSelect from '../../../components/inputs/permissionsSelect';
import AccessiblePagesSelect from '../../../components/inputs/accessiblePagesSelect';
import DeleteRole from './deleteRole';
import DashboardsSelect from '../../../components/inputs/dashboardsSelect';
import { NavigationStyles } from '../../../interfaces/role';
import { usePermissions } from '../../../providers/userContextProvider';
import PageConfigurationSelect from '../../../components/inputs/pageConfigurationSelect';
import { Switch } from '../../../ovComponents';
import { AuthenticationConnections } from '../../../interfaces';

type EditRoleProps = {
  afterUpdate: () => void;
  roleToUpdate: any,
  handleClose: () => void;
};

type RoleState = {
  id?: string;
  translatedName: { en: string; fr?: string };
  translatedDescription: { en: string; fr?: string };
  organization: { id: string };
  roleProfile?: { id: string };
  nonIndividualPageConfiguration?: { id: string };
  individualPageConfiguration?: { id: string };
  goalPageConfiguration?: { id: string };
  accountPageConfiguration?: { id: string };
  householdPageConfiguration?: { id: string };
  makeHouseholdDefaultView?: boolean;
  subAccountPageConfiguration?: { id: string };
  permissions: string[];
  accessiblePages: any[];
  dashboards: any[];
  navigationStyle: NavigationStyles,
  defaultAuthenticationConnection?: string,
};

const UPDATE_ROLE = gql`
mutation updateRole($input: UpdateRoleInput!) {
  updateRole(input: $input) {
    role {
      id
    }
  }
}
`;

const EditRole = ({ afterUpdate, roleToUpdate, handleClose }: EditRoleProps) => {
  const { t } = useTranslation(['orgSettings']);
  const { permissions } = usePermissions();
  const [role, setRole] = useState<RoleState>(roleToUpdate);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [dashboards, setDashboards] = useState<string[]>(roleToUpdate?.dashboards?.map((x: any) => x.id) || []);

  const [updateRole, { loading }] = useMutation(UPDATE_ROLE, {
    variables: {
      input: {
        roleId: role?.id,
        translatedName: { en: role?.translatedName?.en, fr: role?.translatedName?.fr },
        translatedDescription: { en: role?.translatedDescription?.en, fr: role?.translatedDescription?.fr },
        organizationId: role?.organization?.id,
        roleProfileId: role?.roleProfile?.id,
        permissions: role?.permissions,
        accessiblePages: role?.accessiblePages,
        navigationStyle: role?.navigationStyle,
        defaultAuthenticationConnection: role?.defaultAuthenticationConnection || null,
        nonIndividualPageConfigurationId: role?.nonIndividualPageConfiguration?.id || null,
        individualPageConfigurationId: role?.individualPageConfiguration?.id || null,
        goalPageConfigurationId: role?.goalPageConfiguration?.id || null,
        accountPageConfigurationId: role?.accountPageConfiguration?.id || null,
        householdPageConfigurationId: role?.householdPageConfiguration?.id || null,
        makeHouseholdDefaultView: role?.makeHouseholdDefaultView || null,
        subAccountPageConfigurationId: role?.subAccountPageConfiguration?.id || null,
        dashboards,
      },
    },
  });

  useEffect(() => {
    setRole(roleToUpdate);
    setDashboards(roleToUpdate?.dashboards?.map((x: any) => x.id) || []);
  }, [roleToUpdate]);

  const onDeleteMenuClick = () => {
    setDeleteDialogOpen(true);
  };

  const update = async (event: any) => {
    event.preventDefault();
    await updateRole();
    afterUpdate();
  };

  if (!role) {
    return <></>;
  }

  const menuItems = [
    <MenuItem key="deleteItem" onClick={onDeleteMenuClick} >{t('shared:delete')}</MenuItem>,
  ];

  const handleRoleProfileChange = (data: {
    id: string,
    permissions: string[],
    dashboards: string[],
    accessiblePages: string[],
    navigationStyle: NavigationStyles,
    nonIndividualPageConfigurationId: string,
    individualPageConfigurationId: string,
    goalPageConfigurationId: string,
    accountPageConfigurationId: string,
    householdPageConfigurationId: string,
    subAccountPageConfigurationId: string,

  }) => {
    setRole((prev) => ({
      ...prev,
      roleProfile: { id: data.id },
      permissions: data.permissions,
      accessiblePages: data.accessiblePages,
      navigationStyle: data.navigationStyle,
      nonIndividualPageConfigurationId: { id: data.nonIndividualPageConfigurationId },
      individualPageConfigurationId: { id: data.individualPageConfigurationId },
      goalPageConfigurationId: { id: data.goalPageConfigurationId },
      accountPageConfigurationId: { id: data.accountPageConfigurationId },
      householdPageConfigurationId: { id: data.householdPageConfigurationId },
      subAccountPageConfigurationId: { id: data.subAccountPageConfigurationId },
    }));
    setDashboards(data.dashboards.map((x: any) => x.id));
  };

  return (
    <>
      <FormModal
        key={role.id} loading={loading} title={t('roleModal.editTitle')} formButton={t('update')} onSubmit={update} open handleClose={handleClose} maxWidth='md' menuItems={menuItems}
      >
        <ListItem>
          <TranslatableString
            label={t('roleModal.name')}
            value={role.translatedName}
            onChange={(value) => setRole({ ...role, translatedName: value })} />
        </ListItem>
        <ListItem>
          <TranslatableString
            label={t('roleModal.description')}
            value={role.translatedDescription}
            onChange={(value) => setRole({ ...role, translatedDescription: value })} />
        </ListItem>
        <ListItem>
          <OrganizationSelect
            value={role.organization.id}
            label={t('roleModal.organization')}
            onChange={(event: any) => setRole({ ...role, organization: { id: event.target.value as string } })}
          />
        </ListItem>
        <ListItem>
          <RoleProfileSelect
            value={role?.roleProfile?.id}
            label={t('roleModal.roleProfile')}
            organizationId={role.organization.id}
            onChange={handleRoleProfileChange}
          />
        </ListItem>
        <ListItem>
          <AccessiblePagesSelect
            accessiblePages={role.accessiblePages}
            label={t('roleModal.accessiblePages')}
            onChange={(event: any) => setRole({ ...role, accessiblePages: event.target.value })}
          />
        </ListItem>
        <ListItem>
          <DashboardsSelect
            selectedDashboards={dashboards}
            label={t('roleModal.dashboards')}
            onChange={(event: any) => setDashboards(event.target.value)}
          />
        </ListItem>
        <ListItem>
          <TextField
            select
            value={role.navigationStyle ?? NavigationStyles.FULL}
            label={t('roleTable.navigationStyle')}
            sx={{ width: '100%' }}
            onChange={(event: any) => setRole({ ...role, navigationStyle: event.target.value })}
          >
            <MenuItem key='1' value={NavigationStyles.FULL}>{NavigationStyles.FULL}</MenuItem>
            <MenuItem key='2' value={NavigationStyles.SIMPLE}>{NavigationStyles.SIMPLE}</MenuItem>
          </TextField>
        </ListItem>
        <ListItem>
          <TextField
            select
            fullWidth
            value={role?.defaultAuthenticationConnection ?? 'NA'}
            label={t('organizationDetails.defaultAuthenticationConnectionTitle')}
            onChange={(e) => setRole({ ...role, defaultAuthenticationConnection: e.target.value === 'NA' ? undefined : e.target.value })}
          >
            <MenuItem key={'NA'} value={'NA'}>{t('roleModal.undefinedDefaultAuthenticationConnection')}</MenuItem>
            {Object.values(AuthenticationConnections).map((x: any) => (
              <MenuItem key={x} value={x}>
                {t(`organizationDetails.defaultAuthenticationConnectionOptions.${x}`)}
              </MenuItem>
            ))}
          </TextField>
        </ListItem>
        {
          permissions.includes('read:page_configurations') && (
            <>
              <ListItem>
                <PageConfigurationSelect
                  filter={{ type: 'NON_INDIVIDUAL' }}
                  value={role.nonIndividualPageConfiguration?.id as string}
                  label={t('roleModal.nonIndividualPageConfiguration')}
                  organizationId={role.organization.id}
                  onChange={(event: any) => setRole({ ...role, nonIndividualPageConfiguration: { id: event.target.value as string } })}
                />
              </ListItem>
              <ListItem>
                <PageConfigurationSelect
                  filter={{ type: 'INDIVIDUAL' }}
                  value={role.individualPageConfiguration?.id as string}
                  label={t('roleModal.individualPageConfiguration')}
                  organizationId={role.organization.id}
                  onChange={(event: any) => setRole({ ...role, individualPageConfiguration: { id: event.target.value as string } })}
                />
              </ListItem>
              <ListItem>
                <PageConfigurationSelect
                  filter={{ type: 'GOAL' }}
                  value={role.goalPageConfiguration?.id as string}
                  label={t('roleModal.goalPageConfiguration')}
                  organizationId={role.organization.id}
                  onChange={(event: any) => setRole({ ...role, goalPageConfiguration: { id: event.target.value as string } })}
                />
              </ListItem>
              <ListItem>
                <PageConfigurationSelect
                  filter={{ type: 'ACCOUNT' }}
                  value={role.accountPageConfiguration?.id as string}
                  label={t('roleModal.accountPageConfiguration')}
                  organizationId={role.organization.id}
                  onChange={(event: any) => setRole({ ...role, accountPageConfiguration: { id: event.target.value as string } })}
                />
              </ListItem>
              <ListItem>
                <PageConfigurationSelect
                  filter={{ type: 'SUB_ACCOUNT' }}
                  value={role.subAccountPageConfiguration?.id as string}
                  label={t('roleModal.subAccountPageConfiguration')}
                  organizationId={role.organization.id}
                  onChange={(event: any) => setRole({ ...role, subAccountPageConfiguration: { id: event.target.value as string } })}
                />
              </ListItem>
              <ListItem>
                <PageConfigurationSelect
                  filter={{ type: 'HOUSEHOLD' }}
                  value={role.householdPageConfiguration?.id as string}
                  label={t('roleModal.householdPageConfiguration')}
                  organizationId={role.organization.id}
                  onChange={(event: any) => setRole({ ...role, householdPageConfiguration: { id: event.target.value as string } })}
                />
              </ListItem>
              <ListItem>
                <Switch
                  label={t('roleModal.makeHouseholdDefaultView')}
                  checked={!!role.makeHouseholdDefaultView}
                  onChange={(e) => setRole({ ...role, makeHouseholdDefaultView: e })}
                />
              </ListItem>
            </>
          )
        }
        <ListItem>
          <PermissionsSelect
            permissions={role.permissions}
            onChange={(event: any) => {
              let p: any = [...role.permissions];
              if (event.target.checked) {
                p.push(event.target.value);
              } else {
                p = remove((x) => x === event.target.value, p);
              }
              setRole({ ...role, permissions: p });
            }} />
        </ListItem>
      </FormModal>
      <DeleteRole
        open={deleteDialogOpen}
        roleToDelete={role}
        afterDelete={() => {
          setDeleteDialogOpen(false);
          afterUpdate();
        }}
        handleClose={() => setDeleteDialogOpen(false)}
      />
    </>
  );
};

export default EditRole;
