import { PublicOrganization } from './organization';
import { TranslatedString } from './shared';

export enum DowntimeSchedulePlatform {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  WEB = 'WEB',
}

export enum DowntimeScheduleState {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  INACTIVE = 'INACTIVE',
}

export enum DowntimeScheduleTransitionStates {
  activate = 'activate',
  archive = 'archive',
  deactivate = 'deactivate',
}

export interface DowntimeSchedule {
  id: string,
  name: string,
  note?: string,
  platforms?: DowntimeSchedulePlatform[],
  startTime: Date,
  endTime?: Date,
  excludeRoles?: string[],
  helpLink?: string,
  state?: DowntimeScheduleState
  organization: PublicOrganization,
  translatedDescription?: TranslatedString,
  translatedTitle: TranslatedString,
}
