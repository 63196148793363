import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import { Add } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { useRef } from 'react';
import { CustomFieldConditionalRule, CustomFieldConditionalRulesComparisonTypes } from '../../../../interfaces/customField';
import {
  Autocomplete,
  Button,
  IconButton, InputAdornment, MenuItem, SelectField,
  TextField,
} from '../../../2-component';
import { Box } from '../../../1-primative';

const ACCOUNT_ATTRIBUTES = [
  'id',
  'type',
  'applyForGovFunds',
  'jurisdiction',
  'sourceOfFunds',
  'repCode.code',
  'user.physicalAddress.country',
  'user.physicalAddress.city',
  'user.physicalAddress.province',
  'user.physicalAddress.streetName',
  'user.physicalAddress.postal',
  'user.physicalAddress.unitNumber',
  'user.physicalAddress.houseNumber',
  'user.physicalAddress.neighborhood',
  'user.maritalStatus',
  'user.countryOfTaxResidence',
  'baseCurrency',
  'availableCurrencies',
];

const ENTITY_ATTRIBUTES = [
  'id',
  'firstName',
  'middleName',
  'lastName',
  'primaryEmail',
  'phone',
  'dateOfBirth',
  'language',
  'preferredMethodOfCommunication',
  'inProvinceSince',
  'gender',
  'physicalAddress.city',
  'physicalAddress.jurisdiction',
  'physicalAddress.streetName',
  'physicalAddress.postal',
  'physicalAddress.unitNumber',
  'physicalAddress.houseNumber',
  'physicalAddress.neighborhood',
  'physicalAddress.country',
];
const GOAL_ATTRIBUTES = [
  'id',
  'name',
  'timeHorizon',
  'targetAmountCents',
  'householdClientGroup.id',
  'householdClientGroup.name',
];
const SUB_ACCOUNT_ATTRIBUTES = [''];
const HOUSEHOLD_ATTRIBUTES = [''];

export const getAvailableAttributeOptions = (type: string) => {
  if (type === 'ACCOUNT') {
    return ACCOUNT_ATTRIBUTES;
  }
  if (type === 'ENTITY') {
    return ENTITY_ATTRIBUTES;
  }
  if (type === 'SUB_ACCOUNT') {
    return SUB_ACCOUNT_ATTRIBUTES;
  }
  if (type === 'GOAL') {
    return GOAL_ATTRIBUTES;
  }
  if (type === 'HOUSEHOLD') {
    return HOUSEHOLD_ATTRIBUTES;
  }
  return [];
};

const BuildCustomFieldRules = ({
  customField, setCustomField, disabled = false,
}: { customField: any; setCustomField: (customField: any) => void; disabled?: boolean, }) => {
  const { t } = useTranslation(['workflowCompletions']);
  const inputRef = useRef<HTMLInputElement>(null);

  let rules = customField.conditionalRules && customField.conditionalRules.length > 0
    ? customField.conditionalRules : [{ field: '', comparison: CustomFieldConditionalRulesComparisonTypes.EQUALS, value: '' }];
  const hasPendingLines = !!rules.find((rule: any) => !rule.field || !rule.value);

  return (
    <Box sx={{ mb: 1, width: '100%' }}>
      {rules.map((rule: any, i: number) => (
        <Box gap={2} key={i} display='flex' flexDirection='row' alignItems='end' marginBottom={2}>
          <Autocomplete
            data-testid='conditionalRulesField'
            freeSolo
            sx={{ flex: 1 }}
            disabled={disabled}
            infoTooltip={t('conditionFieldInfo')}
            options={getAvailableAttributeOptions(customField?.objectType) || []}
            fullWidth
            label={t('eventTriggerRule.field')}
            value={rule.field ?? ''}
            isOptionEqualToValue={(option: string, value: string) => option === value}
            onInputChange={async (e: React.SyntheticEvent, value: string) => {
              rules[i].field = value;
              setCustomField({ ...customField, conditionalRules: rules });
            }}
            onChange={(e: React.SyntheticEvent, value: string, reason: string) => {
              if (reason === 'clear') {
                rules[i].field = '';
              } else if (value) {
                rules[i].field = value;
                setCustomField({ ...customField, conditionalRules: rules });
              }
            }}
            componentsProps={{ popupIndicator: { sx: { display: 'none' } } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
              inputRef,
              disabled,
            }}
          />
          <SelectField
            label={t('eventTriggerRule.comparison')}
            value={rule.comparison || ''}
            fullWidth
            sx={{ flex: 1 }}
            onChange={(e: any) => {
              rules[i].comparison = e.target.value;
              setCustomField({ ...customField, conditionalRules: rules });
            }}
          >
            {Object.values(CustomFieldConditionalRulesComparisonTypes).map((comp) => (
              <MenuItem key={comp} value={comp}>
                {comp}
              </MenuItem>
            ))}
          </SelectField>
          <TextField
            label={t('eventTriggerRule.value')}
            value={rule.value ?? ''}
            fullWidth
            sx={{ flex: 1 }}
            onChange={(e: any) => {
              let expectedValue = e.target.value;
              /* if value input is a number and customField objectType included in the pre populated attribute list
                we are extracting the number value by removing decimal point
              */
              if (expectedValue && !isNaN(expectedValue) && getAvailableAttributeOptions(customField?.objectType).includes(rules[i]?.field)) {
                expectedValue = Number(expectedValue).toString();
              }
              rules[i].value = expectedValue;
              setCustomField({ ...customField, conditionalRules: rules });
            }}
          />
          {rules.length > 0 && !disabled && (
            <IconButton
              data-testid='edit-button'
              onClick={() => {
                rules = rules.filter((f: any, index: number) => index !== i);
                setCustomField({ ...customField, conditionalRules: rules });
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ))}
      <Button
        variant='tonal'
        leadingIcon={Add}
        label={t('eventTriggerRule.addNewRule')}
        disabled={hasPendingLines || disabled}
        onClick={() => {
          const newLine: CustomFieldConditionalRule = { field: '', comparison: CustomFieldConditionalRulesComparisonTypes.EQUALS, value: '' };
          rules = [...rules, newLine];
          setCustomField({ ...customField, conditionalRules: rules });
        }}
      />
    </Box>
  );
};

export default BuildCustomFieldRules;
