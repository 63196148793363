/* eslint-disable implicit-arrow-linebreak */
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Table,
  TableHead,
  TableBody,
  TextField,
  Typography,
  TableRow,
  TableCell,
  Chip,
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { FormTypes } from 'interfaces';
import { DateTime } from '../../../components/misc/dateTime/dateTime';
import { usePermissions } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import AccountLabel from './accountLabel';
import SubAccountLabel from './subAccountLabel';
import DocumentViewerModal from './documents/documentViewer';
import DocumentUpload from './documentUpload';
import { NoPermissionAlert } from '../../../components/misc/noPermissionAlert';
import GoalLabel from './goalLabel';
import ClientGroupLabel from './clientGroupLabel';
import { FileDocumentObjectTypes, FileDocumentTypes } from '../../../interfaces/fileDocument';

export const FETCH_DOCUMENTS = gql`
query fetchFileDocuments($filter: FileDocumentQueryFilter) {
  fetchFileDocuments(input:{
    filter: $filter
    pagination: {perPage: 1000}
  }) {
    fileDocuments {
      id type name fileName mediaType uploadedAt updatedAt
      objectType objectId
      sharedClient sharedCustodian
      creator { id }
      user { id firstName lastName }
    }
  }
}`;

export const FETCH_ALL_USER_AGREEMENTS = (permissions: string[]) => gql`
  query fetchAllUserAgreements($id: ObjectID!) {
    fetchUser(userId: $id) {
      user {
        id
        ${permissions.includes('read:client_low_risk_pii') ? 'firstName lastName' : ''}
        allLatestFormAgreements {
          id
          account { id }
          user { id }
          goal { id }
          subAccount { id }
          scheduledTransfer { id state frequency subAccount { id account { type } } }
          displayName
          type
          signedAt
          signedUrl
          templateUrl
          financialProduct { id }
        }
        allIncompleteFormAgreements {
          id
          account { id }
          user { id }
          goal { id }
          subAccount { id }
          scheduledTransfer { id state frequency subAccount { id account { type } } }
          translatedDisplayName { en }
          type
          changeType
          templateUrl
          isPrefilledPdfEnabled
          digitalSignatureEnabled
          minVersion
        }
      }
    }
  }
`;

export const FETCH_ACCOUNT_AGREEMENTS = gql`
  query fetchAccountAgreements($id: ObjectID!) {
    fetchAccount(accountId: $id) {
      account {
        id
        latestFormAgreements { id displayName type signedAt signedUrl templateUrl }
        incompleteFormAgreements { id translatedDisplayName{en} type changeType templateUrl isPrefilledPdfEnabled digitalSignatureEnabled minVersion }
      }
    }
  }
`;

export const FETCH_CLIENT_GROUP_AGREEMENTS = gql`
  query fetchClientGroupAgreements($id: ObjectID!) {
    fetchClientGroup(clientGroupId: $id) {
      clientGroup {
        id
        latestFormAgreements { id displayName type signedAt signedUrl templateUrl }
        incompleteFormAgreements { id translatedDisplayName{en} type changeType templateUrl isPrefilledPdfEnabled digitalSignatureEnabled minVersion }
      }
    }
  }
`;

// Ignoring eslint to align it with the objectType returned in document-service
// eslint-disable-next-line @typescript-eslint/naming-convention
type relatedObjectTypes = 'USER' | 'ACCOUNT' | 'SUBACCOUNT' | 'GOAL' | 'SCHEDULED_TRANSFER' | 'CLIENT_GROUP';

export interface CombinedDocumentAndAgreement {
  id: string,
  state: 'SAVED' | 'SIGNED' | 'PENDING',
  relatedObjectId: string,
  relatedObjectType: relatedObjectTypes,
  displayName: string,
  type: string,
  signedAt?: Date,
  signedUrl?: string,
  templateUrl?: string,
  isPrefilledPdfEnabled?: boolean,
  digitalSignatureEnabled?: boolean,
  name?: string,
  fileName?: string,
  mediaType?: string,
  uploadedAt?: Date,
  updatedAt?: Date,
  sharedClient?: boolean,
  sharedCustodian?: boolean,
  creatorId?: string,
  isFileDocument?: boolean,
  minVersion?: number,
}

export const getRelatedObjectTypeAndId = (object: any): {
  relatedObjectType?: relatedObjectTypes,
  relatedObjectId?: string,
} => {
  if (object.user?.id) return { relatedObjectType: 'USER', relatedObjectId: object.user?.id };
  if (object.account?.id) return { relatedObjectType: 'ACCOUNT', relatedObjectId: object.account?.id };
  if (object.subAccount?.id) return { relatedObjectType: 'SUBACCOUNT', relatedObjectId: object.subAccount?.id };
  if (object.goal?.id) return { relatedObjectType: 'GOAL', relatedObjectId: object.goal?.id };
  if (object.scheduledTransfer?.id) return { relatedObjectType: 'SCHEDULED_TRANSFER', relatedObjectId: object.scheduledTransfer?.id };
  return {};
};

export const getDocumentName = (fileItem: any) => {
  if (fileItem.isFileDocument) {
    return fileItem.name;
  }
  return fileItem.translatedDisplayName?.en || fileItem.name || fileItem.displayName || fileItem.fileName || fileItem.type;
};

const combineDocsAndAgreements = (documents: any, agreements: any): CombinedDocumentAndAgreement[] => {
  const combined: CombinedDocumentAndAgreement[] = [];
  combined.push(...documents.fetchFileDocuments.fileDocuments.map((d: any) => ({
    id: d.id,
    type: d.type,
    name: d.name,
    fileName: d.fileName,
    mediaType: d.mediaType,
    uploadedAt: d.uploadedAt,
    updatedAt: d.updatedAt,
    relatedObjectType: d.objectType,
    relatedObjectId: d.objectId,
    creatorId: d.creator?.id,
    state: 'SAVED',
    isFileDocument: true,
    sharedClient: d?.sharedClient,
    sharedCustodian: d?.sharedCustodian,
  })));

  /* FETCH_ALL_USER_AGREEMENTS */
  if (agreements.fetchUser) {
    combined.push(...agreements.fetchUser.user.allLatestFormAgreements.map((a: any) => ({
      ...a,
      id: a.id,
      displayName: a.displayName,
      type: a.type,
      signedAt: a.signedAt,
      signedUrl: a.signedUrl,
      templateUrl: a.templateUrl,
      minVersion: a.minVersion,
      ...getRelatedObjectTypeAndId(a),
      state: 'SIGNED',
    })));
    combined.push(...agreements.fetchUser.user.allIncompleteFormAgreements.map((a: any) => ({
      ...a,
      id: a.id,
      displayName: a.translatedDisplayName?.en,
      type: a.type,
      changeType: a.changeType,
      templateUrl: a.templateUrl,
      isPrefilledPdfEnabled: a.isPrefilledPdfEnabled,
      digitalSignatureEnabled: a.digitalSignatureEnabled,
      minVersion: a.minVersion,
      ...getRelatedObjectTypeAndId(a),
      state: 'PENDING',
    })));
  }

  /* FETCH_ACCOUNT_AGREEMENTS */
  if (agreements.fetchAccount) {
    const { account } = agreements.fetchAccount;
    combined.push(...account.latestFormAgreements.map((a: any) => ({
      id: a.id,
      displayName: a.displayName,
      type: a.type,
      signedAt: a.signedAt,
      signedUrl: a.signedUrl,
      templateUrl: a.templateUrl,
      relatedObjectType: 'ACCOUNT',
      relatedObjectId: account.id,
      state: 'SIGNED',
      custodianAccountNumber: account.custodianAccountNumber,
      accountType: account.type,
    })));
    combined.push(...account.incompleteFormAgreements.map((a: any) => ({
      id: a.id,
      displayName: a.translatedDisplayName?.en,
      type: a.type,
      changeType: a.changeType,
      templateUrl: a.templateUrl,
      isPrefilledPdfEnabled: a.isPrefilledPdfEnabled,
      digitalSignatureEnabled: a.digitalSignatureEnabled,
      relatedObjectType: 'ACCOUNT',
      relatedObjectId: account.id,
      state: 'PENDING',
      custodianAccountNumber: account.custodianAccountNumber,
      accountType: account.type,
      minVersion: a.minVersion,
    })));
  }
  /* FETCH_CLIENT_GROUP_AGREEMENTS */
  if (agreements.fetchClientGroup) {
    const { clientGroup } = agreements.fetchClientGroup;
    combined.push(...clientGroup.latestFormAgreements.map((a: any) => ({
      id: a.id,
      displayName: a.displayName,
      type: a.type,
      signedAt: a.signedAt,
      signedUrl: a.signedUrl,
      templateUrl: a.templateUrl,
      relatedObjectType: 'CLIENT_GROUP',
      relatedObjectId: clientGroup.id,
      state: 'SIGNED',
      // custodianAccountNumber: clientGroup.custodianAccountNumber,
      // accountType: account.type,
    })));
    combined.push(...clientGroup.incompleteFormAgreements.map((a: any) => ({
      id: a.id,
      displayName: a.translatedDisplayName?.en,
      type: a.type,
      changeType: a.changeType,
      templateUrl: a.templateUrl,
      isPrefilledPdfEnabled: a.isPrefilledPdfEnabled,
      digitalSignatureEnabled: a.digitalSignatureEnabled,
      relatedObjectType: 'CLIENT_GROUP',
      relatedObjectId: clientGroup.id,
      state: 'PENDING',
      minVersion: a.minVersion,
      // custodianAccountNumber: account.custodianAccountNumber,
      // accountType: account.type,
    })));
  }
  return combined;
};

export const ScheduleTransferLabel = ({ doc }: { doc: any }) => {
  const accountType = doc?.scheduledTransfer?.subAccount?.account?.type;
  const frequency = doc?.scheduledTransfer?.frequency;
  const { t } = useTranslation(['client', 'accountTypes']);
  return <>
    {t('padAgreement')}:
    &nbsp;
    {t(`accountTypes:${accountType}`)}
    &nbsp;
    -
    &nbsp;
    {t(`frequency.${frequency}`)}
  </>;
};

export const RelatedTo = ({ doc, relatedObjectType, relatedObjectId }: { doc: any, relatedObjectType: string, relatedObjectId: string }) => {
  const { t } = useTranslation('client');

  if (relatedObjectType === 'USER') { return <>{t('client')}</>; }

  if (relatedObjectType === 'ACCOUNT') { return <AccountLabel accountId={relatedObjectId} />; }

  if (relatedObjectType === 'SUBACCOUNT') { return <SubAccountLabel financialProductId={doc.financialProduct?.id} subAccountId={relatedObjectId} />; }

  if (relatedObjectType === 'GOAL') { return <GoalLabel goalId={relatedObjectId} />; }

  if (relatedObjectType === 'SCHEDULED_TRANSFER') { return <ScheduleTransferLabel doc={doc} />; }

  if (relatedObjectType === 'CLIENT_GROUP') { return <ClientGroupLabel clientGroupId={relatedObjectId} />; }

  return <>{relatedObjectType}</>;
};

export const generateColor = (state: string) => {
  if (state.toUpperCase() === 'SAVED') {
    return 'primary';
  } if (state.toUpperCase() === 'PENDING') {
    return 'warning';
  }
  return 'success';
};

/* logical sort of objects: user, goal, account, subaccount, scheduledTransfer */
export function objectTypeSortingWeight(objType: string, isFileDocument?: boolean): number {
  if (isFileDocument) return 5;
  if (objType === 'USER') return 50;
  if (objType === 'GOAL') return 40;
  if (objType === 'ACCOUNT') return 30;
  if (objType === 'SUBACCOUNT') return 20;
  if (objType === 'SCHEDULED_TRANSFER') return 10;
  return 0;
}

const Documents = ({
  userId,
  onlyAccountId,
  subtitle,
  clientGroupId,
}: {
  userId: string,
  onlyAccountId?: string,
  subtitle?: string,
  clientGroupId?: string,
}) => {
  const { t } = useTranslation(['client']);
  const { permissions } = usePermissions();
  const [searchByName, setSearchByName] = usePageState('', 'docName');
  const [searchByType, setSearchByType] = usePageState('any', 'docType');
  const [searchByState, setSearchByState] = usePageState('any', 'docState');
  const [documentsCombined, setDocumentsCombined] = useState<CombinedDocumentAndAgreement[]>([]);
  const [documentsFiltered, setDocumentsFiltered] = useState<CombinedDocumentAndAgreement[]>([]);
  const [selectedDocumentIndex, setSelectedDocumentIndex] = useState<number | null>(null);
  const { data: documents, loading: docsLoading } = useQuery(FETCH_DOCUMENTS, {
    variables: {
      filter:
        !onlyAccountId
          ? { userIds: [userId], types: ['OTHER', 'BANKING', 'ID_VERIFICATION'] }
          : { objectIds: [onlyAccountId], objectTypes: ['ACCOUNT'], types: 'OTHER' },
    },
    fetchPolicy: 'no-cache',
  });
  const {
    data: agreements,
    loading: agreementsLoading,
  } = useQuery((!onlyAccountId && !clientGroupId) ? FETCH_ALL_USER_AGREEMENTS(permissions) : (clientGroupId ? FETCH_CLIENT_GROUP_AGREEMENTS : FETCH_ACCOUNT_AGREEMENTS), {
    variables: {
      id: (!onlyAccountId && !clientGroupId) ? userId : (clientGroupId ?? onlyAccountId),
    },
    fetchPolicy: 'no-cache',
  });
  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  useEffect(() => {
    if (docsLoading || agreementsLoading || !documents || !agreements) return;
    setDocumentsCombined(combineDocsAndAgreements(documents, agreements));
  }, [documents, agreements, docsLoading, agreementsLoading]);

  useEffect(() => {
    const filtered = documentsCombined.filter((doc: any) => {
      if (searchByName) {
        if (
          !doc.translatedDisplayName?.en?.toLowerCase().includes(searchByName.toLowerCase())
          && !doc.displayName?.toLowerCase().includes(searchByName.toLowerCase())
          && !doc.name?.toLowerCase().includes(searchByName.toLowerCase())
          && !doc.fileName?.toLowerCase().includes(searchByName.toLowerCase())
        ) { return false; }
      }
      if (searchByType !== 'any') {
        if (doc.type !== searchByType) return false;
      }
      if (searchByState !== 'any') {
        if (doc.state !== searchByState) return false;
      }
      return true;
    });
    filtered.sort((a: any, b: any) =>
      /* sort by document relation: client, account, subaccount */
      objectTypeSortingWeight(b.relatedObjectType, b.isFileDocument) - objectTypeSortingWeight(a.relatedObjectType, a.isFileDocument)
      /* sort alphabetically by document name */
      + Math.sign(getDocumentName(a).localeCompare(getDocumentName(b))));
    setDocumentsFiltered(filtered);
  }, [documentsCombined, searchByName, searchByType, searchByState]);

  const getDocumenType = (fileItem: any) => {
    if ([FileDocumentTypes.FORM,
      FileDocumentTypes.STATEMENT,
      FileDocumentTypes.OTHER,
      FileDocumentTypes.NOTES,
      FileDocumentTypes.BANKING,
      FileDocumentTypes.INVOICE,
      FileDocumentTypes.LOGO,
      FileDocumentTypes.ID_VERIFICATION,
    ].includes(fileItem.type)) {
      return t(`formTypes.${fileItem.type}`);
    }
    return t('formTypes.FORM');
  };

  const generateObjectType = (): FileDocumentObjectTypes => {
    if (onlyAccountId) return FileDocumentObjectTypes.ACCOUNT;
    if (clientGroupId) return FileDocumentObjectTypes.CLIENTGROUP;
    if (userId && onlyAccountId) return FileDocumentObjectTypes.ACCOUNT;
    if (userId) return FileDocumentObjectTypes.USER;
    return FileDocumentObjectTypes.USER;
  };
  if (!permissions.includes('read:files')) return <NoPermissionAlert missing='read:files' />;

  return (
    <Box sx={{ p: 2 }}>
      <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Grid item>
          <Typography variant='h6'>{t('documents.title')}</Typography>
          {subtitle && <Typography variant='caption'>{subtitle}</Typography>}
        </Grid>
        <Grid item>
          <Button variant='outlined' color='primary' size='small' onClick={() => setUploadModalOpen(true)} disabled={!permissions.includes('write:files')}>
            {t('documents.addDocument')}
          </Button>
        </Grid>
      </Grid>
      <Grid container style={{ display: 'flex', justifyContent: 'space-between' }} sx={{ paddingTop: 2 }} spacing={2}>
        <Grid item xs={6}>
          <TextField
            label={t('documents.searchByName')}
            value={searchByName}
            onChange={(e: any) => setSearchByName(e.target.value)}
            placeholder={t('documents.typeToSearch')}
            sx={{ width: '100%' }}
            size="small"
          >
          </TextField>
        </Grid>
        <Grid item xs={3}>
          <TextField select
            label={t('documents.docType')}
            value={searchByType}
            onChange={(e: any) => setSearchByType(e.target.value)}
            sx={{ width: '100%' }}
            fullWidth
            size="small"
          >
            <MenuItem key='any' value={'any'}>{t('documents.any')}</MenuItem>
            {Object.values(FileDocumentTypes).map((type) => <MenuItem key={type} value={type}>{type}</MenuItem>)}
            {Object.values(FormTypes).map((type) => <MenuItem key={type} value={type}>{type}</MenuItem>)}
          </TextField>
        </Grid>
        <Grid item xs={3}>
          <TextField select
            label={t('documents.docState')}
            value={searchByState}
            onChange={(e: any) => setSearchByState(e.target.value)}
            sx={{ width: '100%' }}
            fullWidth
            size="small"
          >
            <MenuItem value={'any'}>{t('documents.any')}</MenuItem>
            <MenuItem value={'PENDING'}>{t('documents.PENDING')}</MenuItem>
            <MenuItem value={'SAVED'}>{t('documents.SAVED')}</MenuItem>
            <MenuItem value={'SIGNED'}>{t('documents.SIGNED')}</MenuItem>
          </TextField>
        </Grid>
      </Grid>
      {docsLoading || agreementsLoading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ margin: 5 }} />
        </Box>
      )
        : <Table sx={{ minWidth: 650 }} aria-label="table">
          <TableHead>
            <TableRow>
              <TableCell><Typography variant='overline'>{t('documents.name')}</Typography></TableCell>
              <TableCell><Typography variant='overline'>{t('documents.type')}</Typography></TableCell>
              <TableCell><Typography variant='overline'>{t('documents.associatedTo')}</Typography></TableCell>
              <TableCell><Typography variant='overline'>{t('documents.dateUpdated')}</Typography></TableCell>
              <TableCell><Typography variant='overline'>{t('documents.state')}</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documentsFiltered.map((doc: any, index: number) => (
              <TableRow
                key={index}
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setSelectedDocumentIndex(index);
                }}
              >
                <TableCell sx={{ wordBreak: 'break-all' }}>
                  {getDocumentName(doc)}
                  {(doc.relatedObjectType === 'SCHEDULED_TRANSFER') && doc?.scheduledTransfer?.state === 'CANCELED' ? ` [${t('canceled')}]` : null}
                </TableCell>
                <TableCell>
                  <Typography variant='subtitle2'>{getDocumenType(doc)}</Typography>
                </TableCell>
                <TableCell sx={{ wordBreak: 'break-all' }}>
                  <RelatedTo doc={doc} relatedObjectType={doc.relatedObjectType} relatedObjectId={doc.relatedObjectId} />
                </TableCell>
                <TableCell>
                  <DateTime variant="subtitle2" date={doc.updatedAt || doc.signedAt || doc.uploadedAt} />
                </TableCell>
                <TableCell>
                  <Chip label={t(`documents.${doc.state}`)} color={generateColor(doc.state)}></Chip>
                </TableCell>
              </TableRow>
            ))
            }
          </TableBody>
        </Table>
      }
      {selectedDocumentIndex !== null
        && <DocumentViewerModal
          onModalClose={() => setSelectedDocumentIndex(null)}
          afterSigned={() => setSelectedDocumentIndex(null)}
          title={getDocumentName(documentsFiltered[selectedDocumentIndex])}
          doc={documentsFiltered[selectedDocumentIndex]}
        />
      }
      {
        uploadModalOpen
        && <DocumentUpload
          forUserId={userId}
          fixedObjectType={generateObjectType()}
          fixedObjectId={onlyAccountId ?? clientGroupId}
          handleClose={() => { setUploadModalOpen(false); }}
          clientGroupId={clientGroupId}
        />
      }
    </Box>
  );
};

export default Documents;
