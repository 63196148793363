import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTranslation } from 'react-i18next';
import { formatMoneyValue } from '../../../../../util';
import { TradeToVerify } from './subTradeRequestsTable';
import { useLocalization } from '../../../../../util/useLocalization';
import { Link } from '../../../../1-primative';
import {
  IconButton, TableCell, TableRow, Checkbox, Badge,
} from '../../../../2-component';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';

const TESTID_SUB_TRADES_TABLE_ROW = (testIdPrefix: string, row: number, nested?: boolean, g = 0) => `${testIdPrefix}-row-${row}${nested ? `-split-${g}` : ''}`;
const TESTID_SUB_TRADES_TABLE_ROW_CELL = (testIdPrefix: string, row: number, cell: string, nested?: boolean, g = 0) => `${testIdPrefix}-row-${row}-${nested ? `split-${g}-` : ''}cell-${cell}`;

const SUB_TRADE_REQUEST_STATES_ORDERED: Record<string, number> = {
  INITIATED: 1,
  READY: 2,
  REQUESTED: 3,
  RECONCILED: 4,
  CANCELED: 5,
};

export const GenerateSourceLink = ({ item }: { item: any }) => {
  const { t } = useTranslation(['components', 'shared', 'transfer']);

  const account = item?.subAccount?.account ?? null;

  if (!account.user || !account.type) return <></>;

  return (
    <Link
      href={`/clients/${account?.user?.id}/account/${account?.id}`}
      target='_blank'
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
      }}
    >
      {account?.user?.firstName}'s {t(`accountTypes:${account?.type}`)}
    </Link>
  );
};

const SubTradeRequestsTableRow = ({
  trade,
  expandable,
  verifiable,
  editable,
  tradesToVerify,
  onTradeVerify,
  onClick,
  testIdPrefix = '',
  index = 0,
}: {
  trade: any;
  expandable: boolean;
  verifiable: boolean;
  editable: boolean;
  tradesToVerify: TradeToVerify[];
  onTradeVerify: (trade: TradeToVerify) => void;
  onClick?: () => void;
  testIdPrefix?: string;
  index?: number;
}) => {
  const { t } = useTranslation(['components', 'shared', 'transfer']);
  const { localizedDate, localizedDateTime } = useLocalization();
  const { sys } = useThemeTokens();

  const [open, setOpen] = useState(false);

  const getLowestTradeStatus = (row: any): string => {
    if (row?.splits?.length) {
      const states = row.splits.map((split: any) => split.state);

      return t(`transfer:states.${states.sort((a: string, b: string) => SUB_TRADE_REQUEST_STATES_ORDERED[a] - SUB_TRADE_REQUEST_STATES_ORDERED[b])[0]}`);
    }

    return t(`transfer:states.${row.state}`);
  };

  const LocalTableRow = ({ row, nested, g = 0 }: { row: any; nested?: boolean; g?: number }) => {
    const isShowVerification = verifiable && ['INITIATED', 'READY'].includes(row?.state) && !row?.bulkTradeRun?.id;
    const hasExpansion = !!row?.splits?.length;
    const showControlColumn = expandable || verifiable;
    const isTradeSelected = (item: any) => tradesToVerify?.find((tradeToVerify: TradeToVerify) => item?.id === tradeToVerify?.id)?.selected ?? false;
    const isTradeSelectedToVerify = isTradeSelected(row);

    const controlColumnStyles = {
      width: '48px',
      padding: 0,
      backgroundColor: nested ? sys.color.surfaceContainer : undefined,
    };

    const controlWrapperStyles = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '48px',
    };

    return (
      <TableRow
        key={row?.id}
        onClick={onClick}
        pointer={!!onClick}
        hover={!!onClick}
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
          backgroundColor: nested ? sys.color.surfaceContainer : undefined,
        }}
        testId={TESTID_SUB_TRADES_TABLE_ROW(testIdPrefix, index, nested, g)}
      >
        {/* Control Column - Always present, contains either expansion control or verification checkbox */}
        {showControlColumn && (
          <TableCell sx={controlColumnStyles} testId={TESTID_SUB_TRADES_TABLE_ROW_CELL(testIdPrefix, index, 'expand-verify', nested, g)}>
            <div style={controlWrapperStyles}>
              {!nested && hasExpansion ? (
                <IconButton
                  aria-label='expand row'
                  size='small'
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setOpen(!open);
                  }}
                  sx={{
                    margin: 0,
                    padding: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              ) : (
                isShowVerification
                && editable && (
                  <Checkbox
                    checked={isTradeSelectedToVerify}
                    onChange={() => onTradeVerify({
                      id: row?.id,
                      selected: !isTradeSelectedToVerify,
                      state: row?.state,
                      verified: !!row?.verifiedAt,
                    })
                    }
                    customStyle={{
                      margin: 0,
                      padding: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    onClick={(e: any) => e.stopPropagation()}
                  />
                )
              )}
            </div>
          </TableCell>
        )}

        <TableCell sx={{ backgroundColor: nested ? sys.color.surfaceContainer : undefined }} testId={TESTID_SUB_TRADES_TABLE_ROW_CELL(testIdPrefix, index, 'account', nested, g)}>
          <GenerateSourceLink item={row} />
        </TableCell>
        <TableCell sx={{ backgroundColor: nested ? sys.color.surfaceContainer : undefined }} testId={TESTID_SUB_TRADES_TABLE_ROW_CELL(testIdPrefix, index, 'type', nested, g)}>
          {row.isSellAll ? t('components:portfolioTable.sellAll') : row.type}
        </TableCell>
        <TableCell sx={{ backgroundColor: nested ? sys.color.surfaceContainer : undefined }} number testId={TESTID_SUB_TRADES_TABLE_ROW_CELL(testIdPrefix, index, 'amount', nested, g)}>
          {formatMoneyValue(nested ? row?.moneyAllocatedCents : row?.preSplitMoneyAllocatedCents ?? row?.moneyAllocatedCents)}
        </TableCell>
        <TableCell sx={{ backgroundColor: nested ? sys.color.surfaceContainer : undefined }} testId={TESTID_SUB_TRADES_TABLE_ROW_CELL(testIdPrefix, index, 'security', nested, g)}>
          {row.financialProduct?.ticker}
        </TableCell>
        <TableCell sx={{ backgroundColor: nested ? sys.color.surfaceContainer : undefined }} testId={TESTID_SUB_TRADES_TABLE_ROW_CELL(testIdPrefix, index, 'settlement-days', nested, g)}>
          {row.financialProduct?.settlementDays}
        </TableCell>
        <TableCell sx={{ backgroundColor: nested ? sys.color.surfaceContainer : undefined }} testId={TESTID_SUB_TRADES_TABLE_ROW_CELL(testIdPrefix, index, 'exchange', nested, g)}>
          {row.financialProduct?.exchange}
        </TableCell>
        <TableCell sx={{ backgroundColor: nested ? sys.color.surfaceContainer : undefined }} testId={TESTID_SUB_TRADES_TABLE_ROW_CELL(testIdPrefix, index, 'settlement-date', nested, g)}>
          {row?.settlementDate && localizedDate(row?.settlementDate)}
        </TableCell>
        <TableCell sx={{ backgroundColor: nested ? sys.color.surfaceContainer : undefined }} testId={TESTID_SUB_TRADES_TABLE_ROW_CELL(testIdPrefix, index, 'state', nested, g)}>
          <Badge label={getLowestTradeStatus(row)} color={getLowestTradeStatus(row) === 'RECONCILED' ? 'positive' : 'warning'} />
        </TableCell>

        {verifiable && (
          <TableCell sx={{ backgroundColor: nested ? sys.color.surfaceContainer : undefined }} testId={TESTID_SUB_TRADES_TABLE_ROW_CELL(testIdPrefix, index, 'verified-at', nested, g)}>
            {row.verifiedAt ? `${row.verifiedByOrganizationUser?.firstName} ${row.verifiedByOrganizationUser?.lastName}` : '-'}
            <br />
            {row.verifiedAt && localizedDateTime(row?.verifiedAt)}
          </TableCell>
        )}
      </TableRow>
    );
  };

  return (
    <>
      <LocalTableRow row={trade} />
      {!!trade?.splits?.length && open && (
        <>
          {trade?.splits.map((split: any, g: number) => (
            <LocalTableRow key={split?.id} row={split} g={g} nested />
          ))}
        </>
      )}
    </>
  );
};

export default SubTradeRequestsTableRow;
