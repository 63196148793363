import { useTranslation } from 'react-i18next';
import {
  Dialog, DialogTitle, DialogContent, Button,
  DialogFooter,
} from 'ovComponents/2-component';
import { Typography, Box } from 'ovComponents/1-primative';
import { Breakpoint, SxProps } from '@mui/material';

export enum ConfirmButtonColors {
  primary = 'primary',
  destructive = 'destructive',
}

export enum CancelButtonColors {
  primary = 'primary',
  destructive = 'destructive',
}

export const ConfirmationModal = ({
  onConfirm,
  onCancel,
  children,
  title,
  open,
  bodyText,
  confirmButtonLabel,
  cancelButtonLabel,
  deleteButtonLabel,
  maxWidth = 'sm',
  loading = false,
  dialogStyles,
  confirmButtonColor = ConfirmButtonColors.destructive,
  cancelButtonColor,
  showCancelButton = true,
  onDelete,
  showDeleteButton,
  testIdPrefix = 'confirmation-modal',
}: {
  onConfirm: () => void,
  onCancel: () => void,
  onDelete?: () => void,
  title: string,
  open: boolean,
  bodyText?: string,
  children?: React.ReactNode,
  confirmButtonLabel?: string,
  cancelButtonLabel?: string,
  deleteButtonLabel?: string,
  maxWidth?: Breakpoint | false,
  loading?: boolean,
  dialogStyles?: {
    dialogContainer?: SxProps,
    dialogContentStyles?: SxProps,
    dialogTitleStyles?: SxProps,
    confirmButton?: SxProps,
    cancelButton?: SxProps,
  },
  confirmButtonColor?: ConfirmButtonColors,
  cancelButtonColor?: CancelButtonColors,
  showCancelButton?: boolean,
  showDeleteButton?: boolean,
  testIdPrefix?: string,
}) => {
  const { t } = useTranslation('shared');
  return (
    <Dialog
      onClose={onCancel}
      open={open}
      maxWidth={maxWidth}
      fullWidth
      sx={[...(dialogStyles?.dialogContainer && Array.isArray(dialogStyles?.dialogContainer) ? dialogStyles.dialogContainer : [dialogStyles?.dialogContainer])]}
    >
      <DialogTitle>{title}</DialogTitle>

      {(children || bodyText) && (
        <DialogContent
          sx={[
            { minWidth: '400px' },
            ...(dialogStyles?.dialogContentStyles && Array.isArray(dialogStyles?.dialogContentStyles) ? dialogStyles.dialogContentStyles : [dialogStyles?.dialogContentStyles]),
          ]}
        >
          {children}
          {!children && bodyText && <Typography variant='bodyLarge'>{bodyText}</Typography>}
        </DialogContent>
      )}

      <DialogFooter>
        <Box display='flex' justifyContent='end'>
          {showCancelButton ? (
            <Button
              type='button'
              variant='outlined'
              color={cancelButtonColor}
              disabled={loading}
              onClick={onCancel}
              label={cancelButtonLabel ?? t('shared:cancel')}
              dataTestId={`${testIdPrefix}-cancel-button`}
            />
          ) : (
            <></>
          )}

          {showDeleteButton ? (
            <Button
              type='button'
              variant='outlined'
              color={cancelButtonColor}
              disabled={loading}
              onClick={onDelete}
              label={deleteButtonLabel ?? t('shared:delete')}
              dataTestId={`${testIdPrefix}-delete-button`}
            />
          ) : (
            <></>
          )}

          <Button
            sx={{ ml: 1 }}
            type='button'
            variant='filled'
            color={confirmButtonColor}
            disabled={loading}
            onClick={onConfirm}
            label={confirmButtonLabel ?? t('shared:confirm')}
            dataTestId={`${testIdPrefix}-confirm-button`}
          />
        </Box>
      </DialogFooter>
    </Dialog>
  );
};

export default ConfirmationModal;
