import dayjs from 'dayjs';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { Add, Close } from '@mui/icons-material';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import { usePageState } from '../../../util/usePageState';
import {
  Box,
  Button,
  CircularProgress,
  Dialog, DialogContent, DialogTitle, IconButton, Pagination, Table, TableBody, TableCell, TableHeadCell, TableRow, Typography,
} from '../../../ovComponents';
import { DowntimeSchedule, DowntimeScheduleState } from '../../../interfaces/downtimeSchedule';
import DowntimeScheduleForm from '../../../ovComponents/4-module/downtimeScheduleForm/downtimeScheduleForm';

const FETCH_DOWNTIME_SCHEDULES = gql`
  query fetchDowntimeSchedules($input: FetchDowntimeSchedulesInput!) {
    fetchDowntimeSchedules(input: $input) {
      downtimeSchedules {
        id
        name
        note
        platforms
        startTime
        endTime
        excludeRoles
        helpLink
        state
        organization {
          id
          name
        }
        translatedDescription {
          en
          fr
        }
        translatedTitle {
          en
          fr
        }
      }
      totalCount
    }
  }
`;

enum DowntimeScheduleStatus {
  UPCOMING = 'UPCOMING',
  ONGOING = 'ONGOING',
  COMPLETED = 'COMPLETED',
}

const DowntimeSchedules = () => {
  const { activeOrganization } = useContext(UserContext);
  const { permissions } = usePermissions();
  const [page, setPage] = usePageState(1, 'page');
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState<'edit' | 'create'>('edit');
  const [activeDowntimeSchedule, setActiveDowntimeSchedule] = useState<Partial<DowntimeSchedule>>({});
  const perPage = 20;
  const { t } = useTranslation(['orgSettings', 'shared']);

  const {
    loading, error, data, refetch, previousData,
  } = useQuery(FETCH_DOWNTIME_SCHEDULES, {
    variables: {
      input: {
        filter: {
          organization: activeOrganization.id,
        },
        pagination: {
          offSet: (page - 1) * perPage,
          perPage,
        },
      },
    },
  });

  useEffect(() => function cleanupOnUnmount() { setPage(1); }, [setPage]);

  const onCloseDialog = () => {
    setOpen(false);
    setActiveDowntimeSchedule({});
  };

  const getDowntimeScheduleStatus = (downtimeSchedule: DowntimeSchedule) => {
    if (downtimeSchedule.state !== DowntimeScheduleState.ACTIVE) return undefined;
    const now = dayjs().utc();
    const startTime = dayjs(downtimeSchedule.startTime);
    const endTime = downtimeSchedule.endTime && dayjs(downtimeSchedule.endTime);

    if (now.isBefore(startTime)) return DowntimeScheduleStatus.UPCOMING;
    if (endTime && now.isAfter(endTime)) return DowntimeScheduleStatus.COMPLETED;
    return DowntimeScheduleStatus.ONGOING;
  };

  if (error) (<Typography>Error</Typography>);

  return (
    <Box sx={{ m: '-24px -24px' }}>
      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 100 }} />
        </Box>
      ) : (
        <>
          <Box sx={{
            display: 'flex', justifyContent: 'end', alignItems: 'center', mt: 1, mr: 1, mb: 1,
          }}>
            {permissions.includes('write:organization_settings') && (
              <Button
                onClick={() => {
                  setAction('create');
                  setOpen(true);
                }}
                label={t('downtimeScheduleTable.add')}
                variant='outlined'
                leadingIcon={Add}
              />
            )}
          </Box>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableHeadCell><Typography variant='bodySmall'>{t('downtimeScheduleTable.name').toUpperCase()}</Typography></TableHeadCell>
                <TableHeadCell><Typography variant='bodySmall'>{t('downtimeScheduleTable.state').toUpperCase()}</Typography></TableHeadCell>
                <TableHeadCell><Typography variant='bodySmall'>{t('downtimeScheduleTable.status').toUpperCase()}</Typography></TableHeadCell>
                <TableHeadCell><Typography variant='bodySmall'>{t('downtimeScheduleTable.organization').toUpperCase()}</Typography></TableHeadCell>
              </TableRow>
              {((data || previousData)?.fetchDowntimeSchedules?.downtimeSchedules || []).map((downtimeSchedule: DowntimeSchedule) => (
                <TableRow
                  key={downtimeSchedule.id}
                  pointer={permissions.includes('write:organization_settings')}
                  onClick={permissions.includes('write:organization_settings') ? (() => {
                    setActiveDowntimeSchedule(downtimeSchedule);
                    setAction('edit');
                    setOpen(true);
                  }) : undefined}
                >
                  <TableCell component="th" scope="row">
                    {downtimeSchedule.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {t(`downtimeScheduleState.${downtimeSchedule.state}`)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {getDowntimeScheduleStatus(downtimeSchedule) ? t(`downtimeScheduleStatus.${getDowntimeScheduleStatus(downtimeSchedule)}`) : '-'}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {downtimeSchedule.organization.name}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={Math.ceil(((data || previousData)?.fetchDowntimeSchedules?.totalCount ?? 0) / perPage)}
            page={page}
            onChange={(_e, newPage) => setPage(newPage)}
            sx={{
              p: 1,
              textAlign: 'right',
              '.MuiPagination-ul': {
                justifyContent: 'end',
              },
            }}
          />
          <Dialog open={open} onClose={onCloseDialog} fullWidth maxWidth='sm'>
            <DialogTitle>
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
                <Typography variant='headingSmall'>{t('downtimeScheduleModal.title')}</Typography>
                <IconButton onClick={onCloseDialog}>
                  <Close />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent>
              <DowntimeScheduleForm
                baseDowntimeSchedule={activeDowntimeSchedule}
                action={action}
                afterSubmit={() => {
                  setOpen(false);
                  setActiveDowntimeSchedule({});
                  refetch();
                }}
                onTransition={() => {
                  setOpen(false);
                  setActiveDowntimeSchedule({});
                  refetch();
                }}
              />
            </DialogContent>
          </Dialog>
        </>
      )}
    </Box>
  );
};

export default DowntimeSchedules;
