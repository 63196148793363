import { gql, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
  ClientGroup, SubAccount, SubAccountStates, getSubAccountName,
} from '../../../../../../interfaces';
import { FETCH_GOAL } from '../editGoal';
import { useGlobalToast } from '../../../../../../providers/globalToastProvider';
import { ConfirmationModal } from '../../../../../3-pattern';
import { Typography } from '../../../../../1-primative';

const FETCH_SUBACCOUNTS_OF_GOAL = gql`
  query fetchSubAccounts($goalId: ObjectID!) {
    fetchSubAccounts(input: { filter: { goalId: $goalId }, pagination: { perPage: 1000 }}) {
      subAccounts {
        id
        state

        account {
          id
          type
          custodianAccountNumber
          householdClientGroup { id }
        }
        statistics { marketValueCents }
        financialProduct { translatedName { en } }
      }
    }
  }
`;

export const ASSOCIATE_GOAL_TO_HOUSEHOLD = gql`
  mutation associateGoalToHousehold($goalId: ObjectID!, $clientGroupId: ObjectID!) {
    updateGoal (
      input: {
        goalId: $goalId
        householdClientGroupId: $clientGroupId
      }
    ) {
      goal {
        id
      }
    }
  }
`;

export const AssociateGoalToHouseholdModal = ({
  handleClose, goalId, household,
}: { goalId: string, handleClose: () => void, household: ClientGroup }) => {
  const { t } = useTranslation(['accountDetail']);
  const { showToast } = useGlobalToast();
  const [outOfHouseholdSubAccounts, setOutOfHouseholdSubAccounts] = useState<SubAccount[]>([]);
  const { loading } = useQuery(FETCH_SUBACCOUNTS_OF_GOAL, {
    variables: { goalId },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setOutOfHouseholdSubAccounts(data.fetchSubAccounts.subAccounts.filter((subAccount: SubAccount) => (
        subAccount.state !== SubAccountStates.INACTIVE
      )));
    },
  });

  const associationCompleted = (): void => {
    handleClose();
    showToast({ message: t('goalsDetails:associateToHouseholdDialog.success'), severity: 'success' });
  };

  const [associateGoalMutation] = useMutation(ASSOCIATE_GOAL_TO_HOUSEHOLD, {
    variables: {
      goalId,
      clientGroupId: household.id,
    },
    refetchQueries: [FETCH_GOAL],
    onCompleted: associationCompleted,
  });

  return (
    <ConfirmationModal
      title={t('goalsDetails:associateToHouseholdDialog.title', { name: household.name })}
      open={true}
      maxWidth='sm'
      onConfirm={associateGoalMutation}
      onCancel={handleClose}
      loading={loading}
    >
      <Typography>
        {loading && <Typography>{t('goalsDetails:associateToHouseholdDialog.checkingSubaccounts')}...</Typography>}
        {outOfHouseholdSubAccounts.length > 0
        && <>
          {t('goalsDetails:associateToHouseholdDialog.thereAreSubAccounts')}
          {outOfHouseholdSubAccounts.map((sa) => <Typography key={sa.id}>{getSubAccountName(sa)}</Typography>)}
        </>
        }
        {!loading && outOfHouseholdSubAccounts.length === 0 && <>
          {t('goalsDetails:associateToHouseholdDialog.thereAreNoSubAccounts')}
        </>}
      </Typography>
    </ConfirmationModal>
  );
};
