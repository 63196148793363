import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { PortfolioReport } from '../../../../../interfaces/portfolioReport';
import { DraftSubTradeRequest, PortfolioSecurity, PortfolioSubAccount } from './interfaces';
import { sumSecuritiesCurrentCents, sumSecuritiesDeviationCents, sumSubAccountsCurrentCents } from './utils';
import { TableCell, TableRow } from '../../../../2-component';
import SmallDollarPercentage from './smallDollarPercentage';
import { TESTID_PORTFOLIO_WIDGET } from '../portfolio';

export const SecurityTotalTableRow = ({
  securities,
  subAccounts,
  portfolioReport,
  draftTrades,
  pendingTrades,
}: {
  securities: PortfolioSecurity[];
  subAccounts: PortfolioSubAccount[];
  portfolioReport?: PortfolioReport;
  draftTrades: Map<string, DraftSubTradeRequest>;
  pendingTrades: Map<string, DraftSubTradeRequest>;
}) => {
  const { t } = useTranslation(['components', 'accountTypes']);

  const securitiesCurrentCents = useMemo(() => sumSecuritiesCurrentCents(securities, [draftTrades, pendingTrades]), [securities, draftTrades, pendingTrades]);
  const subAccountsCurrentCents = useMemo(() => sumSubAccountsCurrentCents(subAccounts, [draftTrades, pendingTrades]), [subAccounts, draftTrades, pendingTrades]);
  const securitiesDeviationCents = useMemo(() => sumSecuritiesDeviationCents(securities, [draftTrades, pendingTrades]), [securities, draftTrades, pendingTrades]);

  const totalCurrentCents = securitiesCurrentCents + subAccountsCurrentCents;
  const totalDeviationCents = securitiesDeviationCents + subAccountsCurrentCents - (portfolioReport?.expectedCashCents ?? 0);

  return (
    <TableRow testId={`${TESTID_PORTFOLIO_WIDGET}-securities-table-total-row`}>
      <TableCell sx={{ fontWeight: 'bold' }}>{t('portfolioTable.total')}</TableCell>
      <TableCell />
      <TableCell right>
        {/* sum of the current */}
        <SmallDollarPercentage bold cents={totalCurrentCents} showPercentage={false} />
      </TableCell>
      <TableCell right>
        {/* sum of the deviation */}
        <SmallDollarPercentage bold cents={totalDeviationCents} showPercentage={false} />
      </TableCell>
    </TableRow>
  );
};
