import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, Typography } from '../../../../1-primative';
import {
  Dialog, DialogTitle, DialogContent, MenuItem, Button,
  TextField,
  SelectField,
  Form,
  DialogFooter,
  TranslatableTextField,
} from '../../../../2-component';
import { UserContext, usePermissions } from '../../../../../providers/userContextProvider';
import { getBackendLanguage, translateBackend } from '../../../../../assets/i18n/config';
import { Account, AccountStates, ClientGroup } from '../../../../../interfaces';
import { invalidFields } from '../../../workflowCompletion/subSteps/utils';
import { ActionContext } from '../../../../5-page';
import { useGlobalToast } from '../../../../../providers/globalToastProvider';
import { AssociateAccountToHouseholdModal } from './components/associateAccountToHouseholdModal';
import { DissociateAccountFromHouseholdModal } from './components/dissociateAccountFromHouseholdModal';
import filterHouseholdsWithAccess from '../../../../../util/filterHouseholdsWithAccess';

enum AccountTransition {
  activate = 'activate',
  request = 'request',
  freeze = 'freeze',
  fail = 'fail',
  ready = 'ready',
  cancel = 'cancel',
}

const CLOSE_ACCOUNT = gql`
  mutation closeAccount($input: CloseAccountInput!) {
    closeAccount(input: $input) {
      account {
        id
      }
    }
  }
`;

const UPDATE_ACCOUNT = gql`
  mutation updateAccount($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      account {
        id
      }
    }
  }
`;

const TRANSITION_ACCOUNT = gql`
  mutation transitionAccount($input: TransitionAccountInput!) {
    transitionAccount(input: $input) {
      account {
        id
      }
    }
  }
`;

export const FETCH_ACCOUNT = (permissions: string[]) => gql`
  query fetchAccount($accountId: ObjectID!) {
    fetchAccount(accountId: $accountId) {
      account {
        id
        forceOpen
        nickName
        translatedNickName { en fr }
        householdClientGroup {
          id
          name
        }
        user {
          id
          entityName
          firstName
          lastName
          households {
            id
            name
            relationships {
              type
              user {
                id
              }
              accessType
            }
          }
        }
        ${permissions.includes('read:account_basic') ? 'state' : ''}
        ${permissions.includes('read:account_number') ? 'custodianAccountNumber' : ''}
      }
    }
  }
`;

export const EditAccount = ({ objectId, onClose, options = {} }: { objectId: string, onClose: () => void, options?: any }) => {
  const { t } = useTranslation(['client', 'pageConfiguration', 'accountsDetail']);
  const [editOpen, setEditOpen] = useState(false);
  const [closeOpen, setCloseOpen] = useState(false);
  const [associateAccountModal, setAssociateAccountModal] = useState<ClientGroup | null>(null);
  const [dissociateAccountModal, setDissociateAccountModal] = useState(false);
  const { permissions } = usePermissions();
  const [invalidFieldsList, setInvalidFieldsList] = useState<string[]>([]);
  const [focused, setFocused] = useState<string[]>([]);
  const [accountData, setAccountData] = useState<Account>();
  const [accountHouseholds, setAccountHouseholds] = useState<ClientGroup[]>([]);
  const [updated, setUpdated] = useState(false);
  const [updatedState, setUpdatedState] = useState(false);
  const [associateAccountToHousehold, setAssociateAccountToHousehold] = useState(false);
  const [disassociateAccountToHousehold, setDisassociateAccountToHousehold] = useState(false);
  const { refreshWidgets } = useContext(ActionContext);
  const { userId: paramsUserId } = useParams();
  const { activeEntity } = useContext(UserContext);
  const userId = paramsUserId ?? activeEntity?.id;
  const navigate = useNavigate();
  const { showToast } = useGlobalToast();

  const convertStateToTransition: { [key in AccountStates]?: AccountTransition } = {
    ACTIVE: AccountTransition.activate,
    REQUESTED: AccountTransition.request,
    FROZEN: AccountTransition.freeze,
    FAILED: AccountTransition.fail,
    READY: AccountTransition.ready,
    CANCELED: AccountTransition.cancel,
  };

  const allowedStates: { [key in AccountStates]?: AccountStates[] } = {
    ACTIVE: [AccountStates.ACTIVE, AccountStates.FROZEN],
    REQUESTED: [AccountStates.REQUESTED, AccountStates.ACTIVE, AccountStates.FAILED, AccountStates.CANCELED, AccountStates.FROZEN],
    FROZEN: [AccountStates.FROZEN, AccountStates.ACTIVE],
    FAILED: [AccountStates.FAILED],
    INITIATED: [AccountStates.INITIATED, AccountStates.READY, AccountStates.REQUESTED, AccountStates.CANCELED, AccountStates.FROZEN],
    READY: [AccountStates.READY, AccountStates.REQUESTED, AccountStates.CANCELED, AccountStates.FROZEN],
    CANCELED: [AccountStates.CANCELED],
  };

  const showNickName = !!options?.nickName?.enabled && permissions.includes('write:account_number');
  const showAccountNumber = !!options?.custodianAccountNumber?.enabled && permissions.includes('write:account_number') && permissions.includes('read:account_number');
  const showAccountStatus = !!options?.state?.enabled && permissions.includes('transition:account') && permissions.includes('read:account_basic');
  const showEdit = (showNickName || showAccountNumber || showAccountStatus) && options.enableAccountEditing;

  const { loading: accountLoading, data: accountFetched, refetch } = useQuery(FETCH_ACCOUNT(permissions), {
    variables: {
      accountId: objectId,
    },
    onCompleted: (data) => {
      setAccountData(data.fetchAccount.account);
      const households = filterHouseholdsWithAccess((data.fetchAccount.account?.user?.households ?? []), data.fetchAccount.account?.user?.id);
      setAccountHouseholds(households);
      setAssociateAccountToHousehold(
        !data.fetchAccount.account?.householdClientGroup
        && households.length > 0,
      );
      setDisassociateAccountToHousehold(!!data.fetchAccount.account?.householdClientGroup);
    },
  });

  const [updateAccount] = useMutation(UPDATE_ACCOUNT, {
    variables: {
      input: {
        accountId: objectId,
        custodianAccountNumber: showAccountNumber ? accountData?.custodianAccountNumber : undefined,
        ...(showNickName ? {
          // EN is required in the BE even if user is only editing FR
          translatedNickName: (
            translateBackend(accountData?.translatedNickName, getBackendLanguage()) ? { en: accountData?.translatedNickName?.en || '-', fr: accountData?.translatedNickName?.fr } : null
          ),
        } : {}),
      },
    },
    onError: () => setAccountData(accountFetched.fetchAccount.account),
  });

  const [forceOpenAccount] = useMutation(UPDATE_ACCOUNT, {
    variables: {
      input: {
        accountId: objectId,
        forceOpen: true,
      },
    },
    onCompleted: () => {
      showToast({ message: t('client:accountDetails.forceOpenSuccess'), severity: 'success' });
      refetch();
    },
    onError: () => setAccountData(accountFetched.fetchAccount.account),
  });

  const [updateAccountState] = useMutation(TRANSITION_ACCOUNT, {
    variables: {
      input: {
        accountId: objectId,
        transition: accountData?.state ? convertStateToTransition[accountData.state] : undefined,
      },
    },
    onError: () => setAccountData(accountFetched.fetchAccount.account),
  });

  const [closeAccount, { loading }] = useMutation(CLOSE_ACCOUNT, {
    variables: {
      input: {
        accountId: objectId,
        inactiveReason: 'OTHER',
      },
    },
    onCompleted: () => {
      navigate(`/clients/${userId}`);
    },
  });

  const onCloseClose = () => {
    onClose();
    setCloseOpen(false);
  };

  const onEditClose = () => {
    onClose();
    setAccountData(accountFetched.fetchAccount.account);
    setEditOpen(false);
  };

  const error = (key: keyof Account): boolean => (
    ((!accountData?.[key] && focused.includes(key)) || invalidFieldsList.includes(key)) && options?.[key]?.required !== 'NOT_REQUIRED'
  );

  const isAccountNumberInvalid = !accountData?.custodianAccountNumber && permissions.includes('read:account_number');
  const accountNumberError = () => accountData?.state === AccountStates.ACTIVE && isAccountNumberInvalid;

  const refetchAll = () => {
    if (refreshWidgets) refreshWidgets();
    refetch();
  };

  const submit = () => {
    const fields = invalidFields({
      ...options,
    }, accountData as any);
    setFocused(fields);
    setInvalidFieldsList(fields);
    if (fields.length === 0 && !accountNumberError()) {
      if (updated) updateAccount().then(() => refetchAll());
      if (accountData?.state && ![AccountStates.INITIATED, AccountStates.INACTIVE].includes(accountData.state) && updatedState) {
        setTimeout(() => updateAccountState().then(() => refetchAll()), 250);
      }
      setEditOpen(false);
      onClose();
      setUpdated(false);
      setUpdatedState(false);
    }
  };

  return (
    <>
      {showEdit && <MenuItem onClick={() => setEditOpen(true)}>{t('editAccount.edit')}</MenuItem>}
      {options.enableAddingAccountToHousehold && associateAccountToHousehold && (
        accountHouseholds.length > 1 ? (
          accountHouseholds.map((household) => (
            <MenuItem onClick={() => setAssociateAccountModal(household)}>{t('client:accountDetails.asociateAccountToNamedHousehold', { name: household.name })}</MenuItem>
          ))
        ) : (
          <MenuItem onClick={() => setAssociateAccountModal(accountHouseholds[0])}>{t('client:accountDetails.asociateAccountToHousehold')}</MenuItem>
        )
      )}
      {options.enableAddingAccountToHousehold && disassociateAccountToHousehold && (
        <MenuItem onClick={() => setDissociateAccountModal(true)}>{t('client:accountDetails.dissociateAccountFromHousehold')}</MenuItem>
      )}
      {options?.canCloseAccount && (permissions.includes('write:account_basic') || permissions.includes('write:account_basic')) && (
        <MenuItem onClick={() => setCloseOpen(true)}>{t('pageConfiguration:editAccount.closeAccount')}</MenuItem>
      )}
      {options?.canForceOpenAccount && (permissions.includes('write:force_account_open')) && !accountData?.forceOpen && (
        <MenuItem onClick={ () => forceOpenAccount()}>{t('pageConfiguration:editAccount.forceOpenAccount')}</MenuItem>
      )}
      <Dialog open={closeOpen} onClose={onCloseClose} fullWidth maxWidth='xs'>
        <DialogTitle>{t('editAccount.closeAccount')}</DialogTitle>
        <DialogContent>
          <Typography variant='bodyLarge'>{t('editAccount.closeAccountText')}</Typography>
          <Box mt={2} display='flex' justifyContent='flex-end'>
            <Button variant='text' label={t('editAccount.cancel')} onClick={onCloseClose} sx={{ mr: 1 }}/>
            <Button variant='filled' color='destructive' label={t('editAccount.close')} onClick={closeAccount} disabled={loading} />
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog open={editOpen} onClose={onEditClose} fullWidth maxWidth='xs'>
        <DialogTitle display='flex' justifyContent='space-between' alignItems='center'>
          {t('editAccount.editAccount')}
          <CloseIcon onClick={onEditClose} sx={{ cursor: 'pointer' }}/>
        </DialogTitle>
        <Form onSubmit={submit}>
          <DialogContent>
            {accountData && (
              <Grid container spacing={2}>
                {showNickName && (
                  <Grid item xs={12}>
                    <TranslatableTextField
                      data-testid='account-nickName'
                      fullWidth
                      label={translateBackend(options?.nickName?.label)}
                      disabled={accountLoading || options?.nickName?.required === 'NOT_EDITABLE'}
                      value={accountData?.translatedNickName ?? { en: accountData?.nickName ?? '' }}
                      onChange={(e: any) => {
                        setUpdated(true);
                        setAccountData({ ...accountData, translatedNickName: e || null, nickName: translateBackend(e) });
                        if (e && invalidFieldsList.includes('nickName')) {
                          const newList = invalidFieldsList.filter((elem) => elem !== 'nickName');
                          setInvalidFieldsList([...newList]);
                        }
                      }}
                      error={error('nickName')}
                      locked={options?.nickName?.required === 'NOT_EDITABLE'}
                      lockMessage={t('pageConfiguration:notEditableMessage')}
                      fallbackLanguage={getBackendLanguage()}
                    />
                  </Grid>
                )}
                {showAccountStatus && (
                  <Grid item xs={12}>
                    <SelectField
                      data-testid='account-state'
                      onChange={(e: any) => {
                        if (e.target.value === accountFetched?.fetchAccount?.account?.state) {
                          setUpdatedState(false);
                        } else {
                          setUpdatedState(true);
                        }
                        setAccountData({ ...accountData, state: e.target.value });
                        if (e.target.value && invalidFieldsList.includes('state')) {
                          const newList = invalidFieldsList.filter((elem) => elem !== 'state');
                          setInvalidFieldsList([...newList]);
                        }
                      }}
                      disabled={accountLoading || options?.state?.required === 'NOT_EDITABLE'}
                      label={translateBackend(options?.state?.label)}
                      fullWidth
                      value={accountData?.state ?? ''}
                      onBlur={() => setFocused([...focused, 'state'])}
                      error={error('state')}
                      locked={options?.state?.required === 'NOT_EDITABLE'}
                      lockMessage={t('pageConfiguration:notEditableMessage')}
                    >
                    {allowedStates[accountFetched?.fetchAccount?.account?.state as AccountStates]
                      ?.map((state: AccountStates) => (
                        <MenuItem key={state} disabled={state === AccountStates.INITIATED || (state === AccountStates.ACTIVE && isAccountNumberInvalid)} value={state}>
                          {t(`accountsDetail:accountState.${state}`)}
                        </MenuItem>
                      ))
                    }
                    </SelectField>
                  </Grid>
                )}
                {showAccountNumber && (
                  <Grid item xs={12}>
                    <TextField
                      data-testid='account-custodianAccountNumber'
                      onChange={(e: any) => {
                        setUpdated(true);
                        if (accountData?.state === AccountStates.ACTIVE && !e.target.value) setUpdatedState(false);
                        setAccountData({
                          ...accountData,
                          custodianAccountNumber: e.target.value || null,
                          ...accountData?.state === AccountStates.ACTIVE && !e.target.value ? { state: accountFetched?.fetchAccount?.account?.state } : {},
                        });
                        if (e.target.value && invalidFieldsList.includes('custodianAccountNumber')) {
                          const newList = invalidFieldsList.filter((elem) => elem !== 'custodianAccountNumber');
                          setInvalidFieldsList([...newList]);
                        }
                      }}
                      disabled={accountLoading || options?.custodianAccountNumber?.required === 'NOT_EDITABLE'}
                      label={translateBackend(options?.custodianAccountNumber?.label)}
                      fullWidth
                      value={accountData?.custodianAccountNumber ?? ''}
                      onBlur={() => setFocused([...focused, 'custodianAccountNumber'])}
                      error={error('custodianAccountNumber') || accountNumberError()}
                      locked={options?.custodianAccountNumber?.required === 'NOT_EDITABLE'}
                      lockMessage={t('pageConfiguration:notEditableMessage')}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </DialogContent>
          <DialogFooter>
            <Box display='flex' justifyContent='end'>
              <Button data-testid="confirm-button" label={t('client:update')} disabled={accountLoading || !(updated || updatedState)} type='submit' />
            </Box>
          </DialogFooter>
        </Form>
      </Dialog>
      {associateAccountModal && accountData?.id && (
        <AssociateAccountToHouseholdModal
          accountId={accountData.id}
          household={associateAccountModal}
          handleClose={() => {
            setAssociateAccountModal(null);
            refetch();
            onClose();
          }}
        />
      )}
      {dissociateAccountModal && accountData?.id && (
        <DissociateAccountFromHouseholdModal
          accountId={accountData.id}
          householdName={accountData.householdClientGroup?.name ?? ''}
          handleClose={() => {
            setDissociateAccountModal(false);
            refetch();
            onClose();
          }}
        />
      )}
    </>
  );
};
