/* eslint-disable no-useless-escape */
import intersection from 'lodash/fp/intersection';
import { CustomField, CustomFieldConditionalRulesComparisonTypes } from '../interfaces/customField';

// Introducing inner object validation
export const getNestedValue = (obj: any, path: string) => path.split('.').reduce((acc, key) => acc && acc[key], obj);

export const evaluateRule = (
  object: any,
  rule: { field: string, comparison: CustomFieldConditionalRulesComparisonTypes, value: any },
) => {
  let objectValue = object[rule.field];

  if (!objectValue && rule.field) {
    const relatedCustomObjs = object.customFields?.filter((a: CustomField) => a.key === rule.field) || [];
    if (relatedCustomObjs[0]) {
      objectValue = relatedCustomObjs[0].value;
    }
  }
  /*
    Introducing inner object validation
    usage:
    If your object has PhysicalAddress { unit: '123', jurisdiction: 'CA_QC', country: 'CA'}
    now you can define customField conditional logics with inner objects
    rule examples:
         'PhysicalAddress.jurisdiction' EQUALS 'CA_QC'
         'PhysicalAddress.country' EQUALS 'CA'
  */

  if (!objectValue && rule.field && rule.field.includes('.')) {
    objectValue = getNestedValue(object, rule.field);
  }
  let expectedValue = rule.value;
  const { comparison } = rule;
  if (!objectValue) return false;

  switch (comparison) {
    case CustomFieldConditionalRulesComparisonTypes.EQUALS:
      if (typeof objectValue === 'number') {
        const objectValueNumber = Number(expectedValue);
        const expectedValueNumber = Number(objectValue);
        return objectValueNumber === expectedValueNumber;
      }
      return objectValue.toString() === expectedValue.toString();
    case CustomFieldConditionalRulesComparisonTypes.NOT_EQUALS:
      if (typeof objectValue === 'number') {
        const objectValueNumber = Number(expectedValue);
        const expectedValueNumber = Number(objectValue);
        return objectValueNumber !== expectedValueNumber;
      }
      return objectValue.toString() !== expectedValue.toString();
    case CustomFieldConditionalRulesComparisonTypes.INCLUDES:
      if (expectedValue.includes('[')) {
        expectedValue = expectedValue.replace(/[\[\]]/g, '').split(',').map((item: string) => item.replace(/"/g, '').trim());
      }
      if (objectValue.includes('[')) {
        objectValue = objectValue.replace(/[\[\]]/g, '').split(',').map((item: string) => item.replace(/"/g, '').trim());
      }
      if (Array.isArray(objectValue) && Array.isArray(expectedValue)) {
        const intersectionItems = intersection(objectValue, expectedValue) || [];
        return intersectionItems && intersectionItems.length > 0;
      }
      if (Array.isArray(objectValue)) {
        return objectValue.includes(expectedValue);
      }
      if (Array.isArray(expectedValue)) {
        return expectedValue.includes(objectValue);
      }
      return false;
    case CustomFieldConditionalRulesComparisonTypes.NOT_INCLUDES:
      if (expectedValue.includes('[')) {
        expectedValue = expectedValue.replace(/[\[\]]/g, '').split(',').map((item: string) => item.replace(/"/g, '').trim());
      }
      if (objectValue.includes('[')) {
        objectValue = objectValue.replace(/[\[\]]/g, '').split(',').map((item: string) => item.replace(/"/g, '').trim());
      }
      if (Array.isArray(objectValue) && Array.isArray(expectedValue)) {
        const intersectionItems = intersection(objectValue, expectedValue) || [];
        return intersectionItems && intersectionItems.length === 0;
      }
      if (Array.isArray(objectValue)) {
        return !objectValue.includes(expectedValue);
      }
      if (Array.isArray(expectedValue)) {
        return !expectedValue.includes(objectValue);
      }
      return true;
    case CustomFieldConditionalRulesComparisonTypes.IN:
      expectedValue = expectedValue.replace('[', '').replace(']', '');
      return !!expectedValue.split(',')?.includes(objectValue);
    case CustomFieldConditionalRulesComparisonTypes.NOT_IN:
      expectedValue = expectedValue.replace('[', '').replace(']', '');
      return !expectedValue.split(',')?.includes(objectValue);
    case CustomFieldConditionalRulesComparisonTypes.GREATER_THAN:
      return objectValue > expectedValue;
    case CustomFieldConditionalRulesComparisonTypes.LESS_THAN:
      return objectValue < expectedValue;
    case CustomFieldConditionalRulesComparisonTypes.GREATER_THAN_AGO:
      if (objectValue instanceof Date) {
        const currentDate = new Date();
        const differenceInMilliseconds: any = currentDate.getTime() - objectValue.getTime();
        return differenceInMilliseconds > expectedValue;
      }
      return false;
    case CustomFieldConditionalRulesComparisonTypes.LESS_THAN_AGO:
      if (objectValue instanceof Date) {
        const currentDate = new Date();
        const differenceInMilliseconds: any = currentDate.getTime() - objectValue.getTime();
        return differenceInMilliseconds < expectedValue;
      }
      return false;
    default:
      return false;
  }
};

export const evaluateTriggerRules = (customField: CustomField, objectPayload?: any, ignoreCustomFieldTriggerRules?: boolean) => {
  if (!customField.conditionalRules || customField.conditionalRules.length === 0 || ignoreCustomFieldTriggerRules) {
    return true;
  }
  if (!objectPayload) {
    return false;
  }
  let result = true;
  for (const rule of customField.conditionalRules) {
    result = evaluateRule(objectPayload, rule);
    if (!result) break;
  }

  return result;
};
