import { ListItem, TextField } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormModal from './formModal';
import { usePermissions } from '../../providers/userContextProvider';

interface InputProp {
  hasError: boolean,
  message: string,
  value: string,
}

interface BankAccountProps {
  id: string,
  bankAccountNumber: string,
  institutionNumber: string,
  name: string,
  transitNumber: string,
}

export const CREATE_BANK_ACCOUNT = (permissions: string[]) => gql`
  mutation createBankAccount($input: CreateBankAccountInput!) {
    createBankAccount(input: $input) {
      bankAccount {
        id
        institutionNumber
        name
        bankAccountNumber
        transitNumber
        ${permissions.includes('read:bank_account_disclosed') ? 'bankAccountNumberDisclosed transitNumberDisclosed' : ''}
      }
    }
  }
`;

const styles = {
  input: {
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
    },
  },
};

const AddBankAccountModal = ({
  afterCreate, userId, open, handleClose,
}: { afterCreate: (bankAccount: BankAccountProps) => void, userId: string, open: boolean, handleClose: () => void }) => {
  const { t } = useTranslation(['components']);
  const { permissions } = usePermissions();
  const [bankAccountName, setBankAccountName] = useState<InputProp>({ hasError: false, message: '', value: '' });
  const [institutionNumber, setInstitutionNumber] = useState<InputProp>({ hasError: false, message: '', value: '' });
  const [transitNumber, setTransitNumber] = useState<InputProp>({ hasError: false, message: '', value: '' });
  const [bankAccount, setBankAccount] = useState<InputProp>({ hasError: false, message: '', value: '' });

  const resetFields = () => {
    setBankAccountName({ value: '', hasError: false, message: '' });
    setInstitutionNumber({ value: '', hasError: false, message: '' });
    setTransitNumber({ value: '', hasError: false, message: '' });
    setBankAccount({ value: '', hasError: false, message: '' });
  };

  const handleOnComplete = (data: { createBankAccount: { bankAccount: { id: string, bankAccountNumber: string, institutionNumber: string, name: string, transitNumber: string } } }) => {
    resetFields();
    afterCreate(data.createBankAccount.bankAccount);
  };

  const [createBankAccountMutation] = useMutation(CREATE_BANK_ACCOUNT(permissions), {
    variables: {
      input: {
        bankAccountNumber: bankAccount.value,
        institutionNumber: institutionNumber.value,
        name: bankAccountName.value,
        transitNumber: transitNumber.value,
        userId,
      },
    },
    onCompleted: (data) => handleOnComplete(data),
  });

  const validateBankAccountName = (value?: string): boolean => {
    let isValid = true;
    const name = value ?? bankAccountName.value;
    if (name.length === 0) {
      isValid = false;
      setBankAccountName((prevState) => ({ ...prevState, hasError: true, message: t('components:bankAccountSelect.addBankModal.inputErrors.bankAccountNameCannotBeEmpty') }));
    } else {
      setBankAccountName((prevState) => ({ ...prevState, hasError: false, message: '' }));
    }
    return isValid;
  };

  const validateInstitutionNumber = (value?: string) => {
    let isValid = true;
    const number = value ?? institutionNumber.value;
    if (number.length === 0 || number.length !== 3) {
      isValid = false;
      const errorMessage = number.length === 0
        ? t('components:bankAccountSelect.addBankModal.inputErrors.institutionNumberCannotBeEmpty')
        : t('components:bankAccountSelect.addBankModal.inputErrors.institutionNumberLength');
      setInstitutionNumber((prevState) => ({ ...prevState, hasError: true, message: errorMessage }));
    } else {
      setInstitutionNumber((prevState) => ({ ...prevState, hasError: false, message: '' }));
    }
    return isValid;
  };

  const validateTransitNumber = (value?: string) => {
    let isValid = true;
    const number = value ?? transitNumber.value;
    if (number.length === 0 || number.length !== 5) {
      isValid = false;
      const errorMessage = number.length === 0
        ? t('components:bankAccountSelect.addBankModal.inputErrors.transitNumberCannotBeEmpty')
        : t('components:bankAccountSelect.addBankModal.inputErrors.transitNumberLength');
      setTransitNumber((prevState) => ({ ...prevState, hasError: true, message: errorMessage }));
    } else {
      setTransitNumber((prevState) => ({ ...prevState, hasError: false, message: '' }));
    }
    return isValid;
  };

  const validateBankAccountNumber = (value?: string) => {
    let isValid = true;
    const number = value ?? bankAccount.value;
    if (number.length === 0 || number.length < 5 || number.length > 17) {
      isValid = false;
      const errorMessage = bankAccount.value.length === 0
        ? t('components:bankAccountSelect.addBankModal.inputErrors.bankAccountNumberCannotBeEmpty')
        : t('components:bankAccountSelect.addBankModal.inputErrors.bankAccountNumberRange');
      setBankAccount((prevState) => ({ ...prevState, hasError: true, message: errorMessage }));
    } else {
      setBankAccount((prevState) => ({ ...prevState, hasError: false, message: '' }));
    }
    return isValid;
  };

  const areFieldsValid = (): boolean => {
    let isValid = true;
    if (!validateBankAccountName()) {
      isValid = false;
    }
    if (!validateInstitutionNumber()) {
      isValid = false;
    }
    if (!validateTransitNumber()) {
      isValid = false;
    }
    if (!validateBankAccountNumber()) {
      isValid = false;
    }
    return isValid;
  };

  const createBankAccount = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (!areFieldsValid()) return;
    createBankAccountMutation().then();
  };

  return (
    <FormModal
      onSubmit={(e) => createBankAccount(e)}
      loading={false}
      disabled={false}
      title={t('components:bankAccountSelect.addBankModal.title')}
      open={open}
      handleClose={handleClose}
      formButton={t('shared:create')}
      maxWidth='sm'
    >
      <ListItem>
        <TextField
          data-testid="bankAccountName-text-field"
          error={bankAccountName.hasError}
          value={bankAccountName.value}
          helperText={bankAccountName.message}
          label={t('components:bankAccountSelect.addBankModal.bankAccountName')}
          fullWidth
          onChange={(e: any) => {
            setBankAccountName((prevState) => ({ ...prevState, value: e.target.value }));
            validateBankAccountName(e.target.value);
          }}
        />
      </ListItem>
      <ListItem>
        <TextField
          data-testid="institutionNumber-text-field"
          sx={styles.input}
          error={institutionNumber.hasError}
          value={institutionNumber.value}
          helperText={institutionNumber.message}
          label={t('components:bankAccountSelect.addBankModal.institutionNumber')}
          inputProps={{ type: 'number' }}
          fullWidth
          onChange={(e: any) => {
            setInstitutionNumber((prevState) => ({ ...prevState, value: e.target.value }));
            validateInstitutionNumber(e.target.value);
          }}
        />
      </ListItem>
      <ListItem>
        <TextField
          data-testid="transitNumber-text-field"
          sx={styles.input}
          error={transitNumber.hasError}
          value={transitNumber.value}
          helperText={transitNumber.message}
          label={t('components:bankAccountSelect.addBankModal.transitNumber')}
          inputProps={{ type: 'number' }}
          fullWidth
          onChange={(e: any) => {
            setTransitNumber((prevState) => ({ ...prevState, value: e.target.value }));
            validateTransitNumber(e.target.value);
          }}
        />
      </ListItem>
      <ListItem>
        <TextField
          data-testid="bankAccount-text-field"
          sx={styles.input}
          error={bankAccount.hasError}
          value={bankAccount.value}
          helperText={bankAccount.message}
          label={t('components:bankAccountSelect.addBankModal.bankAccount')}
          inputProps={{ type: 'number' }}
          fullWidth
          onChange={(e: any) => {
            setBankAccount((prevState) => ({ ...prevState, value: e.target.value }));
            validateBankAccountNumber(e.target.value);
          }}
        />
      </ListItem>
    </FormModal>
  );
};

export default AddBankAccountModal;
