import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/AddRounded';
import { Box } from '../../../../1-primative';
import {
  Dialog, ClientSelectField, MenuItem,
  DialogContent,
  DialogTitle,
  Form,
  Button,
  DialogFooter,
  SelectField, IconButton,
} from '../../../../2-component';
import { AffiliationRelations } from '../../../../../interfaces';

interface Relationship {
  type: string,
  userId: string,
  accessType: string,
}

const AddNewMember = (
  {
    onUserVerify,
  }:
  {
    onUserVerify: (relationship?: Relationship | undefined) => void,
  },
) => {
  const { t } = useTranslation(['clients', 'household']);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({
    accessType: 'VIEW',
    relationType: '',
    userId: '',
    firstName: '',
    lastName: '',
    entityName: '',
  });

  const addRelationship = async () => {
    onUserVerify({
      userId: user.userId,
      type: user.relationType,
      accessType: user.accessType,
    });
    setUser({
      accessType: 'VIEW',
      relationType: '',
      userId: '',
      firstName: '',
      lastName: '',
      entityName: '',
    });
    setOpen(false);
  };

  return (
    <>
      <IconButton data-testid='add-household-member-button' onClick={() => setOpen(true)}>
        <AddIcon data-testid='add-household-member-icon' />
      </IconButton>
      <Dialog fullWidth maxWidth='sm' onClose={() => setOpen(false)} open={open}>
        <DialogTitle onClose={() => setOpen(false)}>
          {t('household:relationModal.relationTitle')}
        </DialogTitle>
        <Form onSubmit={addRelationship}>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <ClientSelectField
                label={t('household:relationModal.client')}
                fullWidth
                user={{
                  id: user.userId, firstName: user.firstName, lastName: user.lastName, entityName: user.entityName,
                }}
                setUser={(u: any) => setUser({
                  ...user, userId: u?.id, firstName: u?.firstName, lastName: u?.lastName, entityName: u?.entityName,
                })}
                isJointAccount
              />
              <SelectField
                fullWidth
                value={user?.relationType ?? ''}
                label={t('household:relationModal.relationType')}
                onChange={(e: any) => setUser({ ...user, relationType: e.target.value })}
              >
                {Object.values(AffiliationRelations).map((relation, index) => (
                  <MenuItem key={index} value={relation}>
                    {t(`affiliationTypes:affiliationRelationship.${relation}`)}
                  </MenuItem>
                ))}
              </SelectField>
              <SelectField
                fullWidth
                label={t('household:relationModal.permission')}
                onChange={(e: any) => setUser({ ...user, accessType: e.target.value })}
                value={user?.accessType ?? ''}
              >
                <MenuItem value="VIEW">{t('household:accessTypes.VIEW')}</MenuItem>
                <MenuItem value="NO_ACCESS">{t('household:accessTypes.NO_ACCESS')}</MenuItem>
              </SelectField>
            </Box>
          </DialogContent>
          <DialogFooter>
            <Button
              type='submit'
              label={t('shared:save')}
              disabled={!user.accessType || !user.userId || !user.relationType}
            />
          </DialogFooter>
        </Form>
      </Dialog>
    </>
  );
};

export default AddNewMember;
