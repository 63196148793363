import { useLazyQuery } from '@apollo/client';
import React, {
  createContext, ReactNode, useCallback, useContext, useEffect, useState,
} from 'react';
import { FETCH_INTEGRATIONS } from '../pages/devSettings/components/integrations';
import { usePermissions, UserContext } from './userContextProvider';
import { initAnalytics, sendAnalyticsEvent } from '../util/analytics/analytics';
import { NavigationStyles } from '../interfaces';

type AnalyticsContextType = {
  sendAnalytic: (props: string, meta?: any) => void,
};

export interface AnalyticsProviderType {
  type: AnalyticsProviderTypes,
  id: string
}

export enum AnalyticsProviderTypes {
  GOOGLE = 'GOOGLE',
  MIXPANEL = 'MIXPANEL',
}

const firebaseDefaultConfig = {
  apiKey: process.env.REACT_APP_ANALYTICS_API_KEY ?? '',
  authDomain: process.env.REACT_APP_ANALYTICS_AUTH_DOMAIN ?? '',
  projectId: process.env.REACT_APP_ANALYTICS_PROJECT_ID ?? '',
  storageBucket: process.env.REACT_APP_ANALYTICS_STORAGE_BUCKET ?? '',
  messagingSenderId: process.env.REACT_APP_ANALYTICS_MESSAGING_SENDER_ID ?? '',
  appId: process.env.REACT_APP_ANALYTICS_APP_ID ?? '',
  measurementId: process.env.REACT_APP_ANALYTICS_MEASUREMENT_ID ?? '',
};

const AnalyticsContext = createContext<AnalyticsContextType>({ sendAnalytic: () => { } });
const AnalyticsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { activeOrganization, userContext, activeEntity } = useContext(UserContext);
  const [provider, setProvider] = useState<AnalyticsProviderType[]>([]);
  const [lastSendEvent, setLastSentEvent] = useState<string>('');
  const { permissions } = usePermissions();
  const getDefaultFirebase = () => {
    try {
      initAnalytics({
        provider: AnalyticsProviderTypes.GOOGLE,
        providerId: 'onevest-default',
        configType: firebaseDefaultConfig,
      });
    } catch (e) { /* empty */ }
  };
  const getDefaultMixPanel = () => {
    try {
      initAnalytics({
        provider: AnalyticsProviderTypes.MIXPANEL,
        providerId: 'onevest-default',
        configType: {
          projectToken: process.env.REACT_APP_MIX_PANEL_PROJECT_TOKEN ?? '',
        },
        user: {
          id: activeEntity?.id ?? userContext.id,
          name: activeEntity ? `${activeEntity?.firstName} ${activeEntity?.lastName}` : `${userContext.firstName} ${userContext.lastName}`,
          email: activeEntity?.primaryEmail ?? userContext.email,
        },
      });
    } catch (e) { /* empty */ }
  };
  const sendAnalytic = useCallback((event: string, meta?: any) => {
    if (event === lastSendEvent) return;
    const mergedMeta = {
      ...meta,
      subdomainName: userContext.organization?.subdomain,
      orgId: activeOrganization.id,
      role: userContext.role?.translatedName?.en,
      roleProfile: userContext.role?.publicRoleProfile?.translatedName?.en,
    };
    sendAnalyticsEvent(provider, event, mergedMeta).then();
    setLastSentEvent(event);
  }, [
    activeOrganization.id,
    lastSendEvent, provider, userContext.organization?.subdomain,
    userContext.role?.translatedName?.en,
    userContext.role?.publicRoleProfile?.translatedName?.en,
  ]);
  const [fetchIntegration] = useLazyQuery(FETCH_INTEGRATIONS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
          type: 'ANALYTICS',
        },
      },
    },
    onCompleted: (response): void => {
      const analyticsIntegrations: AnalyticsProviderType[] = [
        { type: AnalyticsProviderTypes.GOOGLE, id: 'onevest-default' },
        { type: AnalyticsProviderTypes.MIXPANEL, id: 'onevest-default' },
        ...response.fetchIntegrations
          .integrations.filter((integration: any) => integration.type === 'ANALYTICS')
          .map((integration: any) => ({ type: integration.provider, id: integration.id })),
      ];
      setProvider(analyticsIntegrations);
      getDefaultFirebase();
      getDefaultMixPanel();
      response.fetchIntegrations
        .integrations.filter((integration: any) => integration.type === 'ANALYTICS')
        .forEach((integration: any): void => {
          if (integration.provider === AnalyticsProviderTypes.GOOGLE) {
            try {
              initAnalytics(
                {
                  provider: AnalyticsProviderTypes.GOOGLE,
                  providerId: integration.id,
                  configType: {
                    apiKey: integration.configuration.apiKey,
                    authDomain: integration.configuration.authDomain,
                    projectId: integration.configuration.projectId,
                    storageBucket: integration.configuration.storageBucket,
                    messagingSenderId: integration.configuration.messagingSenderId,
                    appId: integration.configuration.appId,
                    measurementId: integration.configuration.measurementId,
                  },
                },
              );
            } catch (e) { /* empty */ }
          }
          if (integration.provider === AnalyticsProviderTypes.MIXPANEL) {
            initAnalytics({
              provider: AnalyticsProviderTypes.MIXPANEL,
              providerId: integration.id,
              configType: {
                projectToken: integration.configuration.TBD,
              },
              user: {
                id: activeEntity?.id ?? userContext.id,
                name: activeEntity ? `${activeEntity?.firstName} ${activeEntity?.lastName}` : `${userContext.firstName} ${userContext.lastName}`,
                email: activeEntity?.primaryEmail ?? userContext.email,
              },
            });
          }
        });
    },
    onError: (e): void => { setDefaultProviders(); },
  });

  const setDefaultProviders = () => {
    getDefaultFirebase();
    getDefaultMixPanel();
    setProvider([
      { type: AnalyticsProviderTypes.GOOGLE, id: 'onevest-default' },
      { type: AnalyticsProviderTypes.MIXPANEL, id: 'onevest-default' },
    ]);
  };

  useEffect(() => {
    const hasUserContext = userContext && Object.entries(userContext).length > 0;
    const isUnifiedClientExperience = userContext.role?.navigationStyle === NavigationStyles.SIMPLE;
    if ((hasUserContext && isUnifiedClientExperience && activeEntity) || (!isUnifiedClientExperience && hasUserContext)) {
      if (permissions.includes('read:integrations')) {
        fetchIntegration().then();
      } else {
        setDefaultProviders();
      }
    }
    // eslint-disable-next-line
  }, [fetchIntegration, activeEntity, userContext]);

  return (
    <AnalyticsContext.Provider value={{ sendAnalytic }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export {
  AnalyticsProvider,
  AnalyticsContext,
};
