import { gql, useMutation, useQuery } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { MenuItem } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useTranslation } from 'react-i18next';
import { Avatar, IconButton, SelectField } from 'ovComponents/2-component';
import { FeatureFlagTypes } from 'interfaces/organizationFeatureFlag';
import { Box } from 'ovComponents/1-primative/box/box';
import { ConfirmationModal } from '../confirmationDialog/confirmationDialog';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import AddBankAccount from '../addBankAccount/addBankAccount';
import { isFeatureEnabled } from '../../../util';
import { getBankIconPath } from './bankAccountIcon';
import { usePermissions } from '../../../providers/userContextProvider';
import { BankAccount } from '../../../interfaces';

const FETCH_USER_BANK_ACCOUNTS = (permissions: string[]) => gql`
  query fetchBankAccounts($input: FetchBankAccountsInput!) {
    fetchBankAccounts(input: $input) {
      bankAccounts {
        id
        institutionNumber
        name
        bankAccountNumber
        bankAccountTransitPhysicalAddress { country }
        transitNumber
        ${permissions.includes('read:bank_account_disclosed') ? 'bankAccountNumberDisclosed transitNumberDisclosed' : ''}
      }
    }
  }
`;

const DELETE_BANK_ACCOUNT = gql`
  mutation deleteBankAccount($bankAccountId: ObjectID!) {
    deleteBankAccount(bankAccountId: $bankAccountId) {
      bankAccount { id name }
    }
  }
`;

interface BankAccountSelectFieldProp {
  userId: string,
  bankAccount: BankAccount | null,
  setBankAccount: (value: BankAccount | null) => void,
  discloseBankAccountNumber?: boolean
  sx?: any,
  onBlur?: () => void,
  error?: any,
  transactionType?: 'DEPOSIT' | 'WITHDRAWAL',
  disabled?: boolean,
}

export const BankAccountSelectField = ({
  userId, bankAccount, discloseBankAccountNumber = false, sx, error, disabled, transactionType, onBlur, setBankAccount,
}: BankAccountSelectFieldProp) => {
  const selectedBankAccountId = useRef('');
  const { t } = useTranslation(['components']);
  const { sys } = useThemeTokens();
  const { permissions } = usePermissions();

  const [addBankAccount, setAddBankAccount] = useState(false);
  const [openDeleteBankModal, setOpenDeleteBankModal] = useState(false);
  const [userBankAccounts, setUserBankAccounts] = useState<BankAccount[]>([]);
  const [addedBankAccountId, setAddedBankAccountId] = useState<string | undefined>();

  const { loading, refetch } = useQuery(FETCH_USER_BANK_ACCOUNTS(permissions), {
    skip: !userId,
    variables: { input: { filter: { userId } } },
    onCompleted: (data) => setUserBankAccounts(data?.fetchBankAccounts?.bankAccounts),
  });

  const [deleteBankAccountMutation] = useMutation(DELETE_BANK_ACCOUNT, {
    variables: { bankAccountId: selectedBankAccountId.current },
    onCompleted: refetch,
  });

  const enableManualBankAccount: boolean = isFeatureEnabled(FeatureFlagTypes.MANUALLY_ADD_BANK_ACCOUNT);
  const enableFlinks: boolean = isFeatureEnabled(FeatureFlagTypes.AUTOMATED_BANK_ACCOUNT_LINKING);

  const setSelectedBankAccount = (bankAccountId: string) => {
    selectedBankAccountId.current = bankAccountId;
    setOpenDeleteBankModal(true);
  };

  const confirmBankAccountDeletion = () => {
    deleteBankAccountMutation();
    setOpenDeleteBankModal(false);
    setBankAccount(null);
  };

  useEffect(() => {
    if (addedBankAccountId) {
      setBankAccountById(addedBankAccountId);
      setAddedBankAccountId(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userBankAccounts, addedBankAccountId]);

  const setBankAccountById = (id: string) => {
    const found = userBankAccounts.find((bank: { id: string; }) => bank.id === id);
    if (found) setBankAccount(found);
  };

  const handleBankAccountSelect = (value: string) => {
    if (value === 'addBank') setAddBankAccount(true);
    else setBankAccountById(value);
  };

  const renderValue = (ba: BankAccount | null) => {
    if (!ba) return '';
    const accountNumber = discloseBankAccountNumber && ba.bankAccountNumberDisclosed
      ? ba.bankAccountNumberDisclosed
      : ba.bankAccountNumber;
    return `${ba.name} ${accountNumber}`;
  };

  return (
    <>
      <SelectField
        fullWidth
        sx={sx}
        testId="select-bank-account"
        onChange={(e: any) => handleBankAccountSelect(e.target.value)}
        label={t('components:bankAccountSelect.addBankModal.bankAccount')}
        value={bankAccount?.id || ''}
        onBlur={onBlur}
        disabled={disabled}
        error={error}
        renderValue={() => renderValue(bankAccount)}
      >
        {!loading && userBankAccounts.map((item: any) => (
          <MenuItem key={item.id} value={item.id} data-testid={`bank-account-item-${item.name}`} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box display='flex' alignItems='center'>
              <Box mr={1}>
                <Avatar size='large'>
                  <img alt='bank-account-icon' src={getBankIconPath(item?.institutionNumber ?? '', item?.transitNumberDisclosed ?? '')} style={{
                    objectFit: 'cover', height: '100%', width: '100%',
                  }} />
                </Avatar>
              </Box>
              {renderValue(item)}
            </Box>
            <IconButton onClick={() => setSelectedBankAccount(item.id)}><DeleteForeverIcon /></IconButton>
          </MenuItem>
        ))}
        {(enableManualBankAccount || enableFlinks) && (
          <MenuItem sx={{ color: sys.color.primary }} data-testid="add-bank-account" value='addBank'>{t('components:bankAccountSelect.addBank')}</MenuItem>
        )}
      </SelectField>
      <AddBankAccount
        data-testid="add-bank-account"
        handleClose={() => setAddBankAccount(false)}
        open={addBankAccount}
        userId={userId}
        bankAccounts={loading ? [] : userBankAccounts}
        afterCreate={async (newBankAccountId: string) => {
          await refetch();
          setAddBankAccount(false);
          setAddedBankAccountId(newBankAccountId);
        }}
        transactionType={transactionType}
      />
      <ConfirmationModal
        open={openDeleteBankModal}
        onCancel={() => setOpenDeleteBankModal(false)}
        onConfirm={confirmBankAccountDeletion}
        title={t('components:bankAccountSelect.deleteBankAccountModal.title')}
        bodyText={t('components:bankAccountSelect.deleteBankAccountModal.body')}
      />
    </>
  );
};
