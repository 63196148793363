/* eslint-disable no-param-reassign */
import {
  Chip,
  FormControlLabel,
  Grid,
  ListItemText,
} from '@mui/material';
import { translateBackend } from 'assets/i18n/config';
import dayjs from 'dayjs';
import { TranslatedString } from 'interfaces';
import { CustomFieldFormats, CustomFieldTypes, CustomOption } from 'interfaces/customField';
import { Box } from 'ovComponents/1-primative/box/box';
import {
  AddressField,
  Autocomplete,
  Checkbox,
  DateField,
  MenuItem,
  RadioGroup,
  SelectField,
  Radio,
} from 'ovComponents/2-component';
import { Switch } from 'ovComponents/2-component/switch/switch';
import { TextField } from 'ovComponents/2-component/textField/textField';
import { useTranslation } from 'react-i18next';
import { kebabCase, uniq } from 'lodash';
import { AmountField } from '../amountField/amountField';
import { additionalInfo } from '../../4-module/configurableOptionFields';
import { NumberField } from '../numberField/numberField';
import { evaluateTriggerRules } from '../../../util/evaluateTriggerRules';
import { SelectionTile } from '../selectionTile/selectionTile';

interface CustomRenderProps {
  customOptions: CustomOption[],
  customData: any,
  update: (newValue: any) => void,
  grid?: boolean,
  focused: string[],
  setFocused: any,
  loading: boolean,
  ignoreCustomFieldTriggerRules?: boolean,
}
export const CustomFieldRenderer = ({
  customOptions,
  customData,
  update, grid,
  focused,
  setFocused,
  loading,
  ignoreCustomFieldTriggerRules,
}: CustomRenderProps) => {
  const { t } = useTranslation('client');
  if (customOptions && customOptions.length === 0) {
    return null;
  }

  return (
    <>
      {customOptions.filter((c: CustomOption) => (evaluateTriggerRules(c.customField, customData, ignoreCustomFieldTriggerRules))).map((customOption: CustomOption, index) => (
        <Grid item key={customOption.customField.key} xs={12} md={12}>
          {customOption.customField && customOption.customField.type === CustomFieldTypes.INTEGER
            && [CustomFieldFormats.NUMBER].includes(customOption.customField.format)
            && (<NumberField
              testId={kebabCase(customOption.customField.key)}
              label={translateBackend(customOption?.label)}
              disabled={loading || customOption?.required === 'NOT_EDITABLE'}
              locked={customOption?.required === 'NOT_EDITABLE'}
              infoTooltip={additionalInfo(customOption?.additionalInfo)}
              number={customData.customFields?.find((i: any) => i.key === customOption.customField.key)?.value ?? ''}
              setNumber={(e: any) => {
                let found = false;
                if (customData.customFields) {
                  customData.customFields.forEach((element: any) => {
                    if (element.key === customOption.customField.key) {
                      element.value = parseInt(e, 10);
                      found = true;
                    }
                  });
                }
                if (found) {
                  update({ ...customData, customFields: customData.customFields });
                } else {
                  const newCustomFields = [...customData.customFields, {
                    key: customOption.customField.key,
                    value: parseInt(e, 10),
                    customField: customOption.customField,
                  }];
                  update({ ...customData, customFields: newCustomFields });
                }
              }}
            />)}

          {customOption.customField && customOption.customField.type === CustomFieldTypes.DECIMAL
            && [CustomFieldFormats.NUMBER].includes(customOption.customField.format)
            && (<NumberField
              testId={kebabCase(customOption.customField.key)}
              label={translateBackend(customOption?.label)}
              disabled={loading || customOption?.required === 'NOT_EDITABLE'}
              locked={customOption?.required === 'NOT_EDITABLE'}
              infoTooltip={additionalInfo(customOption?.additionalInfo)}
              number={customData.customFields?.find((i: any) => i.key === customOption.customField.key)?.value ?? ''}
              setNumber={(e: any) => {
                let found = false;
                if (customData.customFields) {
                  customData.customFields.forEach((element: any) => {
                    if (element.key === customOption.customField.key) {
                      element.value = parseFloat(e);
                      found = true;
                    }
                  });
                }
                if (found) {
                  update({ ...customData, customFields: customData.customFields });
                } else {
                  const newCustomFields = [...customData.customFields, {
                    key: customOption.customField.key,
                    value: parseFloat(e),
                    customField: customOption.customField,
                  }];
                  update({ ...customData, customFields: newCustomFields });
                }
              }}
            />)}

          {customOption.customField && customOption.customField.type === CustomFieldTypes.INTEGER
            && [CustomFieldFormats.CURRENCY].includes(customOption.customField.format)
            && (<AmountField
              testId={kebabCase(customOption.customField.key)}
              infoTooltip={additionalInfo(customOption?.additionalInfo)}
              disabled={loading || customOption?.required === 'NOT_EDITABLE'}
              locked={customOption?.required === 'NOT_EDITABLE'}
              label={translateBackend(customOption?.label)}
              amount={customData.customFields?.find((i: any) => i.key === customOption.customField.key)?.value ?? ''}
              setAmount={(e: any) => {
                let found = false;
                if (customData.customFields) {
                  customData.customFields.forEach((element: any) => {
                    if (element.key === customOption.customField.key) {
                      element.value = parseInt(e, 10);
                      found = true;
                    }
                  });
                }
                if (found) {
                  update({ ...customData, customFields: customData.customFields });
                } else {
                  const newCustomFields = [...customData.customFields, {
                    key: customOption.customField.key,
                    value: parseInt(e, 10),
                    customField: customOption.customField,
                  }];
                  update({ ...customData, customFields: newCustomFields });
                }
              }}
            />)}

          {customOption.customField && customOption.customField.type === CustomFieldTypes.DATE
            && [CustomFieldFormats.DATE].includes(customOption.customField.format)
            && (<DateField
              dataTestId={kebabCase(customOption.customField.key)}
              onChange={(date: any) => {
                let found = false;
                customData.customFields.forEach((element: any) => {
                  if (element.key === customOption.customField.key) {
                    element.value = dayjs(date?.toString()).format('YYYY-MM-DD');
                    found = true;
                  }
                });
                if (found) {
                  update({ ...customData, customFields: customData.customFields });
                } else {
                  const newCustomFields = [...customData.customFields, {
                    key: customOption.customField.key,
                    value: dayjs(date?.toString()).format('YYYY-MM-DD'),
                    customField: customOption.customField,
                  }];
                  update({ ...customData, customFields: newCustomFields });
                }
              }}
              disabled={loading || customOption?.required === 'NOT_EDITABLE'}
              label={translateBackend(customOption?.label)}
              fullWidth
              infoTooltip={additionalInfo(customOption?.additionalInfo)}
              value={customData.customFields?.find((i: any) => i.key === customOption.customField.key)?.value ?? {}}
              onBlur={() => setFocused([...focused, customOption.customField.key])}
              error={!customData.customFields?.find((i: any) => i.key === customOption.customField.key)?.value
                && focused.includes(customOption.customField.key) && customOption?.required !== 'NOT_REQUIRED'}
            />)}

          {customOption.customField && customOption.customField.type === CustomFieldTypes.DATE
            && [CustomFieldFormats.DATE_TIME].includes(customOption.customField.format)
            && (<DateField
              dataTestId={kebabCase(customOption.customField.key)}
              onChange={(date: any) => {
                let found = false;
                customData.customFields.forEach((element: any) => {
                  if (element.key === customOption.customField.key) {
                    element.value = dayjs(date?.toString()).format('YYYY-MM-DD HH:mm:ss a');
                    found = true;
                  }
                });
                if (found) {
                  update({ ...customData, customFields: customData.customFields });
                } else {
                  const newCustomFields = [...customData.customFields, {
                    key: customOption.customField.key,
                    value: dayjs(date?.toString()).format('YYYY-MM-DD HH:mm:ss a'),
                    customField: customOption.customField,
                  }];
                  update({ ...customData, customFields: newCustomFields });
                }
              }}
              infoTooltip={additionalInfo(customOption?.additionalInfo)}
              type={customOption.customField.format}
              disabled={loading || customOption?.required === 'NOT_EDITABLE'}
              label={translateBackend(customOption?.label)}
              fullWidth
              value={customData.customFields?.find((i: any) => i.key === customOption.customField.key)?.value ?? {}}
              onBlur={() => setFocused([...focused, customOption.customField.key])}
              error={!customData.customFields?.find((i: any) => i.key === customOption.customField.key)?.value
                && focused.includes(customOption.customField.key) && customOption?.required !== 'NOT_REQUIRED'}
            />)}

          {customOption.customField && customOption.customField.type === CustomFieldTypes.DECIMAL
            && [CustomFieldFormats.CURRENCY].includes(customOption.customField.format)
            && (<AmountField
              testId={kebabCase(customOption.customField.key)}
              label={translateBackend(customOption?.label)}
              disabled={loading || customOption?.required === 'NOT_EDITABLE'}
              locked={customOption?.required === 'NOT_EDITABLE'}
              infoTooltip={additionalInfo(customOption?.additionalInfo)}
              amount={customData.customFields?.find((i: any) => i.key === customOption.customField.key)?.value ?? ''}
              setAmount={(e: any) => {
                let found = false;
                if (customData.customFields) {
                  customData.customFields.forEach((element: any) => {
                    if (element.key === customOption.customField.key) {
                      element.value = parseFloat(e);
                      found = true;
                    }
                  });
                }
                if (found) {
                  update({ ...customData, customFields: customData.customFields });
                } else {
                  const newCustomFields = [...customData.customFields, {
                    key: customOption.customField.key,
                    value: parseFloat(e),
                    customField: customOption.customField,
                  }];
                  update({ ...customData, customFields: newCustomFields });
                }
              }}
            />)}

          {customOption.customField && customOption.customField.type === CustomFieldTypes.CUSTOM_OBJECT
            && [
              CustomFieldFormats.PHYSICAL_ADDRESS,
            ].includes(customOption.customField.format)
            && (<AddressField
              testId={kebabCase(customOption.customField.key)}
              onChange={(e: any) => {
                let found = false;
                customData.customFields.forEach((element: any) => {
                  if (element.key === customOption.customField.key) {
                    element.value = e.target.value;
                    found = true;
                  }
                });
                if (found) {
                  update({ ...customData, customFields: customData.customFields });
                } else {
                  const newCustomFields = [...customData.customFields, {
                    key: customOption.customField.key,
                    value: e,
                    customField: customOption.customField,
                  }];
                  update({ ...customData, customFields: newCustomFields });
                }
              }}
              disabled={loading || customOption?.required === 'NOT_EDITABLE'}
              label={translateBackend(customOption?.label)}
              fullWidth
              address={customData.customFields?.find((i: any) => i.key === customOption.customField.key)?.value ?? {}}
              onBlur={() => setFocused([...focused, customOption.customField.key])}
              error={!customData.customFields?.find((i: any) => i.key === customOption.customField.key)?.value
                && focused.includes(customOption.customField.key) && customOption?.required !== 'NOT_REQUIRED'}
              infoTooltip={additionalInfo(customOption?.additionalInfo)}
            />)}

          {customOption.customField && customOption.customField.type === CustomFieldTypes.TEXT
            && [
              CustomFieldFormats.SIMPLE_TEXT,
              CustomFieldFormats.PHONE,
              CustomFieldFormats.EMAIL,
            ].includes(customOption.customField.format)
            && (<TextField
              testId={kebabCase(customOption.customField.key)}
              key={customOption.customField.key}
              onChange={(e: any) => {
                let found = false;
                customData.customFields.forEach((element: any) => {
                  if (element.key === customOption.customField.key) {
                    element.value = e.target.value;
                    found = true;
                  }
                });
                if (found) {
                  update({ ...customData, customFields: customData.customFields });
                } else {
                  const newCustomFields = [...customData.customFields, {
                    key: customOption.customField.key,
                    value: e.target.value,
                    customField: customOption.customField,
                  }];
                  update({ ...customData, customFields: newCustomFields });
                }
              }}
              disabled={loading || customOption?.required === 'NOT_EDITABLE'}
              label={translateBackend(customOption?.label)}
              fullWidth
              infoTooltip={additionalInfo(customOption?.additionalInfo)}
              value={customData.customFields?.find((i: any) => i.key === customOption.customField.key)?.value ?? ''}
              onBlur={() => setFocused([...focused, customOption.customField.key])}
              error={!customData.customFields?.find((i: any) => i.key === customOption.customField.key)?.value
                && focused.includes(customOption.customField.key) && customOption?.required !== 'NOT_REQUIRED'}
              locked={customOption?.required === 'NOT_EDITABLE'}
              lockMessage={t('pageConfiguration:notEditableMessage')}
            />)}

          {customOption.customField && customOption.customField.type === CustomFieldTypes.TEXT
            && customOption.customField.format === CustomFieldFormats.TEXT_AREA
            && (<TextField
              testId={kebabCase(customOption.customField.key)}
              multiline={true}
              key={customOption.customField.key}
              rows={3}
              infoTooltip={additionalInfo(customOption?.additionalInfo)}
              onChange={(e: any) => {
                let found = false;
                customData.customFields.forEach((element: any) => {
                  if (element.key === customOption.customField.key) {
                    element.value = e.target.value;
                    found = true;
                  }
                });
                if (found) {
                  update({ ...customData, customFields: customData.customFields });
                } else {
                  const newCustomFields = [...customData.customFields, {
                    key: customOption.customField.key,
                    value: e.target.value,
                    customField: customOption.customField,
                  }];
                  update({ ...customData, customFields: newCustomFields });
                }
              }}
              disabled={loading || customOption?.required === 'NOT_EDITABLE'}
              label={translateBackend(customOption?.label)}
              fullWidth
              value={customData.customFields?.find((i: any) => i.key === customOption.customField.key)?.value ?? ''}
              onBlur={() => setFocused([...focused, customOption.customField.key])}
              error={!customData.customFields?.find((i: any) => i.key === customOption.customField.key)?.value
                && focused.includes(customOption.customField.key) && customOption?.required !== 'NOT_REQUIRED'}
              locked={customOption?.required === 'NOT_EDITABLE'}
              lockMessage={t('pageConfiguration:notEditableMessage')}
            />)}

          {customOption.customField && customOption.customField.type === CustomFieldTypes.BOOLEAN
            && customOption.customField.format === CustomFieldFormats.TOGGLE_SWITCH && (<FormControlLabel
              control={<Switch
                testId={kebabCase(customOption.customField.key)}
                size='small'
                key={customOption.customField.key}
                sx={{ paddingLeft: '12px' }}
                checked={customData.customFields?.find((i: any) => i.key === customOption.customField.key)?.value || false}
                disabled={loading || customOption?.required === 'NOT_EDITABLE'}
                infoTooltip={additionalInfo(customOption?.additionalInfo)}
                onChange={(e: any) => {
                  let found = false;
                  customData.customFields?.forEach((element: any) => {
                    if (element.key === customOption.customField.key) {
                      element.value = e;
                      found = true;
                    }
                  });
                  if (found) {
                    update({ ...customData, customFields: customData.customFields });
                  } else {
                    const newCustomFields = [...customData.customFields, {
                      key: customOption.customField.key,
                      value: e,
                      customField: customOption.customField,
                    }];
                    update({ ...customData, customFields: newCustomFields });
                  }
                }}
                label={translateBackend(customOption?.label)}
              />}
              label=''
            />)}

          {customOption.customField && customOption.customField.type === CustomFieldTypes.BOOLEAN
            && customOption.customField.format === CustomFieldFormats.CHECKBOX && (<FormControlLabel
              control={<Checkbox
                testId={kebabCase(customOption.customField.key)}
                size='small'
                label={translateBackend(customOption?.label)}
                infoTooltip={additionalInfo(customOption?.additionalInfo)}
                key={customOption.customField.key}
                customStyle={{ paddingLeft: '12px' }}
                disabled={loading || customOption?.required === 'NOT_EDITABLE'}
                checked={customData?.customFields?.find((i: any) => i.key === customOption.customField.key)?.value || false}
                onChange={(e: any) => {
                  let found = false;
                  customData.customFields?.forEach((element: any) => {
                    if (element.key === customOption.customField.key) {
                      element.value = e;
                      found = true;
                    }
                  });
                  if (found) {
                    update({ ...customData, customFields: customData.customFields });
                  } else {
                    const newCustomFields = [...customData.customFields, {
                      key: customOption.customField.key,
                      value: e,
                      customField: customOption.customField,
                    }];
                    update({ ...customData, customFields: newCustomFields });
                  }
                }}
              />}
              label={''}
            />)}

          {customOption.customField && customOption.customField.type === CustomFieldTypes.SINGLE_SELECT
            && [CustomFieldFormats.SEARCH_LIST].includes(customOption.customField.format)
            && (<Autocomplete
              testId={kebabCase(customOption.customField.key)}
              label={translateBackend(customOption?.label)}
              disabled={loading || customOption?.required === 'NOT_EDITABLE'}
              locked={customOption?.required === 'NOT_EDITABLE'}
              lockMessage={t('pageConfiguration:notEditableMessage')}
              infoTooltip={additionalInfo(customOption?.additionalInfo)}
              options={customOption?.customField?.selectOptions?.map((x: any) => ({
                key: x.value,
                label: translateBackend(x.displayText),
              })) || []}
              size='small'
              value={customData.customFields?.find((i: any) => i.key === customOption.customField.key)?.value || ''}
              isOptionEqualToValue={(option: any, value: any) => option.key === value.key}
              fullWidth
              getOptionLabel={(option) => {
                if (option && option.key) {
                  return option.label;
                }
                const derivedLabel = customOption?.customField?.selectOptions?.find((item: any) => item.value === option)?.displayText || '';
                return translateBackend(derivedLabel);
              }}
              renderOption={(props: any, option: any) => (
                <li
                  {...props}
                  data-testid={`${kebabCase(customOption.customField.key)}-option`}
                >
                  {option.label}
                </li>
              )}
              onChange={(e: React.SyntheticEvent, value: any) => {
                let found = false;
                if (customData?.customFields) {
                  customData?.customFields?.forEach((element: any) => {
                    if (element?.key === customOption?.customField?.key && value?.key) {
                      element.value = value?.key;
                      found = true;
                    }
                  });
                }
                if (found) {
                  update({ ...customData, customFields: customData.customFields });
                } else {
                  const newCustomFields = [...customData.customFields, {
                    key: customOption.customField.key,
                    value: value ? value.key : '',
                    customField: customOption.customField,
                  }];
                  update({ ...customData, customFields: newCustomFields });
                }
              }}
            />
            )}

          {customOption.customField && customOption.customField.type === CustomFieldTypes.SINGLE_SELECT
            && [CustomFieldFormats.DROPDOWN_LIST].includes(customOption.customField.format)
            && (<SelectField
              testId={kebabCase(customOption.customField.key)}
              key={customOption.customField.key}
              infoTooltip={additionalInfo(customOption?.additionalInfo)}
              disabled={loading || customOption?.required === 'NOT_EDITABLE'}
              locked={customOption?.required === 'NOT_EDITABLE'}
              lockMessage={t('pageConfiguration:notEditableMessage')}
              fullWidth
              value={customData.customFields?.find((i: any) => i.key === customOption.customField.key)?.value || ''}
              label={translateBackend(customOption?.label)}
              onChange={(e: any) => {
                let found = false;
                customData?.customFields?.forEach((element: any) => {
                  if (element.key === customOption.customField.key) {
                    element.value = e.target.value;
                    found = true;
                  }
                });
                if (found) {
                  update({ ...customData, customFields: customData.customFields });
                } else {
                  const newCustomFields = [...customData.customFields, {
                    key: customOption.customField.key,
                    value: e.target.value,
                    customField: customOption.customField,
                  }];
                  update({ ...customData, customFields: newCustomFields });
                }
              }}
              onBlur={() => setFocused([...focused, customOption.customField.key])}
              error={!customData?.customFields?.find((i: any) => i.key === customOption.customField.key)?.value
                && focused.includes(customOption.customField.key) && customOption?.required !== 'NOT_REQUIRED'}
            >
              {
                customOption?.customField?.selectOptions?.map((optionItem: {
                  displayText?: TranslatedString, value: any,
                }) => (
                  <MenuItem key={optionItem.value} value={optionItem.value} data-testid={kebabCase(optionItem.value)}>{translateBackend(optionItem.displayText)}</MenuItem>
                ))
              }
            </SelectField>)}

          {customOption.customField && customOption.customField.type === CustomFieldTypes.SINGLE_SELECT
            && [CustomFieldFormats.RADIO_BUTTON].includes(customOption.customField.format)
            && ((customOption?.customField?.selectOptions?.length !== undefined) && customOption?.customField.selectOptions.length <= 2)
            && (
              <RadioGroup
              testId={kebabCase(customOption.customField.key)}
              key={customOption.customField.key}
              label={translateBackend(customOption?.label)}
              infoTooltip={additionalInfo(customOption?.additionalInfo)}
              disabled={loading || customOption?.required === 'NOT_EDITABLE'}
              error={!customData?.customFields?.find((i: any) => i.key === customOption.customField.key)?.value
                && focused.includes(customOption.customField.key) && customOption?.required !== 'NOT_REQUIRED'}
              value={customData.customFields?.find((i: any) => i.key === customOption.customField.key)?.value || ''}
              fullWidth
              onChange={(e: any) => {
                let found = false;
                customData?.customFields?.forEach((element: any) => {
                  if (element.key === customOption.customField.key) {
                    element.value = e.target.value;
                    found = true;
                  }
                });
                if (found) {
                  update({ ...customData, customFields: customData.customFields });
                } else {
                  const newCustomFields = [...customData.customFields, {
                    key: customOption.customField.key,
                    value: e.target.value,
                    customField: customOption.customField,
                  }];
                  update({ ...customData, customFields: newCustomFields });
                }
              }}
              >
                {
                customOption?.customField?.selectOptions?.map((optionItem: {
                  displayText?: TranslatedString, value: any,
                }) => (
                  <Radio value={optionItem.value} testId={kebabCase(optionItem.value)} label={translateBackend(optionItem?.displayText)}/>
                ))
                }
              </RadioGroup>
            )}

            {customOption.customField && customOption.customField.type === CustomFieldTypes.SINGLE_SELECT
            && [CustomFieldFormats.RADIO_BUTTON].includes(customOption.customField.format)
            && ((customOption?.customField?.selectOptions?.length !== undefined) && customOption?.customField.selectOptions.length > 2)
            && (
                <SelectionTile
                sx={Array.isArray(customOptions) && customOptions[customOptions.length - 1] !== customOption ? { paddingBottom: '32px' } : undefined}
                testId={kebabCase(customOption.customField.key)}
                infoTooltip={additionalInfo(customOption?.additionalInfo)}
                label={translateBackend(customOption?.label)}
                disabled={loading || customOption?.required === 'NOT_EDITABLE'}
                error={!customData?.customFields?.find((i: any) => i.key === customOption.customField.key)?.value
                  && focused.includes(customOption.customField.key) && customOption?.required !== 'NOT_REQUIRED'}
                value={customData.customFields?.find((i: any) => i.key === customOption.customField.key)?.value || ''}
                options={customOption?.customField?.selectOptions.map((optionItem) => (
                  { label: translateBackend(optionItem?.displayText), value: optionItem?.value }
                ))}
                onChange={(e: any) => {
                  let found = false;
                  customData?.customFields?.forEach((element: any) => {
                    if (element.key === customOption.customField.key) {
                      element.value = e.target.value;
                      found = true;
                    }
                  });
                  if (found) {
                    update({ ...customData, customFields: customData.customFields });
                  } else {
                    const newCustomFields = [...customData.customFields, {
                      key: customOption.customField.key,
                      value: e.target.value,
                      customField: customOption.customField,
                    }];
                    update({ ...customData, customFields: newCustomFields });
                  }
                }}/>
            )}

          {customOption.customField && customOption.customField.type === CustomFieldTypes.MULTIPLE_SELECT
            && [CustomFieldFormats.DROPDOWN_LIST].includes(customOption.customField.format)
            && (<>
            <SelectField
              testId={kebabCase(customOption.customField.key)}
              key={customOption.customField.key}
              disabled={loading || customOption?.required === 'NOT_EDITABLE'}
              locked={customOption?.required === 'NOT_EDITABLE'}
              lockMessage={t('pageConfiguration:notEditableMessage')}
              infoTooltip={additionalInfo(customOption?.additionalInfo)}
              fullWidth
              multiple
              value={customData.customFields?.find((i: any) => i.key === customOption.customField.key)?.value || []}
              label={translateBackend(customOption?.label)}
              onChange={(e: any) => {
                let found = false;
                customData?.customFields?.forEach((element: any) => {
                  if (element.key === customOption.customField.key) {
                    element.value = e.target.value;
                    found = true;
                  }
                });
                if (found) {
                  update({ ...customData, customFields: customData.customFields });
                } else {
                  const newCustomFields = [...customData.customFields, {
                    key: customOption.customField.key,
                    value: e.target.value,
                    customField: customOption.customField,
                  }];
                  update({ ...customData, customFields: newCustomFields });
                }
              }}
              renderValue={(selected: any) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value: any) => {
                    const derivedLabel = customOption?.customField?.selectOptions?.find((item: any) => item.value === value)?.displayText || '';
                    return (
                      <Chip size='small' key={value} label={translateBackend(derivedLabel)} />);
                  })}
                </Box>
              )}
              onBlur={() => setFocused([...focused, customOption.customField.key])}
              error={!customData?.customFields?.find((i: any) => i.key === customOption.customField.key)?.value
                && focused.includes(customOption.customField.key) && customOption?.required !== 'NOT_REQUIRED'}
            >
              {
                  customOption?.customField?.selectOptions?.map((optionItem: {
                    displayText?: TranslatedString, value: any,
                  }) => (
                    <MenuItem key={optionItem.value} value={optionItem.value} data-testid={kebabCase(optionItem.value)}>{translateBackend(optionItem.displayText)}</MenuItem>
                  ))
                }
            </SelectField>
            </>
            )}

          {customOption.customField && customOption.customField.type === CustomFieldTypes.MULTIPLE_SELECT
            && [CustomFieldFormats.CHECKBOX].includes(customOption.customField.format)
            && (<>
            <SelectField
              testId={kebabCase(customOption.customField.key)}
              key={customOption.customField.key}
              disabled={loading || customOption?.required === 'NOT_EDITABLE'}
              locked={customOption?.required === 'NOT_EDITABLE'}
              lockMessage={t('pageConfiguration:notEditableMessage')}
              infoTooltip={additionalInfo(customOption?.additionalInfo)}
              fullWidth
              multiple
              value={customData.customFields?.find((i: any) => i.key === customOption.customField.key)?.value || []}
              label={translateBackend(customOption?.label)}
              onChange={(e: any) => {
                let found = false;
                customData?.customFields?.forEach((element: any) => {
                  if (element.key === customOption.customField.key) {
                    element.value = e.target.value;
                    found = true;
                  }
                });
                if (found) {
                  update({ ...customData, customFields: customData.customFields });
                } else {
                  const newCustomFields = [...customData.customFields, {
                    key: customOption.customField.key,
                    value: e.target.value,
                    customField: customOption.customField,
                  }];
                  update({ ...customData, customFields: newCustomFields });
                }
              }}
              renderValue={(selected: any) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value: any) => {
                    const derivedLabel = customOption?.customField?.selectOptions?.find((item: any) => item.value === value)?.displayText || '';
                    return (
                      <Chip size='small' key={value} label={translateBackend(derivedLabel)} />);
                  })}
                </Box>
              )}
              onBlur={() => setFocused([...focused, customOption.customField.key])}
              error={!customData?.customFields?.find((i: any) => i.key === customOption.customField.key)?.value
                && focused.includes(customOption.customField.key) && customOption?.required !== 'NOT_REQUIRED'}
            >
              {
                  customOption?.customField?.selectOptions?.map((optionItem: {
                    displayText?: TranslatedString, value: any,
                  }) => (
                    <MenuItem key={optionItem.value} value={optionItem.value} data-testid={kebabCase(optionItem.value)}>
                      <Checkbox customStyle={{ paddingRight: '5px' }} size='small' checked={customData?.customField?.selectOptions?.find((item: any) => item.value === optionItem.value) > -1} />
                      <ListItemText primary={translateBackend(optionItem.displayText)} />
                    </MenuItem>
                  ))
                }
            </SelectField>
            </>
            )}

          {customOption.customField && customOption.customField.type === CustomFieldTypes.MULTIPLE_SELECT
            && [CustomFieldFormats.SEARCH_LIST].includes(customOption.customField.format)
            && (<Autocomplete
              testId={kebabCase(customOption.customField.key)}
              label={translateBackend(customOption?.label)}
              disabled={loading || customOption?.required === 'NOT_EDITABLE'}
              locked={customOption?.required === 'NOT_EDITABLE'}
              lockMessage={t('pageConfiguration:notEditableMessage')}
              size='small'
              infoTooltip={additionalInfo(customOption?.additionalInfo)}
              options={customOption?.customField?.selectOptions?.map((x: any) => ({
                key: x.value,
                label: translateBackend(x.displayText),
              })) || []}
              value={customData?.customFields?.find((i: any) => i.key === customOption.customField.key)?.value || []}
              isOptionEqualToValue={(option: any, value: any) => option.key === value.key}
              multiple
              fullWidth
              getOptionLabel={(option) => {
                if (option && option.key) {
                  return option.label;
                }
                const derivedLabel = customOption?.customField?.selectOptions?.find((item: any) => item.value === option)?.displayText || '';
                return translateBackend(derivedLabel);
              }}
              renderOption={(props: any, option: any) => (
                <li
                  {...props}
                  data-testid={`${kebabCase(customOption.customField.key)}-option`}
                >
                  {option.label}
                </li>
              )}
              onChange={(e: React.SyntheticEvent, value: any) => {
                let found = false;
                if (customData?.customFields) {
                  customData?.customFields?.forEach((element: any) => {
                    if (element.key === customOption.customField.key) {
                      element.value = uniq(value.map((a: any) => (a?.key ? a.key : a)));

                      found = true;
                    }
                  });
                }
                if (found) {
                  update({ ...customData, customFields: customData.customFields });
                } else {
                  const newCustomFields = [...customData.customFields, {
                    key: customOption.customField.key,
                    value: uniq(value),
                    customField: customOption.customField,
                  }];
                  update({ ...customData, customFields: newCustomFields });
                }
              }}
            />
            )}
        </Grid>
      ))}
    </>
  );
};
export default CustomFieldRenderer;
