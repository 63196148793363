/* eslint-disable object-curly-newline */
import { Typography as MuiTypography, SxProps, TypographyProps as MuiTypographyProps } from '@mui/material';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { useTypographyTokens } from './typography.tokens';

export type TypographyVariantOptions =
  | 'displayLarge'
  | 'displayLargeVariant'
  | 'displayMedium'
  | 'displayMediumVariant'
  | 'displaySmall'
  | 'headingLarge'
  | 'headingMedium'
  | 'headingSmall'
  | 'headingXSmall'
  | 'headingOverline'
  | 'titleLarge'
  | 'titleMedium'
  | 'bodyLarge'
  | 'bodyMedium'
  | 'bodySmall'
  | 'labelLarge'
  | 'labelSmall'
  | 'button'
  | 'trimmedXSmall'
  | 'trimmedSmall'
  | 'trimmedNormal'
  | 'trimmedMedium'
  | 'trimmedLarge'
  | 'monospacedXlarge'
  | 'monospacedLarge'
  | 'monospacedSmall'
  | 'none';

export interface TypographyPropsVariants {
  variant?: TypographyVariantOptions;
}
export interface TypographyProps extends Omit<MuiTypographyProps, 'variant'>, TypographyPropsVariants {
  weight?: 'regular' | 'bold';
  sx?: SxProps;
  children?: React.ReactNode;
  colorVariant?: 'default' | 'variant';
}

export const Typography = ({ variant = 'bodySmall', sx, children, weight = 'regular', colorVariant = 'default', ...props }: TypographyProps) => {
  const { sys, comp } = useThemeTokens(useTypographyTokens(weight));
  const color = props.color || (colorVariant === 'default' ? sys.color.onSurface : sys.color.onSurfaceVariant);

  return (
    <MuiTypography
      sx={{
        ...comp.typography[variant],
        color,
        ...sx,
      }}
      {...props}
    >
      {children}
    </MuiTypography>
  );
};
