import {
  Typography, TextField, MenuItem,
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useHouseholdContext } from 'pages/household';
import { useClientContext } from 'pages/client';
import { Theme, ThemeState } from 'interfaces';
import { usePermissions, UserContext } from '../../providers/userContextProvider';

const FETCH_THEMES = gql`
  query fetchThemes($input: FetchThemesInput!) {
    fetchThemes(input: $input) {
      themes {
        id
        translatedName {
          en
        }
      }
    }
  }
`;

const FETCH_AVAILABLE_THEMES = gql`
  query fetchAvailableThemes($input: FetchAvailableThemesInput!) {
    fetchAvailableThemes(input: $input) {
      themes {
        id
        translatedName {
          en
        }
      }
    }
  }
`;

const ThemeSelect = ({
  onChange, value, label, disabled = false, readonly = false, focused, inheritedText, suitabilityScore,
}: {
  onChange: (event: any) => void, value: string, label: string, disabled?: boolean, readonly?: boolean, focused?: boolean, inheritedText?: string, suitabilityScore?: number
}) => {
  const { activeOrganization } = useContext(UserContext);
  const clientContext = useClientContext();
  const householdContext = useHouseholdContext();
  const organizationId = activeOrganization.id ?? clientContext?.orgSettings.id ?? householdContext?.orgSettings.id;
  const [themes, setThemes] = useState<Theme[]>([]);
  const { permissions } = usePermissions();
  const doReadAllThemes = permissions.includes('read:all_themes');

  const { loading, error } = useQuery(!doReadAllThemes && suitabilityScore !== undefined ? FETCH_AVAILABLE_THEMES : FETCH_THEMES, {
    skip: !organizationId,
    variables: {
      input: !doReadAllThemes && suitabilityScore !== undefined ? {
        suitabilityScore,
        organizationId,
      } : {
        filter: { organizationId, state: ThemeState.ACTIVE },
        pagination: { perPage: 1000 },
      },
    },
    onCompleted: (data) => {
      setThemes((data.fetchThemes ?? data.fetchAvailableThemes).themes);
    },
  });

  if (error) (<Typography>Error</Typography>);

  /* pre-select if there's exactly one theme available */
  useEffect(() => {
    if (themes.length === 1 && value !== themes[0].id) onChange(themes[0].id);
  }, [themes, value, onChange]);

  return (
    <>
      <TextField
        select
        focused={focused}
        value={themes.length > 0 ? value : ''}
        label={label}
        fullWidth
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
        InputProps={{
          readOnly: readonly,
        }}
      >
        {
          loading && <MenuItem>...</MenuItem>
        }
        { themes.map((x: any) => (
          <MenuItem key={x.id} value={x.id}>{`${x.translatedName.en}${inheritedText ?? ''}`}</MenuItem>
        ))
        }
      </TextField>
    </>
  );
};

export default ThemeSelect;

export const testables = {
  FETCH_THEMES,
  FETCH_AVAILABLE_THEMES,
};
