import { SxProps } from '@mui/material';
import { Box, Typography } from '../../ovComponents';
import { translateBackend } from '../../assets/i18n/config';
import downTimeImage from '../../assets/images/downtime/downtime.svg';
import { DowntimeType } from '../../providers/userContextProvider';
import { useLayoutTokens } from '../../ovComponents/4-module/layout/layout.tokens';
import { useThemeTokens } from '../../providers/themeTokenProvider';

const Downtime = ({ downtime, testId = 'downtime' }: { downtime: DowntimeType, supportUrl?: string, testId?: string }) => {
  const { sys } = useThemeTokens(useLayoutTokens());
  return (
    <Box data-testid={testId} sx={containerSx}>
      <Box data-testid={`${testId}-content-container`} display='flex' gap="20px" flexDirection='column' width='40%' justifyContent='center' alignItems='center'>
        <Typography data-testid={`${testId}-title`} variant='displayMediumVariant' textAlign='center'>{translateBackend(downtime.translatedTitle)}</Typography>
        {downtime.translatedDescription && (
          <Typography data-testid={`${testId}-description`} variant='bodyLarge' textAlign='center' color={sys.color.onSurfaceVariant}>{translateBackend(downtime.translatedDescription)}</Typography>
        )}
      </Box>

      <Box display='flex' alignItems='center' justifyContent='center' maxHeight={{ xs: '70%', sm: '55%', md: '40%' }} maxWidth={{ xs: '70%', sm: '55%', md: '40%' }}>
        <img src={downTimeImage} alt='downtime-img' width='100%' height='100%' />
      </Box>
    </Box>
  );
};

export default Downtime;

const containerSx: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  gap: '30px',
  height: { xs: '100%', sm: 'calc(100vh - 110px)' }, // Note: 110px is the collective height for margin-top and top and bottom paddings.
  mt: { xs: 2, md: 0 },
};
