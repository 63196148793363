import {
  Typography, Box, CircularProgress, Table, TableHead,
  TableRow, TableCell, TableBody, Pagination,
  Grid, TextField, MenuItem, IconButton,
  Menu, Link as MuiLink, Chip, Tooltip,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ClearIcon from '@mui/icons-material/Clear';
import {
  gql, useMutation, useQuery,
} from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { useCorporateContext } from '../../../pages/corporation';
import { useHouseholdContext } from '../../../pages/household';
import { Date } from '../../misc/date/date';
import { formatMoneyValueCentsWithDecimals } from '../../../util';
import { usePermissions, UserContext } from '../../../providers/userContextProvider';
import NewTransaction from './newTransaction';
import { SubAccountSelect } from '../../inputs/subAccountSelect';
import AccountTypeSelect from '../../inputs/accountTypeSelect';
import {
  Currencies, HOLDING_CONTRIBUTION_TRANSACTION_TYPES, Transaction, TransactionTypes,
} from '../../../interfaces/transaction';
import UpdateTransaction from './updateTransaction';
import ConfirmationModal from '../../modals/confirmationModal';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import { NoPermissionAlert } from '../../misc/noPermissionAlert';
import { useClientContext } from '../../../pages/client';
import { LocalizedDatePicker } from '../../fields/localizedDatePicker';
import DownloadTransactions from './downloadTransactions';
import SecuritySelect from '../../inputs/securitySelect';
import { useAvailableCurrencies } from '../../../ovComponents';

export const FETCH_TRANSACTIONS_QUERY = (permissions: string[]) => `query fetchTransactions($input: FetchTransactionsInput!) {
  fetchTransactions(input: $input) {
    totalCount
    transactions {
      id
      type
      subAccount {
        id
        goal {
          id
          name
        }
        account {
          id
          type
          householdClientGroup {
            id
          }
        }
      }
      financialProduct { id ticker }
      date
      quantity
      priceCents
      valueCents
      currency
      valueCents
      description
      settleDate
      settleDateToWithdraw
      goal { id }
      organization { id }
      objectType
      objectId
      user {
        id
        ${permissions.includes('read:client_low_risk_pii') ? 'firstName middleName lastName' : ''}
      }
    }
  }
}`;

export const FETCH_TRANSACTIONS = (permissions: string[]) => gql`${FETCH_TRANSACTIONS_QUERY(permissions)}`;

export const DELETE_TRANSACTION = gql`
  mutation deleteTransaction($transactionId: ObjectID!) {
    deleteTransaction(transactionId: $transactionId) {
      transaction {
        id
      }
    }
  }
`;

const TransactionsTable = ({
  showClient = false, disabled, filter, onChange = () => { },
}: { showClient?: boolean, disabled?: boolean, filter: any, onChange?: () => void }) => {
  const { t } = useTranslation(['components', 'shared']);
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const clientContext = useClientContext();
  const householdContext = useHouseholdContext();
  const corporateContext = useCorporateContext();
  const { showToast } = useGlobalToast();
  const [activeTransaction, setActiveTransaction] = useState<Transaction>();
  const [page, setPage] = useState(1);
  const [filterOptions, setFilterOptions] = useState({
    type: 'ANY',
    accountType: 'ANY',
    subAccountId: filter.subAccountIds ?? 'any',
    dateAfter: null as string | null,
    dateBefore: null as string | null,
    financialProductId: 'ANY',
    currency: 'ANY',
  });
  const organizationId = (filter?.clientGroupId || filter.userIds)
    ? (corporateContext?.orgSettings?.id ?? householdContext?.orgSettings?.id)
    : (clientContext?.orgSettings?.id ?? activeOrganization.id);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const pageSize = 15;
  const [deleteTransaction, deleteOpt] = useMutation(DELETE_TRANSACTION);

  const queryFilter = {
    organizationId: organizationId ?? activeOrganization.id,
    types: filterOptions.type === 'ANY' ? undefined : filterOptions.type,
    dateAfter: filterOptions.dateAfter === null ? undefined : filterOptions.dateAfter,
    dateBefore: filterOptions.dateBefore === null ? undefined : filterOptions.dateBefore,
    accountType: filterOptions.accountType === 'ANY' ? undefined : filterOptions.accountType,
    subAccountIds: filterOptions.subAccountId === 'any' || filterOptions.subAccountId === '' ? undefined : filterOptions.subAccountId,
    financialProductIds: filterOptions.financialProductId === 'ANY' ? undefined : [filterOptions.financialProductId],
    currency: filterOptions.currency === 'ANY' ? undefined : filterOptions.currency,
  };

  const {
    loading, error, data, previousData, refetch,
  } = useQuery(FETCH_TRANSACTIONS(permissions), {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    variables: {
      input: {
        filter: {
          ...filter,
          ...queryFilter,
        },
        pagination: {
          sortField: 'date', sortDesc: false, perPage: pageSize, offSet: (page - 1) * pageSize,
        },
      },
    },
    onCompleted: (newData) => {
      if (page !== 1 && newData?.fetchTransactions?.totalCount > 0 && newData?.fetchTransactions?.transactions.length === 0) {
        setPage(1);
      }
    },
  });

  useAvailableCurrencies({
    userId: filter.userId,
    clientGroupId: filter.clientGroupId,
  });

  const canWrite = (transaction?: Transaction): boolean => (
    permissions.includes('write:transaction')
    || (permissions.includes('write:adjustment_transaction') && (
      !transaction || transaction.type === TransactionTypes.ADJUSTMENT
    ))
  );

  const onTransactionChange = () => {
    refetch();
    onChange();
  };

  const onConfirmDeleteTransaction = async () => {
    if (!activeTransaction?.id) return;
    const response = await deleteTransaction({
      variables: { transactionId: activeTransaction.id },
    });
    if (response?.data) {
      showToast({ severity: 'info', message: t('components:transaction.deleteTransaction.deletedToastMessage') });
    }
    onTransactionChange();
    setDeleteConfirmationOpen(false);
  };

  const addTypeDetails = (transaction: Transaction) => {
    if (transaction.financialProduct) {
      return ` - ${transaction.financialProduct.ticker}`;
    }
    return '';
  };

  const countDecimals = (value: number): number => {
    const num = value.toString().replace(/,/g, '');
    if (!num.includes('.')) return 0;
    return num.toString().split('.')[1].length;
  };

  const getUnroundedValue = (value?: number): string => {
    if (!value) return '';
    const decimalPlaces = countDecimals(value);
    return value.toFixed(Math.min(decimalPlaces, 6));
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (error) (<Typography>Error</Typography>);

  if (!permissions.includes('read:transaction')) return <NoPermissionAlert missing='read:transaction' />;

  return (
    <Box>
      {loading && !previousData ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ m: 18 }} />
        </Box>
      ) : (
        <>
          <Grid container sx={{ p: 2 }} spacing={1}>
            <Grid item sm={3} md={3} lg={3} xl={2}>
              <AccountTypeSelect
                userType=''
                value={filterOptions.accountType}
                onChange={(value) => setFilterOptions((prev) => ({ ...prev, accountType: value, subAccountId: '' }))}
              />
            </Grid>

            <Grid item sm={3} md={3} lg={3} xl={2}>
              <SubAccountSelect
                includeAnyOption
                {...filter.userId && { forObject: 'USER', forId: filter.userId }}
                {...filter.clientGroupId && { forObject: 'CLIENT_GROUP', forId: filter.clientGroupId }}
                onlyAccountType={filterOptions.accountType === 'ANY' ? undefined : filterOptions.accountType}
                value={filterOptions.subAccountId}
                setSubAccount={(e) => setFilterOptions((prev) => ({ ...prev, subAccountId: e.target.value }))}
                label={t('components:subAccount')}
                size='small'
              />
            </Grid>

            <Grid item sm={3} md={3} lg={3} xl={2}>
              <TextField
                select
                value={filterOptions.type}
                label={t('components:type')}
                onChange={(e: any) => setFilterOptions((prev) => ({ ...prev, type: e.target.value }))}
                size='small'
                fullWidth
                placeholder={t('components:any')}
              >
                <MenuItem value="ANY">{t('components:any')}</MenuItem>
                {Object.values(TransactionTypes)
                  .map((x: TransactionTypes) => (
                  <MenuItem value={x} key={x}>{t(`components:transaction.types.${x}`)}</MenuItem>
                  ))}
              </TextField>
            </Grid>

            <Grid item sm={3} md={3} lg={3} xl={1}>
              <LocalizedDatePicker
                label={t('components:fromDate')}
                value={filterOptions.dateAfter}
                onChange={(date) => setFilterOptions((prev) => ({ ...prev, dateAfter: dayjs(date).format('YYYY-MM-DD') }))}
                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                InputProps={{ endAdornment: filterOptions.dateAfter && (<IconButton onClick={() => setFilterOptions((prev) => ({ ...prev, dateAfter: null }))}><ClearIcon /></IconButton>) }}
              />
            </Grid>

            <Grid item sm={3} md={3} lg={3} xl={1}>
              <LocalizedDatePicker
                label={t('components:toDate')}
                value={filterOptions.dateBefore}
                onChange={(date) => setFilterOptions((prev) => ({ ...prev, dateBefore: dayjs(date).format('YYYY-MM-DD') }))}
                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                InputProps={{ endAdornment: filterOptions.dateBefore && (<IconButton onClick={() => setFilterOptions((prev) => ({ ...prev, dateBefore: null }))}><ClearIcon /></IconButton>) }}
              />
            </Grid>

            <Grid item sm={3} md={3} lg={3} xl={2}>
              <SecuritySelect
                value={filterOptions?.financialProductId ?? ''}
                setSecurity={(e) => setFilterOptions((prev) => ({ ...prev, financialProductId: e?.id ?? e }))}
                label={t('components:security')}
                size='small'
                withAny
              />
            </Grid>

            <Grid item sm={3} md={3} lg={3} xl={1}>
              <TextField
                select
                value={filterOptions.currency}
                label={t('components:transaction.table.currency')}
                onChange={(e: any) => setFilterOptions((prev) => ({ ...prev, currency: e.target.value }))}
                size='small'
                fullWidth
                placeholder={t('components:any')}
              >
                <MenuItem value="ANY">{t('components:any')}</MenuItem>
                {Object.values(Currencies).map((x: Currencies) => (
                  <MenuItem value={x} key={x}>{t(`components:transaction.currencies.${x}`)}</MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item sm={3} md={3} lg={3} xl={1} sx={{ textAlign: 'right' }}>
              {permissions.includes('write:adjustment_transaction') && (
                <NewTransaction
                  {...filter.userId && { forObject: 'USER', forId: filter.userId }}
                  {...filter.clientGroupId && { forObject: 'CLIENT_GROUP', forId: filter.clientGroupId }}
                  afterCreate={onTransactionChange}
                  disableButton={disabled}
                />
              )}
              {permissions.includes('read:api_exports') && (<DownloadTransactions filter={filter} queryFilter={queryFilter} />)}
            </Grid>

            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              {loading ? (<CircularProgress size='30px' sx={{ marginTop: '6px' }} />) : <></>}
            </Grid>
          </Grid>
          <Grid container sx={{ overflow: 'hidden' }}>
            <Grid item xs={12} sx={{ overflow: 'auto' }}>
              <Table aria-label="table">
                <TableHead>
                  <TableRow>
                    {showClient && (
                      <TableCell><Typography variant='overline'>{t('components:transaction.table.client')}</Typography></TableCell>
                    )}
                    <TableCell><Typography variant='overline'>{t('components:transaction.table.account')}</Typography></TableCell>
                    <TableCell><Typography variant='overline'>{t('components:transaction.table.date')}</Typography></TableCell>
                    <TableCell><Typography variant='overline'>{t('components:transaction.table.type')}</Typography></TableCell>
                    <TableCell><Typography variant='overline'>{t('components:transaction.table.quantity')}</Typography></TableCell>
                    <TableCell><Typography variant='overline'>{t('components:transaction.table.valueCents')}</Typography></TableCell>
                    <TableCell><Typography variant='overline'>{t('components:transaction.table.bookCost')}</Typography></TableCell>
                    <TableCell><Typography variant='overline'>{t('components:transaction.table.bookValue')}</Typography></TableCell>
                    <TableCell><Typography variant='overline'>{t('components:transaction.table.description')}</Typography></TableCell>
                    <TableCell><Typography variant='overline'>{t('components:transaction.table.currency')}</Typography></TableCell>
                    {canWrite() && (
                      <TableCell align='right'><Typography variant='overline'>{t('components:transaction.table.actions')}</Typography></TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {((data || previousData)?.fetchTransactions?.transactions || []).map((transaction: Transaction) => (
                    <TableRow
                      hover
                      onClick={() => {
                        setActiveTransaction(transaction);
                      }}
                      selected={transaction.id === activeTransaction?.id}
                      key={transaction.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 }, textDecoration: 'none' }}
                    >
                      {showClient && (
                        <TableCell component="th" scope="row">
                          <MuiLink component={Link} to={`/clients/${transaction.user?.id}`}>{transaction.user?.firstName} {transaction.user?.lastName}</MuiLink>
                        </TableCell>
                      )}
                      <TableCell component="th" scope="row">
                        {transaction.subAccount?.goal?.name} - {transaction.subAccount?.account?.type}
                      </TableCell>
                      <TableCell><Date variant='subtitle2' date={transaction.date} /></TableCell>
                      <TableCell>{t(`components:transaction.types.${transaction.type}`)}<b>{addTypeDetails(transaction)}</b></TableCell>
                      <TableCell>
                        {transaction.quantity === 0 ? '-' : (
                          <Tooltip title={getUnroundedValue(transaction.quantity)}>
                            <Chip
                              size='small'
                              label={getUnroundedValue(transaction.quantity)}
                              color={(transaction.quantity ?? 0) > 0 ? 'success' : 'error'}
                            />
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell>
                        <Chip
                          size='small'
                          label={formatMoneyValueCentsWithDecimals(transaction.valueCents, '$', 6)}
                          color={(transaction.valueCents ?? 0) >= 0 ? 'success' : 'error'}
                        />
                      </TableCell>
                      <TableCell>
                        {HOLDING_CONTRIBUTION_TRANSACTION_TYPES.includes(transaction.type)
                          ? formatMoneyValueCentsWithDecimals(transaction.priceCents, '$', 6)
                          : ''}
                      </TableCell>
                      <TableCell>
                        {HOLDING_CONTRIBUTION_TRANSACTION_TYPES.includes(transaction.type)
                          ? formatMoneyValueCentsWithDecimals((transaction.priceCents ?? 0) * (transaction.quantity ?? 0), '$', 6)
                          : ''}
                      </TableCell>
                      <TableCell>
                        <Typography title={transaction.description} sx={{
                          maxWidth: '300px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap',
                        }}>
                          {transaction.description}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography title={transaction.currency} sx={{
                          maxWidth: '300px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap',
                        }}>
                          {transaction.currency}
                        </Typography>
                      </TableCell>
                      {canWrite() && (
                        <TableCell align='right'><IconButton size='small'
                          onClick={(e) => {
                            e.stopPropagation();
                            setActiveTransaction(transaction);
                            handleClick(e);
                          }}
                          disabled={!canWrite(transaction)}
                        ><MoreVertIcon /></IconButton></TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
            <Pagination
              count={Math.ceil(((data || previousData)?.fetchTransactions?.totalCount ?? 0) / pageSize)}
              page={page}
              onChange={(_e, newPage) => setPage(newPage)}
              sx={{
                p: 1,
                textAlign: 'right',
                '.MuiPagination-ul': {
                  justifyContent: 'end',
                },
              }}
            />
          </Grid>
          <Menu
            anchorEl={anchorEl}
            id="transaction-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem value='UPDATE' onClick={() => setUpdateModalOpen(true)}>{t('components:transaction.actions.update')}</MenuItem>
            <MenuItem value='DELETE' onClick={() => setDeleteConfirmationOpen(true)}>{t('components:transaction.actions.delete')}</MenuItem>
          </Menu>
        </>
      )}
      {activeTransaction && (
        <>
          <UpdateTransaction transaction={activeTransaction} open={updateModalOpen} handleClose={() => setUpdateModalOpen(false)} afterUpdate={onTransactionChange} />
          <ConfirmationModal
            open={deleteConfirmationOpen}
            loading={deleteOpt.loading}
            title={t('components:transaction.deleteTransaction.title')}
            bodyText={t('components:transaction.deleteTransaction.body')}
            onConfirm={() => onConfirmDeleteTransaction()}
            onCancel={() => setDeleteConfirmationOpen(false)}
            confirmButton={t('shared:delete')}
            cancelButton={t('shared:cancel')}
          />
        </>
      )}
    </Box>
  );
};

export default TransactionsTable;
