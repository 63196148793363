import {
  Add, Edit, RemoveCircleOutline, CheckCircle, ArrowForwardIosSharp,
} from '@mui/icons-material';
import InfoDialog from 'ovComponents/2-component/infoDialog/infoDialog';
import { kebabCase } from 'lodash';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import { Box, Typography } from '../../1-primative';
import { IconButton } from '../../2-component';
import { useSelectionTileTokens } from '../selectionTile/selectionTile.tokens';

type CompletionBoxState = 'todo' | 'complete' | 'error' | 'next';
type CompletionBoxType = 'checkbox' | 'radio' | 'tile' | 'completion';

export const CompletionBox = ({
  state, title, onRemove, onAdd, onEdit, children, variant = 'edit', edittable = true, selected, infoTooltip,
  type, testId, error, errorText,
}: {
  state: CompletionBoxState; title: string; onRemove?: () => void; onAdd?: () => void; onEdit?: () => void;
  children?: any; variant?: 'edit' | 'view'; edittable?: boolean; type?: CompletionBoxType; selected?: boolean;
  infoTooltip?: string; testId?: string; error?: boolean; errorText?: string;
}) => {
  const { comp, sys } = useThemeTokens(useSelectionTileTokens());

  const borderColor = () => {
    if (error) return sys.color.negative;
    if (variant === 'view') return undefined;
    switch (state) {
      case 'todo':
        return sys.color.outlineVariant;
      case 'complete':
        return sys.color.positive;
      case 'error':
        return sys.color.negative;
      default:
        return sys.color.outlineVariant;
    }
  };

  return (
    <>
      <Box
        data-testid={testId ? `${testId}-${kebabCase(title)}` : 'completion-box'}
        display='flex'
        borderRadius={sys.borderRadius.xl}
        border={`1px solid ${borderColor()}`}
        pt={1}
        pl={3}
        pr={2}
        pb={2}
        flexDirection='column'
        mb={2}
        sx={{
          transition: 'background-color 0.3s ease-in-out',
          cursor: (state === 'todo' || type === 'completion') && variant === 'edit' ? 'pointer' : 'default',
          '&:hover': {
            backgroundColor: (state === 'todo' || type === 'completion') && variant === 'edit' ? sys.color.background : 'default',
          },
          background: selected ? comp.selectionTile.hoverBackgroundColor : sys.color.surface,
          boxShadow: variant === 'view' ? sys.elevation.surface : undefined,
        }}
        onClick={(state === 'todo' && variant === 'edit') || state === 'next' ? onAdd : undefined}
      >
        <Box display='flex' justifyContent='space-between' alignItems='center' minHeight={40}>
          <Box display='flex' flexDirection='row' alignItems='center'>
            <Box display='flex' flexDirection='row' alignItems={'center'}>
              <Typography data-testid={testId ? `${testId}-title-${kebabCase(title)}` : `completion-box-title-${kebabCase(title)}` } variant='titleMedium' sx={{ mb: '4px', mr: '4px' }}>
                {title}
                {infoTooltip && <InfoDialog information={infoTooltip} />}
              </Typography>
            </Box>
            {state === 'complete' && <CheckCircle sx={{ color: sys.color.positive, fontSize: '16px', ml: 0.5 }} />}
          </Box>
          <Box>
            {state === 'todo' && edittable && (
              <IconButton disableRipple={variant === 'edit'} onClick={variant === 'edit' ? undefined : onAdd} size='medium'>
                <Add data-testid={testId ? `${testId}-add-button` : 'add-button'} sx={{ color: sys.color.onSurfaceVariant }} />
              </IconButton>
            )}
            {state === 'complete' && onEdit && edittable && (
              <IconButton onClick={onEdit} size='small' sx={{ height: '26px' }}>
                <Edit data-testid={testId ? `${testId}-edit-button` : 'edit-button'} sx={{ fontSize: '16px' }} />
              </IconButton>
            )}
            {state === 'complete' && onRemove && edittable && (
              <IconButton onClick={onRemove} size='small' sx={{ height: '26px' }}>
                <RemoveCircleOutline data-testid={testId ? `${testId}-remove-button` : 'remove-button'} sx={{ fontSize: '16px' }} />
              </IconButton>
            )}
            {state === 'next' && <ArrowForwardIosSharp data-testid={testId ? `${testId}-next-button` : 'next-button'} sx={{ fontSize: '16px', color: sys.color.onSurfaceVariant }} />}
          </Box>
        </Box>
        {children}
      </Box>
      {error && (
        <Typography variant='labelSmall' sx={{ position: 'relative', top: '-10px' }} style={{ color: sys.color.negative }}>
          {errorText}
        </Typography>
      )}
    </>
  );
};
