/* eslint-disable object-curly-newline */
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { cloneDeep } from 'lodash/fp';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import SubTradeRequestsTableRow from './subTradeRequestsTableRow';
import { UserContext, usePermissions } from '../../../../../providers/userContextProvider';
import { Box, Skeleton, Typography } from '../../../../1-primative';
import { Checkbox, Table, TableBody, TableRow, Tooltip, TableHeadCell, TableCell, Dialog, DialogTitle, CardContent, Pagination, TextField, MenuItem } from '../../../../2-component';
import { AuditLog } from '../../auditLog/auditLog';
import { SubTradeRequest, SubTradeRequestStates } from '../../../../../interfaces/subTradeRequest';
import { buildInitialTradeToVerifyState, getSkeletonColumnsNumber } from './utils';
import { PageObjectType } from '../../../../5-page';
import { FilterModal } from '../../../../3-pattern';
import { FETCH_SUB_TRADE_REQUESTS } from './queries';

const TESTID_SUB_TRADES_TABLE_HEAD_CELL = (testIdPrefix: string, cell: string) => `${testIdPrefix}-head-cell-${cell}`;

const PER_PAGE = 15;
const VERIFIABLE_UNVERIFIABLE_STATES = [SubTradeRequestStates.INITIATED, SubTradeRequestStates.READY];

export const SUB_TRADE_REQUEST_PENDING_STATES = (allowPendingTransactions: boolean) => {
  const pendingStates = [SubTradeRequestStates.INITIATED, SubTradeRequestStates.READY];

  if (!allowPendingTransactions) {
    pendingStates.push(SubTradeRequestStates.REQUESTED);
  }

  return pendingStates;
};

const SUB_TRADE_REQUEST_COMPLETED_STATES = (allowPendingTransactions: boolean) => {
  const completedStates = [SubTradeRequestStates.RECONCILED, SubTradeRequestStates.CANCELED];

  if (allowPendingTransactions) {
    completedStates.push(SubTradeRequestStates.REQUESTED);
  }

  return completedStates;
};

interface SubTradeRequestFilter {
  // goalId or subAccountId should be present by default outside of this filter
  states?: SubTradeRequestStates[];
}

export interface TradeToVerify {
  id: string;
  selected: boolean;
  state: SubTradeRequestStates;
  verified: boolean;
}

const getQueryInput = (filter: Record<string, string>, pagination: Record<string, number>) => ({ filter, pagination });

const getFilter = ({
  id,
  type,
  filter,
  bulkTradeRequestId,
  sourceId,
  allowPendingTransactions,
}: {
  id?: string;
  type?: PageObjectType;
  filter?: SubTradeRequestFilter;
  bulkTradeRequestId?: string;
  sourceId?: string;
  allowPendingTransactions: boolean;
}) => {
  let filterQuery = {};

  // !todo: bulkTradeRequestId is temp, will be removed in the future
  if (bulkTradeRequestId) {
    filterQuery = {
      bulkTradeRequestId,
      ...(sourceId && { sourceId }),
    };
  }

  if (type && id) {
    filterQuery = {
      ...(type === PageObjectType.GOAL ? { goalId: id } : { subAccountId: id }),
      ...(filter || { states: SUB_TRADE_REQUEST_PENDING_STATES(allowPendingTransactions) }),
    };
  }

  return filterQuery;
};

const getPagination = (usePagination: boolean, page = 1) => ({
  ...(usePagination ? { perPage: PER_PAGE, offSet: (page - 1) * PER_PAGE } : { perPage: 100 }),
});

export const SubTradeRequestsTableHeader = ({
  expandable,
  verifiable,
  editable,
  isAllTradesToVerifySelected,
  onTradeVerifySelectAll,
  testIdPrefix = '',
}: {
  expandable?: boolean;
  verifiable?: boolean;
  editable?: boolean;
  isAllTradesToVerifySelected?: boolean;
  onTradeVerifySelectAll?: (isChecked: boolean) => void;
  testIdPrefix?: string;
}) => {
  const { t } = useTranslation(['components', 'shared']);

  return (
    <>
      <TableRow>
        {(expandable || (verifiable && editable)) && (
          <TableHeadCell testId={TESTID_SUB_TRADES_TABLE_HEAD_CELL(testIdPrefix, 'select-all')}>
            {verifiable && editable && (
              <Tooltip title={t('components:generateTrades.table.selectAll')} placement='top'>
                <Checkbox
                  checked={isAllTradesToVerifySelected}
                  onChange={(checked: boolean) => {
                    if (onTradeVerifySelectAll) onTradeVerifySelectAll(checked);
                  }}
                />
              </Tooltip>
            )}
          </TableHeadCell>
        )}
        <TableHeadCell testId={TESTID_SUB_TRADES_TABLE_HEAD_CELL(testIdPrefix, 'account')}>{t('components:generateTrades.table.account')}</TableHeadCell>
        <TableHeadCell testId={TESTID_SUB_TRADES_TABLE_HEAD_CELL(testIdPrefix, 'type')}>{t('components:generateTrades.table.type')}</TableHeadCell>
        <TableHeadCell right testId={TESTID_SUB_TRADES_TABLE_HEAD_CELL(testIdPrefix, 'amount')}>{t('components:generateTrades.table.amount')}</TableHeadCell>
        <TableHeadCell testId={TESTID_SUB_TRADES_TABLE_HEAD_CELL(testIdPrefix, 'security')}>{t('components:generateTrades.table.security')}</TableHeadCell>
        <TableHeadCell testId={TESTID_SUB_TRADES_TABLE_HEAD_CELL(testIdPrefix, 'settlement-days')}>{t('components:generateTrades.table.settlementDays')}</TableHeadCell>
        <TableHeadCell testId={TESTID_SUB_TRADES_TABLE_HEAD_CELL(testIdPrefix, 'exchange')}>{t('components:generateTrades.table.exchange')}</TableHeadCell>
        <TableHeadCell testId={TESTID_SUB_TRADES_TABLE_HEAD_CELL(testIdPrefix, 'settlement-date')}>{t('components:generateTrades.table.settlementDate')}</TableHeadCell>
        <TableHeadCell testId={TESTID_SUB_TRADES_TABLE_HEAD_CELL(testIdPrefix, 'state')}>{t('components:generateTrades.table.state')}</TableHeadCell>
        {verifiable && <TableHeadCell testId={TESTID_SUB_TRADES_TABLE_HEAD_CELL(testIdPrefix, 'verified-at')}>{t('components:generateTrades.table.verifiedAt')}</TableHeadCell>}
      </TableRow>
    </>
  );
};

const SubTradeRequestsTable = ({
  id,
  type,
  bulkTradeRequestId, // !todo: old support, will be removed
  sourceId, // !todo: old support, will be removed
  onTradesToVerifyUpdate,
  editable = true,
  useHeader = false,
  useFilter = false,
  usePagination = false,
  translationStrings,
  testIdPrefix = 'sub-trades-table',
}: {
  id?: string; // !todo: should be a required parameter
  type?: PageObjectType; // !todo: should be a required parameter
  bulkTradeRequestId?: string; // !todo: should be removed when bulkTradeRequest is deprecated
  sourceId?: string; // !todo: should be removed when bulkTradeRequest is deprecated
  onTradesToVerifyUpdate?: (trades: TradeToVerify[]) => void;
  editable?: boolean;
  useHeader?: boolean;
  useFilter?: boolean;
  usePagination?: boolean;
  translationStrings?: { noRecordsFound?: string; header?: string };
  testIdPrefix?: string;
}) => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const { t } = useTranslation('shared, components');

  const [expandable, setExpandable] = useState(false);
  const [verifiable, setVerifiable] = useState(false);
  const [auditOpen, setAuditOpen] = useState(false);
  const [activeSubTradeRequest, setActiveSubTradeRequest] = useState<any>(null);

  const [tradesToVerify, setTradesToVerify] = useState<TradeToVerify[]>([]);
  const [initialTradesToVerifyState, setInitialTradesToVerifyState] = useState<TradeToVerify[]>([]);

  const [subTradeRequests, setSubTradeRequests] = useState<SubTradeRequest[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);

  const [stateFilter, setStateFilter] = useState<SubTradeRequestStates | 'ANY'>('ANY');
  const [statesFilter, setStatesFilter] = useState<SubTradeRequestStates[]>(SUB_TRADE_REQUEST_COMPLETED_STATES(activeOrganization?.allowPendingTransactions ?? false));

  const { loading } = useQuery(FETCH_SUB_TRADE_REQUESTS(true), {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      input: getQueryInput(
        getFilter({ id, type, ...(useFilter && { filter: { states: statesFilter } }), bulkTradeRequestId, sourceId, allowPendingTransactions: activeOrganization?.allowPendingTransactions ?? false }),
        getPagination(usePagination, page),
      ),
    },
    skip: !id && !bulkTradeRequestId,
    onCompleted: (data: { fetchSubTradeRequests?: { subTradeRequests?: SubTradeRequest[]; totalCount?: number } }) => {
      setSubTradeRequests(data?.fetchSubTradeRequests?.subTradeRequests ?? []);
      setTotalCount(data?.fetchSubTradeRequests?.totalCount ?? 0);
    },
  });

  useEffect(() => {
    const isCanVerify = permissions.includes('write:sub_trade_request_verification');
    const isVerificationRequired = activeOrganization?.requireSubTradeRequestApproval ?? false;

    const isShowVerification = subTradeRequests?.some((trade: SubTradeRequest) => VERIFIABLE_UNVERIFIABLE_STATES.includes(trade?.state) && !trade?.bulkTradeRun?.id);

    setExpandable(subTradeRequests?.some((trade: SubTradeRequest) => !!trade?.splits?.length));
    setVerifiable(isCanVerify && isVerificationRequired && isShowVerification);

    const initialTradesToVerifyStateLocal = [];

    if (subTradeRequests?.length) {
      for (const subTradeRequest of subTradeRequests) {
        if (subTradeRequest?.splits?.length) {
          for (const split of subTradeRequest.splits) {
            if (VERIFIABLE_UNVERIFIABLE_STATES.includes(split.state)) {
              initialTradesToVerifyStateLocal.push(buildInitialTradeToVerifyState(split));
            }
          }
        } else if (VERIFIABLE_UNVERIFIABLE_STATES.includes(subTradeRequest.state)) {
          initialTradesToVerifyStateLocal.push(buildInitialTradeToVerifyState(subTradeRequest));
        }
      }

      setTradesToVerify(cloneDeep(initialTradesToVerifyStateLocal));
      setInitialTradesToVerifyState(cloneDeep(initialTradesToVerifyStateLocal));
    } else {
      setTradesToVerify([]);
      setInitialTradesToVerifyState([]);
    }
  }, [subTradeRequests, permissions, activeOrganization]);

  const filterTradesToVerify = (items: TradeToVerify[]) => {
    const filteredTradesToVerify: TradeToVerify[] = [];

    items.forEach((item: TradeToVerify) => {
      const initialTradeToVerifyState = initialTradesToVerifyState.find((initTrade: TradeToVerify) => initTrade.id === item.id);

      if (initialTradeToVerifyState?.selected !== item?.selected) {
        filteredTradesToVerify.push(item);
      }
    });

    if (onTradesToVerifyUpdate) onTradesToVerifyUpdate(filteredTradesToVerify);
  };

  const onTradeVerify = (item: TradeToVerify) => {
    const index = tradesToVerify.findIndex((tradeToVerify: TradeToVerify) => item?.id === tradeToVerify?.id);
    tradesToVerify[index].selected = item?.selected;

    setTradesToVerify([...tradesToVerify]); // keeping the state of selected sub-trades
    filterTradesToVerify([...tradesToVerify]); // preparing an array of sub-trades whose state has changed
  };

  const selectAllTradesToVerify = (isChecked: boolean) => {
    const updatedTradesToVerify = tradesToVerify.map((trade: TradeToVerify) => ({ ...trade, selected: isChecked }));

    setTradesToVerify([...updatedTradesToVerify]);
    filterTradesToVerify([...updatedTradesToVerify]);
  };

  const isAllTradesToVerifySelected = tradesToVerify?.every((tradeToVerify: TradeToVerify) => tradeToVerify.selected) ?? false;

  const onStateChange = (state: SubTradeRequestStates | 'ANY') => {
    setStateFilter(state);

    if (state === 'ANY') setStatesFilter(new Array(...SUB_TRADE_REQUEST_COMPLETED_STATES(activeOrganization?.allowPendingTransactions ?? false)));

    if (state !== 'ANY') setStatesFilter(new Array(state));

    setPage(1);
  };

  return (
    <>
      {(useHeader || useFilter) && (
        <CardContent>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            {useHeader && <Typography variant='headingXSmall'>{t(translationStrings?.header ?? 'components:generateTrades.activeTradeRequests')}</Typography>}

            {useFilter && (
              <Box display='flex' alignItems='center' justifyContent='end' gap={1}>
                <FilterModal filterExists={stateFilter !== 'ANY'} testIds={{ dialogOpenButton: `${testIdPrefix}-filter-open-button`, dialogSubmitButton: `${testIdPrefix}-filter-submit-button` }}>
                  <Box display='flex' flexDirection='column' gap={2}>
                    <TextField
                      select
                      fullWidth
                      label={t('components:generateTrades.table.state')}
                      value={stateFilter}
                      onChange={(e: any) => {
                        onStateChange(e.target.value);
                      }}
                      testId={`${testIdPrefix}-filter-state`}
                    >
                      <MenuItem value='ANY'>{t('components:any')}</MenuItem>

                      {SUB_TRADE_REQUEST_COMPLETED_STATES(activeOrganization?.allowPendingTransactions ?? false).map((state) => (
                        <MenuItem value={state}>{t(`components:pendingTransfers.states.${state}`)}</MenuItem>
                      ))}
                    </TextField>
                  </Box>
                </FilterModal>
              </Box>
            )}
          </Box>
        </CardContent>
      )}

      <Table sx={{ borderBottom: 'hidden' }}>
        <TableBody>
          <SubTradeRequestsTableHeader
            expandable={expandable}
            verifiable={verifiable}
            editable={editable}
            isAllTradesToVerifySelected={isAllTradesToVerifySelected}
            onTradeVerifySelectAll={(isChecked: boolean) => selectAllTradesToVerify(isChecked)}
            testIdPrefix={testIdPrefix}
          />

          {loading
            && [...Array(usePagination ? PER_PAGE : 5)].map((x: any, i: number) => (
              <TableRow key={i}>
                {[...Array(getSkeletonColumnsNumber([verifiable, (expandable || (verifiable && editable))], 8))].map((y: any, g: number) => (
                  <TableCell key={`c-${g}`}>
                    <Skeleton width='100%' />
                  </TableCell>
                ))}
              </TableRow>
            ))}

          {!loading
            && subTradeRequests?.map((trade: SubTradeRequest, index: number) => (
              <SubTradeRequestsTableRow
                key={trade.id}
                trade={trade}
                expandable={expandable}
                verifiable={verifiable}
                editable={editable}
                tradesToVerify={tradesToVerify}
                onTradeVerify={(item: TradeToVerify) => onTradeVerify(item)}
                onClick={() => {
                  setAuditOpen(true);
                  setActiveSubTradeRequest(trade);
                }}
                testIdPrefix={testIdPrefix}
                index={index}
              />
            ))}

          <Dialog open={auditOpen} onClose={() => setAuditOpen(false)}>
            <DialogTitle onClose={() => setAuditOpen(false)}>{t('shared:auditLog')}</DialogTitle>
            {activeSubTradeRequest && <AuditLog objectId={activeSubTradeRequest.id ?? ''} objectType='SubTradeRequest' options={{}} />}
          </Dialog>
        </TableBody>
      </Table>

      {!loading && !subTradeRequests?.length && (
        <CardContent sx={{ pb: '16px !important' }}>
          <Box display='flex' flexDirection='row' alignItems='left' justifyContent='center'>
            <InfoRoundedIcon sx={{ mr: 1 }} />

            <Typography variant='bodyLarge' weight='bold'>
              {t(translationStrings?.noRecordsFound ?? 'components:generateTrades.noRecordsFound')}
            </Typography>
          </Box>
        </CardContent>
      )}

      {usePagination && !!subTradeRequests?.length && (
        <Pagination
          count={Math.ceil((totalCount ?? 0) / PER_PAGE)}
          page={page}
          onChange={(_e, newPage) => {
            setPage(newPage);
          }}
          sx={{
            p: 1,
            textAlign: 'right',
            '.MuiPagination-ul': {
              justifyContent: 'end',
            },
          }}
        />
      )}
    </>
  );
};

export default SubTradeRequestsTable;
