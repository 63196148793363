/* eslint-disable object-curly-newline */
import { useContext, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useGlobalToast } from '../../../../../providers/globalToastProvider';
import { formatMoneyValue } from '../../../../../util';
import { Box } from '../../../../1-primative';
import { Button, Dialog, DialogFooter, DialogTitle, Table, TableBody, TableCell, TableHeadCell, TableRow } from '../../../../2-component';
import { PageObjectType } from '../../../../5-page';
import { UserContext } from '../../../../../providers/userContextProvider';
import { TESTID_PORTFOLIO_WIDGET } from '../portfolio';

const TESTID_PORTFOLIO_WIDGET_CONFIRM_TRADES_TABLE_HEAD_CELL = (cell: string) => `${TESTID_PORTFOLIO_WIDGET}-confirm-trades-table-head-cell-${cell}`;
const TESTID_PORTFOLIO_WIDGET_CONFIRM_TRADES_TABLE_ROW = (index: number) => `${TESTID_PORTFOLIO_WIDGET}-confirm-trades-table-row-${index}`;
const TESTID_PORTFOLIO_WIDGET_CONFIRM_TRADES_TABLE_ROW_CELL = (index: number, cell: string) => `${TESTID_PORTFOLIO_WIDGET}-confirm-trades-table-row-${index}-cell-${cell}`;

const CREATE_SUB_TRADE_REQUESTS = gql`
  mutation createSubTradeRequests($input: CreateSubTradeRequestsInput!) {
    createSubTradeRequests(input: $input)
  }
`;

export const PAIR_SUB_TRADE_REQUESTS = gql`
  mutation pairSubTradeRequests($input: PairSubTradeRequestsInput!) {
    pairSubTradeRequests(input: $input)
  }
`;

const GenerateTradesModal = ({ afterCreate, trades, type, id, modalOpenDisabled }: { afterCreate: () => void; trades: any[]; type: PageObjectType; id: string; modalOpenDisabled: boolean }) => {
  const { t } = useTranslation(['components', 'shared']);
  const { showToast } = useGlobalToast();
  const { activeOrganization } = useContext(UserContext);
  const [open, setOpen] = useState(false);

  const [createSubTradeRequests, { loading }] = useMutation(CREATE_SUB_TRADE_REQUESTS);
  const [pairSubTradeRequests] = useMutation(PAIR_SUB_TRADE_REQUESTS);

  const create = async (event: any) => {
    const subTradeRequestsToCreate = [];

    for (let i = 0; i < trades.length; i++) {
      const trade = trades[i];

      const subTradeRequest = {
        subAccountId: trade.subAccountId,
        financialProductId: trade.financialProductId,
        maxQuantity: trade.maxQuantity,
        moneyAllocatedCents: Math.abs(trade.amountCents),
        type: trade.type,
        isSellAll: trade.isSellAll,
      };

      subTradeRequestsToCreate.push(subTradeRequest);
    }

    if (subTradeRequestsToCreate.length) {
      const response = await createSubTradeRequests({
        variables: {
          input: {
            source: type, // !todo: temp, will be removed when bulkTradeRequest is deprecated
            sourceId: id,
            subTradeRequests: subTradeRequestsToCreate,
          },
        },
      });

      if (!response.errors?.length && activeOrganization?.allowViewSubTradeRequestPairs) {
        pairSubTradeRequests({ variables: { input: { source: type, sourceId: id } } });
      }
    }

    afterCreate();

    showToast({ severity: 'info', message: t('components:generateTrades.tradesSent') });
  };

  return (
    <>
      <Button label={t('components:generateTrades.confirm')} onClick={() => setOpen(true)} disabled={modalOpenDisabled} dataTestId={`${TESTID_PORTFOLIO_WIDGET}-confirm-trades-button`} />

      <Dialog open={open} onClose={() => setOpen(false)} maxWidth='sm' fullWidth>
        <DialogTitle onClose={() => setOpen(false)} testIds={{ dialogCloseButton: `${TESTID_PORTFOLIO_WIDGET}-dialog-close-button` }}>
          {t('components:generateTrades.confirmTrades')}
        </DialogTitle>

        <Table>
          <TableBody>
            <TableRow>
              <TableHeadCell testId={TESTID_PORTFOLIO_WIDGET_CONFIRM_TRADES_TABLE_HEAD_CELL('account')}>{t('components:generateTrades.table.account')}</TableHeadCell>
              <TableHeadCell testId={TESTID_PORTFOLIO_WIDGET_CONFIRM_TRADES_TABLE_HEAD_CELL('type')}>{t('components:generateTrades.table.type')}</TableHeadCell>
              <TableHeadCell right testId={TESTID_PORTFOLIO_WIDGET_CONFIRM_TRADES_TABLE_HEAD_CELL('amount')}>
                {t('components:generateTrades.table.amount')}
              </TableHeadCell>
              <TableHeadCell testId={TESTID_PORTFOLIO_WIDGET_CONFIRM_TRADES_TABLE_HEAD_CELL('security')}>{t('components:generateTrades.table.security')}</TableHeadCell>
            </TableRow>

            {trades.map((trade, index) => (
              <TableRow testId={TESTID_PORTFOLIO_WIDGET_CONFIRM_TRADES_TABLE_ROW(index)} key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component='th' scope='row' testId={TESTID_PORTFOLIO_WIDGET_CONFIRM_TRADES_TABLE_ROW_CELL(index, 'account')}>
                  {trade.accountName}
                </TableCell>
                <TableCell testId={TESTID_PORTFOLIO_WIDGET_CONFIRM_TRADES_TABLE_ROW_CELL(index, 'type')}>{trade.isSellAll ? t('components:portfolioTable.sellAll') : trade.type}</TableCell>
                <TableCell number testId={TESTID_PORTFOLIO_WIDGET_CONFIRM_TRADES_TABLE_ROW_CELL(index, 'amount')}>
                  {formatMoneyValue(Math.abs(trade.amountCents))}
                </TableCell>
                <TableCell testId={TESTID_PORTFOLIO_WIDGET_CONFIRM_TRADES_TABLE_ROW_CELL(index, 'security')}>{trade.ticker}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <DialogFooter>
          <Box display='flex' justifyContent='end'>
            <Button
              label={`${t('components:generateTrades.confirmTrades')}${modalOpenDisabled ? '' : ` (${trades.length})`}`}
              onClick={create}
              disabled={loading}
              dataTestId={`${TESTID_PORTFOLIO_WIDGET}-submit-trades-button`}
            />
          </Box>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default GenerateTradesModal;
