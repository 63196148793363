import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography } from '../../../../1-primative';
import {
  Dialog, DialogTitle, DialogContent, MenuItem, TextField, Button,
} from '../../../../2-component';
import { UserContext } from '../../../../../providers/userContextProvider';
import { ClientGroup, Goal } from '../../../../../interfaces';
import filterHouseholdsWithAccess from '../../../../../util/filterHouseholdsWithAccess';
import { AssociateGoalToHouseholdModal } from './components/associateGoalToHouseholdModal';
import { DissociateGoalFromHouseholdModal } from './components/dissociateGoalFromHouseholdModal';

export const FETCH_GOAL = gql`
  query fetchGoal($goalId: ObjectID!) {
    fetchGoal(goalId: $goalId) {
      goal {
        id
        name
        targetAmountCents
        user {
          id
          households {
            id
            name
            relationships {
              user {
                id
              }
              accessType
            }
          }
        }
        householdClientGroup {
          id
          name
        }
      }
    }
  }
`;

const UPDATE_GOAL = gql`
  mutation updateGoal($input: UpdateGoalInput!) {
    updateGoal(input: $input) {
      goal {
        id
      }
    }
  }
`;

const CLOSE_GOAL = gql`
  mutation archiveGoal($input: ArchiveGoalInput!) {
    archiveGoal(input: $input) {
      goal {
        id
      }
    }
  }
`;

export const EditGoal = ({
  objectId, onClose, canClose, canUpdate, options = {},
}: {
  objectId: string, onClose: () => void, canClose: boolean, canUpdate: boolean, options?: any,
}) => {
  const { t } = useTranslation(['client', 'accountsDetail']);
  const [goal, setGoal] = useState<Partial<Goal>>({});
  const [editOpen, setEditOpen] = useState(false);
  const [closeOpen, setCloseOpen] = useState(false);
  const [associateGoalModal, setAssociateGoalModal] = useState<ClientGroup | null>(null);
  const [associateGoalToHousehold, setAssociateGoalToHousehold] = useState(false);
  const [goalHouseholds, setGoalHouseholds] = useState<ClientGroup[]>([]);
  const [dissociateGoalModal, setDissociateGoalModal] = useState(false);
  const [disassociateGoalToHousehold, setDisassociateGoalToHousehold] = useState(false);

  const { userId: paramsUserId } = useParams();
  const { activeEntity } = useContext(UserContext);
  const userId = paramsUserId ?? activeEntity?.id;
  const navigate = useNavigate();

  const { data, refetch } = useQuery(FETCH_GOAL, {
    variables: {
      goalId: objectId,
    },
    fetchPolicy: 'no-cache',
  });

  const [updateGoal] = useMutation(UPDATE_GOAL, {
    variables: {
      input: {
        goalId: objectId,
        name: goal.name,
        targetAmountCents: goal.targetAmountCents,
      },
    },
    onCompleted: () => {
      window.location.reload();
    },
  });

  const [closeGoal, { loading }] = useMutation(CLOSE_GOAL, {
    variables: {
      input: {
        goalId: objectId,
      },
    },
    onCompleted: () => {
      navigate(`/clients/${userId}`);
    },
    onError: (e: any) => {
    },
  });

  useEffect(() => {
    if (data) {
      setGoal(data.fetchGoal.goal);
      const households = filterHouseholdsWithAccess((data.fetchGoal.goal?.user?.households ?? []), data.fetchGoal.goal?.user?.id);
      setGoalHouseholds(households);
      setAssociateGoalToHousehold(
        !data.fetchGoal.goal?.householdClientGroup
        && households.length > 0,
      );
      setDisassociateGoalToHousehold(!!data.fetchGoal.goal?.householdClientGroup);
    }
  }, [data, setGoal]);

  const onEditClose = () => {
    onClose();
    setEditOpen(false);
  };

  const onCloseClose = () => {
    onClose();
    setCloseOpen(false);
  };

  return (
    <>
      {canUpdate
        && <MenuItem onClick={() => setEditOpen(true)}>{t('client:editGoal.edit')}</MenuItem>
      }
      {canClose
        && <MenuItem onClick={() => setCloseOpen(true)}>{t('client:editGoal.close')}</MenuItem>
      }
      {options.enableAddingGoalToHousehold && associateGoalToHousehold && (
        goalHouseholds.map((household) => (
          <MenuItem onClick={() => setAssociateGoalModal(household)}>{t('goalsDetails:changeToHouseholdGoal', { name: goalHouseholds.length > 1 ? household.name : '' })}</MenuItem>
        ))
      )}
      {options.enableAddingGoalToHousehold && disassociateGoalToHousehold && (
        <MenuItem onClick={() => setDissociateGoalModal(true)}>{t('goalsDetails:dissociateGoalFromHousehold')}</MenuItem>
      )}
      <Dialog open={editOpen} onClose={onEditClose} fullWidth maxWidth='xs'>
        <DialogTitle>{t('client:editGoal.editGoal')}</DialogTitle>
        <DialogContent>
          <TextField
            value={goal.name}
            onChange={(e: any) => setGoal({ ...goal, name: e.target.value })}
            label={t('client:editGoal.goalName')}
            fullWidth
          />
          <TextField
            sx={{ mt: 2 }}
            value={(goal.targetAmountCents ?? 0) / 100}
            type='dollar'
            leadingIcon='dollar'
            onChange={(e: any) => setGoal({ ...goal, targetAmountCents: e.target.value * 100 })}
            label={t('client:editGoal.targetAmount')}
            fullWidth
          />
          <Box mt={2} display='flex' justifyContent='flex-end'>
            <Button variant='text' label={t('client:editGoal.cancel')} onClick={onEditClose} sx={{ mr: 1 }}/>
            <Button variant='filled' label={t('client:editGoal.save')} onClick={updateGoal} />
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog open={closeOpen} onClose={onCloseClose} fullWidth maxWidth='xs'>
        <DialogTitle>{t('client:editGoal.closeGoal')}</DialogTitle>
        <DialogContent>
          <Typography variant='bodyLarge'>{t('client:editGoal.closeGoalText')}</Typography>
          <Box mt={2} display='flex' justifyContent='flex-end'>
            <Button variant='text' label={t('client:editGoal.cancel')} onClick={onCloseClose} sx={{ mr: 1 }}/>
            <Button variant='filled' color='destructive' label={t('client:editGoal.close')} onClick={closeGoal} disabled={loading} />
          </Box>
        </DialogContent>
      </Dialog>
      {associateGoalModal && goal?.id && (
        <AssociateGoalToHouseholdModal
          goalId={goal.id}
          household={associateGoalModal}
          handleClose={() => {
            setAssociateGoalModal(null);
            refetch();
            onClose();
          }}
        />
      )}
      {dissociateGoalModal && goal?.id && (
        <DissociateGoalFromHouseholdModal
          goalId={goal.id}
          householdName={goal.householdClientGroup?.name ?? ''}
          handleClose={() => {
            setDissociateGoalModal(false);
            refetch();
            onClose();
          }}
        />
      )}
    </>
  );
};
