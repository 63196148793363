import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  ListItem,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import EditIcon from '@mui/icons-material/Edit';
import DetailItem from 'pages/client/components/detailItem';
import { isArray, isNull } from 'lodash';
import { gql, useLazyQuery } from '@apollo/client';
import { LocalizedDatePicker } from '../fields/localizedDatePicker';
import { CreateIdVerificationWizardButton } from '../wizards/createIdVerificationWizard/button';
import AmountField from './amountField';
import TaxInformationField from './taxInformationField';
import FeeGridSelect from './feeGridSelect';
import BillingScheduleSelect from './billingScheduleSelect';
import CitizenField from './citizenField';
import { usePermissions } from '../../providers/userContextProvider';
import CreateNewModal, { CreateNewModalProp } from '../modals/createNewModal';
import { Field } from '../../pages/client/components/profile';
import { DetailMeta } from '../../pages/client/components/clientDetailBox';
import ClientDetailBoxAlert from '../../pages/client/components/clientDetailBoxAlert';
import AlertChip from '../misc/alertChip';
import NewNote from '../notes/newNote';
import { NoteObjectTypes } from '../../interfaces/note';
import { EntityTypeSelect } from './entityTypeSelect';
import NumberField from './numberField';
import { AddressField, Radio, RadioGroup } from '../../ovComponents';
import { DigitFormatter333, DigitFormatter324, DigitFormatter27 } from '../../ovComponents/2-component/textField/textField';
import { eligibleTaxIdTypes, encryptedTaxIdPlaceholder } from '../../interfaces/user';

const VALIDATE_TAX_ID = gql`
  query validateTaxId($taxIdType: TaxIdTypes!, $taxId: String!) {
    validateTaxId(taxIdType: $taxIdType, taxId: $taxId)
  }
`;

const showField = (field: Field, permissions: string[], referenceObject: any) => {
  if (!permissions.includes(`read:${field.permission}`)) { return false; }
  let show = true;
  if (field.showIf) {
    Object.keys(field.showIf).forEach((x: any) => {
      if (!referenceValueMatch(referenceObject[x], field.showIf[x])) {
        show = false;
      }
    });
  }
  if (field.showIfAny) {
    let met = false;
    Object.keys(field.showIfAny).forEach((x: any) => {
      if (referenceValueMatch(referenceObject[x], field.showIfAny[x])) {
        met = true;
      }
    });
    if (!met) show = false;
  }
  return show;
};

const referenceValueMatch = (referenceValue: any, soughtValue: any): boolean => {
  const valid = isArray(referenceValue)
    ? soughtValue.some((a: any) => referenceValue.includes(a))
    : soughtValue.includes(referenceValue);
  return valid;
};

type Props = {
  title: string,
  modalTitle?: string,
  object?: any,
  section?: string,
  children: any,
  buttonText?: string,
  fields?: Field[],
  i18nextNamespace?: 'client' | 'corporations'
  onUpdate: (object: any) => void,
  loading: boolean,
  state?: 'Empty' | 'Completed' | 'Error',
  meta?: DetailMeta,
  modalTitlePrefix?: string,
  disableWhenVerifying?: boolean,
  modalButton?: string,
  icon?: any,
  isIdVerification?: boolean,
  userId?: string,
};

const EditableDetailsBox = ({
  title, modalTitle, object, section, buttonText, fields = [], i18nextNamespace, onUpdate, loading, children, meta, state, modalTitlePrefix,
  disableWhenVerifying, modalButton, icon, isIdVerification, userId,
}: Props) => {
  const { t } = useTranslation(['corporations', 'client', 'feeAndBilling', 'components', 'base']);
  const [noteContentLength, setNoteContentLength] = useState(0);
  const { permissions } = usePermissions();

  const isIndividual = object.type === 'INDIVIDUAL';

  const [objectState, setObjectState] = useState<any>(object);
  useEffect(() => {
    setObjectState(object);
  }, [object]);
  const updateObject = async () => {
    onUpdate(objectState);
  };
  const [fieldsToShow, setFieldsToShow] = useState(fields.filter((field) => showField(field, permissions, objectState)));

  const updateFieldsToShow = (newObjectState?: any) => {
    const newFields = fields
      .filter((field) => showField(field, permissions, newObjectState || objectState))
      .filter((field) => field.type !== 'hidden');
    setFieldsToShow(newFields);
  };

  const updateTaxIdFieldsToShow = (newObjectState?: any) => {
    const taxIdField = fields.find((f) => f.key === 'taxId');
    const taxIdTypeField = fields.find((f) => f.key === 'taxIdType');
    if (!taxIdField || !taxIdTypeField) return;

    const types = eligibleTaxIdTypes(newObjectState.countryOfTaxResidence, isIndividual);
    const newTaxIdTypeField = {
      ...taxIdTypeField,
      type: 'radioGroup',
      options: types,
    };
    const newFields = fields.map((field) => (field.key === 'taxIdType' ? newTaxIdTypeField : field) as Field);
    setFieldsToShow(newFields);

    setObjectState({ ...newObjectState, taxIdType: types[0] });
  };

  useEffect(() => {
    updateFieldsToShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, permissions]);

  const [validateTaxId] = useLazyQuery(VALIDATE_TAX_ID);
  const [taxIdInvalid, setTaxIdInvalid] = useState(false);

  if (!object) return <></>;

  const notAvailableView = (): JSX.Element => (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <InfoRoundedIcon style={{ transform: 'rotate(180deg)' }} />
      <Typography variant="h6" fontWeight="600" sx={{ fontSize: 16 }}>
        {meta?.emptyMeta?.notAvailableMeta?.header}
      </Typography>
      <Typography variant="h6" fontWeight="500" sx={{ fontSize: 12 }}>
        {meta?.emptyMeta?.notAvailableMeta?.body}
      </Typography>
    </Box>
  );

  const flattenObject = (field: Field): any => {
    if (field.parentObject) return !isNull(objectState[field.parentObject]) ? objectState[field.parentObject][field.key] : '';
    return objectState[field.key] ?? '';
  };

  const checkIncompleteFields = (): boolean => {
    const exemptFields = [
      'uniqueCircumstances',
      'isAccreditedInvestor',
    ];

    if (disableWhenVerifying) {
      let noteValidation = true;
      const result = fieldsToShow.filter((field) => {
        const fieldValue = flattenObject(field);
        const isIncompleteField = (
          fieldValue === ''
          || fieldValue === undefined
          || fieldValue === null
        );

        const failsValidation = (
          field.validation
          && field.validation.rule
          && !field.validation.rule.test(flattenObject(field) ?? '')
        );

        return (
          !exemptFields.includes(field.key)
          && field.required !== false
          && (isIncompleteField || failsValidation)
        );
      });
      const noteField = fieldsToShow.find((field) => field.key === 'uniqueCircumstances');
      if (noteField) {
        noteValidation = noteContentLength > (noteField.validation?.maxLength ?? 1000);
      }
      return result.length !== 0 || noteValidation;
    }
    return validateForm();
  };

  const validateForm = (): boolean => {
    const result = fieldsToShow.filter((field) => field.validation && field.validation.rule && !field.validation.rule.test(flattenObject(field) ?? ''));
    return result.length !== 0;
  };

  const onChanged = (field: Field, value: any, shouldUpdateFieldsToShow?: boolean): void => {
    let newObjectState = {};
    if (field.parentObject) {
      newObjectState = { ...objectState, [field.parentObject]: { ...objectState[field.parentObject], [field.key]: value } };
    } else {
      newObjectState = { ...objectState, [field.key]: value };
    }
    setObjectState(newObjectState);
    if (shouldUpdateFieldsToShow) updateFieldsToShow(newObjectState);
  };

  const citizenshipValue = (field: Field): string[] => {
    if (field.options && field.options === 'singleSelect') {
      if (objectState[field.key] !== null) return [objectState[field.key]];
      return [];
    }
    return objectState[field.key];
  };

  const getTaxIdFormatter = (): any => {
    switch (objectState.taxIdType) {
      case 'SIN':
      case 'BN': {
        return DigitFormatter333;
      }
      case 'SSN':
      case 'ITIN': {
        return DigitFormatter324;
      }
      case 'EIN': {
        return DigitFormatter27;
      }
      default: {
        return undefined;
      }
    }
  };

  const getFormControls = () => (
    <Grid container spacing={2} p={2}>
      {
        fieldsToShow.map((field) => {
          const readonly = field.readonly || !permissions.includes(`write:${field.permission}`);
          return (<Grid item xs={field.column} key={field.key}>
            {field.type === 'date' ? (
              <LocalizedDatePicker
                readOnly={readonly}
                label={field?.label}
                value={objectState[field.key] ?? field?.defaultValue}
                onChange={(date) => setObjectState({ ...objectState, [field.key]: dayjs(date?.toString()).format('YYYY-MM-DD') })}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            ) : field.type === 'select' ? (
              <TextField
                select
                value={flattenObject(field) ?? ''}
                label={field?.label}
                fullWidth
                required={field?.required}
                InputProps={{
                  readOnly: readonly,
                }}
                onChange={async (event) => {
                  onChanged(field, event.target.value, true);
                  // special condition added to employementFields
                  if (field.key === 'employmentStatus') {
                    if (event.target.value === 'UNEMPLOYED') {
                      setObjectState({
                        ...objectState,
                        employmentSituation: 'UNEMPLOYED_BUT_LOOKING',
                        employmentStatus: 'UNEMPLOYED',
                      });
                    } else {
                      setObjectState({
                        ...objectState,
                        employmentSituation: '',
                        employmentStatus: event.target.value,
                      });
                    }
                  }
                }}
              >
                {
                  field.options.map((key: string) => (
                    <MenuItem key={key} value={key}>{t(`${i18nextNamespace}:edit.${field.key}Options.${key}`)}</MenuItem>
                  ))
                }
              </TextField>
            ) : field.type === 'multiSelect' ? (
              <FormControl fullWidth>
                <InputLabel shrink htmlFor="select-multiple-native" variant='outlined'>
                  {field?.label}
                </InputLabel>
                <Select
                  multiple
                  notched={true}
                  value={flattenObject(field) ?? []}
                  onChange={async (event) => onChanged(field, event.target.value, true)}
                  label={field?.label}
                  inputProps={{
                    readOnly: readonly,
                  }}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value: any) => (
                        <Chip key={value} label={t(`${i18nextNamespace}:edit.${field.key}Options.${value}`)} />
                      ))}
                    </Box>
                  )}
                >
                  {
                    field.options.map((key: string) => (
                      <MenuItem key={key} value={key}>{t(`${i18nextNamespace}:edit.${field.key}Options.${key}`)}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            ) : field.type === 'booleanSelect' ? (
              <TextField
                select
                value={objectState[field.key] === true
                  ? t('components:booleanOptions.yes') : (objectState[field.key] === false
                    ? t('components:booleanOptions.no') : ((objectState[field.key] === null) ? t('components:booleanOptions.no') : undefined))}
                label={field?.label}
                fullWidth
                InputProps={{
                  readOnly: readonly,
                }}
                onChange={async (event) => {
                  const newUser = { ...objectState, [field.key]: event.target.value === 'Yes' };
                  setObjectState(newUser);
                  updateFieldsToShow(newUser);
                }}
              >
                <MenuItem key="Yes" value="Yes">{field.booleanSelectType === 'TrueOrFalse' ? t('shared:true') : t('components:booleanOptions.yes')}</MenuItem>
                <MenuItem key="No" value="No">{field.booleanSelectType === 'TrueOrFalse' ? t('shared:false') : t('components:booleanOptions.no')}</MenuItem>
              </TextField>
            ) : field.type === 'switch' ? (
              <FormGroup>
                <FormControlLabel
                  control={<Switch
                    inputProps={{
                      readOnly: readonly,
                    }}
                    checked={Boolean(objectState[field.key]) || false}
                    onChange={async (event) => {
                      if (!permissions.includes(`write:${field.permission}`)) return;
                      const newUser = { ...objectState, [field.key]: event.target.checked };
                      setObjectState(newUser);
                      updateFieldsToShow(newUser);
                    }}
                  />}
                  label={field?.label}
                />
              </FormGroup>
            ) : field.type === 'text_array' ? (
              <TextField label={field?.label} variant="outlined" value={(objectState[field.key] ?? '')} fullWidth
                onChange={async (e) => {
                  const newUser = { ...objectState, [field.key]: e.target.value.split(',') };
                  setObjectState(newUser);
                  updateFieldsToShow(newUser);
                }}
                InputProps={{
                  readOnly: readonly,
                }}
                placeholder={field.placeholder}
                type='text_array'
              />
            ) : field.type === 'address' ? (
              <AddressField
                label={field?.label || ''}
                manualAddressEntry
                readonly={readonly}
                address={flattenObject(field)}
                onChange={async (event) => onChanged(field, event, true)}
              />
            ) : field.type === 'foreignTax' ? (
              <TaxInformationField
                readonly={readonly}
                taxInformation={objectState[field.key]}
                countryOfTaxResidence={objectState.countryOfTaxResidence}
                onChange={async (e) => {
                  const newUser = { ...objectState, [field.key]: e };
                  setObjectState(newUser as any);
                  updateFieldsToShow(newUser);
                }}
              />
            ) : field.type === 'citizenship' ? (
              <CitizenField
                citizenships={citizenshipValue(field)}
                label={field?.label || ''}
                readonly={readonly}
                singleSelect={field.options === 'singleSelect'}
                onChange={async (e) => {
                  const value = field.options && field.options === 'singleSelect' ? e[0] : e;
                  const newUser = { ...objectState, [field.key]: value };
                  setObjectState(newUser as any);
                  updateFieldsToShow(newUser);
                  updateTaxIdFieldsToShow(newUser);
                }}
              />
            ) : field.type === 'feeTier' ? (
              <FeeGridSelect
                label={field?.label || ''}
                setValue={(newValue) => {
                  const newObjectState = { ...objectState, newFeeTierId: newValue, feeTier: { id: newValue } };
                  setObjectState(newObjectState as any);
                  updateFieldsToShow(newObjectState);
                }}
                value={objectState?.feeTier ? objectState?.feeTier.id : 'inherited'}
                readonly={readonly}
                showInherited
              />
            ) : field.type === 'billingSchedule' ? (
              <BillingScheduleSelect
                label={field?.label || ''}
                setValue={(newValue) => {
                  const newObjectState = { ...objectState, newBillingScheduleId: newValue, billingSchedule: { id: newValue } };
                  setObjectState(newObjectState as any);
                  updateFieldsToShow(newObjectState);
                }}
                value={objectState?.billingSchedule ? objectState?.billingSchedule?.id : 'inherited'}
                readonly={readonly}
                isInherited={objectState?.applicableBillingSchedule?.id !== objectState?.billingScheduleId}
                applicableBillingScheduleId={objectState?.applicableBillingSchedule?.id}
                showInherited
              />
            ) : field.type === 'cents' ? (
              <AmountField
                label={field?.label}
                variant="outlined"
                value={objectState[field.key]}
                fullWidth
                onChange={(e: any) => {
                  setObjectState({ ...objectState, [field.key]: e.target.valueCents });
                }}
                placeholder={field.placeholder}
                InputProps={{
                  readOnly: readonly,
                }}
              />
            ) : field.type === 'riskReason' ? (
              <ListItem sx={{ marginBottom: '10px', paddingTop: '0px', marginTop: '-20px' }}>
                <DetailItem
                  title={field?.label ?? ''}
                  checker={() => true}
                  grid={12}
                  value={
                    <ul>
                      {
                        objectState[field.key]?.map((reason: any, index: any) => <li key={index}>{reason.translatedDescription?.en}</li>)
                      }
                    </ul>
                  }
                  hideElement={objectState[field.key]?.length === 0}
                />
              </ListItem>
            ) : field.type === 'note' ? (
              <NewNote
                data={objectState[field.key]}
                title={field?.label ?? ''}
                objectId=""
                hidePostAndClearButton
                characterCountLimiter={field.validation?.maxLength ?? 1000}
                noteObjectType={NoteObjectTypes.USER}
                contentLengthChanged={setNoteContentLength}
                onChange={(e) => {
                  const newUser = { ...objectState, [field.key]: e };
                  setObjectState(newUser as any);
                  updateFieldsToShow(newUser);
                }}
              />
            ) : field.type === 'nonIndividualEntityType' ? (
              <EntityTypeSelect
                nonIndividual
                value={objectState[field.key]}
                setValue={(newValue) => {
                  setObjectState({ ...objectState, [field.key]: newValue });
                }}
              />
            ) : field.type === 'number' ? (
              <NumberField
                value={(flattenObject(field) ?? '')}
                setNumberValue={(newValue) => {
                  setObjectState({ ...objectState, [field.key]: newValue });
                }}
                decimalPlaces={field.decimalPlaces}
                label={field?.label}
                variant="outlined"
                fullWidth
                required={field?.required}
                placeholder={field.placeholder}
                error={field.validation
                  && ((field.dontFetch && flattenObject(field)) || !field.dontFetch) && field.validation?.rule ? !field.validation?.rule.test((flattenObject(field) ?? '')) : false}
                helperText={
                  field.validation && ((field.dontFetch && flattenObject(field)) || !field.dontFetch) && field.validation?.rule && !field.validation?.rule.test((flattenObject(field) ?? ''))
                    ? t(field.validation.message)
                    : ''
                }
                InputProps={{
                  readOnly: readonly,
                }}
              />
            ) : field.type === 'radioGroup' ? (
              <TextField
                variant="outlined"
                label={field?.label}
                fullWidth
                InputProps={{
                  inputComponent: () => <RadioGroup
                    value={objectState[field.key]}
                    onChange={(e: any) => setObjectState({ ...objectState, [field.key]: e.target.value })}
                    sx={{ px: 2, py: 1 }}
                  >
                    {field.options.map((type: string) => <Radio key={type} value={type} size='small' label={type} />)}
                  </RadioGroup>,
                }}
              />
            ) : field.type === 'taxId' ? (
              <TextField
                label={t(`taxId:${objectState.taxIdType}`)}
                value={objectState.taxId}
                onChange={(e: any) => {
                  const newValue = e.target.value !== '' ? e.target.value : undefined;
                  setObjectState({ ...objectState, taxId: newValue });
                  if (newValue) {
                    validateTaxId({
                      variables: { taxIdType: objectState.taxIdType, taxId: newValue },
                      onCompleted: (data) => { setTaxIdInvalid(!data.validateTaxId); },
                    });
                  }
                }}
                fullWidth
                InputProps={{
                  inputComponent: getTaxIdFormatter(),
                }}
                placeholder={objectState.taxIdExists ? encryptedTaxIdPlaceholder(objectState.taxIdType) : ''}
                error={taxIdInvalid}
                helperText={
                  taxIdInvalid
                    ? t('client:profile.fieldValidations.pleaseEnterValidTaxId')
                    : ''
                }
              />
            ) : (
              <TextField
                label={field?.label}
                variant="outlined"
                value={(flattenObject(field) ?? '')}
                fullWidth
                required={field?.required}
                onChange={(e) => onChanged(field, (field.type === 'cents' ? (parseFloat(e.target.value) * 100) : e.target.value))}
                placeholder={field.placeholder}
                error={field.validation
                  && ((field.dontFetch && flattenObject(field)) || !field.dontFetch) && field.validation?.rule ? !field.validation?.rule.test((flattenObject(field) ?? '')) : false}
                helperText={
                  field.validation && ((field.dontFetch && flattenObject(field)) || !field.dontFetch) && field.validation?.rule && !field.validation?.rule.test((flattenObject(field) ?? ''))
                    ? t(field.validation.message)
                    : ''
                }
                type='text'
                InputProps={{
                  readOnly: readonly,
                }} />
            )}
          </Grid>);
        })
      }
    </Grid>
  );

  const FormModal = ({ createNewModalProp }: { createNewModalProp: CreateNewModalProp }) => (
    <>
      {isIdVerification && (<CreateIdVerificationWizardButton userId={userId ?? ''} />)}
      {!isIdVerification && (<CreateNewModal
        buttonVariant='text' buttonType='BUTTON'
        loading={loading} modalButton={t('client:form.edit')}
        buttonText={buttonText} title={modalTitle ?? `${t('client:form.update')} - ${title}`}
        onSubmit={updateObject} children={getFormControls()}
        {...createNewModalProp}
      />)}
    </>
  );

  const requiredView = (): JSX.Element => (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <ReportProblemRoundedIcon />
      <Typography variant="h6" fontWeight="600" sx={{ fontSize: 16, marginBottom: '16px' }} color="error">
        {meta?.emptyMeta?.requiredMeta?.header}
      </Typography>
      {
        FormModal({
          createNewModalProp: {
            icon: (<span> </span>),
            buttonVariant: 'outlined',
            buttonType: 'BUTTON',
            loading,
            modalButton: meta?.emptyMeta?.requiredMeta?.buttonText,
            title: modalTitle ?? `${t('client:form.update')} - ${title}`,
            onSubmit: () => updateObject(),
            children: getFormControls(),
            disabled: validateForm(),
          },
        })
      }
    </Box>
  );

  return (
    <Paper elevation={2} sx={{ marginBottom: 2, padding: 2 }} >
      {meta?.showAlert
        && <ClientDetailBoxAlert action={FormModal({ createNewModalProp: { sx: { float: 'right', color: '#111315' }, icon: (<EditIcon data-testid="add-icon" />), disabled: validateForm() } })} />
      }
      {meta && meta.label
        && <AlertChip label={meta.label} leadingIcon={state === 'Completed' ? 'checkmark' : 'problem'} actionItem={meta.actionItem} />
      }
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" fontWeight="bold" sx={{ marginBottom: 0 }}>
          {title}
        </Typography>
        {meta === undefined || (meta && (meta.canUpdate === undefined || meta.canUpdate))
          ? !meta?.showAlert
            ? FormModal({
              createNewModalProp: {
                disabled: checkIncompleteFields(),
                icon: icon ?? (<EditIcon data-testid="add-icon" />),
                modalButton,
              },
            })
            : undefined
          : undefined
        }
      </Box>
      {
        state === 'Empty' ? (
          meta?.emptyMeta?.emptyStateType === 'Not Available' ? notAvailableView() : requiredView()
        ) : (
          <Box width='100%'>
            {children}
          </Box>
        )
      }
    </Paper>
  );
};

export default EditableDetailsBox;
