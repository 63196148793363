/* eslint-disable object-curly-newline */
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { SubTradeRequest, SubTradeRequestStates } from 'interfaces/subTradeRequest';
import { DraftSubTradeRequest, PortfolioSecurity, PortfolioSubAccount } from './interfaces';
import { useThemeTokens } from '../../../../../providers/themeTokenProvider';
import { getAmountFieldInputColorByKey, getKey, getPendingAmountCentsByKey, isSellAllByKey } from './utils';
import { TableCell } from '../../../../2-component';
import { Box, Typography } from '../../../../1-primative';
import { AmountField } from '../../../../3-pattern';
import ChangeTooltip from './changeTooltip';
import { formatMoneyValue } from '../../../../../util';
import { TESTID_PORTFOLIO_WIDGET_HOLDINGS_TABLE_ROW_CELL } from '../portfolio';

export const SubAccountHoldingTableCell = ({
  security,
  subAccount,
  draftTrades,
  pendingTrades,
  handleHoldingChange,
  viewPreTradePositions = false,
  isMakeHigher = false,
  subAccountTradeRequests = [],
  index = 0,
  subIndex = 0,
}: {
  security: PortfolioSecurity;
  subAccount: PortfolioSubAccount;
  draftTrades: Map<string, DraftSubTradeRequest>;
  pendingTrades: Map<string, DraftSubTradeRequest>;
  handleHoldingChange: (security: PortfolioSecurity, subAccount: PortfolioSubAccount, amount: string) => void;
  viewPreTradePositions?: boolean;
  isMakeHigher?: boolean;
  subAccountTradeRequests?: SubTradeRequest[];
  index: number;
  subIndex: number;
}) => {
  const { t } = useTranslation(['components', 'accountTypes']);
  const { sys } = useThemeTokens();

  const financialProductId = subAccount.holding?.financialProduct?.id;
  const subAccountId = subAccount.id;

  const relevantSubTradeRequests = subAccountTradeRequests.filter(
    (subAccountTradeRequest) => subAccountTradeRequest.subAccount?.id === subAccountId && subAccountTradeRequest.financialProduct?.id === financialProductId,
  );

  const requestedTradesInfo = relevantSubTradeRequests.map((req) => ({
    type: req.type,
    isSellAll: req.isSellAll,
    moneyAllocatedCents: req.splits?.length
      ? req.splits
        .filter((split) => split.state === SubTradeRequestStates.REQUESTED) // only include splits in REQUESTED state
        .reduce((sum, split) => sum + (split.moneyAllocatedCents ?? 0), 0)
      : req.moneyAllocatedCents ?? 0, // otherwise, use the main subtraderequest's moneyAllocatedCents
    settlementDate: req.settlementDate ?? 'N/A',
  }));

  const key = getKey(security.id, subAccount.id);

  const draftAmountCents = useMemo(() => getPendingAmountCentsByKey(key, [draftTrades, pendingTrades]), [key, draftTrades, pendingTrades]); // whatever we input now
  const pendingAmountCents = useMemo(() => getPendingAmountCentsByKey(key, [pendingTrades]), [key, pendingTrades]); // whatever is already created

  const amountCents = draftAmountCents || 0;

  const isSellAll = useMemo(() => isSellAllByKey(key, [draftTrades, pendingTrades]), [key, draftTrades, pendingTrades]);
  const fieldColor = useMemo(() => getAmountFieldInputColorByKey(key, amountCents, [draftTrades, pendingTrades], sys), [key, amountCents, draftTrades, pendingTrades, sys]);

  const sx = {
    border: `1px solid ${Math.abs(amountCents) > 0 ? sys.color.warning : 'default'}`,
    borderRadius: '4px',
  };

  const inputProps = {
    style: {
      fontSize: '12px',
      color: fieldColor,
      fontVariantNumeric: 'tabular-nums',
      minWidth: '65px',
    },
  };

  return (
    <TableCell
      sx={{ height: isMakeHigher ? '50px' : '42px', width: `${Math.max(subAccount.name.length * 10, 150)}px`, minWidth: '150px' }}
      testId={TESTID_PORTFOLIO_WIDGET_HOLDINGS_TABLE_ROW_CELL(index, subIndex)}
    >
      <Box sx={{ width: `${Math.max(subAccount.name.length * 10, 150)}px`, minWidth: '150px' }} justifyContent='start' display='flex' alignItems='center' gap={1}>
        <AmountField
          useFormatAmountValueCents
          fullWidth={false}
          size='small'
          label=''
          amount={`${(subAccount.holding?.valueCents ?? 0) + amountCents}`}
          disabled={subAccount.inactive || !!pendingAmountCents}
          sx={sx}
          inputProps={inputProps}
          setAmount={(e: any) => {
            handleHoldingChange(security, subAccount, e);
          }}
        />

        <ChangeTooltip change={amountCents} original={subAccount.holding?.valueCents ?? 0} isSellAll={isSellAll} requestedTradeInfo={requestedTradesInfo} />
      </Box>

      {viewPreTradePositions && Math.abs(amountCents) > 0 && (
        <Typography display='block'>
          {t('portfolioTable.original')}: {formatMoneyValue(subAccount.holding?.valueCents ?? 0)}
        </Typography>
      )}
    </TableCell>
  );
};
