/* eslint-disable object-curly-newline */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-await-in-loop */
import { gql, useMutation, useQuery } from '@apollo/client';
import { isEqual, remove, union } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { Date } from '../../../../components/misc/date/date';
import { formatMoneyValue, generateClientNameString } from '../../../../util';
import { TransactionTypes } from '../../../../pages/reconcilitation/components/transactionTypes';
import { UserContext, usePermissions } from '../../../../providers/userContextProvider';
import { usePageState } from '../../../../util/usePageState';
import DownloadReconciliation from '../../../../pages/reconcilitation/components/downloadReconciliation';

import { Typography, Box, Skeleton } from '../../../1-primative';
import {
  Button,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  TextField,
  MenuItem,
  Card,
  CardContent,
  TableHeadCell,
  Badge,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
} from '../../../2-component';
import { FilterModal } from '../../../3-pattern';

export const FETCH_CUSTODIAN_ACTIVITIES_QUERY = `query fetchCustodianActivities($input: FetchCustodianActivitiesInput!) {
  fetchCustodianActivities(input: $input) {
    custodianActivities {
      id
      processDate
      symbol
      amountValueCents
      quantity
      priceCents
      securityType
      state
      transactionType
      transactionId
      transactionDesc
      processDate
      effectiveDate
      settleDate
      custodianAccountNumber
      clientName
      transactionId
      subTransactionId
      cusip
      transactionDesc
      transactionType
      commission
      accruedInterest
      securityType
      currency
      account {
      type
      user {
        id
        firstName
        lastName
        entityName
      }
      organization {
        id
        custodian {
          id
        }
      }
    }
  }
    totalCount
  }
}`;

const FETCH_CUSTODIAN_ACTIVITIES = gql`${FETCH_CUSTODIAN_ACTIVITIES_QUERY}`;

const RECONCILE_FLAG = gql`
  mutation reconcileCustodianActivity($custodianActivityId: ObjectID!) {
    reconcileCustodianActivity(custodianActivityId: $custodianActivityId) {
      custodianActivity {
        id
      }
    }
  }
`;

const FORCE_RECONCILE_FLAG = gql`
  mutation forceReconcileCustodianActivity($custodianActivityId: ObjectID!) {
    forceReconcileCustodianActivity(custodianActivityId: $custodianActivityId) {
      custodianActivity {
        id
      }
    }
  }
`;

export const controlColumnStyles = {
  width: '48px',
  padding: 0,
};

export const controlWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '48px',
};

const isFilterActive = ({
  symbol,
  custodianAccountNumber,
  selectedTransactionType,
  custodianActivityState,
}: {
  symbol: string | undefined,
  custodianAccountNumber: string | undefined,
  selectedTransactionType: string,
  custodianActivityState: string,
}) => {
  if (symbol !== undefined && symbol !== '') return true;
  if (custodianAccountNumber !== undefined && custodianAccountNumber !== '') return true;
  if (selectedTransactionType !== 'ANY') return true;
  if (custodianActivityState !== 'CREATED') return true;
  return false;
};

const Activities = () => {
  const [pageSize, setPageSize] = usePageState(25, 'pageSize');
  const { t } = useTranslation(['reconciliation']);
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [activeCustodianActivity, setActiveCustodianActivity] = useState<any | null>(null);
  const [custodianActivityState, setCustodianActivityState] = useState('CREATED');
  const [selectedTransactionType, setSelectedTransactionType] = useState('ANY');
  const [reconcileCustodianActivity, reconcileLoadingOptions] = useMutation(RECONCILE_FLAG);
  const [forceReconcileCustodianActivity, forceReconcileOptions] = useMutation(FORCE_RECONCILE_FLAG);
  const [selected, setSelected] = useState<string[]>([]);
  const [symbol, setSymbol] = usePageState<string | undefined>(undefined, 'symbol');
  const [custodianAccountNumber, setCustodianAccountNumber] = usePageState<string | undefined>(undefined, 'CAN');
  const [skip, setSkip] = useState(false);

  const queryFilter = {
    state: custodianActivityState,
    transactionType: selectedTransactionType === 'ANY' ? undefined : selectedTransactionType,
    organizationId: activeOrganization.id,
    symbol,
    custodianAccountNumber,
  };

  const { loading, error, data, refetch, previousData } = useQuery(FETCH_CUSTODIAN_ACTIVITIES, {
    errorPolicy: 'all',
    variables: {
      input: {
        filter: { ...queryFilter },
        pagination: {
          sortField: 'createdAt',
          sortDesc: true,
          perPage: pageSize,
          offSet: (page - 1) * pageSize,
        },
      },
    },
    skip,
  });

  const reconcileMultipleCustodianActivities = async (type: 'RECONCILE' | 'FORCE_RECONCILE') => {
    for (const id of selected) {
      if (type === 'RECONCILE') {
        await reconcileCustodianActivity({
          variables: {
            custodianActivityId: id,
          },
        });
      } else {
        await forceReconcileCustodianActivity({
          variables: {
            custodianActivityId: id,
          },
        });
      }
    }
    setSelected([]);
    refetch();
  };

  useEffect(() => function cleanupOnUnmount() {
    setPage(1);
    setPageSize(25);
    setSymbol(undefined);
    setCustodianAccountNumber(undefined);
  }, []);

  const debounceQueryRequest = useCallback(
    debounce(() => {
      /**
                         * This is workaround as several issue with a component re-rendering were found.
                         *
                         * For some reason the dobounce's reference is getting lost on the component re-rendering
                         * when any of the vars present in the useQuery gets updated. The useQuery (useLazyQuery) has some
                         * weird hooks behavior.
                         *
                         * @see https://github.com/apollographql/apollo-client/issues/7484
                         * @see https://github.com/apollographql/apollo-client/issues/5912
                         * @see https://github.com/apollographql/react-apollo/issues/3088
                         */
      setSkip(false);
    }, 500),
    [],
  );

  const handleChange = (value: any, setter: any) => {
    setSkip(true);
    setter(value || undefined);
    debounceQueryRequest();
  };

  if (error) (<Typography>Error</Typography>);

  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
          <Box display="flex">
            <TextField
              label={t('symbol')}
              value={symbol || ''}
              onChange={(e: any) => handleChange(e.target.value, setSymbol)}
              leadingIcon="search"
              sx={{ width: '250px' }}
            />

            <TextField
              label={t('custodianAccountNumber')}
              value={custodianAccountNumber || ''}
              onChange={(e: any) => handleChange(e.target.value, setCustodianAccountNumber)}
              leadingIcon="search"
              sx={{ width: '250px' }}
            />
          </Box>

          <Box display="flex" gap={2} alignItems="center">
            <FilterModal filterExists={isFilterActive({
              symbol,
              custodianAccountNumber,
              selectedTransactionType,
              custodianActivityState,
            })}>
              <Box display="flex" flexDirection="column" gap={2}>
                <TextField
                  select
                  value={selectedTransactionType}
                  onChange={(e: any) => {
                    setSelectedTransactionType(e.target.value);
                    setPage(1);
                  }}
                  label={t('transactionType')}
                  fullWidth
                >
                  {TransactionTypes.map((type) => (
                    <MenuItem key={type.key} value={type.key}>
                      {type.value}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  select
                  value={custodianActivityState}
                  onChange={(e: any) => {
                    setCustodianActivityState(e.target.value);
                    setPage(1);
                  }}
                  label={t('state')}
                  fullWidth
                >
                  <MenuItem value="CREATED">{t('activityState.CREATED')}</MenuItem>
                  <MenuItem value="RECONCILED">{t('activityState.RECONCILED')}</MenuItem>
                </TextField>
              </Box>
            </FilterModal>

            <Button
              label={t('flagState.ALL_RECONCILE')}
              variant="filled"
              color="primary"
              disabled={isEqual(selected, []) || reconcileLoadingOptions.loading}
              loading={reconcileLoadingOptions.loading}
              onClick={() => reconcileMultipleCustodianActivities('RECONCILE')}
            />

            <Button
              label={t('flagState.ALL_FORCE_RECONCILE')}
              variant="filled"
              color="primary"
              disabled={isEqual(selected, []) || forceReconcileOptions.loading}
              loading={forceReconcileOptions.loading}
              onClick={() => reconcileMultipleCustodianActivities('FORCE_RECONCILE')}
            />

            {permissions.includes('read:api_exports') && (
              <DownloadReconciliation
                tab='activities'
                queryFilter={queryFilter}
              />
            )}
          </Box>
        </Box>
      </CardContent>

      <Card loading={loading}>
        <Box sx={{ overflowX: 'auto' }}>
          <Table>
            <TableBody>
              <TableRow sx={{
                textAlign: 'center',
                verticalAlign: 'bottom',
              }}>
                <TableHeadCell>
                  <Box display='flex' alignItems='center' justifyContent='center'>
                    <Checkbox
                      checked={!loading && data?.fetchCustodianActivities?.custodianActivities?.length > 0
                        && isEqual(
                          selected,
                          data.fetchCustodianActivities.custodianActivities.map((x: any) => x.id),
                        )}
                      onChange={(checked: boolean) => {
                        if (!loading && data?.fetchCustodianActivities?.custodianActivities) {
                          setSelected(checked
                            ? data.fetchCustodianActivities.custodianActivities.map((x: any) => x.id)
                            : []);
                        }
                      }}
                      customStyle={{
                        margin: 0,
                        padding: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    />
                  </Box>
                </TableHeadCell>
                <TableHeadCell>{t('table.client')}</TableHeadCell>
                <TableHeadCell>{t('table.date')}</TableHeadCell>
                <TableHeadCell>{t('table.type')}</TableHeadCell>
                <TableHeadCell>{t('table.custodianAccountNumber')}</TableHeadCell>
                <TableHeadCell>{t('table.amount')}</TableHeadCell>
                <TableHeadCell>{t('table.accountType')}</TableHeadCell>
                <TableHeadCell>{t('table.ticker')}</TableHeadCell>
                <TableHeadCell>{t('table.quantity')}</TableHeadCell>
                <TableHeadCell number>{t('table.price')}</TableHeadCell>
                <TableHeadCell>{t('table.state')}</TableHeadCell>
                <TableHeadCell right>{t('table.actions')}</TableHeadCell>
              </TableRow>
              {loading && !previousData && [...Array(pageSize)].map((_, i) => (
                <TableRow key={i}>
                  <TableCell><Skeleton width="100%" /></TableCell>
                  <TableCell><Skeleton width="100%" /></TableCell>
                  <TableCell><Skeleton width="100%" /></TableCell>
                  <TableCell><Skeleton width="100%" /></TableCell>
                  <TableCell><Skeleton width="100%" /></TableCell>
                  <TableCell><Skeleton width="100%" /></TableCell>
                  <TableCell><Skeleton width="100%" /></TableCell>
                  <TableCell><Skeleton width="100%" /></TableCell>
                  <TableCell><Skeleton width="100%" /></TableCell>
                  <TableCell><Skeleton width="100%" /></TableCell>
                </TableRow>
              ))}

              {(data || previousData)?.fetchCustodianActivities?.custodianActivities?.map((activity: any) => {
                const userId = activity.account?.user?.id;
                const accountId = activity.account?.id;
                const custodianAccountNumberrecord = activity.custodianAccountNumber || '-';

                return (
                  <TableRow
                    hover
                    pointer
                    key={activity.id}
                    onClick={() => {
                      setOpen(true);
                      setActiveCustodianActivity(activity);
                    }}
                    selected={activeCustodianActivity?.id === activity.id}
                  >
                    <TableCell sx={controlColumnStyles}>
                      <div style={controlWrapperStyles}>
                        <Checkbox
                          checked={selected.includes(activity.id)}
                          onClick={(e: any) => e.stopPropagation()}
                          onChange={(checked: boolean) => {
                            setSelected(checked
                              ? union(selected, [activity.id])
                              : remove((x: string) => x === activity.id, selected));
                          }}
                          customStyle={{
                            margin: 0,
                            padding: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={`/clients/${userId}`}
                        target="_blank"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {generateClientNameString(activity.account?.user, false, true)}
                      </Link>
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                      <Date variant='subtitle3' date={activity.processDate} />
                    </TableCell>
                    <TableCell><Badge label={activity.transactionType} /></TableCell>
                    <TableCell>
                      <Link
                        to={`/clients/${userId}/account/${accountId}`}
                        target="_blank"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {custodianAccountNumberrecord}
                      </Link>
                    </TableCell>
                    <TableCell number>{formatMoneyValue(activity.amountValueCents)}</TableCell>
                    <TableCell><Badge label={activity.account?.type} /></TableCell>
                    <TableCell>{activity.symbol}</TableCell>
                    <TableCell number>{activity.quantity}</TableCell>
                    <TableCell number>{formatMoneyValue(activity.priceCents)}</TableCell>
                    <TableCell>
                      <Badge
                        label={activity.state}
                        color={activity.state === 'CREATED' ? 'negative' : 'positive'}
                        variant="text"
                      />
                    </TableCell>
                    <TableCell right>
                      <Box display="flex" gap={1} justifyContent="flex-end">
                        <Button
                          label={t('activityState.RECONCILE')}
                          disabled={activity.state !== 'CREATED'}
                          variant="filled"
                          color="primary"
                          size="sm"
                          onClick={(e) => {
                            e.stopPropagation();
                            reconcileCustodianActivity({
                              variables: { custodianActivityId: activity.id },
                            }).then(() => refetch());
                          }}
                        />
                        <Button
                          label={t('activityState.FORCE_RECONCILE')}
                          disabled={activity.state !== 'CREATED'}
                          variant="filled"
                          color="primary"
                          size="sm"
                          onClick={(e) => {
                            e.stopPropagation();
                            forceReconcileCustodianActivity({
                              variables: { custodianActivityId: activity.id },
                            }).then(() => refetch());
                          }}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Card>

      <Box>
        <Pagination
          count={Math.ceil(((data || previousData)?.fetchCustodianActivities?.totalCount ?? 0) / pageSize)}
          page={page}
          perPage={pageSize}
          onChangePerPage={(newPageSize) => setPageSize(newPageSize)}
          onChange={(_e, newPage) => setPage(newPage)}
          sx={{
            p: 1,
            textAlign: 'right',
            '.MuiPagination-ul': {
              justifyContent: 'end',
            },
          }}
        />
      </Box>

      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>{activeCustodianActivity?.message}</DialogTitle>
        <DialogContent>
          {activeCustodianActivity?.details?.account?.user && (
            <Button
              label={`${t('goTo')} ${activeCustodianActivity.details.clientName}`}
              variant="filled"
              color="primary"
              fullWidth
              component={Link}
              target="_blank"
              to={`/clients/${activeCustodianActivity.details.account?.user}`}
            />
          )}
          <pre>{JSON.stringify(activeCustodianActivity, null, 2)}</pre>
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default Activities;
