/* eslint-disable object-curly-newline */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { gql as gqlQuery, useLazyQuery, useMutation } from '@apollo/client';
import {
  Dialog, DialogContent, DialogTitle, IconButton,
} from '../../2-component';
import { useGlobalToast } from '../../../providers/globalToastProvider';
import { Box, Typography } from '../../1-primative';
import { formatPercentValue } from '../../../util';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import ConfigurationModal, { Configuration } from './configurationModal';

export enum ExportApiReportFormatters {
  DIVIDE_BY_100 = 'DIVIDE_BY_100',
  MULTIPLY_BY_100 = 'MULTIPLY_BY_100',
}

enum DataExportStates {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  GENERATING = 'GENERATING',
}

export interface Column {
  gqlAlias: string,
  header: string,
  formatter?: ExportApiReportFormatters,
}

export interface DownloadButtonProps {
  columns: Column[],
  additionalColumns?: Column[],
  datasetPath: string,
  fileName?: string,
  filter: Record<string, any>,
  additionalFilter?: Record<string, any>,
  gql: string,
  queryPath: string,
  sortDesc?: boolean,
  sortField?: string,
  perPage?: number,
  progressTitle?: string,
  configurable?: boolean,
  testId?: string,
}

const EXPORT_API_REPORT = gqlQuery`
  mutation exportApiReport($input: ExportApiReportInput!) {
    exportApiReport(input: $input) {
      apiExport {
        id
      }
    }
  }
`;

const FETCH_API_EXPORT = gqlQuery`
  query fetchApiExport($input: FetchApiExportInput!) {
    fetchApiExport(input: $input) {
      apiExport {
        id
        progress
        state
        fileUrl
      }
    }
  }
`;

/**
 * @param columns - use it for columns that are usually present in a table and visible to a user
 * @param additionalColumns - use it for additional columns to fetch the data beyond the table columns
 * @param filter - use it for required filters ex. user, organization
 * @param additionalFilter - use it for optional filters ex. state, type
 * @param configurable - use it for showing a configuration dialog before exporting, a user will be able to choose desired columns to export
 */
export const DownloadButton = ({
  columns,
  additionalColumns,
  datasetPath,
  fileName,
  filter,
  additionalFilter,
  gql,
  queryPath,
  sortDesc,
  sortField,
  perPage,
  progressTitle,
  configurable,
  testId,
}: DownloadButtonProps) => {
  const [open, setOpen] = useState(false);
  const [openConfiguration, setOpenConfiguration] = useState(false);

  const { t } = useTranslation('components');

  const { sys } = useThemeTokens();

  const { showToast } = useGlobalToast();

  const [fetchApiExport, { data, startPolling, stopPolling, refetch }] = useLazyQuery(FETCH_API_EXPORT);

  const queryParams = {
    gql,
    filter,
    queryPath,
    datasetPath,
    columns,
    fileName,
    sortField,
    sortDesc,
    perPage,
  };

  const [exportApiReport] = useMutation(EXPORT_API_REPORT, {
    variables: { input: queryParams },
    onCompleted: (dataApiExport: any) => {
      setOpen(true);
      fetchApiExport({
        variables: {
          input: {
            apiExportId: dataApiExport.exportApiReport.apiExport.id,
          },
        },
      });
    },
  });

  const onExport = (configuration?: Configuration) => {
    exportApiReport({
      variables: {
        input: {
          ...queryParams,
          filter: configuration?.useAdditionalFilter ? { ...filter, ...additionalFilter } : filter,
          columns: configuration?.selectedColumns ?? columns,
        },
      },
    });
  };

  useEffect(() => {
    if (data?.fetchApiExport) {
      if (data.fetchApiExport.apiExport.state === DataExportStates.GENERATING) {
        startPolling(1000);
      } else {
        if (data.fetchApiExport.apiExport.state === DataExportStates.COMPLETED) {
          setTimeout(() => window.open(data.fetchApiExport.apiExport.fileUrl, '_blank'), 1000);
        } else {
          showToast({ severity: 'error', message: t('downloadButton.failedDownloadMessage') });
        }
        setTimeout(() => {
          if (data.fetchApiExport.apiExport.progress < 1 && data.fetchApiExport.apiExport.state !== DataExportStates.FAILED) {
            refetch();
          } else {
            stopPolling();
            setOpen(false);
          }
        }, 500);
      }
    }
  }, [data, startPolling, stopPolling, showToast, t, refetch]);

  const onDownloadButtonClick = () => {
    if (configurable) {
      setOpenConfiguration(true);
      return;
    }

    exportApiReport();
  };

  return (
    <>
      <IconButton onClick={() => onDownloadButtonClick()} label={t('downloadButton.download')} testId={testId ?? 'download-button'}>
        <FileDownloadOutlinedIcon />
      </IconButton>

      {configurable && (
        <ConfigurationModal
          open={openConfiguration}
          columns={columns}
          additionalColumns={additionalColumns}
          onClose={() => setOpenConfiguration(false)}
          onExport={(configuration: Configuration) => onExport(configuration)}
        />
      )}

      <Dialog onClose={() => setOpen(false)} open={open} fullWidth maxWidth='xs'>
        <DialogTitle>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            {progressTitle ?? t('downloadButton.progressModalTitle')}
            <IconButton onClick={() => setOpen(false)} sx={{ color: `${sys.color.onSurface}` }}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box
              sx={{
                position: 'relative',
                height: '8px',
                borderRadius: '4px',
                backgroundColor: `${sys.color.surfaceContainerVariant}`,
                width: '90%',
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  height: '8px',
                  borderRadius: '4px',
                  backgroundColor: `${sys.color.primary}`,
                  transition: 'width 0.5s',
                  width: `${(data?.fetchApiExport?.apiExport?.progress ?? 0) * 100}%`,
                }}
              />
            </Box>
            <Typography sx={{ fontWeight: 500 }}>
              {formatPercentValue(data?.fetchApiExport?.apiExport?.progress ?? 0, 0)}
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
