import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { UserContext } from '../../../providers/userContextProvider';
import { Typography } from '../../1-primative';
import { SelectField, MenuItem } from '../../2-component';

export const FETCH_PRODUCTS = gql`
  query fetchModelPortfolios($input: FetchModelPortfoliosInput!) {
    fetchModelPortfolios(input: $input) {
      modelPortfolios {
        id
        name
        state
      }
    }
  }
`;
export const ModelPortfoliosSelect = ({
  onChange,
  value,
  label,
  disabled = false,
  themeId,
  size,
  inheritedText,
  includeAnyOption,
  testId,
}: {
  onChange: (event: any) => void;
  value: string;
  label: string;
  disabled?: boolean;
  themeId?: string;
  inheritedText?: string;
  includeAnyOption?: boolean;
  size?: 'small' | 'medium' | undefined;
  testId?: string;
}) => {
  const { t } = useTranslation(['components']);
  const { activeOrganization } = useContext(UserContext);
  const { loading, error, data } = useQuery(FETCH_PRODUCTS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
          themeId,
        },
        pagination: { perPage: 100 },
      },
    },
  });

  if (error) <Typography>Error</Typography>;

  return (
    <>
      <SelectField value={data ? value : ''} label={label} size={size} fullWidth onChange={onChange} disabled={disabled} testId={testId}>
        {includeAnyOption && <MenuItem value='ANY'>{t('components:any')}</MenuItem>}
        {loading ? (
          <MenuItem>...</MenuItem>
        ) : (
          data.fetchModelPortfolios.modelPortfolios
            .filter((x: any) => x.state !== 'INACTIVE')
            .map((x: any) => <MenuItem key={Math.random()} value={x.id}>{`${x.name}${inheritedText ?? ''}`}</MenuItem>)
        )}
      </SelectField>
    </>
  );
};

export default ModelPortfoliosSelect;
