import { useContext, useState } from 'react';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { gql, useMutation } from '@apollo/client';
import { TFunction, useTranslation } from 'react-i18next';
import { BackupTable, Cancel, CheckCircle } from '@mui/icons-material';
import { CardContent, IconButton } from '../../../../2-component';
import { Box, Typography } from '../../../../1-primative';
import SubTradeRequestsTable, { SUB_TRADE_REQUEST_PENDING_STATES, TradeToVerify } from './subTradeRequestsTable';
import { UserContext, usePermissions } from '../../../../../providers/userContextProvider';
import { useGlobalToast } from '../../../../../providers/globalToastProvider';
import { DownloadSubTradeRequests } from './downloadSubTradeRequests';
import { TradeVerificationStatus } from '../../../../../interfaces/subTradeRequest';
import { PageObjectType } from '../../../../5-page';

export const CANCEL_BULK_TRADE = gql`
  mutation transitionBulkTradeRequest($input: TransitionBulkTradeRequestInput!) {
    transitionBulkTradeRequest(input: $input) {
      bulkTradeRequest {
        id
      }
    }
  }
`;

const CANCEL_SUB_TRADE_REQUESTS = gql`
  mutation cancelSubTradeRequests($input: CancelSubTradeRequestsInput!) {
    cancelSubTradeRequests(input: $input)
  }
`;

export const VERIFY_SUB_TRADE_REQUESTS = gql`
  mutation verifySubTradeRequests($input: VerifySubTradeRequestsInput!) {
    verifySubTradeRequests(input: $input)
  }
`;

const getVerificationStatus = (trades: TradeToVerify[]): TradeVerificationStatus => {
  const verifiedCount = trades.filter((trade) => trade.verified).length;
  const totalCount = trades.length;

  if (verifiedCount === totalCount) {
    return TradeVerificationStatus.TO_UNVERIFIED;
  }

  if (verifiedCount === 0) {
    return TradeVerificationStatus.TO_VERIFIED;
  }

  return TradeVerificationStatus.SOME_VERIFIED_UNVERIFIED;
};

const getToastMessage = (status: TradeVerificationStatus, t: TFunction): string => {
  switch (status) {
    case TradeVerificationStatus.TO_VERIFIED:
      return t('components:generateTrades.verifyTradesResult');
    case TradeVerificationStatus.TO_UNVERIFIED:
      return t('components:generateTrades.unverifyTradesResult');
    case TradeVerificationStatus.SOME_VERIFIED_UNVERIFIED:
      return t('components:generateTrades.verifyUnverifyTradesResult');
    default:
      return t('components:generateTrades.verifyTradesResult');
  }
};

const SubTradeRequests = ({
  id,
  type,
  afterCancel,
  afterVerified,
  isCancelDisabled,
  openTradePairs,
  showTradePairs,
  showComponent,
  testIdPrefix = 'sub-trades',
}: {
  id: string;
  type: PageObjectType;
  afterCancel: () => void;
  afterVerified: () => void;
  isCancelDisabled?: boolean;
  openTradePairs: () => void;
  showTradePairs?: boolean;
  showComponent: boolean;
  testIdPrefix?: string;
}) => {
  const { permissions } = usePermissions();
  const { activeOrganization } = useContext(UserContext);

  const { t } = useTranslation(['components', 'shared', 'errorBoundaries']);
  const { showToast } = useGlobalToast();
  const [tradesToVerify, setTradesToVerify] = useState<TradeToVerify[]>([]);

  const [cancelSubTradeRequests, { loading: loadingCancelSubTradeRequests }] = useMutation(CANCEL_SUB_TRADE_REQUESTS, {
    variables: {
      input: {
        source: type,
        sourceId: id,
      },
    },
    onCompleted: () => afterCancel(),
  });

  const [verifySubTradeRequests] = useMutation(VERIFY_SUB_TRADE_REQUESTS, {
    variables: {
      input: {
        organizationId: activeOrganization.id,
        subTradeRequestIds: tradesToVerify.map((trade: TradeToVerify) => trade?.id),
      },
    },
    onCompleted: () => {
      afterVerified();
      setTradesToVerify([]);

      const status = getVerificationStatus(tradesToVerify);

      showToast({
        severity: 'success',
        message: getToastMessage(status, t),
      });
    },
    onError: () => {
      showToast({
        severity: 'error',
        message: t('errorBoundaries:base.title'),
      });
    },
  });

  const cancel = async () => {
    cancelSubTradeRequests();
  };

  const isShowVerificationBtn = permissions.includes('write:sub_trade_request_verification') && (activeOrganization?.requireSubTradeRequestApproval ?? false);
  const isShowCancelTradesBtn = permissions.includes('transition:sub_trade_request');

  return (
    <>
      {showComponent ? (
        <>
          <CardContent>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Typography variant='headingXSmall'>{t('components:generateTrades.activeTradeRequests')}</Typography>

              <Box display='flex' alignItems='center' justifyContent='end' gap={1}>
                {isShowVerificationBtn && (
                  <IconButton
                    onClick={() => {
                      if (tradesToVerify.length) verifySubTradeRequests();
                    }}
                    disabled={!tradesToVerify.length}
                    label={t('components:generateTrades.verifyUnverifyTrades')}
                    testId={`${testIdPrefix}-verification-button`}
                  >
                    <CheckCircle />
                  </IconButton>
                )}

                {isShowCancelTradesBtn && id && (
                  <DownloadSubTradeRequests
                    filter={{
                      ...(type === PageObjectType.GOAL ? { goalId: id } : { subAccountId: id }),
                      states: SUB_TRADE_REQUEST_PENDING_STATES(activeOrganization?.allowPendingTransactions ?? false),
                    }}
                    testId={`${testIdPrefix}-download-button`}
                  />
                )}

                {showTradePairs && (
                  <IconButton onClick={openTradePairs} label={t('showPairs')} testId={`${testIdPrefix}-show-pairs-button`}>
                    <BackupTable />
                  </IconButton>
                )}

                {isShowCancelTradesBtn && (
                  <IconButton disabled={isCancelDisabled || loadingCancelSubTradeRequests} onClick={() => cancel()} label={t('components:generateTrades.cancelTrades')}>
                    <Cancel />
                  </IconButton>
                )}
              </Box>
            </Box>
          </CardContent>

          <SubTradeRequestsTable type={type} id={id} onTradesToVerifyUpdate={(trades: TradeToVerify[]) => setTradesToVerify(trades)} testIdPrefix={testIdPrefix} />
        </>
      ) : (
        <CardContent sx={{ pb: '16px !important' }}>
          <Box display='flex' flexDirection='row' alignItems='left' justifyContent='center'>
            <InfoRoundedIcon sx={{ mr: 1 }} />
            <Typography variant='bodyLarge' weight='bold'>
              {t('components:generateTrades.noActiveTradeRequests')}
            </Typography>
          </Box>
        </CardContent>
      )}
    </>
  );
};

export default SubTradeRequests;
