import {
  ListItem, MenuItem, TextField, FormGroup, FormControlLabel, Switch, Tooltip,
} from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'theme/colors';
import FormModal from '../../../components/modals/formModal';
import ThemeSelect from '../../../components/inputs/themeSelect';
import ModelPortfoliosSelect from '../../../components/inputs/modelPortfoliosSelect';
import { FETCH_SUB_ACCOUNTS } from '../../../components/cards/subAccounts';
import { FETCH_SUB_ACCOUNT } from '../../subAccountsDetail';
import { Goal, TRADING_PROCESS_LIST } from '../../../interfaces';
import { usePermissions } from '../../../providers/userContextProvider';

const FETCH_SUBACCOUNT_SUITABILITY_SCORE = gql`
  query fetchSubAccountSuitabilityScore($id: ObjectID!) {
    fetchSubAccount(subAccountId: $id) {
      subAccount {
        goal {
          suitabilityScore
        }
      }
    }
  }
`;

const UPDATE_SUB_ACCOUNT = gql`
  mutation updateSubAccount($input: UpdateSubAccountInput!) {
    updateSubAccount(input: $input) {
      subAccount {
        id
        skipIPS
        allowClientDeposits
        liquidated
      }
    }
  }
`;

const END_LIQUIDATION = gql`
  mutation endLiquidation($input: EndLiquidationInput!) {
    endLiquidation(input: $input) {
      subAccount {
        id
      }
    }
  }
`;

const highlightStyle = {
  borderWidth: '2px', borderColor: colors.primary, borderStyle: 'solid', borderRadius: '4px',
};

const EditSubAccount = ({
  afterUpdate, subAccountToUpdate, open, handleClose, goalList, highlight,
}: { afterUpdate: () => void, subAccountToUpdate: any, open: boolean, handleClose: () => void, goalList?: Goal[], highlight?: string }) => {
  const { t } = useTranslation('client');
  const { permissions } = usePermissions();
  const [subAccount, setSubAccount] = useState(subAccountToUpdate);
  const [localOpen, setLocalOpen] = useState(open);
  const suitabilityScoreQuery = useQuery(FETCH_SUBACCOUNT_SUITABILITY_SCORE, { variables: { id: subAccountToUpdate.id }, skip: !subAccountToUpdate?.id });
  const [updateSubAccount, { loading }] = useMutation(UPDATE_SUB_ACCOUNT, {
    variables: {
      input: {
        name: subAccount?.name ?? undefined,
        goalId: subAccount?.goal?.id ?? undefined,
        subAccountId: subAccount?.id,
        financialProductId: subAccount?.financialProduct?.id !== subAccountToUpdate.financialProduct?.id ? subAccount?.financialProduct?.id : undefined,
        themeId: subAccount?.theme?.id !== subAccountToUpdate?.theme?.id ? subAccount?.theme?.id : undefined,
        skipIPS: subAccount?.skipIPS !== subAccountToUpdate?.skipIPS ? subAccount?.skipIPS : undefined,
        allowClientDeposits: subAccount?.allowClientDeposits !== subAccountToUpdate?.allowClientDeposits ? subAccount?.allowClientDeposits : undefined,
        isPartial: subAccount?.isPartial !== subAccountToUpdate?.isPartial ? subAccount?.isPartial : undefined,
        tradingProcess: subAccount?.tradingProcess !== subAccountToUpdate?.tradingProcess ? subAccount?.tradingProcess : undefined,
      },
    },
    refetchQueries: [FETCH_SUB_ACCOUNTS(permissions), FETCH_SUB_ACCOUNT(permissions)],
  });

  const [endLiquidation] = useMutation(END_LIQUIDATION, {
    variables: {
      input: {
        subAccountId: subAccount?.id,
      },
    },
  });

  useEffect(() => {
    setLocalOpen(open);
    setSubAccount(subAccountToUpdate);
  }, [subAccountToUpdate, open]);

  const update = async (event: any) => {
    event.preventDefault();
    if (subAccountToUpdate.liquidated === true && subAccount.liquidated === false) await endLiquidation();
    await updateSubAccount();
    afterUpdate();
  };

  if (!subAccount) {
    return <></>;
  }

  return (
    <FormModal loading={loading} title={t('subAccountModal.editTitle')} formButton={t('update')} onSubmit={update} open={localOpen} handleClose={handleClose} >
      {goalList ? (
        <ListItem key='selectGoal'>
          <TextField select fullWidth
            value={subAccount?.goal?.id}
            label={t('selectGoal')} focused={highlight === 'goalType'}
            onChange={(e) => setSubAccount({ ...subAccount, goal: { id: e.target.value } })}
          >
            {goalList.map((x: any) => (
              <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>
            ))}
          </TextField>
        </ListItem>
      ) : null}
      <ListItem key='subAccountName'>
          <TextField
            fullWidth
            focused={highlight === 'subAccountName'}
            value={subAccount?.name ?? ''}
            label={t('editName')}
            onChange={(e) => setSubAccount({ ...subAccount, name: e.target.value })}
          />
      </ListItem>
      <ListItem>
        <ThemeSelect
          focused={highlight === 'portfolioTheme'}
          value={subAccount.theme?.id || ''}
          onChange={(newValue) => setSubAccount({ ...subAccount, theme: { id: newValue } })}
          label={t('selectTheme')}
          suitabilityScore={permissions.includes('read:all_themes') ? undefined : suitabilityScoreQuery?.data?.fetchSubAccount.subAccount.goal.suitabilityScore}
        />
      </ListItem>
      <ListItem>
        <ModelPortfoliosSelect
          focused={highlight === 'portfolio'}
          value={subAccount.financialProduct?.id || ''}
          onChange={(e) => setSubAccount({ ...subAccount, financialProduct: { id: e.target.value } })}
          label={t('selectProduct')}
          themeId={subAccount?.theme?.id}
          suggestableOnly
        />
      </ListItem>
      <ListItem key='tradingProcess'>
        <TextField select fullWidth
          value={subAccount?.tradingProcess}
          label={t('tradingProcess')} focused={highlight === 'tradingProcess'}
          onChange={(e) => setSubAccount({ ...subAccount, tradingProcess: e.target.value })}
        >
          {TRADING_PROCESS_LIST.map((x: any) => (
            <MenuItem key={x.value} value={x.value}>{x.name}</MenuItem>
          ))}
        </TextField>
      </ListItem>
      <ListItem>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={<Switch
              checked={!subAccount.skipIPS}
              onChange={() => {
                setSubAccount({ ...subAccount, skipIPS: !subAccount.skipIPS });
              }}
            />}
            label={t('subAccountModal.requireInvestorPolicyStatement')}
            sx={{ ...highlight === 'skipIPS' && highlightStyle }}
          />
        </FormGroup>
      </ListItem>
      <ListItem>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={<Switch
              checked={subAccount.allowClientDeposits}
              onChange={() => {
                setSubAccount({ ...subAccount, allowClientDeposits: !subAccount.allowClientDeposits });
              }}
            />}
            label={t('allowClientDeposits')}
            sx={{ ...highlight === 'allowDeposit' && highlightStyle }}
          />
        </FormGroup>
      </ListItem>
      <ListItem>
        <FormGroup sx={{ width: '100%' }}>
          <FormControlLabel
            control={<Switch
              checked={subAccount.isPartial}
              onChange={() => {
                setSubAccount({ ...subAccount, isPartial: !subAccount.isPartial });
              }}
            />}
            label={t('subAccountModal.allowFractionalShares')}
            sx={{ ...highlight === 'allowFractionalShares' && highlightStyle }}
          />
        </FormGroup>
      </ListItem>
      {permissions.includes('write:subAccount_liquidation') && (
      <ListItem>
        <Tooltip
          title={
            (subAccount.liquidated === false) ? t('subAccountModal.liquidatedNoTooltip') : t('subAccountModal.liquidatedYesTooltip')
          }
        >
          <FormGroup sx={{ width: '100%' }}>
            <FormControlLabel
              control={<Switch
                checked={subAccount.liquidated}
                onChange={(e) => {
                  setSubAccount({ ...subAccount, liquidated: e.target.checked });
                }}
              />}
              label={t('subAccountModal.liquidated')}
              sx={{ ...highlight === 'liquidated' && highlightStyle }}
              disabled={subAccountToUpdate?.liquidated === false}
            />
          </FormGroup>
        </Tooltip>
      </ListItem>
      )}
    </FormModal>
  );
};

export default EditSubAccount;
