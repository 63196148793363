import { Account } from './account';
import { FinancialProduct } from './financialProduct';
import { Goal } from './goal';
import { SubAccount } from './subAccount';
import { User } from './user';

export enum TransactionTypes {
  EFT = 'EFT',
  CANCEL_EFT = 'CANCEL_EFT',
  BUY = 'BUY',
  CANCEL_BUY = 'CANCEL_BUY',
  SELL = 'SELL',
  CANCEL_SELL = 'CANCEL_SELL',
  DIVIDEND = 'DIVIDEND',
  EXTERNAL_TRANSFER_IN = 'EXTERNAL_TRANSFER_IN',
  EXTERNAL_TRANSFER_OUT = 'EXTERNAL_TRANSFER_OUT',
  TRANSFER_IN = 'TRANSFER_IN',
  TRANSFER_OUT = 'TRANSFER_OUT',
  CANCEL_TRANSFER = 'CANCEL_TRANSFER',
  CORPORATE_ACTION = 'CORPORATE_ACTION',
  CANCEL_CORPORATE_ACTION = 'CANCEL_CORPORATE_ACTION',
  MANAGEMENT_FEE = 'MANAGEMENT_FEE',
  CANCEL_MANAGEMENT_FEE = 'CANCEL_MANAGEMENT_FEE',
  BORROW_FEE = 'BORROW_FEE',
  INTEREST = 'INTEREST',
  RISK_EXPOSURE_FEE = 'RISK_EXPOSURE_FEE',
  WITHHOLDING_TAX = 'WITHHOLDING_TAX',
  DEREGISTRATION_FEE = 'DEREGISTRATION_FEE',
  DEREGISTRATION_TAX = 'DEREGISTRATION_TAX',
  GST = 'GST',
  HST = 'HST',
  ADJUSTMENT = 'ADJUSTMENT',
  GOV_CONTRIBUTIONS = 'GOV_CONTRIBUTIONS',
  SWITCH_IN = 'SWITCH_IN',
  CANCEL_SWITCH_IN = 'CANCEL_SWITCH_IN',
  CANCEL_SWITCH_OUT = 'CANCEL_SWITCH_OUT',
  DISTRIBUTION = 'DISTRIBUTION',
  CHEQUE = 'CHEQUE',
  DEPOSIT_BUY = 'DEPOSIT_BUY',
  DISTRIBUTION_REINVESTMENT = 'DISTRIBUTION_REINVESTMENT',
  EXTERNAL_TRANSFER_IN_SECURITY = 'EXTERNAL_TRANSFER_IN_SECURITY',
  EXPENSE = 'EXPENSE',
  OTHER = 'OTHER',
  TRANSFER_IN_SECURITY = 'TRANSFER_IN_SECURITY',
  TRANSFER_OUT_SECURITY = 'TRANSFER_OUT_SECURITY',
  SWITCH_OUT = 'SWITCH_OUT', // TODO: deprecated
  SWI_MARKET = 'SWI_MARKET',
  SWI_COST = 'SWI_COST',
  SWO_MARKET = 'SWO_MARKET',
  SWO_COST = 'SWO_COST',
  MARKET_DRIP = 'MARKET_DRIP',
  ACCRUED_INTEREST = 'ACCRUED_INTEREST',
  CANADA_LEARNING_BOND = 'CANADA_LEARNING_BOND',
  COMMISSION = 'COMMISSION',
  DISABILITY_ASSISTANCE_PAYMENT = 'DISABILITY_ASSISTANCE_PAYMENT',
  ADD_CAD_EDU_SAVING_GRANT = 'ADD_CAD_EDU_SAVING_GRANT',
  HOME_BUYER_PLAN = 'HOME_BUYER_PLAN',
  LIFE_LEARNING_PLAN = 'LIFE_LEARNING_PLAN',
  MANAGEMENT_FEE_REDEMPTION = 'MANAGEMENT_FEE_REDEMPTION',
  REBATE = 'REBATE',
  REDEMPTION = 'REDEMPTION',
  RETURN_OF_CAPITAL = 'RETURN_OF_CAPITAL',
  WIRE_TRANSFER = 'WIRE_TRANSFER',
  WRITE_OFF = 'WRITE_OFF',
  STOCK_DRIP = 'STOCK_DRIP',
  CANCEL_BORROW_FEE = 'CANCEL_BORROW_FEE',
  CANCEL_CHEQUE = 'CANCEL_CHEQUE',
  CANCEL_DEPOSIT = 'CANCEL_DEPOSIT',
  CANCEL_FEE = 'CANCEL_FEE',
  CANCEL_HST = 'CANCEL_HST',
  CANCEL_INTEREST = 'CANCEL_INTEREST',
  CANCEL_REBATE = 'CANCEL_REBATE',
  CANCEL_TRANSFER_IN = 'CANCEL_TRANSFER_IN',
  CANCEL_TRANSFER_OUT = 'CANCEL_TRANSFER_OUT',
  FEDERAL_TAX = 'FEDERAL_TAX',
  SHORT_TERM_GAIN_LOSS = 'SHORT_TERM_GAIN_LOSS',
  LONG_TERM_GAIN_LOSS = 'LONG_TERM_GAIN_LOSS',
  NON_EDU_CAPITAL_WITHDRAWAL = 'NON_EDU_CAPITAL_WITHDRAWAL',
  CANCEL_ADJUSTMENT = 'CANCEL_ADJUSTMENT',
}

export enum SwitchTransactionTypes {
  SWO_COST = 'SWO_COST',
  SWI_COST = 'SWI_COST',
  SWO_MARKET = 'SWO_MARKET',
  SWI_MARKET = 'SWI_MARKET',
}

export enum SwitchTypes {
  AT_COST = 'AT_COST',
  AT_MARKET = 'AT_MARKET',
  SWO_COST = 'SWO_COST',
  CUSTODY_FEE = 'CUSTODY_FEE',
  INCOME_FUND_TRANSFER = 'INCOME_FUND_TRANSFER',
}

export enum TransferSourcesTypes {
  USER = 'USER',
  REPRESENTATIVE = 'REPRESENTATIVE',
  FEE = 'FEE',
  DEREGISTRATION = 'DEREGISTRATION',
  DEREGISTRATION_TAX = 'DEREGISTRATION_TAX',
  WITHHOLDING_TAX = 'WITHHOLDING_TAX',
  DIVIDEND = 'DIVIDEND',
  TO_SUB_ACCOUNT = 'TO_SUB_ACCOUNT',
  FROM_SUB_ACCOUNT = 'FROM_SUB_ACCOUNT',
  TO_ACCOUNT = 'TO_ACCOUNT',
  FROM_ACCOUNT = 'FROM_ACCOUNT',
  EXTERNAL_TRANSFER_IN = 'EXTERNAL_TRANSFER_IN',
  EXTERNAL_TRANSFER_OUT = 'EXTERNAL_TRANSFER_OUT',
  ADJUSTMENT = 'ADJUSTMENT',
  CUSTODIAN_ACTIVITY = 'CUSTODIAN_ACTIVITY',
  CHEQUE = 'CHEQUE',
  GOV_CONTRIBUTIONS = 'GOV_CONTRIBUTIONS',
  DISTRIBUTION = 'DISTRIBUTION',
}

export enum Currencies {
  CAD = 'CAD',
  USD = 'USD',
}

export enum TransactionObjectTypes {
  TRANSFER = 'Transfer',
  SUB_TRADE = 'SubTrade',
  CUSTODIAN_ACTIVITY = 'CustodianActivity',
  ADJUSTMENT = 'Adjustment',
}

export enum InterestTypes {
  INTEREST_IN = 'INTEREST_IN',
  INTEREST_OUT = 'INTEREST_OUT',
}

export const TRADE_TYPES = [
  TransactionTypes.BUY,
  TransactionTypes.SELL,
  TransactionTypes.CANCEL_BUY,
  TransactionTypes.CANCEL_SELL,
  TransactionTypes.SWI_MARKET,
  TransactionTypes.SWI_COST,
  TransactionTypes.SWITCH_IN,
  TransactionTypes.CANCEL_SWITCH_IN,
  TransactionTypes.SWO_MARKET,
  TransactionTypes.SWO_COST,
  TransactionTypes.SWITCH_OUT,
  TransactionTypes.CANCEL_SWITCH_OUT,
  TransactionTypes.DEPOSIT_BUY,
  TransactionTypes.DISTRIBUTION_REINVESTMENT,
  TransactionTypes.DIVIDEND,
  TransactionTypes.EXTERNAL_TRANSFER_IN_SECURITY,
  TransactionTypes.TRANSFER_IN_SECURITY,
  TransactionTypes.TRANSFER_OUT_SECURITY,
  TransactionTypes.CORPORATE_ACTION,
  TransactionTypes.CANCEL_CORPORATE_ACTION,
  TransactionTypes.MARKET_DRIP,
  TransactionTypes.STOCK_DRIP,
  TransactionTypes.CANCEL_TRANSFER_IN,
  TransactionTypes.CANCEL_TRANSFER_OUT,
];

export const POSITIVE_VALUE_TYPES = [
  TransactionTypes.SELL,
  TransactionTypes.CANCEL_BUY,
  TransactionTypes.EXTERNAL_TRANSFER_IN,
  TransactionTypes.TRANSFER_IN,
  TransactionTypes.DIVIDEND,
  TransactionTypes.CANCEL_SWITCH_IN,
  TransactionTypes.DISTRIBUTION,
  TransactionTypes.CANADA_LEARNING_BOND,
  TransactionTypes.COMMISSION,
  TransactionTypes.DISABILITY_ASSISTANCE_PAYMENT,
  TransactionTypes.ADD_CAD_EDU_SAVING_GRANT,
  TransactionTypes.MANAGEMENT_FEE_REDEMPTION,
  TransactionTypes.REBATE,
  TransactionTypes.REDEMPTION,
  TransactionTypes.RETURN_OF_CAPITAL,
  TransactionTypes.WIRE_TRANSFER,
  TransactionTypes.WRITE_OFF,
  TransactionTypes.CANCEL_BORROW_FEE,
  TransactionTypes.CANCEL_FEE,
  TransactionTypes.CANCEL_HST,
  TransactionTypes.CANCEL_REBATE,
  TransactionTypes.CANCEL_TRANSFER_IN,
  TransactionTypes.CANCEL_TRANSFER_OUT,
  TransactionTypes.TRANSFER_OUT_SECURITY,
];

export const NEGATIVE_VALUE_TYPES = [
  TransactionTypes.BUY,
  TransactionTypes.CANCEL_SELL,
  TransactionTypes.EXTERNAL_TRANSFER_OUT,
  TransactionTypes.TRANSFER_OUT,
  TransactionTypes.BORROW_FEE,
  TransactionTypes.RISK_EXPOSURE_FEE,
  TransactionTypes.WITHHOLDING_TAX,
  TransactionTypes.DEREGISTRATION_FEE,
  TransactionTypes.DEREGISTRATION_TAX,
  TransactionTypes.GST,
  TransactionTypes.HST,
  TransactionTypes.CANCEL_SWITCH_OUT,
  TransactionTypes.DEPOSIT_BUY,
  TransactionTypes.EXTERNAL_TRANSFER_IN_SECURITY,
  TransactionTypes.DISTRIBUTION_REINVESTMENT,
  TransactionTypes.TRANSFER_IN_SECURITY,
  TransactionTypes.HOME_BUYER_PLAN,
  TransactionTypes.LIFE_LEARNING_PLAN,
  TransactionTypes.STOCK_DRIP,
  TransactionTypes.MARKET_DRIP,
  TransactionTypes.FEDERAL_TAX,
  TransactionTypes.CANCEL_DEPOSIT,
  TransactionTypes.NON_EDU_CAPITAL_WITHDRAWAL,
];

// These transactions should use the EOD price / current price to define the net/new contributions
export const HOLDING_CONTRIBUTION_TRANSACTION_TYPES = [
  TransactionTypes.DEPOSIT_BUY,
  TransactionTypes.EXTERNAL_TRANSFER_IN_SECURITY,
  TransactionTypes.TRANSFER_IN_SECURITY,
  TransactionTypes.TRANSFER_OUT_SECURITY,
];

export const ADJUSTED_COST_BASE_TYPES = [
  TransactionTypes.DEPOSIT_BUY,
  TransactionTypes.DISTRIBUTION_REINVESTMENT,
  TransactionTypes.EXTERNAL_TRANSFER_IN_SECURITY,
  TransactionTypes.STOCK_DRIP,
  TransactionTypes.MARKET_DRIP,
  TransactionTypes.TRANSFER_IN_SECURITY,
  TransactionTypes.TRANSFER_OUT_SECURITY,
];
export const QUANTITY_PRICE_MULTIPLIERS = [
  TransactionTypes.DEPOSIT_BUY,
  TransactionTypes.EXTERNAL_TRANSFER_IN_SECURITY,
  TransactionTypes.TRANSFER_IN_SECURITY,
  TransactionTypes.TRANSFER_OUT_SECURITY,
  TransactionTypes.STOCK_DRIP,
  TransactionTypes.MARKET_DRIP,
];

export const getValueSign = (type: TransactionTypes, adjustmentType?: 'DEPOSIT' | 'WITHDRAW') => {
  if (POSITIVE_VALUE_TYPES.includes(type)) return 1;
  if (NEGATIVE_VALUE_TYPES.includes(type)) return -1;

  if (adjustmentType) return adjustmentType === 'DEPOSIT' ? 1 : -1;
  return 0;
};

export const isAdjustmentTypeAvailable = (type: TransactionTypes) => (!POSITIVE_VALUE_TYPES.includes(type)
  && !NEGATIVE_VALUE_TYPES.includes(type)
  && !(['SWITCH',
    TransactionTypes.INTEREST,
    TransactionTypes.ACCRUED_INTEREST,
    TransactionTypes.CANCEL_CHEQUE,
    TransactionTypes.CANCEL_INTEREST,
    TransactionTypes.CANCEL_ADJUSTMENT,
  ].includes(type))
);

export interface Transaction {
  id: string;
  date?: Date;
  subAccount?: SubAccount;
  account?: Account;
  goal?: Goal;
  user?: User;
  financialProduct?: FinancialProduct;
  type: TransactionTypes;
  quantity?: number;
  priceCents?: number;
  valueCents?: number;
  currency?: Currencies;
  description?: string;
  objectType?: TransactionObjectTypes;
  objectId?: string;
}
