import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Box, Skeleton, Typography } from '../../../1-primative';
import {
  Card, Table, TableBody, TableCell, TableHeadCell, TableRow,
} from '../../../2-component';
import { TransferModal } from './components/transferModal';
import { translateBackend } from '../../../../assets/i18n/config';
import { PendingTransferCell } from './components/pendingTransferCell';
import { usePermissions } from '../../../../providers/userContextProvider';
import {
  Column, DownloadButton, DownloadButtonProps, ExportApiReportFormatters,
} from '../../../3-pattern';
import { FETCH_ACCOUNT_CUSTODIAN_UPCOMING_TRANSACTION_QUERY, FETCH_UPCOMING_TRANSFERS_QUERY } from './pendingTransfers.queries';

const BASE_COLUMN_DEFINITIONS = {
  account: { label: { en: 'Account', fr: 'Compte' }, type: 'account' },
  type: { label: { en: 'Type', fr: 'Type' }, type: 'type' },
  source: { label: { en: 'Source', fr: 'Source' }, type: 'source' },
  state: { label: { en: 'State', fr: 'État' }, type: 'state' },
  createdAt: { label: { en: 'Created At', fr: 'Créé à' }, type: 'createdAt' },
  value: { label: { en: 'Value', fr: 'Valeur' }, type: 'value' },
  scheduledAt: { label: { en: 'Date', fr: 'Date' }, type: 'scheduledAt' },
  securityName: { label: { en: 'Security Name', fr: 'Nom de sécurité' }, type: 'securityName' },
  frequency: { label: { en: 'Frequency', fr: 'Fréquence' }, type: 'frequency' },
  bankAccountInstitution: { label: { en: 'Bank Account Institution Number', fr: 'Numéro de l\'institution' }, type: 'bankAccountInstitution' },
  bankAccountTransitNumber: { label: { en: 'Bank Account Transit Number', fr: 'Numéro de transit' }, type: 'bankAccountTransitNumber' },
  bankAccountNumber: { label: { en: 'Bank Account Number', fr: 'Numéro de compte bancaire' }, type: 'bankAccountNumber' },
  account_id: { label: { en: 'Account ID', fr: 'Compte' }, type: 'account_id' },
  firstName: { label: { en: 'User First Name', fr: 'Prénom' }, type: 'firstName' },
  lastName: { label: { en: 'User Last Name', fr: 'Nom' }, type: 'lastName' },
  currency: { label: { en: 'Currency', fr: 'Devise' }, type: 'currency' },
  subAccount: { label: { en: 'Sub-Account', fr: 'Sous-compte' }, type: 'subAccount' },
  goal: { label: { en: 'Goal', fr: 'Objectif' }, type: 'goal' },
  user: { label: { en: 'User', fr: 'Utilisateur' }, type: 'user' },
  organization: { label: { en: 'Organization', fr: 'Organisation' }, type: 'organization' },
  householdClientGroup: { label: { en: 'Household Client Group', fr: 'Groupe client ménage' }, type: 'householdClientGroup' },
};
const DEFAULT_TABLE = [
  BASE_COLUMN_DEFINITIONS.account,
  BASE_COLUMN_DEFINITIONS.type,
  BASE_COLUMN_DEFINITIONS.source,
  BASE_COLUMN_DEFINITIONS.state,
  BASE_COLUMN_DEFINITIONS.value,
  BASE_COLUMN_DEFINITIONS.scheduledAt,
];

const DOWNLOAD_COLUMNS = [
  BASE_COLUMN_DEFINITIONS.account,
  BASE_COLUMN_DEFINITIONS.type,
  BASE_COLUMN_DEFINITIONS.source,
  BASE_COLUMN_DEFINITIONS.state,
  BASE_COLUMN_DEFINITIONS.createdAt,
  BASE_COLUMN_DEFINITIONS.value,
  BASE_COLUMN_DEFINITIONS.scheduledAt,
  BASE_COLUMN_DEFINITIONS.securityName,
  BASE_COLUMN_DEFINITIONS.frequency,
  BASE_COLUMN_DEFINITIONS.bankAccountInstitution,
  BASE_COLUMN_DEFINITIONS.bankAccountTransitNumber,
  BASE_COLUMN_DEFINITIONS.bankAccountNumber,
];

const DOWNLOAD_ADDITIONAL_COLUMNS = [
  BASE_COLUMN_DEFINITIONS.account_id,
  BASE_COLUMN_DEFINITIONS.firstName,
  BASE_COLUMN_DEFINITIONS.lastName,
  BASE_COLUMN_DEFINITIONS.currency,
  BASE_COLUMN_DEFINITIONS.subAccount,
  BASE_COLUMN_DEFINITIONS.goal,
  BASE_COLUMN_DEFINITIONS.user,
  BASE_COLUMN_DEFINITIONS.organization,
  BASE_COLUMN_DEFINITIONS.householdClientGroup,
];

export const PendingTransfersVisual = ({
  upcomingTransfers, loading, transition, options, refetchAll, queryFilter, useCustodianData,
}: {
  upcomingTransfers: any[], loading: boolean, queryFilter?: any, useCustodianData?: boolean,
  transition: (transferId: string, isScheduled: boolean, variables: any) => void, options: any, refetchAll: () => void,
}) => {
  const { t } = useTranslation(['components', 'accountTypes']);
  const { permissions } = usePermissions();
  const [activeTransfer, setActiveTransfer] = useState<any>(null);
  const [open, setOpen] = useState<boolean>(false);

  const aliasMap: any = {
    account: `${useCustodianData ? 'account.type' : 'subAccount.account.type'}`,
    type: 'type',
    source: 'source',
    state: 'state',
    createdAt: 'createdAt',
    value: 'amountCents',
    scheduledAt: 'scheduledDate',
    securityName: `${useCustodianData ? 'financialProduct.cusip' : 'subAccount.financialProduct.cusip'}`,
    frequency: 'frequency',
    bankAccountInstitution: 'bankAccount.institutionNumber',
    bankAccountTransitNumber: 'bankAccount.transitNumber',
    bankAccountNumber: 'bankAccount.bankAccountNumber',
    account_id: `${useCustodianData ? 'account.id' : 'subAccount.account.id'}`,
    firstName: `${useCustodianData ? 'account.user.firstName' : 'subAccount.account.user.firstName'}`,
    lastName: `${useCustodianData ? 'account.user.lastName' : 'subAccount.account.user.lastName'}`,
    currency: 'currency',
    subAccount: 'subAccount.id',
    goal: 'subAccount.goal.id',
    user: `${useCustodianData ? 'account.user.id' : 'subAccount.account.user.id'}`,
    organization: `${useCustodianData ? 'account.organization.id' : 'subAccount.organization.id'}`,
    householdClientGroup: `${useCustodianData ? 'account.householdClientGroup.id' : 'subAccount.account.householdClientGroup.id'}`,
  };

  const getColumn = (item: any): Column => {
    const gqlAlias = aliasMap[item.type] ?? `unknown.${item.type}`;
    const formatter = item.type === 'value' ? ExportApiReportFormatters.DIVIDE_BY_100 : undefined;
    const tableItem = options.table?.find((elem: any) => elem.type === item.type);
    return { gqlAlias, formatter, header: translateBackend(tableItem?.label) || translateBackend(item.label) };
  };

  const columns: Column[] = DOWNLOAD_COLUMNS.map(getColumn);

  const additionalColumns: Column[] = DOWNLOAD_ADDITIONAL_COLUMNS.filter((e: any) => (useCustodianData ? !['currency', 'subAccount', 'goal'].includes(e.type) : true)).map(getColumn);

  const getLedgerDownloadConfig = (): DownloadButtonProps => {
    const baseQuery = useCustodianData
      ? FETCH_ACCOUNT_CUSTODIAN_UPCOMING_TRANSACTION_QUERY(permissions)
      : FETCH_UPCOMING_TRANSFERS_QUERY(permissions);

    const fileName = translateBackend(options.customTitle) || t('components:upcomingTransaction.fileName');

    const config: DownloadButtonProps = {
      gql: baseQuery,
      fileName: `${fileName}_${dayjs().format('YYYY-MM-DD')}`,
      filter: queryFilter,
      queryPath: useCustodianData ? 'fetchCustodianUpcomingTransactions' : 'fetchUpcomingTransfers',
      datasetPath: useCustodianData ? 'upcomingTransactions' : 'upcomingTransfers',
      columns,
      additionalColumns,
      ...(useCustodianData ? {} : { sortField: 'state' }),
      progressTitle: t('components:upcomingTransaction.progressTitle'),
      configurable: true,
    };

    return config;
  };

  const downloadConfig = getLedgerDownloadConfig();
  return (
    <>
      <Typography variant='headingSmall' mb={2}>{options.customTitle ? translateBackend(options.customTitle) : t('components:pendingTransfers.title')}</Typography>
      <Card sx={{ overflowX: 'auto' }}>
        {(options.enableDownload && permissions.includes('read:api_exports')) && (
          <Box display='flex' justifyContent='end' sx={{ p: 2 }}>
            <DownloadButton {...downloadConfig} />
          </Box>
        )}
        <Table>
          <TableBody>
            <TableRow sx={{ height: '40px' }}>
              {(options.table || DEFAULT_TABLE).map((x: any, idx: number) => (
                <TableHeadCell key={x} isFirst={idx === 0} right={![
                  'account', 'type', 'createdAt',
                  'scheduledAt', 'source', 'state',
                  'securityName', 'frequency', 'bankAccount',
                ].includes(x.type)}>{translateBackend(x.label)}</TableHeadCell>
              ))}
            </TableRow>
            {loading ? (
              <>
                {[...Array(5)].map((_x, i) => (
                  <TableRow key={i}>
                    {(options.table || DEFAULT_TABLE).map((_: any, idx: number) => (
                      <TableCell dense isFirst={idx === 0}><Skeleton width='100%' height='16px' /></TableCell>
                    ))}
                  </TableRow>
                ))}
              </>
            ) : (
              <>
                {upcomingTransfers.map((transaction: any) => (
                  <TableRow key={transaction.id} pointer hover sx={{ cursor: 'pointer' }} onClick={() => {
                    setActiveTransfer(transaction);
                    setOpen(true);
                  }}>
                    { (options.table || DEFAULT_TABLE).map((x: any, idx: number) => (
                      <PendingTransferCell isFirst={idx === 0} key={x.type} transaction={transaction} type={x.type} />
                    ))}
                  </TableRow>
                ))}
              </>
            )}
            {upcomingTransfers.length === 0 && !loading && (
              <TableRow>
                <TableCell colSpan={(options.table || DEFAULT_TABLE).length} sx={{ textAlign: 'center' }}>{t('components:transaction.noTransactions')}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Card>
      {activeTransfer && (
        <TransferModal open={open} setOpen={setOpen} transfer={activeTransfer} transition={transition} options={options} />
      )}
    </>
  );
};
