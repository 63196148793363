import {
  Box, Button, ListItem, Stack, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ThemeSelect from 'components/inputs/themeSelect';
import ModelPortfoliosSelect from 'components/inputs/modelPortfoliosSelect';
import { gql, useQuery } from '@apollo/client';
import { colors } from 'ovComponents';
import { usePermissions } from 'providers/userContextProvider';
import { FETCH_CLIENT_GROUP_MEMBERS } from 'components/inputs/householdMemberSelect';
import { Relationship } from 'interfaces';
import { useEffect, useState } from 'react';
import { generateClientNameString } from '../../../../util';
import { StepProps } from '../wizard';

const SUGGEST_PORTFOLIO = gql`
  query suggestPortfolio($suitabilityScore: Int!, $organizationId: ObjectID!, $themeId: ObjectID) {
    suggestFinancialProduct(input:{
      suitabilityScore: $suitabilityScore
      organizationId: $organizationId
      themeId: $themeId
    }) {
      financialProduct {
        id name
      }
    }
  }
`;

export const PickPortfolioStep = ({
  context, state, setState, continueTo,
}: StepProps) => {
  const { t } = useTranslation('createGoalWizard');
  const [scores, setScores] = useState<number[]>([]);
  const { permissions } = usePermissions();

  const { data: suggestPortfolioQuery } = useQuery(SUGGEST_PORTFOLIO, {
    skip: scores.length === 0 || !state.portfolioThemeId,
    variables: {
      suitabilityScore: Math.min(...scores),
      themeId: state.portfolioThemeId,
      organizationId: context.organizationId,
    },
    onCompleted: (data) => {
      if (!state.portfolioFinancialProductId) {
        setState({ ...state, portfolioFinancialProductId: data.suggestFinancialProduct.financialProduct.id });
      }
    },
  });

  if (!context.allowPortfolioPerGoal) return <></>;

  return (
    <>
      <ListItem>
        <Typography variant='wizardTitle'>{t('pickPortfolio.applyPortfolio')}</Typography>
      </ListItem>
      <ListItem>
        <Typography color={colors.neutral600}>
          {t('pickPortfolio.useTheScores')}
        </Typography>
      </ListItem>
      <ListItem>
        <ShowSuitabilityScores forId={context.forId} forObject={context.forObject} state={state} scoresLoaded={setScores}/>
      </ListItem>
      <ListItem/>
      { scores.length !== 0 && (
        <>
        <ListItem>
          <ThemeSelect
            value={state.portfolioThemeId || ''}
            suitabilityScore={permissions.includes('read:all_themes') ? undefined : Math.min(...scores)}
            onChange={(newValue) => setState({ ...state, portfolioThemeId: newValue, portfolioFinancialProductId: undefined })}
            label={t('client:selectTheme')}
          />
        </ListItem>
        {state.portfolioThemeId && (
          <>
          <ListItem>
            <Typography fontWeight={'bold'}>
              {t('recommendedPortfolio')}: &nbsp;
              {suggestPortfolioQuery?.suggestFinancialProduct.financialProduct.name ?? '…'}
            </Typography>
          </ListItem>
          <ListItem>
            <ModelPortfoliosSelect
              value={state.portfolioFinancialProductId || ''}
              themeId={state.portfolioThemeId}
              onChange={(e) => setState({ ...state, portfolioFinancialProductId: e.target.value })}
              label={t('client:selectModel')}
              suggestableOnly
            />
          </ListItem>
          </>
        )}
        <ListItem/>
        </>
      )}
      <ListItem sx={{ flex: 1, alignItems: 'end', marginBottom: 1 }}>
        <Button disabled={!state.portfolioFinancialProductId} fullWidth variant='contained' sx={{ textTransform: 'none' }}
          onClick={() => continueTo('SUMMARY')}
        >
          {t('continue')}
        </Button>
      </ListItem>
      <ListItem>
        <Button fullWidth variant='text' sx={{ textTransform: 'none' }}
          onClick={() => {
            setState({
              ...state, applyPortfolio: false, portfolioThemeId: undefined, portfolioFinancialProductId: undefined,
            });
            continueTo('SUMMARY');
          }}
        >
          {t('skipforNow')}
        </Button>
      </ListItem>
    </>
  );
};

/* informative box: show calculated suitability scores of
 * - client, or all households clients
 * - this new goal
 */
const ShowSuitabilityScores = ({
  forObject, forId, state, scoresLoaded,
}: {
  forObject: 'USER' | 'CLIENT_GROUP'
  forId: string,
  state: StepProps['state'],
  scoresLoaded: (scores: number[]) => void
}) => {
  const { t } = useTranslation('createGoalWizard');
  const { permissions } = usePermissions();

  const { data: goalScore } = useQuery(CALCULATE_GOAL_SUITABILITY_SCORE, {
    variables: { input: { timeHorizon: state.timeHorizon, riskQuestion1: state.riskQuestion1 } },
  });

  const [clientScores, setClientScores] = useState<number[]>([]);

  /* submit all loaded scores to <PickPortfolioStep> at once */
  useEffect(() => {
    if (!goalScore) return;
    if (clientScores.length === 0 && permissions.includes('read:client_suitability')) return;

    scoresLoaded([...clientScores, goalScore.calculateGoalSuitabilityScore]);
  }, [goalScore, clientScores, permissions, scoresLoaded]);

  return (
    <Stack>
      { forObject === 'USER' && <ClientSuitabilityScoreLine userId={forId} setScore={(n) => setClientScores([...clientScores, n])}/>}
      { forObject === 'CLIENT_GROUP' && <MembersSuitabilityScoresLine clientGroupId={forId} setScores={setClientScores}/>}
      <Typography mb={1} fontSize='14px'>
        {t('pickPortfolio.goalSuitabilityScore')}: {goalScore?.calculateGoalSuitabilityScore}
      </Typography>
    </Stack>
  );
};

const MembersSuitabilityScoresLine = ({ clientGroupId, setScores }: { clientGroupId: string, setScores: (scores:number[]) => void }) => {
  const { data } = useQuery(FETCH_CLIENT_GROUP_MEMBERS, {
    variables: { id: clientGroupId },

  });
  const [memberScores, setMemberScores] = useState<number[]>([]);

  useEffect(() => {
    if (!data) return;
    if (memberScores.length === data.fetchClientGroup.clientGroup.relationships.length) {
      setScores(memberScores);
    }
  }, [memberScores, data, setScores]);

  if (!data) return <></>;

  return (
    <Box>
    {data.fetchClientGroup.clientGroup.relationships.map((rel: Relationship, index: number) => (
      <ClientSuitabilityScoreLine key={index} userId={rel.user.id} setScore={ (n) => setMemberScores((prevScores) => [...prevScores, n]) }/>
    ))}
    </Box>
  );
};

const ClientSuitabilityScoreLine = ({ userId, setScore }: { userId: string, setScore: (score:number) => void }) => {
  const { t } = useTranslation('createGoalWizard');
  const { permissions } = usePermissions();

  const { data } = useQuery(FETCH_USER_SUITABILITY_SCORE, {
    variables: { userId },
    skip: !permissions.includes('read:client_suitability'),
    onCompleted: (newData) => setScore(newData?.fetchUser.user.suitabilityScore ?? 0),
  });

  if (!data) return <></>;

  return (
    <Typography mb={1} fontSize='14px'>
      { t('pickPortfolio.someonesSuitabilityScore', { name: generateClientNameString(data?.fetchUser.user) })}: {data?.fetchUser.user.suitabilityScore ?? 0 }
    </Typography>
  );
};

const FETCH_USER_SUITABILITY_SCORE = gql`
  query fetchUserSuitabilityScore($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        firstName
        suitabilityScore
        entityName
      }
    }
  }
`;

const CALCULATE_GOAL_SUITABILITY_SCORE = gql`
  query calculateGoalSuitabilityScore($input: CalculateGoalSuitabilityScoreInput!) {
    calculateGoalSuitabilityScore(input: $input)
  }
`;
