import { gql, useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';
import {
  Badge,
  Button,
  Card, CardContent, Form, MenuItem, SelectField,
} from '../../../2-component';
import { Box, Grid, Typography } from '../../../1-primative';
import { translateBackend } from '../../../../assets/i18n/config';

const FETCH_USER = gql`
  query fetchUser($userId: ObjectID!) {
    fetchUser(userId: $userId) {
      user {
        id
        riskQuestion1
        riskQuestion2
        investmentKnowledge
        suitabilityScore
        isAccreditedInvestor
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        suitabilityScore
      }
    }
  }
`;

export const Suitability = ({ objectId, options }: { objectId: string, options: any }) => {
  const { t } = useTranslation(['client', 'shared']);
  const [user, setUser] = useState<any>({});
  const [edited, setEdited] = useState(false);

  const { data } = useQuery(FETCH_USER, {
    variables: { userId: objectId },
    fetchPolicy: 'no-cache',
  });

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: (d: any) => {
      setEdited(false);
      setUser((prev: any) => ({ ...prev, suitabilityScore: d.updateUser.user.suitabilityScore }));
    },
    variables: {
      input: {
        userId: objectId,
        riskQuestion1: user.riskQuestion1,
        riskQuestion2: user.riskQuestion2,
        investmentKnowledge: user.investmentKnowledge,
        isAccreditedInvestor: user.isAccreditedInvestor === 'true',
      },
    },
  });

  useEffect(() => {
    if (data) {
      setUser(data.fetchUser.user);
    }
  }, [data]);

  const update = (value: string, field: string) => {
    setEdited(true);
    setUser((prev: any) => ({ ...prev, [field]: value }));
  };

  return (
    <>
      <Typography variant='headingSmall' mb={2}>{options.customTitle ? translateBackend(options.customTitle) : t('suitability.title')}</Typography>
      <Card>
        <CardContent>
          <Form onSubmit={updateUser}>
            <Grid container spacing={2}>
              { options?.investmentKnowledge?.enabled && (
                <Grid item xs={12} md={3}>
                  <SelectField
                    label={translateBackend(options.investmentKnowledge.label)}
                    value={user.investmentKnowledge || ''}
                    onChange={(event: any) => update(event.target.value, 'investmentKnowledge')}
                    fullWidth
                  >
                    <MenuItem value='NONE'>{t('edit.investmentKnowledgeOptions.NONE')}</MenuItem>
                    <MenuItem value='SOME'>{t('edit.investmentKnowledgeOptions.SOME')}</MenuItem>
                    <MenuItem value='LIMITED'>{t('edit.investmentKnowledgeOptions.LIMITED')}</MenuItem>
                    <MenuItem value='EXPERT'>{t('edit.investmentKnowledgeOptions.EXPERT')}</MenuItem>
                  </SelectField>
                </Grid>
              )}
              { options?.riskQuestion1?.enabled && (
                <Grid item xs={12} md={3}>
                  <SelectField
                    label={translateBackend(options.riskQuestion1.label)}
                    value={user.riskQuestion1 || ''}
                    onChange={(event: any) => update(event.target.value, 'riskQuestion1')}
                    fullWidth
                  >
                    <MenuItem value='LEVEL_1'>{t('edit.riskQuestion1Options.LEVEL_1')}</MenuItem>
                    <MenuItem value='LEVEL_2'>{t('edit.riskQuestion1Options.LEVEL_2')}</MenuItem>
                    <MenuItem value='LEVEL_3'>{t('edit.riskQuestion1Options.LEVEL_3')}</MenuItem>
                    <MenuItem value='LEVEL_4'>{t('edit.riskQuestion1Options.LEVEL_4')}</MenuItem>
                    <MenuItem value='LEVEL_5'>{t('edit.riskQuestion1Options.LEVEL_5')}</MenuItem>
                  </SelectField>
                </Grid>
              )}
              { options?.riskQuestion2?.enabled && (
                <Grid item xs={12} md={3}>
                  <SelectField
                    label={translateBackend(options.riskQuestion2.label)}
                    value={user.riskQuestion2 || ''}
                    onChange={(event: any) => update(event.target.value, 'riskQuestion2')}
                    fullWidth
                  >
                    <MenuItem value='LEVEL_1'>{t('edit.riskQuestion1Options.LEVEL_1')}</MenuItem>
                    <MenuItem value='LEVEL_2'>{t('edit.riskQuestion1Options.LEVEL_2')}</MenuItem>
                    <MenuItem value='LEVEL_3'>{t('edit.riskQuestion1Options.LEVEL_3')}</MenuItem>
                    <MenuItem value='LEVEL_4'>{t('edit.riskQuestion1Options.LEVEL_4')}</MenuItem>
                    <MenuItem value='LEVEL_5'>{t('edit.riskQuestion1Options.LEVEL_5')}</MenuItem>
                  </SelectField>
                </Grid>
              )}
              { options?.accreditedInvestor?.enabled && (
                <Grid item xs={12} md={3}>
                  <SelectField
                    label={translateBackend(options.accreditedInvestor.label)}
                    value={(user.isAccreditedInvestor || false).toString() || ''}
                    onChange={(event: any) => update(event.target.value, 'isAccreditedInvestor')}
                    fullWidth
                  >
                    <MenuItem value='true'>{t('shared:yes')}</MenuItem>
                    <MenuItem value='false'>{t('shared:no')}</MenuItem>
                  </SelectField>
                </Grid>
              )}
            </Grid>
            <Box display='flex' justifyContent='space-between' alignItems='center' mt={2}>
              <Box>
                {(options.showSuitabilityScore || isNil(options.showSuitabilityScore)) && (
                  <Badge label={`${t('suitabilityScore')}: ${user.suitabilityScore}`} />
                )}
              </Box>
              {options.displayUpdateButton && <Button type='submit' label={t('shared:update')} disabled={!edited}/>}
            </Box>
          </Form>
        </CardContent>
      </Card>
    </>
  );
};
