/* eslint-disable object-curly-newline */
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Column, DownloadButton, DownloadButtonProps, ExportApiReportFormatters } from '../../../../3-pattern';
import { SubTradeRequestStates } from '../../../../../interfaces/subTradeRequest';
import { FETCH_SUB_TRADE_REQUESTS_QUERY } from './queries';

// !todo: bulkTradeRequestId and sourceId are temp, will be removed when bulkTradeRequest is deprecated
export const DownloadSubTradeRequests = ({
  filter,
  testId,
}: {
  filter: { bulkTradeRequestId?: string; sourceId?: string; goalId?: string; subAccountId?: string; states?: SubTradeRequestStates[] };
  testId?: string;
}) => {
  const { t } = useTranslation(['components']);

  const columns: Column[] = [
    { gqlAlias: 'subAccount.account.type', header: t('components:generateTrades.table.accountType') },
    { gqlAlias: 'subAccount.account.custodianAccountNumber', header: t('components:generateTrades.table.custodianAccountNumber') },
    { gqlAlias: 'type', header: t('components:generateTrades.table.type') },
    { gqlAlias: 'moneyAllocatedCents', header: t('components:generateTrades.table.amount'), formatter: ExportApiReportFormatters.DIVIDE_BY_100 },
    { gqlAlias: 'financialProduct.ticker', header: t('components:generateTrades.table.securityTicker') },
    { gqlAlias: 'financialProduct.translatedName.en', header: t('components:generateTrades.table.securityName') },
    { gqlAlias: 'financialProduct.settlementDays', header: t('components:generateTrades.table.settlementDays') },
    { gqlAlias: 'financialProduct.exchange', header: t('components:generateTrades.table.exchange') },
    { gqlAlias: 'settlementDate', header: t('components:generateTrades.table.settlementDate') },
    { gqlAlias: 'pair.financialProduct.ticker', header: t('components:generateTrades.table.pairedWith') },
    { gqlAlias: 'state', header: t('components:generateTrades.table.state') },
  ];

  const additionalColumns: Column[] = [];

  const config: DownloadButtonProps = {
    gql: FETCH_SUB_TRADE_REQUESTS_QUERY(false),
    fileName: `${t('components:generateTrades.activeTradeRequests')}_${dayjs().format('YYYY-MM-DD')}`,
    filter: { showSplits: true, ...filter },
    queryPath: 'fetchSubTradeRequests',
    datasetPath: 'subTradeRequests',
    columns,
    additionalColumns,
    sortField: 'subAccount',
    sortDesc: false,
    progressTitle: t('components:trades.exportProgressTitle'),
  };

  return (
    <DownloadButton
      configurable
      gql={config.gql}
      fileName={config.fileName}
      filter={config.filter}
      additionalColumns={config.additionalColumns}
      queryPath={config.queryPath}
      datasetPath={config.datasetPath}
      columns={config.columns}
      sortField={config.sortField}
      sortDesc={config.sortDesc}
      progressTitle={config.progressTitle}
      testId={testId}
    />
  );
};

export default DownloadSubTradeRequests;
