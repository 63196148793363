/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { WorkflowCompletionVisual } from '../workflowCompletion/workflowCompletion.visual';
import workflow from './withdrawWorkflow.workflow.json';
import { updateNextWorkflowStep, updatePreviousWorkflowStep } from '../../../util/hardCodedWorkflow';
import { PageObjectType } from '../../5-page';

const initialWorkflowCompletion = {
  id: '123',
  currentStep: workflow.steps[0],
  currentSubStep: workflow.steps[0].subSteps[0],
  workflow,
};

export const initialWithdraw = {
  bankAccount: {},
  subAccount: {},
  amountCents: 0,
};

const FETCH_SUB_ACCOUNTS = gql`
  query fetchSubAccount($subAccountId: ObjectID!) {
    fetchSubAccount(subAccountId: $subAccountId) {
      subAccount {
        goal { name type }
        financialProduct { translatedName { en fr } }
        account { id type user { id } }
        id
      }
    }
  }
`;

export const TransferContext = createContext<any>({
  transferData: {},
  setTransferData: () => {},
});

export const WithdrawWorkflow = ({
  open, setOpen, objectId, objectType,
}: {
  open: boolean, setOpen: (open: boolean) => void, objectId: string, objectType: PageObjectType,
}) => {
  const [transferData, setTransferData] = useState<any>({ ...initialWithdraw, objectType, objectId });
  const [workflowCompletion, setWorkflowCompletion] = useState<any>({ objectId, ...initialWorkflowCompletion });

  const [fetchSubAccount] = useLazyQuery(FETCH_SUB_ACCOUNTS, { variables: { subAccountId: objectId } });

  useEffect(() => {
    if (objectType === PageObjectType.SUB_ACCOUNT) {
      fetchSubAccount({ onCompleted: (d: any) => setTransferData({ ...transferData, subAccount: d.fetchSubAccount.subAccount }) });
    }
  }, [setTransferData, objectId, objectType]);

  const onClose = () => {
    setOpen(false);
    setTransferData({ ...initialWithdraw, objectType, objectId });
    setWorkflowCompletion({ objectId, ...initialWorkflowCompletion });
  };

  const updateNext = () => updateNextWorkflowStep(workflowCompletion, setWorkflowCompletion, onClose);
  const updatePrevious = () => updatePreviousWorkflowStep(workflowCompletion, setWorkflowCompletion);

  return (
    <TransferContext.Provider value={{ transferData, setTransferData }}>
      <WorkflowCompletionVisual workflowCompletion={workflowCompletion} open={open} onClose={onClose} onNext={updateNext} previousStep={updatePrevious} loading={false} />
    </TransferContext.Provider>
  );
};
