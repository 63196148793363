import { useState } from 'react';
import { AutoAwesome, Info } from '@mui/icons-material';
import { gql, useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Box, Skeleton, Typography } from '../../1-primative';
import {
  Button, Card, CardContent, Dialog, DialogContent, DialogTitle, IconButton,
} from '../../2-component';
import { BetaBadge } from '../../3-pattern';
import RichTextEditor from '../../../components/inputs/richTextEditor';
import { useThemeTokens } from '../../../providers/themeTokenProvider';

const FETCH_NEXT_BEST_ACTION = gql`
  query generateNextBestActions($input: GenerateNextBestActionsInput!) {
    generateNextBestActions(input: $input) {
      response
    }
  }
`;
export const NextBestAction = ({ objectId, options }: { objectId: string, options: any }) => {
  const { t } = useTranslation('pageConfiguration');
  const { sys } = useThemeTokens();
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(false);

  const [generate] = useLazyQuery(FETCH_NEXT_BEST_ACTION, {
    variables: {
      input: {
        objectId,
        objectType: 'INDIVIDUAL',
      },
    },
    onCompleted: (data) => {
      setContent(data.generateNextBestActions.response);
      setLoading(false);
    },
  });

  const Header = () => (
    <Box sx={{
      display: 'flex', justifyContent: 'space-between', alignItems: 'center',
    }}>
      <Box sx={{
        display: 'flex', justifyContent: 'start', alignItems: 'center', ml: 1,
      }}>
        <AutoAwesome sx={{ mr: 1.5, color: sys.color.primary }} />
        <Typography variant='headingSmall'>{t('nextBestAction.mainTitle')}</Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
        <BetaBadge />
        <IconButton onClick={() => setOpen(true)} sx={{ ml: 1 }}><Info /></IconButton>
      </Box>
    </Box>
  );

  return (
    <>
      <Card>
        <CardContent sx={{ pb: 1 }}>
          { content === null ? loading ? (
            <>
              <Header />
              <Skeleton variant='text' width='100%' height={30} sx={{ mt: 1 }}/>
              <Skeleton variant='text' width='100%' height={30} />
              <Skeleton variant='text' width='100%' height={30} />
            </>
          ) : (
            <>
              <Box sx={{
                display: 'flex', justifyContent: 'end', alignItems: 'center', mb: 1,
              }}>
                <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                  <BetaBadge />
                  <IconButton onClick={() => setOpen(true)} sx={{ ml: 2 }}><Info /></IconButton>
                </Box>
              </Box>
              <Box sx={{
                justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2,
              }}>
                <Typography variant='headingSmall' sx={{ textAlign: 'center' }}>{t('nextBestAction.title')}</Typography>
                <Typography variant='bodyMedium' colorVariant='variant' sx={{ textAlign: 'center', maxWidth: '450px', mt: 1 }}>{t('nextBestAction.description')}</Typography>
                <Button label={t('nextBestAction.generate')} size='lg' leadingIcon={AutoAwesome} sx={{ mt: 2 }} onClick={() => {
                  setLoading(true);
                  generate();
                }} />
              </Box>
            </>
          ) : (
            <>
              <Header />
              <Typography variant='bodyLarge'>
                <RichTextEditor
                  value={content || ''}
                  label=''
                  readonly
                  customEditorStyle={{
                    border: 'none', padding: 0, minHeight: 'auto', li: { mt: 2 }, '.public-DraftEditor-content': { maxHeight: 'none' },
                  }}
                />
              </Typography>
            </>
          )}
        </CardContent>
      </Card>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle onClose={() => setOpen(false)}>
          {t('nextBestAction.disclaimerTitle')}
        </DialogTitle>
        <DialogContent>
          <Typography variant='bodyLarge'>{t('nextBestAction.disclaimer')}</Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};
