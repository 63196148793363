import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Box, Card, CircularProgress, Grid, Stack, Tab, Tabs,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { TabPanel } from 'components/tabs/ovTabs';
import SubAccounts from '../../components/cards/subAccounts';
import AssetsOverview from '../../components/layout/client/assetsOverview';
import CollapseHoldings from '../../components/layout/client/collapseHoldings';
import TransactionsTable from '../../components/tables/transactionsTable/transactionsTable';
import { TransfersTable } from '../../components/tables/transfersTable/transfersTable';
import { Account, AccountTypes, Organization } from '../../interfaces';
import { ObjectType } from '../../providers/statsHooks';
import { usePageState } from '../../util/usePageState';
import Documents from '../client/components/documents';
import AccountHolderCard from './components/accountHolderCard';
import { AccountSummary } from './components/accountSummary';
import Affiliates from './components/Affiliates';
import EditAccountNumber, { UPDATE_ACCOUNT_NUMBER } from '../client/components/editAccountNumber';
import StateChangesModal from '../../components/modals/stateChangesModal';
import { TRANSITION_ACCOUNT } from '../client/components/accounts';
import { UserContext, usePermissions } from '../../providers/userContextProvider';
import TradesTable from '../../components/tables/tradesTable/tradesTable';
import { ViewPage } from '../../ovComponents';
import { useGlobalToast } from '../../providers/globalToastProvider';
import { STATE_CHANGES_FIELD } from '../../util/reusableGraphQLFields';
import { ClientContext } from '../client';

export const FETCH_ACCOUNT = (permissions: string[]) => gql`
  query fetchAccount($id: ObjectID!) {
    fetchAccount(accountId: $id) {
      account {
        id
        type
        state
        ${STATE_CHANGES_FIELD}
        jurisdiction
        tlhEnabled
        forceOpen
        ${permissions.includes('read:account_number') ? 'custodianAccountNumber' : ''}
        statistics {
          marketValueCents
          simpleReturnAmount
          simpleReturnPercent
          pendingContributionCents
        }
        feePaymentAccount{
          id
          type
          ${permissions.includes('read:account_number') ? 'custodianAccountNumber' : ''}
        }
        subAccounts {
          id
          state
        }
        user {
          id
          ${permissions.includes('read:client_low_risk_pii') ? 'firstName' : ''}
          ${permissions.includes('read:client_low_risk_pii') ? 'middleName' : ''}
          ${permissions.includes('read:client_low_risk_pii') ? 'lastName' : ''}
          ${permissions.includes('read:client_low_risk_pii') ? 'primaryEmail' : ''}
          ${permissions.includes('read:client_low_risk_pii') ? 'entityName' : ''}
          type
          powerOfAttorneyGranted
          isForThirdParty
          statistics {
            marketValueCents
          }
          organization {
            id
          }
          accounts {
            id
            type
            statistics {
              marketValueCents
              simpleReturnAmount
              simpleReturnPercent
            }
          }
          households {
            id
            name
            relationships {
              user {
                id
                firstName
                lastName
                middleName
                entityName
                dateOfBirth
                physicalAddress {
                  city
                  country houseNumber province neighborhood postal streetName unitNumber
                }
                subAccounts{
                  id
                }
                accounts {
                  id
                }
                goals{
                  id
                }
                organization {
                  id
                }
                state
              }
            }
          }
        }
        affiliations {
          id
          type
          relation
          allocation
          user {
            id
            ${permissions.includes('read:client_low_risk_pii') ? 'firstName' : ''}
            ${permissions.includes('read:client_low_risk_pii') ? 'gender' : ''}
            ${permissions.includes('read:client_low_risk_pii') ? 'lastName' : ''}
            ${permissions.includes('read:client_low_risk_pii') ? 'primaryEmail' : ''}
            ${permissions.includes('read:client_high_risk_pii') ? 'dateOfBirth' : ''}
            affiliateOnly
          }
        }
        ${permissions.includes('read:billing_schedule') ? 'applicableBillingSchedule { id frequency }' : ''}
        ${permissions.includes('read:fee_tier') ? 'applicableFeeTier { id name }' : ''}
        ${permissions.includes('read:fee_tier') ? 'feeTier { id name }' : ''}
        ${permissions.includes('read:billing_schedule') ? 'billingSchedule { id frequency }' : ''}
        applyForGovFunds
        householdClientGroup {
          id
          name
          type
        }
        corporationClientGroup {
          id
          name
          type
          relationships {
            user {
              id
              firstName
              lastName
              middleName
              dateOfBirth
              physicalAddress {
                city
                country houseNumber province neighborhood postal streetName unitNumber
              }
              subAccounts{
                id
              }
              accounts {
                id
              }
              goals{
                id
              }
              organization {
                id
              }
              state
            }
          }
        }
      }
    }
  }
`;

const AccountsDetail = () => {
  const { t } = useTranslation(['accountsDetail', 'client']);
  const { showToast } = useGlobalToast();
  const { permissions, accessiblePages } = usePermissions();
  const params = useParams();
  const [statsNeedUpdate, setStatsNeedUpdate] = useState(0);
  const [updateAccountName, setUpdateAccountName] = useState(false);
  const [showState, setShowState] = useState(false);
  const [transitionAccount] = useMutation(TRANSITION_ACCOUNT);
  const [updateAccount] = useMutation(UPDATE_ACCOUNT_NUMBER);
  const [account, setAccount] = useState<Account>();
  const { loading, refetch } = useQuery(FETCH_ACCOUNT(permissions), {
    skip: permissions.length === 0,
    variables: { id: params.accountId },
    onCompleted: (data: any) => setAccount(data.fetchAccount.account),
  });
  const [tab, setTab] = usePageState('overview', 'tab');

  const { userContext, setUserId } = useContext(UserContext);

  useEffect(() => {
    if (account) setUserId(account.user.id);
  }, [account, setUserId]);

  if (userContext.role?.accountPageConfiguration) {
    return <ViewPage pageConfiguration={userContext.role.accountPageConfiguration} />;
  }

  if (loading) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress sx={{ marginTop: 50 }} />
      </Box>
    );
  }

  if (!account) return <></>;

  const subAccountIds = account.subAccounts?.map((x) => x.id);
  const showAffiliateTab = ![AccountTypes.PERSONAL].includes(account.type);

  return (
    <Box>
      <ClientContext.Provider value={{
        id: params.userId ?? account.user.id,
        orgSettings: account.user?.organization as Organization,
        totalMarketValueCents: account.user.statistics?.marketValueCents ?? 0,
      }}>
        <AccountSummary
          account={account}
          onUpdateAccount={() => setUpdateAccountName(true)}
          onAccountState={() => setShowState(true)}
          onUpdateAccountState={async (name) => {
            await transitionAccount({ variables: { input: { accountId: account?.id, transition: name } } });
            refetch();
          }}
          onForceOpen={async () => {
            await updateAccount({ variables: { input: { accountId: account?.id, forceOpen: true } } });
            showToast({ message: t('client:accountDetails.forceOpenSuccess'), severity: 'success' });
            refetch();
          }}
        />

        <Box mt={4} mb={3}>
          <Tabs
            value={tab}
            onChange={(event, newValue) => { setTab(newValue); }}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label={t('tabs.overview')} value="overview" />
            <Tab label={t('tabs.subaccount')} value="subaccount" />
            {showAffiliateTab
              && <Tab label={t('tabs.affiliates')} value="affiliates" />
            }
            {permissions.includes('read:files')
              && <Tab label={t('tabs.documents')} value="documents" />
            }
            <Tab label={t('tabs.transfers')} value="transfers" />
            {permissions.includes('read:trade_basic')
              && <Tab label={t('tabs.trades')} value="trades" />
            }
            {accessiblePages.includes('TRANSACTIONS')
              && <Tab label={t('tabs.ledger')} value="ledger" />
            }
          </Tabs>
        </Box>

        <TabPanel value={tab} index="overview">
          <Stack spacing={2}>
            <AssetsOverview id={account.id} type={ObjectType.ACCOUNT} needUpdate={statsNeedUpdate} />
            <CollapseHoldings id={account.id} type={ObjectType.ACCOUNT} isExpanded />
          </Stack>
        </TabPanel>
        <TabPanel value={tab} index="subaccount">
          <SubAccounts ofType='ACCOUNT' ofId={account.id} hasClientGroup={!!account.householdClientGroup} />
        </TabPanel>
        {showAffiliateTab
          && <TabPanel value={tab} index="affiliates">
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Affiliates account={account} />
              </Grid>
              <Grid item xs={12} md={4}>
                <AccountHolderCard account={account} />
              </Grid>
            </Grid>
          </TabPanel>}
        <TabPanel value={tab} index="documents">
          <Card>
            <Documents userId={account.user.id} onlyAccountId={account.id} />
          </Card>
        </TabPanel>
        <TabPanel value={tab} index="transfers">
          <Card>
            <TransfersTable
              filter={{ userId: account.user.id, accountId: account.id }}
              accountType={account.type}
              onTransition={refetch}
              onNewTransfer={() => {
                refetch();
                setStatsNeedUpdate(statsNeedUpdate + 1);
              }}
              level="ACCOUNT"
            />
          </Card>
        </TabPanel>
        <TabPanel value={tab} index="trades">
          <Card>
            <TradesTable filter={{ accountId: params.accountId }} />
          </Card>
        </TabPanel>
        <TabPanel value={tab} index="ledger">
          <Card>
            <TransactionsTable filter={{ userId: account.user.id, subAccountIds }} onChange={() => {
              refetch();
              setStatsNeedUpdate(statsNeedUpdate + 1);
            }} />
          </Card>
        </TabPanel>

        <EditAccountNumber
          afterCreate={() => {
            setUpdateAccountName(false);
            refetch();
          }}
          handleClose={() => setUpdateAccountName(false)}
          open={updateAccountName}
          accountId={account.id}
          accNumber={account.custodianAccountNumber}
        />
        <StateChangesModal
          id={account.id}
          open={showState}
          title={t('client:accountDetails.transitions.title')}
          handleClose={() => {
            setShowState(false);
          }}
          stateChanges={account?.stateMachineChanges || []}
        />
      </ClientContext.Provider>
    </Box>
  );
};

export default AccountsDetail;
export { AccountBreadcrumb } from './accountBreadcrumb';
