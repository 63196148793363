import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { Box } from '../../../../1-primative';
import { ConfirmationModal, ConfirmButtonColors } from '../../../../3-pattern';
import { TextField } from '../../../../2-component';

interface TransferTransitionReasonDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (reason: string) => void;
  loading?: boolean;
  title: string;
  confirmButtonLabel: string;
  abortButtonLabel: string;
}

export const TransferTransitionReasonDialog = ({
  open,
  onClose,
  onConfirm,
  loading = false,
  title,
  confirmButtonLabel,
  abortButtonLabel,
}: TransferTransitionReasonDialogProps) => {
  const { t } = useTranslation(['components']);
  const [reason, setReason] = useState('');

  useEffect(() => {
    if (!open) {
      setReason('');
    }
  }, [open]);

  return (
    <ConfirmationModal
      open={open}
      title={title}
      onConfirm={() => {
        onConfirm(reason);
        setReason('');
      }}
      onCancel={() => {
        onClose();
        setReason('');
      }}
      confirmButtonLabel={confirmButtonLabel}
      cancelButtonLabel={abortButtonLabel}
      confirmButtonColor={ConfirmButtonColors.primary}
      loading={loading}
      dialogStyles={{
        dialogContainer: {
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '416px',
            },
          },
        },
        dialogContentStyles: {
          width: 'auto',
          maxWidth: '421px',
          minWidth: 'auto',
        },
      }}
    >
      <Box>
        <TextField
          label={t('transferTransitions.reason')}
          fullWidth
          value={reason}
          onChange={(e: any) => setReason(e.target.value)}
          multiline
          rows={3}
        />
      </Box>
    </ConfirmationModal>
  );
};
