import {
  useState, useContext,
} from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { usePermissions, UserContext } from 'providers/userContextProvider';
import { PendingTransfersVisual } from './pendingTransfers.visual';
import {
  FETCH_UPCOMING_TRANSFERS, CANCEL_SCHEDULED_TRANSFER, TRANSITION_TRANSFER,
  FETCH_ACCOUNT_CUSTODIAN_UPCOMING_TRANSACTION,
} from './pendingTransfers.queries';
import { PageObjectType } from '../../../5-page';

const baseFilter = (objectType: PageObjectType, objectId: string) => {
  switch (objectType) {
    case PageObjectType.INDIVIDUAL:
      return { userId: objectId };
    case PageObjectType.NON_INDIVIDUAL:
      return { userId: objectId };
    case PageObjectType.HOUSEHOLD:
      return { clientGroupId: objectId };
    case PageObjectType.GOAL:
      return { goalId: objectId };
    case PageObjectType.ACCOUNT:
      return { accountId: objectId };
    case PageObjectType.SUB_ACCOUNT:
      return { subAccountId: objectId };
    default:
      return {};
  }
};

export const PendingTransfers = ({ objectId, objectType, options = {} }: { objectId: string, objectType: PageObjectType, options?: any }) => {
  const [upcomingTransfers, setUpcomingTransfers] = useState([]);
  const { permissions } = usePermissions();
  const { custodianConnection } = useContext(UserContext);

  const useCustodianUpcomingTransfers = options.useCustodianUpcomingTransactions && custodianConnection?.enableFetchCustodianUpcomingTransactions && objectType === PageObjectType.ACCOUNT;
  const filter = {
    ...baseFilter(objectType, objectId),
  };

  const { loading, refetch } = useQuery(FETCH_UPCOMING_TRANSFERS(permissions), {
    variables: {
      input: {
        filter,
        pagination: {
          perPage: 1000,
          sortField: 'state',
        },
      },
    },
    skip: useCustodianUpcomingTransfers,
    onCompleted: (data) => setUpcomingTransfers(data?.fetchUpcomingTransfers?.upcomingTransfers ?? []),
  });

  const { loading: custodianUpcomingTransfersLoading, refetch: custodianUpcomingTransfersRefetch } = useQuery(FETCH_ACCOUNT_CUSTODIAN_UPCOMING_TRANSACTION(permissions), {
    variables: {
      input: {
        filter,
      },
    },
    skip: !useCustodianUpcomingTransfers,
    onCompleted: (responseData) => setUpcomingTransfers(responseData?.fetchCustodianUpcomingTransactions?.upcomingTransactions ?? []),
  });

  const refetchAll = () => (useCustodianUpcomingTransfers ? custodianUpcomingTransfersRefetch() : refetch());

  const [transitionTransfer] = useMutation(TRANSITION_TRANSFER);
  const [cancelScheduledTransfer] = useMutation(CANCEL_SCHEDULED_TRANSFER);

  const transition = (transferId: string, isScheduled: boolean, variables: any) => {
    if (isScheduled) {
      cancelScheduledTransfer({
        variables: { scheduledTransferId: transferId },
        onCompleted: refetchAll,
      });
    } else {
      transitionTransfer({
        variables: { input: { transferId, ...variables } },
        onCompleted: refetchAll,
      });
    }
  };

  return (
    <PendingTransfersVisual
      upcomingTransfers={upcomingTransfers}
      loading={loading || custodianUpcomingTransfersLoading}
      transition={transition}
      options={options}
      refetchAll={refetchAll}
      queryFilter={filter}
      useCustodianData={useCustodianUpcomingTransfers}
    />
  );
};
