/* eslint-disable react-hooks/exhaustive-deps */
import {
  createContext, useContext, useEffect, useState,
} from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { WorkflowCompletionVisual } from '../workflowCompletion/workflowCompletion.visual';
import workflow from './depositWorkflow.workflow.json';
import { updateNextWorkflowStep, updatePreviousWorkflowStep } from '../../../util/hardCodedWorkflow';
import { ActionContext, PageObjectType } from '../../5-page';

export const initialTransfer = {
  type: '',
  bankAccount: {},
  subAccount: {},
  fromSubAccount: {},
  institution: {},
  accountNumber: '',
  accountType: '',
  fiscalYear: undefined,
  transferType: 'ALL_IN_CASH',
  amountCents: 0,
  schedule: 'ONE_TIME',
  scheduledDate: dayjs().add(1, 'day').format('YYYY-MM-DD'),
  skipDocuments: true,
};

const initialWorkflowCompletion = {
  id: '123',
  currentStep: workflow.steps[0],
  currentSubStep: workflow.steps[0].subSteps[0],
  workflow,
};

const FETCH_SUB_ACCOUNTS = gql`
  query fetchSubAccount($subAccountId: ObjectID!) {
    fetchSubAccount(subAccountId: $subAccountId) {
      subAccount {
        id
        financialProduct { translatedName { en fr } }
        goal { name type }
        account { id type user { id } }
      }
    }
  }
`;

export const DepositTransferContext = createContext<any>({
  transferData: {},
  setTransferData: () => {},
});

export const DepositWorkflow = ({
  open, setOpen, objectId, objectType,
}: {
  open: boolean, setOpen: (open: boolean) => void, objectId: string, objectType: PageObjectType,
}) => {
  const { refetch } = useContext(ActionContext);
  const [transferData, setTransferData] = useState<any>({ ...initialTransfer, objectType, objectId });
  const [workflowCompletion, setWorkflowCompletion] = useState<any>({ objectId, ...initialWorkflowCompletion });

  const [fetchSubAccount] = useLazyQuery(FETCH_SUB_ACCOUNTS, { variables: { subAccountId: objectId } });

  useEffect(() => {
    if (objectType === PageObjectType.SUB_ACCOUNT) {
      fetchSubAccount({ onCompleted: (d: any) => setTransferData({ ...transferData, subAccount: d.fetchSubAccount.subAccount }) });
    }
  }, [setTransferData, objectId, objectType]);

  const onClose = () => {
    setOpen(false);
    setTransferData({ ...initialTransfer, objectType, objectId });
    setWorkflowCompletion({ objectId, ...initialWorkflowCompletion });
  };

  const updateNext = () => updateNextWorkflowStep(workflowCompletion, setWorkflowCompletion, onClose);
  const updatePrevious = () => updatePreviousWorkflowStep(workflowCompletion, setWorkflowCompletion);

  return (
    <DepositTransferContext.Provider value={{ transferData, setTransferData }}>
      <WorkflowCompletionVisual workflowCompletion={workflowCompletion} open={open} previousStep={updatePrevious} loading={false} onNext={updateNext} onClose={() => {
        onClose();
        refetch();
      }} />
    </DepositTransferContext.Provider>
  );
};
