import { CSSProperties } from 'react';
import {
  InputAdornment, Autocomplete as MuiAutocomplete, SxProps, TextField,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { styled } from '@mui/material/styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslation } from 'react-i18next';
import {
  Box, Typography, colors, Icon, Tooltip,
} from '../..';
import { useThemeTokens } from '../../../providers/themeTokenProvider';
import InfoDialog from '../infoDialog/infoDialog';
import { useTextFieldTokens } from '../textField/textField.tokens';

export interface AutocompleteProps {
  label: string;
  open?: boolean;
  helpText?: string;
  error?: boolean;
  errorText?: string;
  success?: boolean;
  successText?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  options: any[];
  isOptionEqualToValue?: any;
  value?: any;
  onChange?: any;
  onInputChange?: any;
  onFocus?: any;
  onClick?: any;
  onBlur?: any;
  multiple?: boolean;
  freeSolo?: boolean;
  renderTags?: any;
  renderOption?: any;
  sx?: SxProps;
  labelSx?: SxProps;
  InputProps?: object;
  getOptionLabel?: (option: any) => string;
  width?: string;
  componentsProps?: object;
  inputReadOnly?: boolean;
  filterOptions?: (options: Array<any>, state: object) => Array<any>;
  loading?: boolean;
  autoFocus?: boolean;
  blurOnSelect?: boolean;
  disableClearable?: boolean;
  infoTooltip?: string;
  size?: 'small' | 'medium';
  testId?: string;
  lockMessage?: string;
  locked?: boolean;
  disableCloseOnSelect?: boolean;
}

const StyledTextField = styled(TextField)(() => {
  const tokens = useThemeTokens(useTextFieldTokens());
  const { comp } = tokens;

  return {
    '& .MuiOutlinedInput-root': {
      width: '100%',
      paddingRight: '14px !important',
      paddingLeft: '12px',
      '& input': {
        padding: '0px !important',
        color: comp.textField.textColor,
        fontSize: comp.textField.fontSize,
        fontWeight: comp.textField.fontWeight,
      },
      '& fieldset': {
        borderColor: comp.textField.borderColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: comp.textField.focusBorderColor,
      },
      '& .MuiSelect-select': {
        paddingLeft: '0px',
        marginLeft: '12px',
        marginRight: '12px',
      },
      '& .MuiInputAdornment-positionEnd': {
        marginLeft: '0px',
      },
      '&.Mui-error': {
        '& input': {
          color: comp.textField.errorColor,
        },
        '& fieldset': {
          borderColor: comp.textField.errorColor,
        },
      },
    },
  };
});

export const Autocomplete = ({
  error = false,
  label,
  open,
  options,
  fullWidth,
  errorText,
  helpText,
  successText,
  success = false,
  isOptionEqualToValue,
  value,
  onChange,
  onFocus,
  onClick,
  multiple,
  renderTags,
  renderOption,
  onInputChange,
  labelSx,
  disabled,
  InputProps,
  getOptionLabel,
  width,
  sx,
  onBlur,
  componentsProps,
  filterOptions,
  autoFocus,
  inputReadOnly = false,
  freeSolo = false,
  blurOnSelect = false,
  infoTooltip,
  size,
  testId,
  lockMessage,
  locked,
  disableCloseOnSelect,
}: AutocompleteProps) => {
  const variantStyle: CSSProperties = {};
  const { sys, comp } = useThemeTokens(useTextFieldTokens());
  const { t } = useTranslation('components');

  const style: CSSProperties = {
    ...variantStyle,
    height: multiple ? 'fit-content' : '40px',
    color: comp.textField.textColor,
    fontSize: comp?.textField?.fontSize ?? '16px',
    fontWeight: comp.textField.fontWeight,
    fontFamily: sys.font.base,
    borderRadius: '4px',
    width: fullWidth ? '100%' : width || 'auto',
    background: '#ffffff',
  };

  const helpTextFunc = (isError: boolean, isSuccess: boolean) => {
    if (isError) {
      return (errorText ?? t('fieldErrors.default', { fieldName: label })) || helpText;
    }

    if (isSuccess) {
      return successText || helpText;
    }

    return helpText;
  };

  const helpIcon = (isError: boolean, isSuccess: boolean) => {
    if (isError) {
      return <ErrorIcon sx={{ mr: '4px', color: colors.negative, fontSize: '12px' }} />;
    }
    if (isSuccess) {
      return <CheckCircleIcon sx={{ mr: '4px', color: colors.positive, fontSize: '12px' }} />;
    }
    return <HelpOutlineIcon sx={{ mr: '4px', color: colors.neutral600, fontSize: '12px' }} />;
  };

  return (
    <Box sx={{ ...sx }}>
      { label && (
         <Box display="flex" flexDirection="row" alignItems={'center'}>
          <Typography variant='labelSmall' sx={{ mb: '4px', color: error ? comp.textField.errorColor : comp.textField.labelColor }}>
            {label}
            {infoTooltip && (<InfoDialog information={infoTooltip} />)}
          </Typography>
        </Box>
      )}
      <MuiAutocomplete
        data-testid={testId ?? 'auto-complete'}
        renderInput={(params: any) => (
          <StyledTextField
            ref={params?.InputProps.ref}
            {...params}
            error={!!error}
            InputProps={{
              ...params.InputProps,
              ...InputProps,
              ...(locked
                ? {
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Tooltip title={lockMessage ?? ''}>
                        <Icon icon={LockIcon} size='small' />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }
                : {}),
            }}
            style={style}
            inputProps={{ ...params.inputProps, disabled: inputReadOnly || disabled, 'data-testid': `${testId ?? 'auto-complete'}-input` }}
            autoFocus={autoFocus}
          />
        )}
        open={open}
        // disablePortal
        sx={{
          '& + .MuiAutocomplete-popper .MuiAutocomplete-option': {
            color: comp.textField.textColor,
            fontSize: comp.textField.fontSize,
            fontWeight: comp.textField.fontWeight,
          },
        }}
        freeSolo={freeSolo}
        options={options}
        isOptionEqualToValue={isOptionEqualToValue}
        value={value}
        onChange={onChange}
        onInputChange={onInputChange}
        onFocus={onFocus}
        onClick={onClick}
        onBlur={onBlur}
        fullWidth={fullWidth}
        style={style}
        multiple={multiple}
        renderTags={renderTags}
        getOptionLabel={getOptionLabel}
        componentsProps={componentsProps}
        filterOptions={filterOptions}
        openOnFocus
        blurOnSelect={blurOnSelect}
        renderOption={renderOption}
        size={size}
        disabled={disabled}
        disableCloseOnSelect={disableCloseOnSelect}
      />
      {helpTextFunc(error, success) && (
        <Box alignItems='center' display='flex' mt={0.5}>
          {helpIcon(error, success)}
          <Typography variant='bodySmall' sx={{ color: error ? colors.negative : success ? colors.positive : colors.neutral600 }}>
            {helpTextFunc(error, success) || ''}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
