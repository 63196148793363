export const updateContextTransfer = ({
  existingContext = {}, value, setApplicationContext, setWorkflowCompletionContext,
}: {
  existingContext: any; value: any; setApplicationContext?: (context: any) => void; setWorkflowCompletionContext?: (context: any) => void;
}) => {
  if (setApplicationContext) {
    setApplicationContext({ ...existingContext, ...value });
  } else {
    setWorkflowCompletionContext?.({ ...existingContext, ...value });
  }
};
