import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  validateFields, FormErrors, FieldOptions,
} from 'ovComponents/4-module/workflowCompletion/subSteps/utils';
import { additionalInfo } from 'ovComponents/4-module/configurableOptionFields';
import InfoDialog from 'ovComponents/2-component/infoDialog/infoDialog';
import { translateBackend } from 'assets/i18n/config';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { kebabCase } from 'lodash';
import {
  Button, Typography, Form, SelectionTile, Box,
} from '../../../..';
import { ovAnalyticsEvents } from '../../../../../util/analytics/analytics';
import { AnalyticsContext } from '../../../../../providers/analyticsProvider';

export const GoalRiskQuestionVisual = ({
  options, goalData, loading, updateGoal, continueFunc, workflowCompletion,
}: {
  options: any, goalData: any, loading: boolean, updateGoal: any, continueFunc: any,
  workflowCompletion?: any,
}) => {
  const { sendAnalytic } = useContext(AnalyticsContext);
  const { t } = useTranslation(['client', 'shared']);
  const [focused, setFocused] = useState<string[]>([]);
  const [errors, setErrors] = useState<FormErrors>(null);
  const fieldOptions: FieldOptions = useMemo(() => ({
    riskQuestion1: { required: true },
  }), []);

  const validate = useCallback((candidateFields?: string[]): FormErrors => {
    const data = {
      riskQuestion1: goalData.riskQuestion1,
    };
    const newErrors = validateFields(fieldOptions, data, candidateFields);
    setErrors(newErrors);
    return newErrors;
  }, [fieldOptions, goalData]);

  const handleRiskQuestion1Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFocused([...focused, 'riskQuestion1']);
    updateGoal({ ...goalData, riskQuestion1: e.target.value });
  };

  const submit = () => {
    const formErrors = validate();
    if (formErrors) {
      setFocused(Object.keys(formErrors));
      return;
    }
    sendAnalytic(
      ovAnalyticsEvents.workflowsGoalRiskQuestion1ContinueButtonSelect,
      {
        workflowStepTitle: options?.title,
        workflowId: workflowCompletion?.workflow?.id,
        workflowName: workflowCompletion?.workflow?.name,
        activeWorkflowCompletionId: workflowCompletion?.id,
        objectId: workflowCompletion?.objectId,
        objectType: workflowCompletion?.objectType,
      },
    );
    continueFunc();
  };

  useEffect(() => {
    validate(focused);
  }, [validate, focused]);

  const subTitleStyle = {
    display: 'inline-flex',
    alignItems: 'end',
  };

  return (
    <Form onSubmit={submit}>
      {options?.title && (
        <Typography variant='displayLarge' sx={{ mt: 1 }}>
          {translateBackend(options?.title)}
          {additionalInfo(options?.title?.additionalInfo) && (<InfoDialog information={additionalInfo(options?.title?.additionalInfo) ?? ''} />)}
        </Typography>
      )}
      {options?.subtitle && (
        <Typography style={subTitleStyle} variant='bodyLarge' component={'div'} sx={{ mb: 3, table: { width: '100%' } }}>
          <Box display='inline-block'>
            <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>{translateBackend(options?.subtitle)}</ReactMarkdown>
          </Box>
          {additionalInfo(options?.subtitle?.additionalInfo) && (<InfoDialog information={additionalInfo(options?.subtitle?.additionalInfo) ?? ''} />)}
        </Typography>
      )}
      <SelectionTile
        testId="goal-risk-question-1"
        onChange={handleRiskQuestion1Change}
        value={goalData.riskQuestion1 ?? ''}
        options={options?.options?.map((option: any) => (
          { value: option.value, label: translateBackend(option.label), subtitle: translateBackend(option.description) }
        ))}
        error={errors?.riskQuestion1?.active}
      />
      <Box display='flex' justifyContent='end'>
        <Button dataTestId={`goal-risk-question-${kebabCase(translateBackend(options.title))}-continue-button`}
         label={t('continue')} disabled={loading} sx={{ mt: 3, textAlign: 'center' }} type='submit' />
      </Box>
    </Form>
  );
};

export default GoalRiskQuestionVisual;
