import { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import { MenuItem } from '@mui/material';
import { SelectField } from '../../2-component';
import { UserContext } from '../../../providers/userContextProvider';

const FETCH_NOTIFICATION_GROUPS = gql`
  query fetchNotificationGroups($input: FetchNotificationGroupsInput!) {
    fetchNotificationGroups(input: $input) {
      notificationGroups {
        id
        name { en fr }
        notificationChannels {
          type
          value
          setDefaultState
          allowEditByClient
        }
      }
      totalCount
    }
  }
`;
interface NotificationGroupSelectProp {
  label: string,
  onNotificationGroupSelect: (notificationGroup: any) => void,
  selectedNotificationGroup: any | undefined,
  sx?: any,
  size?: 'small' | 'medium',
  onBlur?: any,
  error?: any,
}
export const NotificationGroupSelect = ({
  label, onNotificationGroupSelect, selectedNotificationGroup, sx = {}, size, onBlur, error,
}: NotificationGroupSelectProp) => {
  const { activeOrganization } = useContext(UserContext);
  const { data } = useQuery(FETCH_NOTIFICATION_GROUPS, {
    variables: {
      input: {
        filter: {
          organizationId: activeOrganization.id,
        },
      },
    },
  });
  return (
    <SelectField
      onChange={(e: any) => onNotificationGroupSelect(data?.fetchNotificationGroups?.notificationGroups?.find((item: any) => item.id === e.target.value))}
      label={label}
      fullWidth
      sx={sx}
      size={size}
      value={selectedNotificationGroup?.id ?? ''}
      onBlur={onBlur}
      error={error}
    >
      {
        data?.fetchNotificationGroups?.notificationGroups?.map((notificationGroup: { id: string, name: { en: string } }) => (
          <MenuItem key={notificationGroup.id} value={notificationGroup.id}>{notificationGroup.name.en}</MenuItem>
        )) ?? []
      }
    </SelectField>
  );
};
