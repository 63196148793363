import mixpanel, { Config, Mixpanel } from 'mixpanel-browser';

const mixPanelConfig: Partial<Config> = { persistence: 'localStorage', cross_site_cookie: false, cookie_expiration: 7200 };

export type MixpanelConfigType = {
  projectToken: string,
};

const projects: Mixpanel[] = [];

const sendAnalyticsEvent = (eventName: string, meta?: any, providerId?: string): void => {
  projects.map((project) => {
    project.track(eventName, meta);
    return true;
  });
};

const initMixPanel = ({ config, user, providerId } : { config?: MixpanelConfigType, user?: any, providerId?: string }): void => {
  let project: Mixpanel;
  if (providerId) {
    project = mixpanel.init(config?.projectToken ?? '', mixPanelConfig, providerId);
  } else {
    project = mixpanel.init(config?.projectToken ?? '', mixPanelConfig, 'default');
  }
  project.identify(user?.id ?? '');
  project.register({
    id: user?.id,
    name: user?.name ?? '', // User's name
    email: user?.email ?? '',
  });
  projects.push(project);
};

export { initMixPanel, sendAnalyticsEvent };
