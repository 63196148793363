import { useTranslation } from 'react-i18next';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useContext } from 'react';
import { UserContext } from 'providers/userContextProvider';
import { Box, Typography } from 'ovComponents/1-primative';
import { TableCell, Tooltip } from '../../../../2-component';
import {
  formatDecimalNumber, formatMoneyValue, formatMoneyValueCentsWithDecimals, generateClientNameString,
} from '../../../../../util';
import { PendingTransactionBadge } from '../../../../3-pattern';
import { useLocalization } from '../../../../../util/useLocalization';
import { TransactionTypes } from '../../../../../interfaces/transaction';
import { Exchanges } from '../../../../../interfaces/financialProduct';

export const LedgerCell = ({
  transaction, type, view, isFirst,
}: {
  transaction: any,
  type: string,
  view: string,
  isFirst?: boolean,
}) => {
  const { t } = useTranslation(['components', 'shared', 'accountTypes']);
  const { activeOrganization } = useContext(UserContext);
  const { localizedDate } = useLocalization();

  const accountHolder = () => generateClientNameString(transaction.account?.user, false, false);

  switch (type) {
    case 'account':
      return (
        <TableCell isFirst={isFirst} dense>
          {t(`accountTypes:${transaction.account?.type}`)}
        </TableCell>
      );
    case 'client':
      return (
        <TableCell isFirst={isFirst} dense>{accountHolder()}</TableCell>
      );
    case 'type':
      return (
        <TableCell isFirst={isFirst} dense>
          <Box display='inline-flex' alignItems='center' flexWrap='wrap'>
            {t(`components:transaction.types.${transaction.type}`)}
            <PendingTransactionBadge transaction={transaction}></PendingTransactionBadge>
          </Box>
        </TableCell>
      );
    case 'currency':
      return (
        <TableCell isFirst={isFirst} dense>{transaction.currency ?? ''}</TableCell>
      );
    case 'costPrice':
      return (
        <TableCell isFirst={isFirst} dense number>{transaction.priceCents ? formatMoneyValueCentsWithDecimals(transaction.priceCents, '$', 6, false) : '-'}</TableCell>
      );
    case 'quantity':
      return (
        <TableCell isFirst={isFirst} dense number>{transaction.quantity === 0 ? '-' : formatDecimalNumber(transaction.quantity)}</TableCell>
      );
    case 'security':
      return (
        <TableCell isFirst={isFirst} dense>{transaction.financialProduct?.ticker ? transaction.financialProduct?.ticker : '-'}</TableCell>
      );
    case 'value':
      // TODO: remove this non-ovComponent after pending transactions epic V1
      return (
        <TableCell isFirst={isFirst} dense number>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {((activeOrganization?.allowPendingTransactions
              && ((transaction.isSellAll && transaction.financialProduct?.exchange === Exchanges.FUNDSERV) || transaction.risky)
              && transaction.pending) || [TransactionTypes.TRANSFER_IN_SECURITY, TransactionTypes.TRANSFER_OUT_SECURITY].includes(transaction.type)) && (
                <Tooltip
                  title={
                    <Typography sx={{ color: 'white' }}>
                      {t('transaction.estimateFinalValue')}
                    </Typography>
                  }
                  placement='top'
                >
                  <FiberManualRecordIcon
                    color='info'
                    sx={{ fontSize: '14px', marginRight: '4px', color: '#465CC8' }}
                  />
                </Tooltip>
          )}
            {formatMoneyValue(transaction.valueCents)}
          </Box>
        </TableCell>
      );
    case 'date':
      return (
        <TableCell isFirst={isFirst} dense>{localizedDate(transaction.date)}</TableCell>
      );
    case 'bookValue':
      return (
        <TableCell isFirst={isFirst} dense number>{transaction.bookValueCents ? formatMoneyValue(transaction.bookValueCents) : '-'}</TableCell>
      );
    case 'description':
      return (
        <TableCell isFirst={isFirst} dense >{transaction.description ?? ''}</TableCell>
      );
    case 'custodianType':
      return (
        <TableCell isFirst={isFirst} dense >{transaction.custodianTransactionType ?? ''}</TableCell>
      );
    case 'accountNumber':
      return (
        <TableCell isFirst={isFirst} dense>{transaction.account?.custodianAccountNumber ?? ''}</TableCell>
      );
    default:
      return (
        <TableCell isFirst={isFirst}>
          {type}
        </TableCell>
      );
  }
};
