import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useTranslation } from 'react-i18next';
import { SubTradeRequestTypes } from 'interfaces/subTradeRequest';
import { Grid, Typography } from '../../../../1-primative';
import { Tooltip } from '../../../../2-component';
import { formatMoneyValue } from '../../../../../util';

interface RequestedTradeInfo {
  type: SubTradeRequestTypes;
  isSellAll: boolean;
  moneyAllocatedCents: number;
  settlementDate: Date | string;
}

const ChangeTooltip = ({
  change, original, total = false, flip = false, isSellAll = false, requestedTradeInfo = [],
}: {
  change: number, original: number, total?: boolean, flip?: boolean, isSellAll?: boolean, requestedTradeInfo?: RequestedTradeInfo[],
}) => {
  const { t } = useTranslation(['components']);

  const isPositive = () => {
    if (change > 0 && !flip) {
      return true;
    }
    if (change > 0 && flip) {
      return false;
    }
    if (change < 0 && !flip) {
      return false;
    }
    return true;
  };

  const getTooltipText = () => {
    const tooltipLines: string[] = [];

    tooltipLines.push(`${t('portfolioTable.original')}: ${formatMoneyValue(original)}`);
    // 1) If allowPendingTransactions set & there's a pending trade from SubTradeRequests in REQUESTED state
    requestedTradeInfo.forEach((trade) => {
      const tradeTypeLabel = trade.isSellAll
        ? t('portfolioTable.requestedSellAll')
        : trade.type === 'BUY'
          ? t('portfolioTable.requestedBuy')
          : t('portfolioTable.requestedSell');

      tooltipLines.push(
        `${tradeTypeLabel} ${t('portfolioTable.pendingReconciliation')} ${trade.settlementDate}: ${formatMoneyValue(trade.moneyAllocatedCents)}`,
      );
    });

    // 2) If the user has entered an initiated trade
    if (change !== 0) {
      const buyOrSell = isPositive()
        ? t('portfolioTable.initiatedBuy')
        : isSellAll
          ? t('portfolioTable.initiatedSellAll')
          : t('portfolioTable.initiatedSell');

      tooltipLines.push(
        `${total ? `${t('portfolioTable.total')} ` : ''}${buyOrSell}: ${formatMoneyValue(
          Math.abs(change),
        )}`,
      );
    }

    // If no lines added, fall back to an empty string
    return tooltipLines.join('\n');
  };

  const tooltipText = getTooltipText();

  return (
    <>
      { change !== 0 ? (
        <Grid item>
          <Tooltip
            title={
              <div style={{ whiteSpace: 'pre-line' }}>
              {tooltipText}
              </div>
            }
          >
            {change > 0 ? (
              <ArrowUpwardIcon color='success' sx={{ fontSize: '14px' }} />
            ) : (
              <ArrowDownwardIcon color='error' sx={{ fontSize: '14px' }} />
            )}
          </Tooltip>
        </Grid>
      ) : (
        requestedTradeInfo.length ? (
          <Tooltip
          title={
            <Typography sx={{ color: 'white', whiteSpace: 'pre-line' }}>
              {tooltipText}
            </Typography>
          }
          placement='top'
        >
          <FiberManualRecordIcon
            color='info'
            sx={{ fontSize: '14px', color: '#465CC8' }}
          />
        </Tooltip>
        ) : (
          <FiberManualRecordIcon
            color='disabled'
            sx={{ fontSize: '14px' }}
          />
        )
      )}
    </>
  );
};

export default ChangeTooltip;
