import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ObjectType } from '@onevesthq/ov-conditions';
import { EdgeProps } from '@xyflow/react';
import { Box } from '../../../1-primative';
import {
  Button, Dialog, DialogContent, DialogFooter, DialogTitle,
  MenuItem,
  SelectField,
} from '../../../2-component';
import { RuleBuilder } from '../../../3-pattern/ruleBuilder/ruleBuilder';

export interface BranchProps extends EdgeProps {
  data: any,
}

export const BranchEditor = ({
  open, setOpen, branch, updateBranch, removeBranch,
}: {
  open: boolean, setOpen: (e: boolean) => void, branch: BranchProps, updateBranch: (b: BranchProps) => void, removeBranch: () => void,
}) => {
  const { t } = useTranslation(['workflowCompletions', 'shared']);
  const [localBranch, setLocalBranch] = useState(branch);
  const [ruleGroup, setRuleGroup] = useState(branch.data?.condition?.ruleGroup);

  useEffect(() => {
    if (branch) {
      setLocalBranch(branch);
      setRuleGroup(branch.data?.condition?.ruleGroup);
    }
  }, [branch]);

  if (!localBranch) {
    return <></>;
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth='md' fullWidth>
      <DialogTitle onClose={() => setOpen(false)}>
        {t('updateBranch')}
      </DialogTitle>
      <DialogContent>
        <SelectField
          label={t('conditionOn')}
          fullWidth
          sx={{ mb: 3 }} value={localBranch.data?.contextObject || 'CLIENT'}
          onChange={(e: any) => setLocalBranch({ ...localBranch, data: { ...localBranch.data, contextObject: e.target.value } })}
        >
          <MenuItem value='CLIENT'>{t('client')}</MenuItem>
          <MenuItem value='ACCOUNT'>{t('contextAccount')}</MenuItem>
          <MenuItem value='GOAL'>{t('contextGoal')}</MenuItem>
        </SelectField>
        { ruleGroup ? (
          <>
            <RuleBuilder
              ruleGroup={ruleGroup}
              objectType={localBranch.data.contextObject === 'ACCOUNT' ? ObjectType.ACCOUNT : localBranch.data.contextObject === 'GOAL' ? ObjectType.GOAL : ObjectType.USER}
              setRuleGroup={setRuleGroup}
            />
            <Button label={t('removeCondition')} sx={{ mt: 2 }} variant='tonal' size='sm' color='destructive' onClick={() => {
              setRuleGroup(undefined);
            }} />
          </>
        ) : (
          <Button label={t('addCondition')} onClick={() => {
            setRuleGroup({
              combinator: 'or',
              rules: [],
            });
          }} />
        )}
      </DialogContent>
      <DialogFooter>
        <Box display='flex' justifyContent='space-between' width='100%'>
        <Button label={t('deleteBranch')} onClick={removeBranch} color='destructive' variant='tonal' />
          <Button label={t('shared:save')} onClick={() => {
            updateBranch({
              ...localBranch,
              animated: ruleGroup,
              data: {
                ...localBranch.data,
                contextObject: localBranch.data.contextObject ?? null,
                condition: ruleGroup ? {
                  ruleGroup,
                } : undefined,
              },
            });
            setOpen(false);
          }} />
        </Box>
      </DialogFooter>
    </Dialog>
  );
};
