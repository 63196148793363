import { translateBackend } from '../../../assets/i18n/config';
import { Box, Typography } from '../../1-primative';
import { Divider } from '../../2-component/divider/divider';

export const DividerWithTitle = ({ options }: { options: any }) => (
  <Box mb={2}>
    <Divider>
    <Typography sx={{ textAlign: 'center' }} variant='headingSmall'>{translateBackend(options.label)}</Typography>
    </Divider>
  </Box>
);
